<nz-table #currentCustomers [nzData]="customersDisplay" [nzPageSize]="60">
  <thead>
  <tr>
    <th>Nombre</th>
    <th>Vehículo</th>
    <th>Teléfono</th>
    <th>Email</th>
    <th>Renta</th>
    <th>Inicio</th>
    <th>Dia de pago</th>
    <th>Acciones</th>

  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let data of currentCustomers.data">
    <td>{{ data.customer.firstName }} {{ data.customer.lastName }}</td>
    <td>{{ data.car.plate }}</td>
    <td>{{ data.customer.phone }}</td>
    <td>{{ data.customer.email }}</td>
    <td>{{ data.amount }}</td>
    <td>{{ data.startDate }}</td>
    <td [ngSwitch]="data.dueDay">
      <span *ngSwitchCase="'0'">Lunes</span>
      <span *ngSwitchCase="'1'">Martes</span>
      <span *ngSwitchCase="'2'">Miércoles</span>
      <span *ngSwitchCase="'3'">Jueves</span>
      <span *ngSwitchCase="'4'">Viernes</span>
      <span *ngSwitchCase="'5'">Sábado</span>
      <span *ngSwitchCase="'6'">Domingo</span></td>
    <td>
      <a nz-button nzType="primary" [routerLink]="'/billing/customer/' + data.customer.id">Open</a>
      <button nz-button nz-dropdown [nzDropdownMenu]="menu">
        Acciones
        <i nz-icon nzType="down"></i>
      </button>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
          <li nz-menu-item>
            <a>Editar Renta</a>
          </li>
          <li nz-menu-item>
            <a>Pausar Renta</a>
          </li>
          <li nz-menu-item>
            <a>Marcar Return</a>
          </li>
          <li nz-menu-item>
            <a>Marcar Repo</a>
          </li>
          <li nz-menu-item>
            <a>Marcar Switch</a>
          </li>
          <li nz-menu-item>
            <a>Marcar Robo</a>
          </li>
          <li nz-menu-item>
            <a>Convertir a LTO</a>
          </li>
        </ul>
      </nz-dropdown-menu>
    </td>
  </tr>
  </tbody>
</nz-table>
