import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomerApiService } from '../../../../infrastructure/api/customer-api.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-guarantor',
  templateUrl: './guarantor.component.html',
  styleUrls: ['./guarantor.component.css']
})
export class GuarantorComponent implements OnInit {
  @Input() customerId: string;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  guarantorForm: FormGroup;
  guarantorFormArray :any[] = [];
  isGuarantor: boolean = true;
  constructor(private customerApiService: CustomerApiService,
    private notificationService: NzNotificationService,
    private nzMessageService: NzMessageService,) { }

  ngOnInit(): void {
    this.fetchGuarantor();
  }

  addGuarantor(event: PointerEvent): void{
    this.guarantorForm = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      address: new FormControl(null, [Validators.required]),
      phone: new FormControl(null, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
      email: new FormControl(null, [Validators.required]) ,
      signatureDate: new FormControl(null, [Validators.required])
    })
    this.guarantorFormArray.push( { form: this.guarantorForm, id: undefined } )   
    this.isGuarantor = false;
  }
  saveGuarantor(form: FormGroup): void{
    const obj = {
      name: form.controls.name.value,
      address: form.controls.address.value,
      phone: form.controls.phone.value,
      email: form.controls.email.value,
      signatureDate: form.controls.signatureDate.value,
    }
    const [guarantor] = this.guarantorFormArray;
    if(guarantor.id === undefined){
      this.customerApiService.addGuarantor(this.customerId, obj).subscribe((res: any) => {
        this.createNotification('success', 'Success', 'Se añadió el fiador correctamente');
        this.closeModal.emit(true);
      }, (err: any) => {
        this.createNotification('error', 'Error', `${err.message}`);
      });
    }else{
      this.customerApiService.updateGuarantor(guarantor.id, obj).subscribe((res: any) => {
        this.createNotification('success', 'Success', 'Guarantor updated successfully');
        this.closeModal.emit(true);
      }, (err: any) => {
        this.createNotification('error', 'Error', `${err.message}`);
      });
    }
  }

  deleteGuarantor(i: number, guarantor): void{
    this.guarantorFormArray.splice(i, 1);
    this.isGuarantor = true;
    if(guarantor.id){
      this.customerApiService.deleteGuarantor(guarantor.id).subscribe((res: any) => {
        this.nzMessageService.info('La dirección fue eliminada');
        this.closeModal.emit(true);
      }, (err: any) => {
        this.createNotification('error', 'Error', `${err.message}`);
      });
    }
  }
  fetchGuarantor(): void{
    this.customerApiService.fetchGuarantor(this.customerId).subscribe((res: any) => {
      let [guarantor] = res;
      this.guarantorFormArray.push({form: new FormGroup({
        name: new FormControl(guarantor.name || null, [Validators.required]),
        address: new FormControl(guarantor.address || null, [Validators.required]),
        phone: new FormControl(guarantor.phone, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
        email: new FormControl(guarantor.email, [Validators.required]) ,
        signatureDate: new FormControl(guarantor.signatureDate, [Validators.required])
      }), id: guarantor.id});
      if( this.guarantorFormArray.length > 0 ) this.isGuarantor = false;
    }, (err: any) => {
    });
  }
  createNotification(type: string, title: string, message: string, duration = 7000): void {
    this.notificationService.create(
      type,
      title,
      message,
      { nzDuration: duration }
    );
  }

}
