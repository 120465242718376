<nz-card *ngIf="WeebFleetInformation" [nzLoading]="WeebFleetInformation ? false : true">
    <div *ngFor="let i of WeebFleetInformation">
        <!-- <p>created_at: {{i.created_at}}</p> -->
        <!-- <p><span style="font-weight: 600;">directión: </span> {{i.direction}}</p> -->
        <!-- <p><span style="font-weight: 600;"></span>lastmsgid: {{i.lastmsgid}}</p>
        <p><span style="font-weight: 600;"></span>latitude_microdeg: {{i.latitude_microdeg}}</p>
        <p><span style="font-weight: 600;"></span>longitude_microdeg: {{i.longitude_microdeg}}</p>
        <p><span style="font-weight: 600;"></span>objectuid: {{i.objectuid}}</p> -->
        <div nz-row>
            <div nz-col nzSpan="12">
                <p><span style="font-weight: 600;">Estado de encendido: </span> {{ i.ignition == 1 ? 'Encendido' :
                    'Apagado'}}</p>
                <p><span style="font-weight: 600;">Último estado de encendido: </span> {{i.ignition_time}}</p>
                <p><span style="font-weight: 600;">Odometro: </span> {{i.odometer}} Kms</p>
                <p><span style="font-weight: 600;">Placa: </span>{{i.plate}}</p>
                <p><span style="font-weight: 600;">Hora de última posición: </span> {{i.pos_time}}</p>
            </div>
            <div nz-col nzSpan="12">
                <p><span style="font-weight: 600;">Posición: </span> {{i.postext}}</p>
                <p><span style="font-weight: 600;">Velocidad: </span> {{i.speed < 2 ? i.speed + ' kms' : i.speed + ' km'
                        }}</p>
                        <p><span style="font-weight: 600;">Estado de movimiento: </span> {{i.standstill === 1 ? 'en
                            movimiento' : 'detenido'}}</p>
                        <p><span style="font-weight: 600;">Estado: </span>
                            {{ i.status === 'A' ? 'Posición de GPS actual disponible'
                            : i.status === 'V' ? 'Advertencia'
                            : i.status === 'L' ? 'last'
                            : i.status === 'O' ? 'Invalido' : ''}}
                        </p>
            </div>
        </div>
    </div>
</nz-card>