<button nz-button nzShape="round" nzType="primary" (click)="openFormModal()"><i nz-icon nzType="file-add" nzTheme="outline"></i> New Reservation</button>
<nz-modal
  nzTitle="Create New Reservation"
  [(nzVisible)]="isFormVisible"
  (nzOnCancel)="isFormVisible=false"
  (nzOnOk)="isFormVisible=false"
  [nzFooter]="null"
>
  <form [formGroup]="form" (ngSubmit)="onSubmitForm()">
    <nz-form-item>
      <nz-form-label [nzSpan]="9" nzRequired>Choose Product</nz-form-label>
      <nz-form-control nzErrorTip="Please choose a product" nzSpan="15">
        <nz-select nzSize="large" formControlName="chosenProductId" nzPlaceHolder="Choose product">
          <nz-option *ngFor="let p of products" [nzValue]="p.id" [nzLabel]="p.name"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSpan]="9" nzRequired>Choose Car to Reserve</nz-form-label>
      <nz-form-control nzErrorTip="Please choose an available car to reserve" nzSpan="15">
        <nz-select nzSize="large" formControlName="carReservedId" nzPlaceHolder="Choose car">
          <nz-option *ngFor="let c of availableCars" [nzValue]="c.id" [nzLabel]="c.plate + ' - ' + c.model"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-control [nzSpan]="12" [nzOffset]="6">
        <button type="submit" nz-button nzType="primary" nzBlock nzSize="large" >Create</button>
      </nz-form-control>
    </nz-form-item>

  </form>
</nz-modal>
