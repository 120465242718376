import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {RentalService} from "../../../../services/rental.service";
import {NotificationService} from "../../../../services/notification.service";

@Component({
  selector: 'app-edit-rental-form',
  templateUrl: './edit-rental-form.component.html',
  styles: [
  ]
})
export class EditRentalFormComponent implements OnInit {

  @Input() rental;
  @Output() formSubmitted: EventEmitter<any> = new EventEmitter<any>();
  form: FormGroup;
  constructor(
    private rentalService: RentalService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      rentalAmount: new FormControl(this.rental.rentalAmount, [Validators.required, Validators.min(0)]),
      paymentDay: new FormControl(this.rental.chargeDayofweek, [Validators.required]),
      ltoPayment: new FormControl(this.rental.ltoPayment),
      ltoTermLength: new FormControl(this.rental.ltoTermLength)
    });
  }
  onSubmit(): void {
    if (this.form.valid) {
      const request = {
        amount: this.form.controls.rentalAmount.value,
        paymentDay: this.form.controls.paymentDay.value,
        ltoPayment: this.form.controls.ltoPayment.value,
        ltoTermLength: this.form.controls.ltoTermLength.value
      };
      this.rentalService.editRental(request, this.rental.id).subscribe(
        (res) => {
          this.formSubmitted.emit(res);
          this.notificationService.createNotification('success', 'El cambio de renta exitoso', 'El cambio de renta ha sido completado con exito');
        }
      );
    }
  }
}
