import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
const endpoint = environment.onboarding_endpoint;
@Injectable({
  providedIn: 'root'
})
export class SendReferencesService {

  constructor(private http:HttpClient) { }
  sendTokuReference(tokuRecuest:any):Observable<any> {
    return this.http.post(endpoint+"sendWaCustomer/tokuReferences",tokuRecuest,{
      headers:{

        Authorization:`Bearer ${localStorage.getItem('token')}`
      }
    })
  }
}
