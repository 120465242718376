import { Component, OnInit } from '@angular/core';
import {RentalService} from "../../../services/rental.service";
import Rental from "../../../model/rental/Rental";

@Component({
  selector: 'app-active-customers',
  templateUrl: './active-customers.component.html',
  styles: [
  ]
})
export class ActiveCustomersComponent implements OnInit {
  searchText = '';
  rentals = [];
  rentalStatusSelected = 'ACTIVE';
  rentalSelected: Rental = {};
  isReturnFormVisible = false;
  isEditRentalFormVisible = false;
  constructor(private rentalService: RentalService) { }

  ngOnInit(): void {
    this.rentalService.fetchRentalsByStatus('ACTIVE').subscribe(
      (res: any[]) => {
        this.rentals = res;
      }
    );
  }
  searchCustomer(text: string): void {
    this.searchText = text;
    this.rentalService.fetchRentalsByStatusAndText(this.rentalStatusSelected, text).subscribe(
      (res: any[]) => {
        this.rentals = res;
      }
    );
  }
  rentalStatusChanged(): void {
    this.rentalService.fetchRentalsByStatusAndText(this.rentalStatusSelected, this.searchText).subscribe(
      (res: any[]) => {
        this.rentals = res;
      }
    );
  }
  openReturnForm(rental): void {
    this.isReturnFormVisible = true;
    this.rentalSelected = rental;
  }
  closeReturnForm(): void {
    this.isReturnFormVisible = false;
    this.rentalSelected = {};
    this.rentalStatusChanged();
  }
  openRentalEditForm(rental): void {
    this.isEditRentalFormVisible = true;
    this.rentalSelected = rental;
  }
  closeRentalEditForm(): void {
    this.isEditRentalFormVisible = false;
    this.rentalSelected = {};
    this.rentalStatusChanged();
  }
}
