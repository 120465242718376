<nz-page-header class="site-page-header">
  <nz-page-header-title>{{ customer?.firstName }} {{ customer?.lastName }}</nz-page-header-title>
  <nz-page-header-tags>
    <nz-tag [nzColor]="'purple'" *ngIf="activeRental?.leaseAmount">LTO Customer</nz-tag>
  </nz-page-header-tags>
  <nz-page-header-extra>
    <button nz-button *appRbacShow="['ADMIN']" (click)="openUpdateCustomer()">Editar conductor</button>
    <button nz-button *appRbacShow="['ADMIN']"><a target="_blank" [href]="url">Generar estado de cuenta</a></button>
    <button nz-button *appRbacShow="['ADMIN']" (click)="isCreateNewPaymentFormVisible = true">Crear Pago</button>
    <button nz-button *appRbacShow="['ADMIN']" nzType="primary" (click)="this.isCreateNewChargeFormVisible = true">Crear
      Cargo</button>
  </nz-page-header-extra>
  <nz-page-header-content>
    <div class="content">
      <div class="main">
        <nz-descriptions nzSize="small" [nzColumn]="2">
          <nz-descriptions-item nzTitle="Nave Lead Id" [nzSpan]="1">{{ customer?.oraculeBosId }}</nz-descriptions-item>
          <nz-descriptions-item nzTitle="Nave Customer Id" [nzSpan]="1">D{{ customer?.customerBosId
            }}</nz-descriptions-item>
          <nz-descriptions-item nzTitle="Id" [nzSpan]="1">{{ customerId }}</nz-descriptions-item>
          <nz-descriptions-item nzTitle="Email" [nzSpan]="1">{{ customer?.email }}</nz-descriptions-item>
          <nz-descriptions-item nzTitle="Email de facturación" [nzSpan]="1">{{ customer?.billingEmail
            }}</nz-descriptions-item>
          <nz-descriptions-item nzTitle="Teléfono" [nzSpan]="1"><a>{{ customer?.phone }}</a></nz-descriptions-item>
          <nz-descriptions-item nzTitle="Teléfono de Emergencia" [nzSpan]="1">...</nz-descriptions-item>
          <nz-descriptions-item nzTitle="Fecha de Nacimiento" [nzSpan]="1">{{ customer?.dateOfBirth
            }}</nz-descriptions-item>
          <nz-descriptions-item nzTitle="CURP" [nzSpan]="1">{{ customer?.curp }}</nz-descriptions-item>
          <nz-descriptions-item nzTitle="RFC" [nzSpan]="1">{{ customer?.rfc }}</nz-descriptions-item>
          <nz-descriptions-item nzTitle="Licencia" [nzSpan]="1">{{ customer?.license }}</nz-descriptions-item>
          <nz-descriptions-item nzTitle="Régimen fiscal" [nzSpan]="1" [ngSwitch]="customer? customer.taxRegime: ''">
            <span *ngSwitchCase="'625'">Régimen de las Actividades Empresariales con ingresos a través de Plataformas
              Tecnológicas</span>
            <span *ngSwitchCase="'605'">Sueldos y Salarios e Ingresos Asimilados a Salarios</span>
            <span *ngSwitchCase="'612'">Personas Físicas con Actividades Empresariales y Profesionales</span>
            <span *ngSwitchCase="'606'">Arrendamiento</span>
            <span *ngSwitchCase="'608'">Demás ingresos</span>
            <span *ngSwitchCase="'621'">Incorporación Fiscal</span>
            <span *ngSwitchCase="'610'">Residentes en el Extranjero sin Establecimiento Permanente en México</span>
            <span *ngSwitchCase="'611'">Ingresos por Dividendos (socios y accionistas)</span>
            <span *ngSwitchCase="'614'">Ingresos por intereses</span>
            <span *ngSwitchCase="'616'">Sin obligaciones fiscales</span>
            <span *ngSwitchCase="'622'">Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</span>
            <span *ngSwitchCase="'629'">De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales</span>
            <span *ngSwitchCase="'630'">Enajenación de acciones en bolsa de valores</span>
            <span *ngSwitchCase="'615'">Régimen de los ingresos por obtención de premios</span>
            <span *ngSwitchCase="'626'">Régimen Simplificado de Confianza</span>
            <span *ngSwitchDefault></span>
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Uso del CFDI" [nzSpan]="1" [ngSwitch]="customer? customer.cfdiUse: ''">
            <span *ngSwitchCase="'G01'">Adquisición de mercancías</span>
            <span *ngSwitchCase="'G02'">Devoluciones, descuentos o bonificaciones</span>
            <span *ngSwitchCase="'G03'">Gastos en general </span>
            <span *ngSwitchCase="'I01'">Construcciones</span>
            <span *ngSwitchCase="'I02'">Mobiliario y equipo de oficina por inversiones</span>
            <span *ngSwitchCase="'I03'">Equipo de transporte</span>
            <span *ngSwitchCase="'I04'">Equipo de cómputo y accesorios</span>
            <span *ngSwitchCase="'I05'">Dados, troqueles, moldes, matrices y herramental</span>
            <span *ngSwitchCase="'I06'">Comunicaciones telefónicas</span>
            <span *ngSwitchCase="'I07'">Comunicaciones satelitales</span>
            <span *ngSwitchCase="'I08'">Otra maquinaria y equipo</span>
            <span *ngSwitchCase="'D01'">Honorarios médicos, dentales y gastos hospitalarios.</span>
            <span *ngSwitchCase="'D02'">Gastos médicos por incapacidad o discapacidad</span>
            <span *ngSwitchCase="'D03'">Gastos funerales</span>
            <span *ngSwitchCase="'D04'">Donativos</span>
            <span *ngSwitchCase="'D05'">Intereses reales efectivamente pagados por créditos hipotecarios (casa
              habitación).</span>
            <span *ngSwitchCase="'D06'">Aportaciones voluntarias al SAR.</span>
            <span *ngSwitchCase="'D07'">Primas por seguros de gastos médicos.</span>
            <span *ngSwitchCase="'D08'">Gastos de transportación escolar obligatoria.</span>
            <span *ngSwitchCase="'D09'">Depósitos en cuentas para el ahorro, primas que tengan como base planes de
              pensiones.</span>
            <span *ngSwitchCase="'D10'">Pagos por servicios educativos (colegiaturas)</span>
            <span *ngSwitchCase="'CP01'">Pagos</span>
            <span *ngSwitchCase="'CN01'">Nómina</span>
            <span *ngSwitchCase="'S01'">Sin Efectos Fiscales</span>
            <span *ngSwitchDefault></span>
          </nz-descriptions-item>
          <nz-descriptions-item *ngIf="this.isCustomerActive"
            nzTitle="Días activo">{{this.timeActive}}</nz-descriptions-item>
          <nz-descriptions-item *ngIf="this.isCustomerActive"
            nzTitle="Semanas activo">{{this.weeksActive}}</nz-descriptions-item>
          <nz-descriptions-item nzTitle="Documentos en Google Drive" [nzSpan]="1"><a
              href="https://drive.google.com/drive/u/0/folders/{{customer?.folderId}}" target="_blank">Ver
              documentos</a></nz-descriptions-item>
          <nz-descriptions-item *appRbacShow="['ADMIN']" nzTitle="Palenca Link" [nzSpan]="1"><a [href]="palencaLink"
              target="_blank">Ver
              Link</a></nz-descriptions-item>
          <nz-descriptions-item nzTitle="Estatus Facturación"  [nzSpan]="1">
            <div style="position: relative;top: -3px;">
              <span nz-icon [nzType]="'check-circle'" *ngIf="isTaxable" [nzTheme]="'twotone'"
                [nzTwotoneColor]="'#52c41a'"></span>

              <span style="cursor: pointer;" nz-icon [nzType]="'close-circle'" nz-tooltip
                [nzTooltipTitle]="this.invoiceError" [nzTooltipColor]="'#FF2D00'" *ngIf="!isTaxable"
                [nzTheme]="'twotone'" [nzTwotoneColor]="'#FF2D00'"></span>
            </div>
            <div style="position: relative;top: -3px;left: 25px;">
              <span nz-icon [nzType]="'sync'" *ngIf="invoiceStatusLoading" [nzSpin]="true"></span>
              <span nz-icon [nzType]="'reload'" *ngIf="!invoiceStatusLoading" style="cursor: pointer;"
                (click)="validateInvoiceStatus(customer.id)"></span>
            </div>
            <div style="position: relative; left: 20px;"> 
              <nz-page-header-tags>
                <nz-tag [nzColor]="'red'" *ngIf="(customer?.ocr === null) || (customer?.ocr === false)"> Sin documento OCR</nz-tag>
              </nz-page-header-tags>
              <nz-page-header-tags>
                <nz-tag [nzColor]="'green'" *ngIf="customer?.ocr===true">Documento OCR</nz-tag>
              </nz-page-header-tags>
            </div>
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Riesgo De Impago">{{ nonPaymenRisk ===
            "LOW"?"Bajo": nonPaymenRisk === "MEDIUM"?"Medio": nonPaymenRisk === "MEDIUMHIGH"?"Medio Alto": nonPaymenRisk
            === "HIGH"?"Alto":""}}</nz-descriptions-item>
          <nz-descriptions-item *ngIf="this.isCustomerActive" nzTitle="Riesgo De Entrevista">{{ interviewRisk ===
            "LOW"?"Bajo": interviewRisk === "MEDIUM"?"Medio": interviewRisk === "MEDIUMHIGH"?"Medio Alto": interviewRisk
            === "HIGH"?"Alto":""}}</nz-descriptions-item>
          <nz-descriptions-item nzTitle="Factura como" [nzSpan]="1">

            <nz-page-header-tags>
              <nz-tag [nzColor]="'red'" *ngIf="customer?.blacklistSat===null ">No hay información</nz-tag>
            </nz-page-header-tags>

            <nz-page-header-tags>
              <nz-tag [nzColor]="'green'" *ngIf="customer?.blacklistSat===false">Persona física</nz-tag>
            </nz-page-header-tags>

            <nz-page-header-tags>
              <nz-tag [nzColor]="'blue'" *ngIf="customer?.blacklistSat">Publico en general</nz-tag>
            </nz-page-header-tags>
          </nz-descriptions-item>
        </nz-descriptions>
      </div>

      <div class="extra" style="display: flex;justify-content: flex-end; margin:0px 0px 20px 0px;">
        <button nz-button nzSpan="2" (click)="createShareResource()" style="margin:10px 10px 10px 10px;">Compartir
          cliente
          por link
        </button>
        <button nz-button nzType="danger" nzSpan="2" (click)="changeStatusBlacklistSat()"
          style="margin:10px 10px 10px 10px;">Facturar como {{customer?.blacklistSat?'persona física':'publico en
          general'}}
        </button>
        <div></div>
        <div></div>
      </div>
      <div class="extra" style="margin-top: 20px;">
        <div nz-row>
          <nz-collapse nz-col [nzSpan]="24" *ngIf="panels.length > 0">
            <nz-collapse-panel style="font-weight: 600;" *ngFor="let panel of panels" [nzHeader]="panel.name"
              [nzActive]="panel.active" [nzDisabled]="panel.disabled">
              <div style="margin:0; width: 100%;" *ngIf="panel.name === 'Direcciones'">
                <div>
                  <app-addresses [data]="addresses"></app-addresses>
                </div>
              </div>
              <div style="margin:0; width: 100%;" *ngIf="panel.name === 'Contratos'">
                <div nz-row [nzGutter]="[12, 12]" *ngIf="customerAgreements">
                  <div nz-col nzSpan="12" *ngFor="let a of customerAgreements; let i = index;">
                    <nz-card>
                      <nz-card-meta [nzTitle]="'Contrato'"
                        [nzDescription]="a.is_lto ? 'LTO' : 'Renta Normal'"></nz-card-meta>
                      <div nz-row style="margin-top: 20px;">
                        <div nz-col [nzSpan]="12">
                          <p><span style="font-weight: 600;">Numero de contrato Mi Nave:
                              {{a.rental_agreement_id}}</span>
                          </p>
                          <p><span style="font-weight: 600;">Monto de la Renta: $ {{a.amount}}</span></p>
                          <p><span style="font-weight: 600;">Inicio del contrato: {{convertDate(a.start_date) }}</span>
                          </p>
                          <p><span style="font-weight: 600;">Fin del contrato: {{convertDate(a.end_date) }}</span></p>
                          <p><span style="font-weight: 600;">Status: {{a.status}}</span></p>
                        </div>
                      </div>
                    </nz-card>
                  </div>
                </div>
              </div>
              <div style="margin:0; width: 100%;" *ngIf="panel.name === 'Vehículo'">
                <div>
                  <app-car-information [carInformation]="CarInformation"></app-car-information>
                </div>
              </div>
              <div style="margin:0; width: 100%;" *ngIf="panel.name === 'WebFleet'">
                <div>
                  <app-web-fleet [WeebFleetInformation]="WeebFleetInformation"></app-web-fleet>
                </div>
              </div>
              <div style="margin:0;" *ngIf="panel.name === 'Palenca'">
                <app-palenca [data]="palencaInformation"></app-palenca>
              </div>
              <div style="margin:0;" *ngIf="panel.name === 'Teléfonos'">
                <div nz-row [nzGutter]="[12, 12]">
                  <div nz-col nzSpan="12" *ngFor="let phone of customerPhones; let i = index;">
                    <nz-card>
                      <nz-card-meta [nzTitle]="'Teléfono'+ ' ' + (i + 1) "></nz-card-meta>
                      <div nz-row style="margin-top: 20px;">
                        <div nz-col [nzSpan]="12">
                          <p>Número: <span style="font-weight: 100;">{{phone.phone}}</span></p>
                          <p>Tipo de teléfono: <span style="font-weight: 100;">{{phone.phone_type_id.type}}</span></p>
                        </div>
                      </div>
                    </nz-card>
                  </div>
                </div>
              </div>
              <div style="margin:0;" *ngIf="panel.name === 'Contratos Digitales'">
                <div nz-row [nzGutter]="[12, 12]">
                  <div nz-col nzSpan="12" *ngFor="let agreements of customerDigitalAgreements; let i = index;">
                    <nz-card>
                      <nz-card-meta [nzTitle]="'Contrato:'+ ' ' + agreements.file_file_name "></nz-card-meta>
                      <div nz-row style="margin-top: 20px;">
                        <div nz-col [nzSpan]="12">
                          <p>Id: <span style="font-weight: 100;">{{agreements.id}}</span></p>
                          <p>Numero de contrato de renta Mi Nave: <span
                              style="font-weight: 100;">{{agreements.rental_agreement_id}}</span></p>
                          <p>Creado: <span
                              style="font-weight: 100;">{{convertToLocalDate(agreements.created_at)}}</span></p>
                          <p>Estado: <span style="font-weight: 100;">{{agreements.state}}</span></p>
                          <a target="_blank" [href]="agreements.miFielUrl">Ver
                            en Mifiel</a>
                        </div>
                      </div>
                    </nz-card>
                  </div>
                </div>
              </div>
            </nz-collapse-panel>
          </nz-collapse>
        </div>
      </div>
    </div>
  </nz-page-header-content>
</nz-page-header>


<!-- <div> <iframe [src]="palencaWidget" style="width: 1200px; border: none; height: 700px; overflow: scroll;"></iframe>
</div> -->
<nz-card nzTitle="">
  <div nz-card-grid [ngStyle]="gridStyle">
    <nz-statistic nzTitle="Renta Semanal" [nzValue]="activeRental.rentalAmount? activeRental.rentalAmount: 'NO_ACTIVE'"
      nzPrefix="$" [nzValueStyle]="{ color: 'green' }"></nz-statistic>
  </div>
  <div nz-card-grid [ngStyle]="gridStyle">
    <nz-statistic nzTitle="Balance" [nzValue]="this.balance.toFixed(2)" *ngIf="balance <= 0" nzPrefix="$"
      [nzValueStyle]="{ color: 'green' }"></nz-statistic>
    <nz-statistic nzTitle="Balance" [nzValue]="this.balance.toFixed(2)" *ngIf="balance > 0" nzPrefix="$"
      [nzValueStyle]="{ color: 'red' }"></nz-statistic>
  </div>
  <div nz-card-grid [ngStyle]="gridStyle">
    <nz-statistic nzTitle="Security Deposit" [nzValue]="securityDepositAccountBalance" nzPrefix="$"></nz-statistic>
  </div>
</nz-card>
<nz-divider></nz-divider>
<nz-tabset nzSize="large" [nzCentered]="true">
  <nz-tab nzTitle="Documentos" *appRbacShow="['ADMIN']">
    <div nz-row>
      <ng-container *ngFor="let document of documents">
        <div nz-col nzSpan="6" *ngFor="let file of document.files">
          <nz-card style="width:300px;" [nzBordered]="false" [nzTitle]="document.documentType.typeName">
            <img *ngIf="file.extension === 'image/png' || file.extension === 'image/jpeg'" nz-image width="200px"
              height="250px" [nzSrc]="this.buildEnpointForImages(file.id)" alt="" />

            <iframe *ngIf="file.extension === 'application/pdf'" style="width: 100%; height: 400px;"
              [src]="this.buildEnpointForPdf(file.id)">
            </iframe>
          </nz-card>
        </div>
      </ng-container>
    </div>
  </nz-tab>
  <nz-tab nzTitle="Transacciones" *appRbacShow="['ADMIN']">
    <br />
    <nz-table #transactionsTable [nzData]="transactions" nzPageSize="25">
      <thead>
        <tr>
          <td>Tipo</td>
          <td>Monto</td>
          <td>Fecha</td>
          <td>Balance</td>
          <td>Semana</td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let t of transactionsTable.data; let i = index">
          <td *ngIf="t.transactionType === 'PAYMENT'" style="font-weight: bold; color: green;">{{ t.transactionName }}
          </td>
          <td *ngIf="t.transactionType === 'CHARGE'" style="font-weight: bold; color: red;">{{ t.transactionName }}</td>
          <td *ngIf="t.transactionType === 'PAYMENT'" style="font-weight: bold; color: green;">{{t.transactionAmount}}
          </td>
          <td *ngIf="t.transactionType === 'CHARGE'" style="font-weight: bold; color: red;">{{t.transactionAmount}}</td>
          <td style="font-weight: 600;">{{t.transactionDueDate}}</td>
          <td style="font-weight: 600;">{{ this.tempBalances[t.transactionId] }}</td>
          <td style="font-weight: 600;">{{t.weekOfDate}}</td>
          <td>
            <button
              *ngIf="t.transactionType === 'CHARGE' && daysDiff(t.transactionDueDate) > -300 && daysDiff(t.transactionDueDate) < 2"
              nz-button (click)="openEditChargeForm(t)">Editar</button>
            <button
              *ngIf="t.transactionType === 'PAYMENT' && daysDiff(t.transactionDueDate) > -180 && daysDiff(t.transactionDueDate) < 2 && paymentMethodsAllowedToEdit.includes(t.transactionCode)"
              nz-button (click)="openEditPaymentForm(t)">Editar</button>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </nz-tab>
  <nz-tab nzTitle="Estado de cuenta" *appRbacShow="['ADMIN']">
    <h4 *ngIf="billingStatement?.getOverDueTotal() > 0">Cargos pendientes de pagar</h4>
    <br />
    <app-charge-payment-reference-table-view *ngIf="billingStatement?.getOverDueTotal() > 0"
      [charges]="billingStatement.getOverDueCharges()"
      [mapChargesTransactions]="billingStatement.getChargesPaymentsMap()"></app-charge-payment-reference-table-view>
    <h4 *ngIf="billingStatement?.getUnappliedPaymentsTotal() < 0">Pagos sin asignar</h4>
    <br />
    <app-unapplied-payments-table-view *ngIf="billingStatement?.getUnappliedPaymentsTotal() < 0"
      [payments]="billingStatement.getUnappliedPayments()" [mapPayments]="billingStatement.getPaymentsChargesMap()">
    </app-unapplied-payments-table-view>
    <h4>Histórico</h4>
    <br />
    <app-charge-payment-reference-table-view [charges]="billingStatement?.charges"
      [mapChargesTransactions]="billingStatement?.getChargesPaymentsMap()"></app-charge-payment-reference-table-view>
  </nz-tab>
</nz-tabset>
<nz-modal [nzWidth]="800" [nzMaskClosable]="false" [(nzVisible)]="isCreateNewChargeFormVisible" nzTitle="Nuevo Cargo"
  (nzOnCancel)="isCreateNewChargeFormVisible=false" (nzOnOk)="isCreateNewChargeFormVisible=false" [nzFooter]="null">
  <ng-container *nzModalContent>
    <app-new-charge-form [chargeTypes]="chargeTypes" (formSubmitted)="createNewCharge($event)"
      [customerId]="this.customerId"></app-new-charge-form>
  </ng-container>
</nz-modal>
<nz-modal [nzWidth]="800" [nzMaskClosable]="false" [(nzVisible)]="isCreateNewPaymentFormVisible" nzTitle="Nuevo Pago"
  (nzOnCancel)="isCreateNewPaymentFormVisible=false" (nzOnOk)="isCreateNewPaymentFormVisible=false" [nzFooter]="null">
  <ng-container *nzModalContent>
    <app-new-payment-form [paymentMethods]="paymentMethods" (formSubmitted)="createNewPayment($event)"
      [customerId]="this.customerId"></app-new-payment-form>
  </ng-container>
</nz-modal>
<nz-modal [nzWidth]="800" [nzMaskClosable]="false" [(nzVisible)]="isEditChargeFormVisible" nzTitle="Editar Cargo"
  (nzOnCancel)="isEditChargeFormVisible=false" (nzOnOk)="isEditChargeFormVisible=false" [nzFooter]="null">
  <ng-container *nzModalContent>
    <app-edit-charge-form [chargeTypes]="chargeTypes" [transaction]="editableChargeSelected"
      (formSubmitted)="editChargeAppliedSuccess()"></app-edit-charge-form>
  </ng-container>
</nz-modal>
<nz-modal [nzWidth]="800" [nzMaskClosable]="false" [(nzVisible)]="isEditPaymentFormVisible" nzTitle="Editar Pago"
  (nzOnCancel)="isEditPaymentFormVisible=false" (nzOnOk)="isEditPaymentFormVisible=false" [nzFooter]="null">
  <ng-container *nzModalContent>
    <app-edit-payment-form [transaction]="editablePaymentSelected" (formSubmitted)="editPaymentAppliedSuccess()">
    </app-edit-payment-form>
  </ng-container>
</nz-modal>
<nz-modal [nzWidth]="850" [(nzVisible)]="isUpdateCustomerFormVisible" nzTitle="Actualizar Conductor"
  (nzOnCancel)="isUpdateCustomerFormVisible=false" (nzOnOk)="isUpdateCustomerFormVisible=false" [nzFooter]="null">
  <ng-container *nzModalContent>
    <app-update-customer-information-form [customer]="this.customer" (formSubmitted)="this.updateCustomerDetail($event)"
      (closeUpdateCustomerForm)="closeUpdateCustomer($event)"
      (addressFormChanges)="addressChanges($event)"></app-update-customer-information-form>
  </ng-container>
</nz-modal>