import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from "rxjs";

const endpoint = environment.fleet_endpoint;
const endpointCore = environment.core_endpoint;

@Injectable({
  providedIn: 'root'
})
export class CarService {
  constructor(private http: HttpClient) { }
  fetchCarsByStatus(status: string): Observable<any[]> {
    return this.http.get<any[]>(endpoint + `cars?status=${status}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  createCar(carRequest: any): Observable<any> {
    return this.http.post(`${endpointCore}cars`, carRequest, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
}


