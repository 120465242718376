import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzUploadChangeParam} from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-upload-document-form',
  templateUrl: './upload-document-form.component.html',
  styles: [
  ]
})
export class UploadDocumentFormComponent implements OnInit {
  @Output() dataSubmitted: EventEmitter<any> = new EventEmitter<any>();
  @Output() fileUploaded: EventEmitter<any> = new EventEmitter<any>();
  @Input() documentTypes: any[] = [];
  @Input() documentId: string;
  validateForm!: FormGroup;
  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    this.dataSubmitted.emit(this.validateForm.getRawValue());
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      userName: [null, [Validators.required]],
      password: [null, [Validators.required]],
      documentType: [null, [Validators.required]],
    });
  }

  handleChange({ file, fileList }: NzUploadChangeParam): void {
    const status = file.status;
    if (status !== 'uploading') {
      console.log('Status is: ', status);
      console.log(file, fileList);
    }
    if (status === 'done') {
      console.log('HandleChange Done');
      this.fileUploaded.emit(file);
      //this.msg.success(`${file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      console.log('HandleChange Error');
      //this.msg.error(`${file.name} file upload failed.`);
    }
  }

}
