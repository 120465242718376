<form [formGroup]="newChargeForm" (ngSubmit)="onSubmit()">
  <nz-form-item>
    <nz-form-label [nzSpan]="9">Tipo de Cargo</nz-form-label>
    <nz-form-control nzErrorTip="Por favor ingresa alguna nota" nzSpan="15">
      <nz-select nzSize="large" formControlName="chargeType" nzPlaceHolder="Selecciona el tipo de cargo">
        <nz-option *ngFor="let charge of chargeTypes" [nzValue]="charge.id" [nzLabel]="charge.name"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="9" nzRequired>Amount</nz-form-label>
    <nz-form-control nzErrorTip="Por favor ingresa el monto" nzSpan="15">
      <input type="number" min="0" nz-input placeholder="Monto" formControlName="amount" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="9" nzRequired>Fecha</nz-form-label>
    <nz-form-control nzErrorTip="" nzSpan="15">
      <input nz-input type="date" formControlName="dueDate" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="9">Notas</nz-form-label>
    <nz-form-control nzErrorTip="Por favor ingresa alguna nota" nzSpan="15">
      <textarea nz-input placeholder="Notas" formControlName="notes"></textarea>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control [nzSpan]="12" [nzOffset]="6">
      <button type="submit" nz-button nzType="primary" nzBlock nzSize="large" >Enviar</button>
    </nz-form-control>
  </nz-form-item>
</form>
