<nz-table
  #openApplications
  [nzData]="applications"
  [nzPageSize]="pageSize"
  [nzPageIndex]="pageIndex"
  (nzQueryParams)="onQueryParamsChange($event)"
  [nzTotal]="10000"
  [nzFrontPagination]="false"
  [nzScroll]="{ y: '1200px' }"
>
  <thead>
  <tr>
    <th>Name</th>
    <th>Phone</th>
    <th>Email</th>
    <th>Source</th>
    <th>Date Applied</th>
    <th>Actions</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let data of openApplications.data">
    <td>{{ data.customer.firstName }} {{ data.customer.lastName }}</td>
    <td>{{ data.customer.phone }}</td>
    <td>{{ data.customer.email }}</td>
    <td>{{ data.source }}</td>
    <td>{{ date.unix(data.dateApplied).format('dddd, MMMM Do, YYYY') }}</td>
    <td>
      <nz-space nzDirection="horizontal">
        <nz-space-item>
          <a nz-button nzType="primary" [routerLink]="'/billing/customer/'+data.customer.id">Open</a>
        </nz-space-item>
        <nz-space-item>
          <button nz-button nzType="default" (click)="quickViewButtonClicked(data)">Quick View</button>
        </nz-space-item>
      </nz-space>
    </td>
  </tr>
  </tbody>
</nz-table>
