import { Component, DoCheck, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import EditRentalAgreement from '../../../../domain/api/core/agreements/requests/EditRentalAgreement';
import IRentalAgreement from '../../../../domain/model/agreements/IRentalAgreement';
@Component({
  selector: 'app-edit-rental-agreement',
  templateUrl: './edit-rental-agreement.component.html',
  styleUrls: ['./edit-rental-agreement.component.css']
})
export class EditRentalAgreementComponent implements OnInit {
  @Input() modalOpen = false;
  @Output() modalClosed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() rental:IRentalAgreement;
  @Output() formSubmitted:EventEmitter<EditRentalAgreement> = new EventEmitter<EditRentalAgreement>();
  form: FormGroup;
  days: string[];
  isOpen:boolean = true;
  displayStyle:string = "none";
  constructor() {
    this.form = new FormGroup({
      amount: new FormControl('', [Validators.required]),
      startDate: new FormControl(Date, [Validators.required]),
      dueDay: new FormControl('',[Validators.required]),
      firstPaymentDate: new FormControl('',[Validators.required]),
      firstPaymentAmount: new FormControl('',[Validators.required]),
      platformsToDrive: new FormControl('',[Validators.required]),
      carModelCode: new FormControl('',[Validators.required]),
      tagNumber: new FormControl(0,[Validators.required]),
    });
    this.days = [
      'Lunes',
      'Martes',
      'Miércoles',
      'Jueves',
      'Viernes',
      'Sábado',
      'Domingo',
    ]
  }
  ngOnInit(): void {
  }
  closeModal(): void {
    this.modalOpen = false;
    this.modalClosed.emit(this.modalOpen);
  }

}
