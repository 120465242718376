import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NzListModule} from 'ng-zorro-antd/list';
import {NzCardModule} from 'ng-zorro-antd/card';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzTagModule} from 'ng-zorro-antd/tag';
import {NzDescriptionsModule} from 'ng-zorro-antd/descriptions';
import {NzTableModule} from "ng-zorro-antd/table";
import { OnboardingViewComponent } from './applications/onboarding-view/onboarding-view.component';
import { ReservationsTableComponent } from './reservations/reservations-table/reservations-table.component';
import {ApplicationsOnboardingTableComponent} from "./applications/applications-onboarding-table/applications-onboarding-table.component";
import {NzPageHeaderModule} from "ng-zorro-antd/page-header";
import {NzDividerModule} from "ng-zorro-antd/divider";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NzRadioModule} from "ng-zorro-antd/radio";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzSpaceModule} from "ng-zorro-antd/space";
import {NzBadgeModule} from "ng-zorro-antd/badge";
import { CloseReservationModalButtonComponent } from './reservations/button-actions/close-reservation-modal-button/close-reservation-modal-button.component';
import { UpdateReservationModalButtonComponent } from './reservations/button-actions/update-reservation-modal-button/update-reservation-modal-button.component';
import { CancelReservationModalButtonComponent } from './reservations/button-actions/cancel-reservation-modal-button/cancel-reservation-modal-button.component';
import {NzModalModule} from "ng-zorro-antd/modal";
import {NzInputModule} from "ng-zorro-antd/input";
import {NzFormModule} from "ng-zorro-antd/form";
import { CreateReservationModalButtonComponent } from './reservations/button-actions/create-reservation-modal-button/create-reservation-modal-button.component';
import {NzSelectModule} from "ng-zorro-antd/select";
import {NzToolTipModule} from "ng-zorro-antd/tooltip";
import {NaveComponentsModule} from "../components/nave-components.module";


@NgModule({
  declarations: [OnboardingViewComponent, ReservationsTableComponent, ApplicationsOnboardingTableComponent, CloseReservationModalButtonComponent, UpdateReservationModalButtonComponent, CancelReservationModalButtonComponent, CreateReservationModalButtonComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzListModule,
    NzCardModule,
    NzGridModule,
    NzIconModule,
    NzTagModule,
    NzDescriptionsModule,
    NzTableModule,
    NzPageHeaderModule,
    NzDividerModule,
    NzRadioModule,
    NzButtonModule,
    NzSpaceModule,
    NzBadgeModule,
    NzModalModule,
    NzInputModule,
    NzFormModule,
    NzSelectModule,
    NzToolTipModule,
    NaveComponentsModule,
  ],
  exports: [
    CreateReservationModalButtonComponent,
    ReservationsTableComponent
  ]
})
export class CrmModule { }
