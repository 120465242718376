import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import CreatePaymentLinkRequest from '../../../../model/billing/request/CreatePaymentLinkRequest';

@Component({
  selector: 'app-new-payment-link-form',
  templateUrl: './new-payment-link-form.component.html',
  styles: [
  ]
})
export class NewPaymentLinkFormComponent implements OnInit {
  @Output() formSubmitted: EventEmitter<CreatePaymentLinkRequest> = new EventEmitter<CreatePaymentLinkRequest>();
  form: FormGroup;
  constructor() { }

  ngOnInit(): void {
    this.form = new FormGroup({
      amount: new FormControl(null, [Validators.required, Validators.min(0)]),
      allowedPaymentMethods: new FormControl(null, [Validators.required]),
      daysToExpire: new FormControl(null, [Validators.min(3), Validators.required]),
      linkReason: new FormControl(null, [Validators.required]),
      requiresEmailNotifications: new FormControl(null, [Validators.required]),
    });
  }
  onSubmit(): void {
    if (this.form.valid) {
      this.formSubmitted.emit({
        amount: this.form.controls.amount.value,
        allowedPaymentMethods: this.form.controls.allowedPaymentMethods.value,
        daysToExpire: this.form.controls.daysToExpire.value,
        linkReasonCode: this.form.controls.linkReason.value,
        requiresEmailNotification: this.form.controls.requiresEmailNotifications.value === '1'
      });
    }
    else {
      console.log(this.form.errors);
    }
  }
}
