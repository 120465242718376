import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { tap } from 'rxjs/operators';
import {environment} from '../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';

const endpoint = environment.onboarding_endpoint;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient,
              private jwtHelper: JwtHelperService) {
  }

  login(loginData): Observable<any> {
    return this.http.post(endpoint + 'auth/signin', loginData)
      .pipe(
        tap((resp: any) => {          
          localStorage.setItem('roles', JSON.stringify(resp['roles']));
          localStorage.setItem('token', resp.token);
          localStorage.setItem('userLoggedId', resp.userId);
        })
      );
  }
  validateToken(): boolean {
    const token = localStorage.getItem('token') || '';
    if (token === '') {
      return false;
    } else {
      return !this.jwtHelper.isTokenExpired(token);
    }
  }

}


