<form [formGroup]="form" (ngSubmit)="onSubmit()" nz-form>
  <nz-form-item>
    <nz-form-label [nzSpan]="9" nzRequired>Fecha de Return</nz-form-label>
    <nz-form-control nzErrorTip="Por favor ingresa la fecha en la que se hizo el return" nzSpan="15">
      <nz-date-picker nzSize="large" nzFormat="yyyy/MM/dd" formControlName="returnDate" [nzDisabledDate]="disabledDate"></nz-date-picker>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="9" nzRequired>Razón</nz-form-label>
    <nz-form-control nzErrorTip="Por favor selecciona una razón" nzSpan="15">
      <nz-select nzSize="large" formControlName="returnReason" nzPlaceHolder="Selecciona una razón">
        <nz-option *ngFor="let r of returnReasons" [nzValue]="r.id" [nzLabel]="r.name"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="9" nzRequired>¿Vehículo disponible para renta?</nz-form-label>
    <nz-form-control nzErrorTip="Por favor indica si el vehiculo esta en condiciones de renta" nzSpan="15">
      <nz-radio-group nzSize="large" formControlName="driveable" nzButtonStyle="solid">
        <label nz-radio-button [nzValue]="1">Si</label>
        <label nz-radio-button [nzValue]="0">No</label>
      </nz-radio-group>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="9">Notas</nz-form-label>
    <nz-form-control nzErrorTip="" nzSpan="15">
      <textarea formControlName="note" nzSize="large" nz-input></textarea>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control [nzSpan]="12" [nzOffset]="6">
      <button type="submit" nz-button nzType="primary" nzBlock nzSize="large" >Enviar</button>
    </nz-form-control>
  </nz-form-item>
</form>
