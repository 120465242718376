import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Customer} from "../../../../model/onboarding/Customer";
import ReturnReason from "../../../../model/rental/ReturnReason";
import {RentalService} from "../../../../services/rental.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import Rental from "../../../../model/rental/Rental";
import ReturnRequest from "../../../../model/rental/request/ReturnRequest";
import {NzNotificationService} from "ng-zorro-antd/notification";

@Component({
  selector: 'app-return-car-form',
  templateUrl: './return-car-form.component.html',
  styles: [
  ]
})
export class ReturnCarFormComponent implements OnInit {
  @Input() customer: Customer;
  @Input() rental: Rental;
  @Output() formSubmitted: EventEmitter<ReturnRequest> = new EventEmitter<ReturnRequest>();
  returnReasons: ReturnReason[] = [];
  form: FormGroup;
  today = new Date();
  constructor(
    private rentalService: RentalService,
    private notificationService: NzNotificationService
  ) { }
  ngOnInit(): void {
    this.form = new FormGroup({
      returnDate: new FormControl(null, [Validators.required]),
      note: new FormControl(),
      returnReason: new FormControl(null, [Validators.required]),
      driveable: new FormControl(null, [Validators.required]),
    });
    this.fetchReturnReasons();
  }
  fetchReturnReasons = () => {
    this.rentalService.fetchReturnReasons().subscribe(
      (reasons) => {
        this.returnReasons = reasons;
        console.log(this.returnReasons);
      }
    );
  }
  onSubmit = () => {
    if (this.form.valid) {
      const request = new ReturnRequest(
        this.rental.id,
        this.form.controls.returnDate.value,
        this.form.controls.returnReason.value,
        this.form.controls.driveable.value,
        this.form.controls.note.value
      );
      request.createdBy = localStorage.getItem('userLoggedId');
      this.rentalService.applyReturn(request).subscribe(
        (res) => {
          this.notificationService.create('success', 'Return Car exitoso', `Se ha completado el return car del conductor ${this.customer.firstName} ${this.customer.lastName}`, { nzDuration: 7000 });
          this.formSubmitted.emit(request);
        }, error => {
          this.notificationService.create('error', 'Return Car fallido', `No se ha completado el return car del conductor ${this.customer.firstName} ${this.customer.lastName}`, { nzDuration: 7000 });
        }
      );
    }
  }
  disabledDate = (current: Date): boolean => {
    // Can not select days before 7 days. Can not select days after two days
    const diff = differenceInCalendarDays(current, this.today);
    return !(diff > -10 && diff < 2);
  }
}
