import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import NewPaymentRequest from "../../../../model/billing/request/NewPaymentRequest";

@Component({
  selector: 'app-new-payment-form',
  templateUrl: './new-payment-form.component.html',
  styles: [
  ]
})
export class NewPaymentFormComponent implements OnInit {
  form: FormGroup;
  @Input() paymentMethods = [];
  @Input() customerId;
  @Output() formSubmitted: EventEmitter<NewPaymentRequest> = new EventEmitter<NewPaymentRequest>();
  constructor() { }

  ngOnInit(): void {
    this.form = new FormGroup({
      paymentMethod: new FormControl(null, [Validators.required]),
      amount: new FormControl(null, [Validators.required]),
      dueDate: new FormControl(null, [Validators.required]),
      notes: new FormControl()
    });
  }
  onSubmit(): void {
    if (this.form.valid) {
      const request = new NewPaymentRequest(
        this.customerId,
        this.form.controls.paymentMethod.value,
        this.form.controls.amount.value,
        this.form.controls.notes.value,
        this.form.controls.dueDate.value,
      );
      this.formSubmitted.emit(request);
    }
  }

}
