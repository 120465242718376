import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-choose-collection-agent-assignment-form',
  templateUrl: './choose-collection-agent-assignment-form.component.html',
  styleUrls: ['./choose-collection-agent-assignment-form.component.css']
})
export class ChooseCollectionAgentAssignmentFormComponent implements OnInit {
  form: FormGroup;
  @Input() users: [];
  @Output() formDataSubmitted: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
    this.form = new FormGroup({
      user: new FormControl(null)
    });
  }
  onSubmit(): void {
    this.formDataSubmitted.emit({
      userId: this.form.controls.user.value
    });
  }

}
