import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-leads-page',
  templateUrl: './leads-page.component.html',
  styleUrls: ['./leads-page.component.css']
})
export class LeadsPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
