import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Customer } from '../../../../app/model/onboarding/Customer';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import * as uuid from 'uuid';

@Component({
  selector: 'app-new-toku-customer',
  templateUrl: './new-toku-customer.component.html',
  styleUrls: ['./new-toku-customer.component.css']
})
export class NewTokuCustomerComponent implements OnInit {
  @Input() customer: Customer;
  @Output() formSubmitted: EventEmitter<any> = new EventEmitter<any>();
  tokuForm: FormGroup;
  constructor() { }

  ngOnInit(): void {
    this.tokuForm = new FormGroup({
      guaranteeAmount: new FormControl('', Validators.required)
    })
  }

  public onSubmit(): void{
    let phone: string;
    if (this.customer.phone.startsWith('+52')) {
      phone = this.customer.phone
    } else {
      phone = '+52' + this.customer.phone
    }
    const tokuRequest = {
      name: this.customer.firstName + ' ' + this.customer.lastName,
      phone_number: phone,
      email: this.customer.email, 
      government_id: this.customer.curp,
      send_mail: false,
      rfc: this.customer.rfc,
      amount: this.tokuForm.controls.guaranteeAmount.value
    }
    const actualDate = moment.utc();
    const newChargeRequest = {
      charge_id: uuid.v4(),
      customerId: this.customer.id,
      chargeTypeId: '9656ccc9-64a4-4246-9325-9df0902d7d68',
      amount: this.tokuForm.controls.guaranteeAmount.value,
      dueDate: actualDate.format('YYYY-MM-DD'),
      note: 'deposit from toku',
    }
    this.formSubmitted.emit({ tokuRequest, newChargeRequest });
  }

}
