import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  private allowedRoles:string[];
  constructor(private router: Router,
              private authService:AuthService){}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
    /* Roles section start */
    let role = JSON.parse(localStorage.getItem('roles'));
    this.allowedRoles = route.children.map(data=>data.data.roles)[0] ? route.children.map(data=>data.data.roles)[0] : route.data["roles"];
    const allowed:boolean = this.authService.getCurrentRole().filter(role=>this.allowedRoles.includes(role)).length > 0;
    if(!allowed){
      if(role){
        switch (role[0]) {
          case 'MONITORING':
            this.router.navigateByUrl('/agreements/active/rental');
            break;
          case 'ADMIN':
            this.router.navigateByUrl('/applications');
            break;
        }
      }else{
        this.router.navigateByUrl('/login');
      }
    }
    return allowed;
  
}



  
      
        /* const isAuthenticated = this.userService.validateToken();
        if (!isAuthenticated) {
          localStorage.clear();
          this.router.navigateByUrl('/login');
        }if(route.data['roles'].length > 0 && allowed){
        return true; */
      
      /* Roles section ends */

}

