import { Component, Input, OnInit } from '@angular/core';
import { WeebFleetResponse } from '../../domain/model/customer/weeb-fleet-response';
import { CarInformationResponse } from '../../domain/model/customer/car-information-response.model';

@Component({
  selector: 'app-car-information',
  templateUrl: './car-information.component.html',
  styleUrls: ['./car-information.component.css']
})
export class CarInformationComponent implements OnInit {
  @Input() carInformation : CarInformationResponse[];
  @Input() WeebFleetInformation : WeebFleetResponse[];
  constructor() { }

  ngOnInit(): void {
  }

}
