<span nz-tooltip nzTooltipTitle="Close Reservation"><button nz-button nzShape="circle" nzType="primary" (click)="openFormModal()"><i nz-icon nzType="file-done" nzTheme="outline"></i></button></span>
<nz-modal
  nzTitle="Close Reservation"
  [(nzVisible)]="isFormVisible"
  (nzOnCancel)="isFormVisible=false"
  (nzOnOk)="isFormVisible=false"
  [nzFooter]="null"
>
  <form [formGroup]="form" (ngSubmit)="onSubmitForm()">
    <nz-form-item>
      <nz-form-label [nzSpan]="9" nzRequired>Pickup Date</nz-form-label>
      <nz-form-control nzErrorTip="Specify pickup date" nzSpan="15">
        <input nzSize="large" nz-input type="date" formControlName="pickupDate" />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSpan]="9" nzRequired>First Payment Date</nz-form-label>
      <nz-form-control nzErrorTip="Specify the start date (date to apply first charge)" nzSpan="15">
        <input nzSize="large" nz-input type="date" formControlName="firstPaymentDate" />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-control [nzSpan]="12" [nzOffset]="6">
        <button type="submit" nz-button nzType="primary" nzBlock nzSize="large" >Enviar</button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-modal>
