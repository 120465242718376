import { Component, OnInit } from '@angular/core';
import { AgreementServiceService } from '../../../services/api/core/agreement-service.service';
import IRentalAgreement from '../../../domain/api/core/agreements/response/RentalAgreementResponse';
import RentalAgreementResponse from '../../../domain/api/core/agreements/response/RentalAgreementResponse';

@Component({
  selector: 'app-rental-agreements-page',
  templateUrl: './rental-agreements-page.component.html',
  styleUrls: ['./rental-agreements-page.component.css']
})
export class RentalAgreementsPageComponent implements OnInit {
  rentals: IRentalAgreement[] = [];
  editAgreementFormOpen = false;
  rentalAgreementSelectedEdit: IRentalAgreement;
  constructor(private agreementService: AgreementServiceService) { }

  ngOnInit(): void {
    this.agreementService.getRentalAgreementsByStatus('ACTIVE')
      .subscribe(res => {
        this.mapRentalResponse(res);
      });
  }
  mapRentalResponse(rentalsResponse: any): void {
    this.rentals = rentalsResponse.data.map(r => {
      return {
        startDate: r.startDate,
        firstPaymentDate: r.firstPaymentDate,
        amount: r.amount,
        customer: {
          firstName: r.customer.firstName,
          lastName: r.customer.lastName,
          fullName: r.customer.fullName,
          id: r.customer.id
        },
        car: {
          id: r.car.id,
          plate: r.car.plate,
          vin: r.car.vin,
          carLocationCode: r.car.carLocationCode,
          carModelCode: r.car.carModelCode
        },
        uuid: r.uuid,
        platformsToDrive: r.platformsToDrive,
        dueDay: r.dueDay,
        firstPaymentAmount: r.firstPaymentAmount,
        id: r.id,
      };
    });
  }
  openEditAgreementForm(editRentalAgreement: any): void {
    this.editAgreementFormOpen = true;
    this.rentalAgreementSelectedEdit = editRentalAgreement;
  }
  editAgreementFormModalClosed(): void {
    // Should be false always, to close modal
    this.editAgreementFormOpen = false;
  }

}
