import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgreementsTableViewComponent } from './agreements/agreements-table-view/agreements-table-view.component';
import { SearchBarComponent } from './agreements/search-bar/search-bar.component';
import { LeaseTableViewComponent } from './agreements/lease-table-view/lease-table-view.component';
import { ApprovedTableViewComponent } from './leads/approved-table-view/approved-table-view.component';
import { EditRentalAgreementComponent } from './agreements/forms/edit-rental-agreement/edit-rental-agreement.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditLeaseAgreementComponent } from './agreements/forms/edit-lease-agreement/edit-lease-agreement.component';
import { LeadsTableViewComponent } from './leads/leads-table-view/leads-table-view.component';
import { MyleadsTableViewComponent } from './leads/myleads-table-view/myleads-table-view.component';

@NgModule({
  declarations: [
    AgreementsTableViewComponent,
    SearchBarComponent,
    LeaseTableViewComponent,
    ApprovedTableViewComponent,
    EditRentalAgreementComponent,
    EditLeaseAgreementComponent,
    LeadsTableViewComponent,
    MyleadsTableViewComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    AgreementsTableViewComponent,
    SearchBarComponent,
    LeaseTableViewComponent,
    ApprovedTableViewComponent,
    EditRentalAgreementComponent,
    EditLeaseAgreementComponent,
    LeadsTableViewComponent,
    MyleadsTableViewComponent
  ]
})
export class BootstrapComponentsModule { }
