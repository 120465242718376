<nz-table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Phone</th>
      <th>Email</th>
      <th>Source</th>
      <th>Status</th>
    </tr>
  </thead>
</nz-table>
