import { Component, OnInit } from '@angular/core';
import {BackofficeCoreService} from "../../../services/backoffice-core.service";

@Component({
  selector: 'app-finished-agreements',
  templateUrl: './finished-agreements.component.html',
  styles: [
  ]
})
export class FinishedAgreementsComponent implements OnInit {
  customers: any[] = [];
  customersDisplay: any[];
  loading: boolean = false;
  constructor(
    private coreService: BackofficeCoreService
  ) { }
  ngOnInit(): void {
    this.coreService.getFinishedRentalAgreements().subscribe(value => {
      this.customers = value;
      if(value){
        this.loading = true;
      }
      this.customersDisplay = this.customers;
    });
  }
  searchCustomer(search): void {
    search = search.toUpperCase();
    if (search.length === 0) {
      this.customersDisplay = this.customers;
    } else {
      this.customersDisplay = this.customers.filter(
        value => 
          ''.concat(value.customer.firstName, ' ', value.customer.lastName).toUpperCase().includes(search) 
          || value.car.plate === search 
          || value.customer.oraculeBosId === search 
          || value.customer_customer_bos_id === search 
          || value.agreement_id === search 
          || value.customer.id === search.toLowerCase()
      );
    }
  }
}
