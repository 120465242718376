<nz-drawer [nzVisible]="visible" [nzWidth]="700" [nzClosable]="false" (nzOnClose)="close()">
  <ng-container *nzDrawerContent>
    <p class="title" style=" margin-bottom: 24px;">Información de Aplicación</p>
    <nz-descriptions [nzColumn]="2" nzTitle="Información Personal">
      <nz-descriptions-item nzTitle="Google Drive" [nzSpan]="2"><a
          href="https://drive.google.com/drive/u/0/folders/{{ application?.customer?.folderId }}"
          target="_blank">Archivos de BOS</a></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Nombre" [nzSpan]="2">{{ application?.customer? application.customer.firstName: ''
        }} {{ application?.customer? application.customer.lastName: '' }}</nz-descriptions-item>
      <nz-descriptions-item nzTitle="Email" [nzSpan]="1">{{ application?.customer? application.customer.email: ''
        }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Email de facturación" [nzSpan]="1">{{ application?.customer? application.customer.billingEmail: ''
        }}</nz-descriptions-item>
      <nz-descriptions-item nzTitle="Teléfono" [nzSpan]="1">{{ application?.customer? application.customer.phone: ''
        }}</nz-descriptions-item>
      <nz-descriptions-item nzTitle="Fecha de Nacimiento" [nzSpan]="1">{{ application?.customer?
        application.customer.dateOfBirth: '' }}</nz-descriptions-item>
      <nz-descriptions-item nzTitle="Edad" [nzSpan]="1">{{ application?.customer?.dateOfBirth?
        date().diff(application.customer.dateOfBirth, 'years'): '' }}</nz-descriptions-item>
      <nz-descriptions-item nzTitle="Genero" [nzSpan]="1">{{ application?.customer? application.customer.gender: ''
        }}</nz-descriptions-item>
      <nz-descriptions-item nzTitle="Licencia" [nzSpan]="1">{{ application?.customer? application.customer.license: ''
      }}</nz-descriptions-item>
      <nz-descriptions-item nzTitle="CURP" [nzSpan]="1">{{ application?.customer? application.customer.curp: ''
        }}</nz-descriptions-item>
      <nz-descriptions-item nzTitle="RFC" [nzSpan]="1">{{ application?.customer? application.customer.rfc: ''
        }}</nz-descriptions-item>
      <nz-descriptions-item nzTitle="Notas" [nzSpan]="2">{{ application?.customer? application.customer.note: ''
        }}</nz-descriptions-item>
      <nz-descriptions-item nzTitle="Régimen fiscal" [nzSpan]="1"
        [ngSwitch]="application?.customer? application.customer.taxRegime: ''">
        <span *ngSwitchCase="'625'">Régimen de las Actividades Empresariales con ingresos a través de Plataformas
          Tecnológicas</span>
        <span *ngSwitchCase="'605'">Sueldos y Salarios e Ingresos Asimilados a Salarios</span>
        <span *ngSwitchCase="'612'">Personas Físicas con Actividades Empresariales y Profesionales</span>
        <span *ngSwitchCase="'606'">Arrendamiento</span>
        <span *ngSwitchCase="'608'">Demás ingresos</span>
        <span *ngSwitchCase="'621'">Incorporación Fiscal</span>
        <span *ngSwitchCase="'610'">Residentes en el Extranjero sin Establecimiento Permanente en México</span>
        <span *ngSwitchCase="'611'">Ingresos por Dividendos (socios y accionistas)</span>
        <span *ngSwitchCase="'614'">Ingresos por intereses</span>
        <span *ngSwitchCase="'616'">Sin obligaciones fiscales</span>
        <span *ngSwitchCase="'622'">Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</span>
        <span *ngSwitchCase="'629'">De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales</span>
        <span *ngSwitchCase="'630'">Enajenación de acciones en bolsa de valores</span>
        <span *ngSwitchCase="'615'">Régimen de los ingresos por obtención de premios</span>
        <span *ngSwitchCase="'626'">Régimen Simplificado de Confianza</span>
        <span *ngSwitchDefault></span>
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Uso del CFDI" [nzSpan]="1"
        [ngSwitch]="application?.customer? application.customer.cfdiUse: ''">
        <span *ngSwitchCase="'G01'">Adquisición de mercancías</span>
        <span *ngSwitchCase="'G02'">Devoluciones, descuentos o bonificaciones</span>
        <span *ngSwitchCase="'G03'">Gastos en general </span>
        <span *ngSwitchCase="'I01'">Construcciones</span>
        <span *ngSwitchCase="'I02'">Mobiliario y equipo de oficina por inversiones</span>
        <span *ngSwitchCase="'I03'">Equipo de transporte</span>
        <span *ngSwitchCase="'I04'">Equipo de cómputo y accesorios</span>
        <span *ngSwitchCase="'I05'">Dados, troqueles, moldes, matrices y herramental</span>
        <span *ngSwitchCase="'I06'">Comunicaciones telefónicas</span>
        <span *ngSwitchCase="'I07'">Comunicaciones satelitales</span>
        <span *ngSwitchCase="'I08'">Otra maquinaria y equipo</span>
        <span *ngSwitchCase="'D01'">Honorarios médicos, dentales y gastos hospitalarios.</span>
        <span *ngSwitchCase="'D02'">Gastos médicos por incapacidad o discapacidad</span>
        <span *ngSwitchCase="'D03'">Gastos funerales</span>
        <span *ngSwitchCase="'D04'">Donativos</span>
        <span *ngSwitchCase="'D05'">Intereses reales efectivamente pagados por créditos hipotecarios (casa
          habitación).</span>
        <span *ngSwitchCase="'D06'">Aportaciones voluntarias al SAR.</span>
        <span *ngSwitchCase="'D07'">Primas por seguros de gastos médicos.</span>
        <span *ngSwitchCase="'D08'">Gastos de transportación escolar obligatoria.</span>
        <span *ngSwitchCase="'D09'">Depósitos en cuentas para el ahorro, primas que tengan como base planes de
          pensiones.</span>
        <span *ngSwitchCase="'D10'">Pagos por servicios educativos (colegiaturas)</span>
        <span *ngSwitchCase="'CP01'">Pagos</span>
        <span *ngSwitchCase="'CN01'">Nómina</span>
        <span *ngSwitchCase="'S01'">Sin Efectos Fiscales</span>
        <span *ngSwitchDefault></span>
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Referencia Bancaria" [nzSpan]="1">
        {{paymentReferences ? paymentReferences.reference_bank: null}}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Deposito tienda conveniencia" [nzSpan]="1">
        {{paymentReferences ? paymentReferences.reference_cash: null}}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="deposit status" [nzSpan]="1">
        <div style="display: flex; align-items: center;">
          <i nz-icon nzType="check-circle" nzTheme="outline" *ngIf="itsPaid" style="margin-right: 5px;"></i>
          <i nz-icon nzType="clock-circle" nzTheme="outline" *ngIf="!itsPaid" style="margin-right: 5px;"></i>
          <span>{{ itsPaid ? 'Pagado' : 'Pendiente' }}</span>
        </div>
      </nz-descriptions-item>
    </nz-descriptions>
    <nz-space nzDirection="horizontal">
      <nz-space-item>
        <button nz-button nzType="default" (click)="updateCustomer()">Actualizar Conductor</button>
      </nz-space-item>
      <nz-space-item>
        <button nz-button nzType="default" (click)="updateApplication()">Actualizar Aplicación</button>
      </nz-space-item>
      <nz-space-item>
        <button nz-button nzType="default" (click)="createTokuCustomer()" [disabled]="this.underwriting?.securityDeposit === null">Deposito de seguridad</button>
      </nz-space-item>
      <nz-space-item>
        <button nz-button nzType="default" (click)="sendWaCustomer(paymentReferences, application.customer.phone, (application.customer.firstName + ' ' + application.customer.lastName) )">Mandar WhastApp</button>
      </nz-space-item>
    </nz-space>
    <nz-divider></nz-divider>
    <h4 nz-typography>Documentos</h4>
    <app-documents-quick-viewer [documents]="documents"
      [customer]="application?.customer"
      (isFileUploaded)="getOcrUploadStatus($event)"
      (fileUploaded)="fileHasBeenUploaded($event)"></app-documents-quick-viewer>
    <nz-space nzDirection="horizontal">
      <nz-space-item *ngIf="application?.applicationStatusCode === 'OPEN'">
        <button nz-popconfirm nzPopconfirmTitle="Estas seguro?" nzOkText="Si" nzCancelText="Cancelar"
          (nzOnConfirm)="this.sendPendingApprovalClicked()" nz-button nzType="default">Send to Approval</button>
      </nz-space-item>
      <nz-space-item>
        <button nz-button nzType="primary" (click)="newDocument()">Agregar Documento</button>
      </nz-space-item>
      <nz-space-item *ngIf="application?.applicationStatusCode === 'OPEN'">
        <button nz-button nzType="default" (click)="sendToDidntTakeACarClicked()">Send to Didn't take a car</button>
      </nz-space-item>
      <nz-space-item *ngIf="application?.applicationStatusCode === 'PNDG_APPRV'">
        <button nz-popconfirm nzPopconfirmTitle="Estas seguro?" nzOkText="Si" nzCancelText="Cancelar"
          (nzOnConfirm)="this.approveApplicationClicked()" nz-button nzType="default">Approve</button>
      </nz-space-item>
      <nz-space-item *ngIf="application?.applicationStatusCode === 'PNDG_APPRV'">
        <button nz-button nzType="default" (click)="denyApplicationClicked()">Deny</button>
      </nz-space-item>
      <nz-space-item *ngIf="application?.applicationStatusCode === 'APPROVED'">
        <button nz-button nzType="default" [disabled]="billingProfileValid || !itsPaid" (click)="createRentalClicked()">Crear contrato</button>
      </nz-space-item>
      <nz-space-item *ngIf="application?.applicationStatusCode === 'APPROVED'">
        <app-create-reservation-modal-button [applicationId]="application.id"></app-create-reservation-modal-button>
      </nz-space-item>
    </nz-space>
    <nz-divider></nz-divider>
  </ng-container>
</nz-drawer>