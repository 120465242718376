<nz-divider
  [nzText]="'Actualizar Información de '.concat(application.customer.firstName, ' ', application.customer.lastName)"></nz-divider>

<form [formGroup]="uwForm" nz-form [nzLayout]="'vertical'" (ngSubmit)="onSubmit()">
  <nz-form-item>
    <nz-form-label [nzSpan]="24" nzRequired>Tipo de Conductor</nz-form-label>
    <nz-form-control nzErrorTip="Por favor selecciona una razón" nzSpan="24">
      <nz-select nzSize="large" formControlName="driverTypeName" nzPlaceHolder="Selecciona una razón">
        <nz-option nzValue="TYPE_1" nzLabel="Tipo 1"></nz-option>
        <nz-option nzValue="TYPE_2" nzLabel="Tipo 2"></nz-option>
        <nz-option nzValue="TYPE_3" nzLabel="Tipo 3"></nz-option>
        <nz-option nzValue="TYPE_4" nzLabel="Tipo 4"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="24">Codigo Postal del INE</nz-form-label>
    <nz-form-control nzErrorTip="" nzSpan="24">
      <input nzSize="large" type="text" nz-input formControlName="ineZipcode" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="24">Año de expiracion INE</nz-form-label>
    <nz-form-control nzErrorTip="" nzSpan="24">
      <input type="number" formControlName="ineExpirationYear" nzSize="large" nz-input />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="24">¿Se valido INE?</nz-form-label>
    <nz-form-control nzErrorTip="" nzSpan="24">
      <nz-radio-group nzSize="large" formControlName="ineHasBeenValidated" nzButtonStyle="solid">
        <label nz-radio-button [nzValue]="1">Si</label>
        <label nz-radio-button [nzValue]="0">No</label>
      </nz-radio-group>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="24">¿Se genero y valido CURP?</nz-form-label>
    <nz-form-control nzErrorTip="" nzSpan="16">
      <nz-radio-group nzSize="large" formControlName="isCurpValidated" nzButtonStyle="solid" nzName="curp_validated">
        <label nz-radio-button [nzValue]="1">Si</label>
        <label nz-radio-button [nzValue]="0">No</label>
      </nz-radio-group>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="24">¿El correo electrónico tiene su nombre embebido?</nz-form-label>
    <nz-form-control nzErrorTip="" nzSpan="16">
      <nz-radio-group nzSize="large" formControlName="nameEmbbededEmail" nzButtonStyle="solid" nzName="email_embbeded">
        <label nz-radio-button [nzValue]="1">Si</label>
        <label nz-radio-button [nzValue]="0">No</label>
      </nz-radio-group>
    </nz-form-control>
  </nz-form-item>

  <nz-divider [nzText]="'SAT'"></nz-divider>

  <nz-form-item>
    <nz-form-label [nzSpan]="24">Email en el RFC</nz-form-label>
    <nz-form-control nzErrorTip="" nzSpan="24">
      <input nzSize="large" type="text" nz-input formControlName="emailInRfc" />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="24" nzRequired>Situación del contribuyente</nz-form-label>
    <nz-form-control nzErrorTip="Por favor selecciona una razón" nzSpan="24">
      <nz-select formControlName="contributorStatus" nzSize="large" nzPlaceHolder="Selecciona la situación">
        <nz-option nzValue="ACTIVE" nzLabel="Activo"></nz-option>
        <nz-option nzValue="INACTIVE" nzLabel="Inactivo"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="24" nzRequired>Cual es el régimen del contribuyente</nz-form-label>
    <nz-form-control nzErrorTip="Por favor selecciona una razón" nzSpan="24">
      <nz-select nzSize="large" nzPlaceHolder="Selecciona una razón" formControlName="contributorRegimen">
        <nz-option [nzValue]="605" nzLabel="Sueldos y Salarios e Ingresos Asimilados a Salarios"></nz-option>
        <nz-option [nzValue]="625"
          nzLabel="Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas"></nz-option>
        <nz-option [nzValue]="612" nzLabel="Personas Físicas con Actividades Empresariales y Profesionales"></nz-option>
        <nz-option [nzValue]="606" nzLabel="Arrendamiento"></nz-option>
        <nz-option [nzValue]="608" nzLabel="Demás ingresos"></nz-option>
        <nz-option [nzValue]="621" nzLabel="Incorporación Fiscal"></nz-option>
        <nz-option [nzValue]="610"
          nzLabel="Residentes en el Extranjero sin Establecimiento Permanente en México"></nz-option>
        <nz-option [nzValue]="611" nzLabel="Ingresos por Dividendos (socios y accionistas)"></nz-option>
        <nz-option [nzValue]="614" nzLabel="Ingresos por intereses"></nz-option>
        <nz-option [nzValue]="616" nzLabel="Sin obligaciones fiscales"></nz-option>
        <nz-option [nzValue]="622" nzLabel="Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras"></nz-option>
        <nz-option [nzValue]="629"
          nzLabel="De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales"></nz-option>
        <nz-option [nzValue]="630" nzLabel="Enajenación de acciones en bolsa de valores"></nz-option>
        <nz-option [nzValue]="615" nzLabel="Régimen de los ingresos por obtención de premios"></nz-option>
        <nz-option [nzValue]="626" nzLabel="Régimen Simplificado de Confianza"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="24">Entregó opinion de cumplimiento</nz-form-label>
    <nz-form-control nzErrorTip="" nzSpan="16">
      <nz-radio-group nzSize="large" formControlName="hasComplianceOpinionUploaded" nzButtonStyle="solid"
        nzName="compliance_opinion">
        <label nz-radio-button [nzValue]="1">Si</label>
        <label nz-radio-button [nzValue]="0">No</label>
      </nz-radio-group>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="24" nzRequired>Antiguedad del CIF</nz-form-label>
    <nz-form-control nzErrorTip="Por favor selecciona una razón" nzSpan="24">
      <nz-select formControlName="cifOldAbbrev" nzSize="large" nzPlaceHolder="Selecciona una razón">
        <nz-option nzValue="LESS_TWO_MONTHS" nzLabel="Menos de dos meses"></nz-option>
        <nz-option nzValue="MORE_TWO_MONTHS" nzLabel="Más de dos meses"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>


  <nz-form-item>
    <nz-form-label [nzSpan]="24">¿Fue posible generar QR?</nz-form-label>
    <nz-form-control nzErrorTip="" nzSpan="16">
      <nz-radio-group nzSize="large" formControlName="qrAbleToGenerate" nzButtonStyle="solid" nzName="qr">
        <label nz-radio-button [nzValue]="1">Si</label>
        <label nz-radio-button [nzValue]="0">No</label>
      </nz-radio-group>
    </nz-form-control>
  </nz-form-item>

  <nz-divider [nzText]="'Licencia'"></nz-divider>

  <nz-form-item>
    <nz-form-label [nzSpan]="24" nzRequired>¿De que estado de la Licencia?</nz-form-label>
    <nz-form-control nzErrorTip="Por favor selecciona una opción" nzSpan="24">
      <nz-select nzSize="large" nzPlaceHolder="Selecciona el estado" formControlName="licenseState">
        <nz-option nzValue="CMX" nzLabel="Ciduad de México"></nz-option>
        <nz-option nzValue="EDO_MEX" nzLabel="Estado de México"></nz-option>
        <nz-option nzValue="PUE" nzLabel="Puebla"></nz-option>
        <nz-option nzValue="GDL" nzLabel="Guadalajara"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="24">Fecha de Expiración</nz-form-label>
    <nz-form-control nzErrorTip="" nzSpan="24">
      <input nzSize="large" type="date" nz-input formControlName="licenseExpirationDate" />
    </nz-form-control>
  </nz-form-item>


  <nz-form-item>
    <nz-form-label [nzSpan]="24">Es una licencia federal</nz-form-label>
    <nz-form-control nzErrorTip="" nzSpan="24">
      <nz-radio-group nzSize="large" formControlName="isFederalLicense" nzButtonStyle="solid" nzName="federal_licence">
        <label nz-radio-button [nzValue]="1">Si</label>
        <label nz-radio-button [nzValue]="0">No</label>
      </nz-radio-group>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="24">Es una licencia permanente</nz-form-label>
    <nz-form-control nzErrorTip="" nzSpan="24">
      <nz-radio-group nzSize="large" formControlName="isPermanentLicense" nzButtonStyle="solid"
        nzName="permanent_licence">
        <label nz-radio-button [nzValue]="1">Si</label>
        <label nz-radio-button [nzValue]="0">No</label>
      </nz-radio-group>
    </nz-form-control>
  </nz-form-item>

  <nz-divider [nzText]="'Domicilio'"></nz-divider>


  <nz-form-item>
    <nz-form-label [nzSpan]="24">Código Postal</nz-form-label>
    <nz-form-control nzErrorTip="" nzSpan="24">
      <input nzSize="large" type="text" nz-input formControlName="zipCode" />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="24">¿Coincide con la dirección del INE?</nz-form-label>
    <nz-form-control nzErrorTip="" nzSpan="24">
      <nz-radio-group nzSize="large" formControlName="proofOfAddressMatchIne" nzButtonStyle="solid">
        <label nz-radio-button [nzValue]="1">Si</label>
        <label nz-radio-button [nzValue]="0">No</label>
      </nz-radio-group>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="24">Nombre del propietario</nz-form-label>
    <nz-form-control nzErrorTip="" nzSpan="24">
      <input nzSize="large" type="text" nz-input formControlName="proofOfAddressOwnerName" />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="24" nzRequired>Fecha del comprobante</nz-form-label>
    <nz-form-control nzErrorTip="Por favor selecciona una opción" nzSpan="24">
      <nz-select nzSize="large" formControlName="proofOfAddressOldAbbrev" nzPlaceHolder="Selecciona una opción">
        <nz-option nzValue="LESS_TWO_MONTHS" nzLabel="Menos de dos meses"></nz-option>
        <nz-option nzValue="MORE_TWO_MONTHS" nzLabel="Mas de dos meses"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="24" nzRequired>Tipo de comprobante</nz-form-label>
    <nz-form-control nzErrorTip="Por favor selecciona una opción" nzSpan="24">
      <nz-select nzSize="large" nzPlaceHolder="Selecciona el tipo de comprobante"
        formControlName="proofOfAddressCategoryAbbrev">
        <nz-option nzValue="CFE" nzLabel="CFE"></nz-option>
        <nz-option nzValue="TELEPHONE" nzLabel="Compañia Telefónica"></nz-option>
        <nz-option nzValue="TELEVISION" nzLabel="Televisión o Cable"></nz-option>
        <nz-option nzValue="OTHER" nzLabel="Otro"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="24" nzRequired>Relación con el socio conductor</nz-form-label>
    <nz-form-control nzErrorTip="Por favor selecciona una opción" nzSpan="24">
      <nz-select nzSize="large" nzPlaceHolder="Selecciona una opción"
        formControlName="proofOfAddressRelationshipAbbrev">
        <nz-option nzValue="SAME" nzLabel="Es el mismo"></nz-option>
        <nz-option nzValue="HUSBAND_WIFE" nzLabel="Es Esposa o Esposo"></nz-option>
        <nz-option nzValue="PARENT" nzLabel="Padre o madre"></nz-option>
        <nz-option nzValue="BROTHER_SISTER" nzLabel="Hermano o hermana"></nz-option>
        <nz-option nzValue="ANOTHER_FAMILY" nzLabel="Otro familiar"></nz-option>
        <nz-option nzValue="NO_RELATIONSHIP" nzLabel="Sin parentesco"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="24" nzRequired>¿Cuantas direcciones son iguales?</nz-form-label>
    <nz-form-control nzErrorTip="Por favor selecciona una opción" nzSpan="24">
      <nz-select nzSize="large" formControlName="fourAddressesMatch" nzPlaceHolder="Selecciona una opción">
        <nz-option nzValue="1/4" nzLabel="Solo una de cuatro"></nz-option>
        <nz-option nzValue="2/4" nzLabel="Solo 2 de 4"></nz-option>
        <nz-option nzValue="3/4" nzLabel="tres de 4"></nz-option>
        <nz-option nzValue="4/4" nzLabel="Todas son iguales"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-divider nzText="Plataforma"></nz-divider>

  <nz-form-item>
    <nz-form-label [nzSpan]="24">Calificación</nz-form-label>
    <nz-form-control nzErrorTip="" nzSpan="24">
      <input nzSize="large" type="number" max="5" min="0" nz-input formControlName="rankingAtPlatformWorkedFor" />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="24">Viajes</nz-form-label>
    <nz-form-control nzErrorTip="" nzSpan="24">
      <input nzSize="large" type="number" nz-input formControlName="estRidesGiven" />
    </nz-form-control>
  </nz-form-item>


  <nz-form-item>
    <nz-form-label [nzSpan]="24" nzRequired>Plataforma</nz-form-label>
    <nz-form-control nzErrorTip="Por favor selecciona una opción" nzSpan="24">
      <nz-select nzSize="large" formControlName="platformWorkOn" nzPlaceHolder="Selecciona una opción">
        <nz-option nzValue="UBER" nzLabel="Uber"></nz-option>
        <nz-option nzValue="DIDI" nzLabel="Didi"></nz-option>
        <nz-option nzValue="CABIFY" nzLabel="Cabify"></nz-option>
        <nz-option nzValue="BEAT" nzLabel="Beat"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="24" nzRequired>Categoria</nz-form-label>
    <nz-form-control nzErrorTip="Por favor selecciona una opción" nzSpan="24">
      <nz-select nzSize="large" formControlName="platformCategoryAbbrev" nzPlaceHolder="Selecciona una opción">
        <nz-option nzValue="UBER_DIAMOND" nzLabel="Diamante"></nz-option>
        <nz-option nzValue="UBER_PLATINUM" nzLabel="Platino"></nz-option>
        <nz-option nzValue="UBER_GOLD" nzLabel="Oro"></nz-option>
        <nz-option nzValue="UBER_BLUE" nzLabel="Azul"></nz-option>
        <nz-option nzValue="UBER_Pro" nzLabel="Pro"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="24">Tasa de aceptación</nz-form-label>
    <nz-form-control nzErrorTip="" nzSpan="24">
      <input nzSize="large" type="number" nz-input formControlName="acceptanceRate" />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="24">Tasa de cancelación</nz-form-label>
    <nz-form-control nzErrorTip="" nzSpan="24">
      <input nzSize="large" type="number" nz-input formControlName="cancellationRate" />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="24">Tiene pruebas de ganacias en las última semanas</nz-form-label>
    <nz-form-control nzErrorTip="" nzSpan="24">
      <nz-radio-group nzSize="large" formControlName="platformProofEarnings" nzButtonStyle="solid"
        nzName="proofEarnings">
        <label nz-radio-button [nzValue]="1">Si</label>
        <label nz-radio-button [nzValue]="0">No</label>
      </nz-radio-group>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="24">Experiencia en plataformas (Años)</nz-form-label>
    <nz-form-control nzErrorTip="" nzSpan="24">
      <input nzSize="large" type="number" nz-input formControlName="yearsOnPlatforms" />
    </nz-form-control>
  </nz-form-item>

  <nz-divider nzText="Garage"></nz-divider>

  <nz-form-item>
    <nz-form-label [nzSpan]="24" nzRequired>¿Que tipo de garage es?</nz-form-label>
    <nz-form-control nzErrorTip="Por favor selecciona una opción" nzSpan="24">
      <nz-select nzSize="large" nzPlaceHolder="Selecciona una razón" formControlName="garageType">
        <nz-option nzValue="PRIVATE" nzLabel="Privado"></nz-option>
        <nz-option nzValue="OPEN" nzLabel="Abierto"></nz-option>
        <nz-option nzValue="STREET" nzLabel="En la calle"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="24" nzRequired>¿En que direccion se guardará el vehículo?</nz-form-label>
    <nz-form-control nzErrorTip="Por favor selecciona una opción" nzSpan="24">
      <nz-select nzSize="large" nzPlaceHolder="Selecciona una razón" formControlName="parkingAddressMatches">
        <nz-option nzValue="INE_ADDR" nzLabel="Dirección del INE"></nz-option>
        <nz-option nzValue="PROOF_ADDR" nzLabel="Dirección del comprobante"></nz-option>
        <nz-option nzValue="RFC_ADDR" nzLabel="Dirección del RFC"></nz-option>
        <nz-option nzValue="OTHER" nzLabel="Otra dirección"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item *ngIf="this.uwForm.controls.parkingAddressMatches.value === 'OTHER'">
    <nz-form-label [nzSpan]="24">Dirección donde se guardará</nz-form-label>
    <nz-form-control nzErrorTip="Debe especificar una dirección" nzSpan="24">
      <input nzSize="large" type="text" nz-input formControlName="parkingAddress" />
    </nz-form-control>
  </nz-form-item>

  <nz-divider nzText="Truora"></nz-divider>

  <nz-form-item>
    <nz-form-label [nzSpan]="24">Calificación total de Truora</nz-form-label>
    <nz-form-control nzErrorTip="" nzSpan="24">
      <input nzSize="large" type="number" nz-input formControlName="truoraTotal" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="24">Calificación legal de Truora</nz-form-label>
    <nz-form-control nzErrorTip="" nzSpan="24">
      <input nzSize="large" type="number" nz-input formControlName="truoraLegal" />
    </nz-form-control>
  </nz-form-item>


  <nz-divider nzText="Accutest"></nz-divider>

  <nz-form-item>
    <nz-form-label [nzSpan]="24" nzRequired>Riesgo</nz-form-label>
    <nz-form-control nzErrorTip="Por favor selecciona una opción" nzSpan="24">
      <nz-select nzSize="large" nzPlaceHolder="Selecciona una razón" formControlName="accutestRisk">
        <nz-option nzValue="LOW" nzLabel="Bajo"></nz-option>
        <nz-option nzValue="MEDIUM" nzLabel="Medio"></nz-option>
        <nz-option nzValue="HIGH" nzLabel="Alto"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="24">¿Hay banderas/warnings?</nz-form-label>
    <nz-form-control nzErrorTip="" nzSpan="24">
      <nz-radio-group nzSize="large" formControlName="accutestWarning" nzButtonStyle="solid">
        <label nz-radio-button nzValue="NO_WARNINGS">No</label>
        <label nz-radio-button nzValue="YELLOW_FLAG">Una Amarilla</label>
        <label nz-radio-button nzValue="ORANGE_FLAG">Una Naranja</label>
        <label nz-radio-button nzValue="RED_FLAG">Una Roja</label>
      </nz-radio-group>
    </nz-form-control>
  </nz-form-item>

  <nz-divider nzText="Background Check INTELAB"></nz-divider>

  <nz-form-item>
    <nz-form-label [nzSpan]="24" nzRequired>Riesgo</nz-form-label>
    <nz-form-control nzErrorTip="Por favor selecciona una opción" nzSpan="24">
      <nz-select nzSize="large" nzPlaceHolder="Selecciona una razón" formControlName="backgroundCheckRisk">
        <nz-option nzValue="LOW" nzLabel="Bajo"></nz-option>
        <nz-option nzValue="MEDIUM" nzLabel="Medio"></nz-option>
        <nz-option nzValue="HIGH" nzLabel="Alto"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-divider nzText="Interview"></nz-divider>

  <nz-form-item>
    <nz-form-label [nzSpan]="24" nzRequired>Riesgo</nz-form-label>
    <nz-form-control nzErrorTip="Por favor selecciona una opción" nzSpan="24">
      <nz-select nzSize="large" nzPlaceHolder="Selecciona una razón" formControlName="interviewRisk">
        <nz-option nzValue="LOW" nzLabel="Bajo"></nz-option>
        <nz-option nzValue="MEDIUMHIGH" nzLabel="Medio Alto"></nz-option>
        <nz-option nzValue="MEDIUM" nzLabel="Medio"></nz-option>
        <nz-option nzValue="HIGH" nzLabel="Alto"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-divider nzText="Non Payment Risk"></nz-divider>

  <nz-form-item>
    <nz-form-label [nzSpan]="24" nzRequired>Riesgo De Impago</nz-form-label>
    <nz-form-control nzErrorTip="Por favor selecciona una opción" nzSpan="24">
      <nz-select nzSize="large" nzPlaceHolder="Selecciona una razón" formControlName="nonPaymentRisk">
        <nz-option nzValue="LOW" nzLabel="Bajo"></nz-option>
        <nz-option nzValue="MEDIUMHIGH" nzLabel="Medio Alto"></nz-option>
        <nz-option nzValue="MEDIUM" nzLabel="Medio"></nz-option>
        <nz-option nzValue="HIGH" nzLabel="Alto"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item *ngIf="uwForm.controls.nonPaymentRisk.value != null || uwForm.controls.nonPaymentRisk.value != undefined">
    <nz-form-label [nzSpan]="24" nzRequired>Deposito en garantía</nz-form-label>
    <nz-form-control nzErrorTip="" nzSpan="24">
      <input nzSize="large" type="number" nz-input formControlName="securityDeposit"/>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-control [nzSpan]="12" [nzOffset]="6">
      <button type="submit" nz-button nzType="primary" nzBlock nzSize="large">Guardar</button>
    </nz-form-control>
  </nz-form-item>
</form>