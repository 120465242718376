<span nz-tooltip nzTooltipTitle="Car Inspection"><button nz-button nzShape="circle" nzType="primary" (click)="openModal()"><i nz-icon nzType="car" nzTheme="outline"></i></button></span>
<nz-modal
  nzTitle="Car Inspection"
  [(nzVisible)]="isModalVisible"
  (nzOnCancel)="isModalVisible=false"
  (nzOnOk)="isModalVisible=false"
  [nzFooter]="null"
>
  <app-survey [json]="inspectionSurveyJson"></app-survey>
</nz-modal>
