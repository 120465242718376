<nz-layout class="app-layout">
    <nz-sider class="menu-sidebar" *ngIf="!isSideBarVisible" nzCollapsible nzWidth="256px" nzBreakpoint="md"
        [(nzCollapsed)]="isCollapsed" [nzTrigger]="null">
        <div class="isDev" *ngIf="isDev"></div>
        <div class="sidebar-logo">
            <a href="https://nbs.nave.mx">
                <img src="https://ng.ant.design/assets/img/logo.svg" alt="logo" />
                <h1>BackOffice</h1>
            </a>
        </div>
        <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
            <li *appRbacShow="['ADMIN']" nz-submenu nzOpen nzTitle="CRM" nzIcon="team">
                <ul>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLink="/applications">Applications</a>
                    </li>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLink="/onboarding">On Boarding</a>
                    </li>
                </ul>
            </li>
            <li *appRbacShow="['ADMIN']" nz-submenu nzOpen nzTitle="Billing" nzIcon="dollar-circle">
                <ul>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLink="/billing/collections">Collections</a>
                    </li>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLink="/billing/paydowns">Paydowns</a>
                    </li>
                </ul>
            </li>
            <li nz-submenu nzOpen nzTitle="Conductores" nzIcon="solution">
                <ul>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLink="/agreements/active/rental">Activos</a>
                    </li>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLink="/agreements/finished/rental">Inactivos</a>
                    </li>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLink="/agreements/active/lease">Activos LTO</a>
                    </li>
                </ul>
            </li>
            <li *appRbacShow="['ADMIN']" nz-submenu nzOpen nzTitle="Fleet" nzIcon="car">
                <ul>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLink="/fleet/cars">Cars</a>
                    </li>
                </ul>
            </li>
            <li *appRbacShow="['ADMIN']" nz-submenu nzOpen nzTitle="Payment Links" nzIcon="link">
                <ul>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLink="/billing/links/new">New Link</a>
                    </li>
                </ul>
            </li>
        </ul>
    </nz-sider>
    <nz-layout>
        <div class="isDev" *ngIf="isDev">
            <p class="isDev-label">Ambiente de desarrollo</p>
        </div>
        <nz-header>
            <div class="app-header" style="background-color: #001529;">
                <div style="display: flex;justify-content: space-between;margin: 0px 70px 0px 30px;">
                    <div (click)="isCollapsed = !isCollapsed">
                        <i class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
                            style="font-size: 25px; color: #a9a9a9;"></i>
                    </div>
                    <div>
                        <app-notification-center></app-notification-center>
                    </div>
                </div>
            </div>
        </nz-header>
        <nz-content>
            <div class="inner-content">
                <router-outlet></router-outlet>
            </div>
        </nz-content>
        <nz-footer>Mi Nave by BTLC @2021</nz-footer>
    </nz-layout>
</nz-layout>