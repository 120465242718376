<div class="site-page-header">
  <h4 class="page-header-title">Formato de recepción de vehículo</h4>
  <h6 class="page-header-subtitle">{{ customer?.firstName }} {{ customer?.lastName }}</h6>
</div>
<nz-page-header class="site-page-header">
  <nz-page-header-content>
    <div class="content">
      <div class="main">
        <nz-descriptions class="custom-descriptions" nzSize="small">
          <nz-descriptions-item class="custom-descriptions-item" nzTitle="Vin">{{
            carInformation?.vin
            }}</nz-descriptions-item>
          <nz-descriptions-item class="custom-descriptions-item" nzTitle="Placas">{{
            carInformation?.plate
            }}</nz-descriptions-item>
          <nz-descriptions-item nzTitle="Marca" class="custom-descriptions-item">{{
            carInformation?.branch ? carInformation?.branch : "---"
            }}</nz-descriptions-item>
          <nz-descriptions-item nzTitle="Modelo" class="custom-descriptions-item">{{
            carInformation?.car_model_code
            }}</nz-descriptions-item>
          <nz-descriptions-item nzTitle="Color" class="custom-descriptions-item">{{
            carInformation?.color
            }}</nz-descriptions-item>
          <nz-descriptions-item nzTitle="Color" class="custom-descriptions-item">{{
            carInformation?.circulation_card
            }}</nz-descriptions-item>
        </nz-descriptions>
      </div>
    </div>
  </nz-page-header-content>
</nz-page-header>

<!-- **************** -->
<!-- Form starts here -->
<!-- **************** -->

<div nz-row>
  <div nz-col nzSpan="24">
    <form [formGroup]="this.returnForm" (ngSubmit)="createRequest()" style="margin: 10px;">
      <nz-form-item style="margin-bottom: 0;">
        <div class="form-label"> No. Póliza </div>
        <nz-form-control nzFlex="auto">
          <input nz-input type="text" formControlName="nopolicy" placeholder="No. Póliza" nzSize="default" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="margin-bottom: 0;">
        <div class="form-label"> Fecha de llegada </div>
        <nz-form-control nzFlex="auto">
          <input nz-input type="date" formControlName="arrivaldate" placeholder="Fecha llegada" nzSize="default" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="margin-bottom: 0;">
        <div class="form-label"> Fecha de salida </div>
        <nz-form-control nzFlex="auto">
          <input nz-input type="date" formControlName="departuredate" placeholder="Fecha salida" nzSize="default" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="margin-bottom: 0;">
        <div class="form-label"> Kilometraje de llegada</div>
        <nz-form-control nzFlex="auto">
          <input nz-input type="number" formControlName="arrivalmileage" placeholder="Kilometraje de llegada"
            nzSize="default" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="margin-bottom: 0;">
        <div class="form-label"> Kilometraje de salida</div>
        <nz-form-control nzFlex="auto">
          <input nz-input type="number" formControlName="outputmileage" placeholder="Kilometraje de salida"
            nzSize="default" />
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
</div>

<!-- ************** -->
<!-- Form ends here -->
<!-- ************** -->

<nz-divider nzText="Accesorios y herramientas"></nz-divider>

<table class="tools-table">
  <tr class="table-row">
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="rightmirror"></label>
        Espejo lateral derecho
      </div>
    </td>
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="windshield"></label>
        Parabrisas
      </div>
    </td>
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="oilbayonet"></label>
        Bayoneta aceite
      </div>
    </td>
  </tr>
  <tr class="table-row">
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="lefttmirror"></label>
        Espejo lateral izquierdo
      </div>
    </td>
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="rearmedallion"></label>
        Medallón tracero
      </div>
    </td>
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="crosskey"></label>
        Llave de cruz
      </div>
    </td>
  </tr>
  <tr class="table-row">
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="rearviewmirror"></label>
        Espejo retrovisor
      </div>
    </td>
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="doorglass"></label>
        Cristales de puertas (laterales)
      </div>
    </td>
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="hydraulicjack"></label>
        Gato
      </div>
    </td>
  </tr>
  <tr class="table-row">
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="rug"></label>
        Tapetes
      </div>
    </td>
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="connector"></label>
        Conector
      </div>
    </td>
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="frontplate"></label>
        Placa delantera
      </div>
    </td>
  </tr>
  <tr class="table-row">
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="cleaners"></label>
        Limpiadores
      </div>
    </td>
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="headlights"></label>
        Faros y luces
      </div>
    </td>
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="backplate"></label>
        Placa tracera
      </div>
    </td>
  </tr>
  <tr class="table-row">
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="horn"></label>
        Claxon
      </div>
    </td>
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="moldings"></label>
        Molduras
      </div>
    </td>
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="policy"></label>
        Póliza
      </div>
    </td>
  </tr>
  <tr class="table-row">
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="visors"></label>
        Viseras
      </div>
    </td>
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="skulls"></label>
        Calaveras
      </div>
    </td>
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="handles"></label>
        Manijas
      </div>
    </td>
  </tr>
  <tr class="table-row">
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="towhook"></label>
        Gancho de arrastre
      </div>
    </td>
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="defenses"></label>
        Defensas
      </div>
    </td>
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="verificationdoc"></label>
        Documento de verificación
      </div>
    </td>
  </tr>
  <tr class="table-row">
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="seatbelts"></label>
        Cinturones de seguridad
      </div>
    </td>
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="grill"></label>
        Parrilla
      </div>
    </td>
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="ac"></label>
        A/C
      </div>
    </td>
  </tr>
  <tr class="table-row">
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="antenna"></label>
        Antena
      </div>
    </td>
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="sparetire"></label>
        Llanta de refacción
      </div>
    </td>
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="safetytriangle"></label>
        Triángulo de seguridad
      </div>
    </td>
  </tr>
  <tr class="table-row">
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="radio"></label>
        Radio
      </div>
    </td>
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="wheelcaps"></label>
        Tapones de rueda
      </div>
    </td>
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="securitystuds"></label>
        Birlos de seguridad
      </div>
    </td>
  </tr>
  <tr class="table-row">
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="handbrake"></label>
        Freno de mano
      </div>
    </td>
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="gascap"></label>
        Tapón de gasolina
      </div>
    </td>
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="plasticpartition"></label>
        Mampara de plástico
      </div>
    </td>
  </tr>
  <tr class="table-row">
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="radiatorcap"></label>
        Tapón de radiador
      </div>
    </td>
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="oilplug"></label>
        Tapón de aceite
      </div>
    </td>
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="batterybrandcheck"></label>
        Marca de batería
      </div>
    </td>
  </tr>
  <tr class="table-row">
    <td class="table-col">
      <div class="table-item">
        <label nz-checkbox [(ngModel)]="tagcheck"></label>
        No. TAG
      </div>
    </td>
  </tr>
</table>

<!-- Second Form -->

<div nz-row>
  <div nz-col nzSpan="24">
    <form [formGroup]="this.tools_form">
      <nz-form-item style="margin-bottom: 0;" *ngIf="tagcheck">
        <div class="form-label">No. TAG</div>
        <nz-form-control nzFlex="auto">
          <input nz-input type="number" formControlName="tag" placeholder="Ingrese el número de tag" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="margin-bottom: 0;" *ngIf="batterybrandcheck">
        <div class="form-label">Marca de bateria</div>
        <nz-form-control nzFlex="auto">
          <input nz-input type="text" formControlName="battery_branch" placeholder="Ingrese la marca de la bateria" />
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
</div>


<br />
<nz-divider nzText="Carrocería e Interiores"></nz-divider>
<br />

<nz-table style="width: 100%" nzSize="small" [nzScroll]="{ x: '950px' }">
  <thead>
    <tr>
      <th>Descripción</th>
      <th>Bueno</th>
      <th>Regular</th>
      <th>Malo</th>
    </tr>
  </thead>
  <tr>
    <td>Costado derecho</td>
    <td>
      <label nz-checkbox [(ngModel)]="rightside.good"
        (ngModelChange)="rightside.good = $event; rightside.regular = false; rightside.bad = false"></label>
    </td>
    <td>
      <label nz-checkbox [(ngModel)]="rightside.regular"
        (ngModelChange)="rightside.regular = $event; rightside.good = false; rightside.bad = false"></label>
    </td>
    <td>
      <label nz-checkbox [(ngModel)]="rightside.bad"
        (ngModelChange)="rightside.bad = $event; rightside.good = false; rightside.regular = false"></label>
    </td>
  </tr>
  <tr>
    <td>Cajuela</td>
    <td>
      <label nz-checkbox [(ngModel)]="trunk.good"
        (ngModelChange)="trunk.good = $event; trunk.regular = false; trunk.bad = false"></label>
    </td>
    <td>
      <label nz-checkbox [(ngModel)]="trunk.regular"
        (ngModelChange)="trunk.regular = $event; trunk.good = false; trunk.bad = false"></label>
    </td>
    <td>
      <label nz-checkbox [(ngModel)]="trunk.bad"
        (ngModelChange)="trunk.bad = $event; trunk.good = false; trunk.regular = false"></label>
    </td>
  </tr>
  <tr>
    <td>Costado izquierdo</td>
    <td>
      <label nz-checkbox [(ngModel)]="leftside.good"
        (ngModelChange)="leftside.good = $event; leftside.regular = false; leftside.bad = false"></label>
    </td>
    <td>
      <label nz-checkbox [(ngModel)]="leftside.regular"
        (ngModelChange)="leftside.regular = $event; leftside.good = false; leftside.bad = false"></label>
    </td>
    <td>
      <label nz-checkbox [(ngModel)]="leftside.bad"
        (ngModelChange)="leftside.bad = $event; leftside.good = false; leftside.regular = false"></label>
    </td>
  </tr>
  <tr>
    <td>Pintura</td>
    <td>
      <label nz-checkbox [(ngModel)]="paint.good"
        (ngModelChange)="paint.good = $event; paint.regular = false; paint.bad = false"></label>
    </td>
    <td>
      <label nz-checkbox [(ngModel)]="paint.regular"
        (ngModelChange)="paint.regular = $event; paint.good = false; paint.bad = false"></label>
    </td>
    <td>
      <label nz-checkbox [(ngModel)]="paint.bad"
        (ngModelChange)="paint.bad = $event; paint.good = false; paint.regular = false"></label>
    </td>
  </tr>
  <tr>
    <td>Cofre</td>
    <td>
      <label nz-checkbox [(ngModel)]="chest.good"
        (ngModelChange)="chest.good = $event; chest.regular = false; chest.bad = false"></label>
    </td>
    <td>
      <label nz-checkbox [(ngModel)]="chest.regular"
        (ngModelChange)="chest.regular = $event; chest.good = false; chest.bad = false"></label>
    </td>
    <td>
      <label nz-checkbox [(ngModel)]="chest.bad"
        (ngModelChange)="chest.bad = $event; chest.good = false; chest.regular = false"></label>
    </td>
  </tr>
  <tr>
    <td>Sistema de alarma</td>
    <td>
      <label nz-checkbox [(ngModel)]="alarmsystem.good"
        (ngModelChange)="alarmsystem.good = $event; alarmsystem.regular = false; alarmsystem.bad = false"></label>
    </td>
    <td>
      <label nz-checkbox [(ngModel)]="alarmsystem.regular"
        (ngModelChange)="alarmsystem.regular = $event; alarmsystem.good = false; alarmsystem.bad = false"></label>
    </td>
    <td>
      <label nz-checkbox [(ngModel)]="alarmsystem.bad"
        (ngModelChange)="alarmsystem.bad = $event; alarmsystem.good = false; alarmsystem.regular = false"></label>
    </td>
  </tr>
  <tr>
    <td>Toldo</td>
    <td>
      <label nz-checkbox [(ngModel)]="awning.good"
        (ngModelChange)="awning.good = $event; awning.regular = false; awning.bad = false"></label>
    </td>
    <td>
      <label nz-checkbox [(ngModel)]="awning.regular"
        (ngModelChange)="awning.regular = $event; awning.good = false; awning.bad = false"></label>
    </td>
    <td>
      <label nz-checkbox [(ngModel)]="awning.bad"
        (ngModelChange)="awning.bad = $event; awning.good = false; awning.regular = false"></label>
    </td>
  </tr>
  <tr>
    <td>Vestidura</td>
    <td>
      <label nz-checkbox [(ngModel)]="clothing.good"
        (ngModelChange)="clothing.good = $event; clothing.regular = false; clothing.bad = false"></label>
    </td>
    <td>
      <label nz-checkbox [(ngModel)]="clothing.regular"
        (ngModelChange)="clothing.regular = $event; clothing.good = false; clothing.bad = false"></label>
    </td>
    <td>
      <label nz-checkbox [(ngModel)]="clothing.bad"
        (ngModelChange)="clothing.bad = $event; clothing.good = false; clothing.regular = false"></label>
    </td>
  </tr>
  <tr>
    <td>Exterior limpio</td>
    <td>
      <label nz-checkbox [(ngModel)]="cleanoutside.good"
        (ngModelChange)="cleanoutside.good = $event; cleanoutside.regular = false; cleanoutside.bad = false"></label>
    </td>
    <td>
      <label nz-checkbox [(ngModel)]="cleanoutside.regular"
        (ngModelChange)="cleanoutside.regular = $event; cleanoutside.good = false; cleanoutside.bad = false"></label>
    </td>
    <td>
      <label nz-checkbox [(ngModel)]="cleanoutside.bad"
        (ngModelChange)="cleanoutside.bad = $event; cleanoutside.good = false; cleanoutside.regular = false"></label>
    </td>
  </tr>
  <tr>
    <td>Interior limpios</td>
    <td>
      <label nz-checkbox [(ngModel)]="cleaninside.good"
        (ngModelChange)="cleaninside.good = $event; cleaninside.regular = false; cleaninside.bad = false"></label>
    </td>
    <td>
      <label nz-checkbox [(ngModel)]="cleaninside.regular"
        (ngModelChange)="cleaninside.regular = $event; cleaninside.good = false; cleaninside.bad = false"></label>
    </td>
    <td>
      <label nz-checkbox [(ngModel)]="cleaninside.bad"
        (ngModelChange)="cleaninside.bad = $event; cleaninside.good = false; cleaninside.regular = false"></label>
    </td>
  </tr>
</nz-table>
<p style="margin: 0cm; font-size: 15px">&nbsp;</p>

<br />
<nz-divider nzText="Llantas"></nz-divider>
<br />

<div nz-row style="width: 100%">
  <nz-table style="width: 100%" nzSize="small" [nzScroll]="{ x: '950px' }" [nzPageSize]="pageSize">
    <thead>
      <tr>
        <th>Descripción</th>
        <th>Nueva</th>
        <th>1/2 Vida</th>
        <th>1/4 Vida</th>
        <th>Lisa</th>
      </tr>
    </thead>
    <tr>
      <td>Delantera derecha</td>
      <td>
        <label nz-checkbox [(ngModel)]="rightfront.new"
          (ngModelChange)="rightfront.new = $event; rightfront.medium = false; rightfront.quarter = false; rightfront.smooth = false"></label>
      </td>
      <td>
        <label nz-checkbox [(ngModel)]="rightfront.medium"
          (ngModelChange)="rightfront.medium = $event; rightfront.new = false; rightfront.quarter = false; rightfront.smooth = false"></label>
      </td>
      <td>
        <label nz-checkbox [(ngModel)]="rightfront.quarter"
          (ngModelChange)="rightfront.quarter = $event; rightfront.new = false; rightfront.medium = false; rightfront.smooth = false"></label>
      </td>
      <td>
        <label nz-checkbox [(ngModel)]="rightfront.smooth"
          (ngModelChange)="rightfront.smooth = $event; rightfront.new = false; rightfront.medium = false; rightfront.quarter = false"></label>
      </td>
    </tr>
    <tr>
      <td>Trasera izquierda</td>
      <td>
        <label nz-checkbox [(ngModel)]="rearleft.new"
          (ngModelChange)="rearleft.new = $event; rearleft.medium = false; rearleft.quarter = false;rearleft.smooth = false"></label>
      </td>
      <td>
        <label nz-checkbox [(ngModel)]="rearleft.medium"
          (ngModelChange)="rearleft.medium = $event; rearleft.new = false; rearleft.quarter = false; rearleft.smooth = false"></label>
      </td>
      <td>
        <label nz-checkbox [(ngModel)]="rearleft.quarter"
          (ngModelChange)="rearleft.quarter = $event; rearleft.new = false; rearleft.medium = false; rearleft.smooth = false"></label>
      </td>
      <td>
        <label nz-checkbox [(ngModel)]="rearleft.smooth"
          (ngModelChange)="rearleft.smooth = $event; rearleft.new = false; rearleft.medium = false; rearleft.quarter = false"></label>
      </td>
    </tr>
    <tr>
      <td>Delantera izquierda</td>
      <td>
        <label nz-checkbox [(ngModel)]="leftfront.new"
          (ngModelChange)="leftfront.new = $event; leftfront.medium = false; leftfront.quarter = false; leftfront.smooth = false"></label>
      </td>
      <td>
        <label nz-checkbox [(ngModel)]="leftfront.medium"
          (ngModelChange)="leftfront.medium = $event; leftfront.new = false; leftfront.quarter = false; leftfront.smooth = false"></label>
      </td>
      <td>
        <label nz-checkbox [(ngModel)]="leftfront.quarter"
          (ngModelChange)="leftfront.quarter = $event; leftfront.new = false; leftfront.medium = false; leftfront.smooth = false"></label>
      </td>
      <td>
        <label nz-checkbox [(ngModel)]="leftfront.smooth"
          (ngModelChange)="leftfront.smooth = $event; leftfront.new = false; leftfront.medium = false; leftfront.quarter = false"></label>
      </td>
    </tr>
    <tr>
      <td>Refacción</td>
      <td>
        <label nz-checkbox [(ngModel)]="repair.new"
          (ngModelChange)="repair.new = $event; repair.medium = false; repair.quarter = false; repair.smooth = false"></label>
      </td>
      <td>
        <label nz-checkbox [(ngModel)]="repair.medium"
          (ngModelChange)="repair.medium = $event; repair.new = false; repair.quarter = false; repair.smooth = false"></label>
      </td>
      <td>
        <label nz-checkbox [(ngModel)]="repair.quarter"
          (ngModelChange)="repair.quarter = $event; repair.new = false; repair.medium = false; repair.smooth = false"></label>
      </td>
      <td>
        <label nz-checkbox [(ngModel)]="repair.smooth"
          (ngModelChange)="repair.smooth = $event; repair.new = false; repair.medium = false; repair.quarter = false"></label>
      </td>
    </tr>
    <tr>
      <td>Trasera derecha</td>
      <td>
        <label nz-checkbox [(ngModel)]="rightrear.new"
          (ngModelChange)="rightrear.new = $event; rightrear.medium = false; rightrear.quarter = false; rightrear.smooth = false"></label>
      </td>
      <td>
        <label nz-checkbox [(ngModel)]="rightrear.medium"
          (ngModelChange)="rightrear.medium = $event; rightrear.new = false; rightrear.quarter = false; rightrear.smooth = false"></label>
      </td>
      <td>
        <label nz-checkbox [(ngModel)]="rightrear.quarter"
          (ngModelChange)="rightrear.quarter = $event; rightrear.new = false; rightrear.medium = false; rightrear.smooth = false"></label>
      </td>
      <td>
        <label nz-checkbox [(ngModel)]="rightrear.smooth"
          (ngModelChange)="rightrear.smooth = $event; rightrear.new = false; rightrear.medium = false; rightrear.quarter = false"></label>
      </td>
    </tr>
    <tr>
      <td>
        <nz-form-label> Marca </nz-form-label>
      </td>
      <td colspan="4">
        <form [formGroup]="tireBrand">
          <nz-form-control nz-Size="small">
            <input nz-input type="text" placeholder="Marca" formControlName="brand" />
          </nz-form-control>
        </form>
      </td>
    </tr>
  </nz-table>
</div>

<br />
<nz-divider nzText="Gasolina"></nz-divider>

<div class="gasoline-container">
  <div nz-col class="gasoline-item">
    <h6 class="gasoline-title">Tanque de gasolina de entrada</h6>
    <div style="display: flex; justify-content: center;">
      <app-car-gasoline-guage-component (getGasoline)="inputGasoline = $event"></app-car-gasoline-guage-component>
    </div>
  </div>
  <div nz-col class="gasoline-item">
    <h6 class="gasoline-title">Tanque de gasolina de salida</h6>
    <div style="display: flex; justify-content: center">
      <app-car-gasoline-guage-component (getGasoline)="outputGasoline = $event"></app-car-gasoline-guage-component>
    </div>
  </div>
</div>

<br />
<nz-divider nzText="Observaciones"></nz-divider>
<br />

<div nz-row style="display: flex; justify-content: center;">
  <div nz-col style=" width: 100%;">
    <form [formGroup]="observationForm">
      <nz-form-control nz-Size="small">
        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
          <input type="text" nz-input placeholder="Ingresa la observación" formControlName="observation" />
        </nz-input-group>
        <ng-template #suffixIconButton>
          <button nz-button nzType="primary" nzSearch
            (click)="addObservation(observationForm.controls.observation.value)"><span nz-icon
              nzType="plus"></span></button>
        </ng-template>
      </nz-form-control>
    </form>
    <div nz-row>
      <div nz-col style=" width: 100%;">
        <nz-list nzBordered nzItemLayout="vertical">
          <nz-list-item *ngFor="let observation of observations; let i = index">
            <ng-container>
              <div class="list-main-container">
                <div class="list-item-delete">
                  <div>
                    <h6>Ovservación {{i+1}}</h6>
                  </div>
                  <button nz-button nzDanger nz-popconfirm nzPopconfirmTitle="¿Estas segur@ de eliminar la observación?"
                    nzOkText="Confirmar" nzCancelText="Cancelar" (nzOnConfirm)="removeObservation(observation)"
                    nzType="primary" style="display: flex; justify-content: center; text-align: center;">
                    <span nz-icon nzType="delete"></span>
                  </button>
                </div>
                <div class="list-container">
                  <div class="item-observation">
                    <p>{{observation.observation}}</p>
                  </div>
                  <div class="upload">
                    <div class="clearfix">
                      <nz-upload [nzCustomRequest]="handleUpload" nzListType="picture-card"
                        [(nzFileList)]="observation.file" [nzShowButton]="observation.file.length < 8">
                        <div>
                          <span nz-icon nzType="plus"></span>
                          <div style="margin-top: 8px">Upload</div>
                        </div>
                      </nz-upload>
                      <nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null"
                        (nzOnCancel)="previewVisible = false">
                        <ng-template #modalContent>
                          <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
                        </ng-template>
                      </nz-modal>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </ng-container>
          </nz-list-item>
        </nz-list>
      </div>
    </div>
  </div>
</div>

<br />
<nz-divider nzText="Requisición de pagos"></nz-divider>
<br />

<h4 class="page-header-title">Adeudo total</h4>
<br />

<div class="charge-btn">
  <button nz-button (click)="isCreateNewChargeFormVisible = true">Crear Cargo</button>
</div>

<nz-table #chargesTable [nzData]="this.transactions" [nzScroll]="{ x: '950px' }">
  <thead>
    <tr>
      <td>Cargos</td>
      <td>Monto del adeudo</td>
      <td>Fecha</td>
      <td>Balance</td>
      <td>Acciones</td>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of this.transactions; let i = index;">
      <td style="font-weight: bold; color: red;">{{ data.charge_type }}</td>
      <td style="font-weight: bold; color: red;">{{ data.amount }}</td>
      <td style="font-weight: 600;">{{ data.date }}</td>
      <td style="font-weight: 600;">{{ getBalance(i, data) }}</td>
      <td>
        <button nz-button nzType="danger" (click)="deleteCharge(data, i)">Eliminar</button>
      </td>
    </tr>
    <tr *ngIf="transactions.length > 0">
      <td style="font-weight: bold;">Total: </td>
      <td style="font-weight: bold;">{{ totalAmount }}</td>
    </tr>
  </tbody>
</nz-table>

<nz-form-item style="text-align: center">
  <nz-form-control><br />
    <button type="submit" nz-button nzType="primary" (click)="openSignatureModal()"
      [disabled]="validateDocument()">Enviar</button>
  </nz-form-control>
</nz-form-item>

<nz-modal nzTitle="Sección de firma" [(nzVisible)]="isVisible" (nzOnOk)="onSubmit()" (nzOnCancel)="handleCancel()">
  <form>
    <div style="display: flex; justify-content: center;">
      <div class="wrapper">
        <div class="signature-header">
          <p>{{signatureCustomerisHidden?'Firma supervisor':'Firma cliente'}}</p>
          <form *ngIf="signatureCustomerisHidden" [formGroup]="signatureForm">
            <nz-form-item>
              <nz-form-control nzErrorTip="Selecione un supervisor.">
                <nz-select nzSize="small" formControlName="supervisor" nzPlaceHolder="Selecione un supervisor.">
                  <nz-option nzValue="Alejandro Magaña Coello" nzLabel="Alejandro Magaña Coello"></nz-option>
                  <nz-option nzValue="Fátima Libertad Arámburo Barrios"
                    nzLabel="Fátima Libertad Arámburo Barrios"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </form>
        </div>
        <p *ngIf="!signatureCustomerisHidden"> {{customer?.firstName}} {{customer?.lastName}}</p>
        <div [hidden]="signatureCustomerisHidden">
          <canvas class="signature-pad" #canvassignaturecustomer width="400" height="300"></canvas>
        </div>
        <div [hidden]="!signatureCustomerisHidden">
          <canvas class="signature-pad" #canvassignaturesupervisor width="400" height="300"></canvas>
        </div>
        <div class="signature-btns">
          <button *ngIf="!signatureCustomerisHidden" nz-button nzType="primary" (click)="clearSignatureCustomer()">
            Limpiar firma cliente
          </button>
          <button *ngIf="signatureCustomerisHidden" nz-button nzType="primary" (click)="clearSignatureSupervisor()">
            Limpiar firma supervisor
          </button>
          <button *ngIf="signatureCustomerisHidden" nz-button nzType="primary"
            (click)="signatureCustomerisHidden=false">
            Firmar como cliente
          </button>
          <button *ngIf="!signatureCustomerisHidden" nz-button nzType="primary"
            (click)="signatureCustomerisHidden=true">
            Firmar como supervisor
          </button>
        </div>
      </div>
    </div>
  </form>
</nz-modal>

<nz-modal nzTitle="Tipo de recepción" [(nzVisible)]="isPageLoaded" (nzOnOk)="handleOkReception()"
  (nzOnCancel)="handleCancelReception()" [nzWidth]="600">
  <form [formGroup]="recepForm">
    <nz-form-item>
      <nz-form-label [nzSpan]="9" nzRequired>Tipo de recepción</nz-form-label>
      <nz-form-control nzErrorTip="Por favor selecione un tipo de recepción." nzSpan="15">
        <nz-select nzSize="large" formControlName="type" nzPlaceHolder="Selecciona un tipo de recepción">
          <nz-option nzValue="delivery" nzLabel="Entrega."></nz-option>
          <nz-option nzValue="return" nzLabel="Regreso."></nz-option>
          <nz-option nzValue="highRisk" nzLabel="Riesgo Alto."></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-modal>

<nz-modal [nzWidth]="400" [nzMaskClosable]="false" [(nzVisible)]="isCreateNewChargeFormVisible" nzTitle="Agregar cargo"
  (nzOnCancel)="isCreateNewChargeFormVisible=false" (nzOnOk)="isCreateNewChargeFormVisible=false" [nzFooter]="null">
  <ng-container *nzModalContent>
    <app-fleet-charges-form [customerId]="this.customerId"
      (formSubmitted)="handleNewChargeRequest($event)"></app-fleet-charges-form>
  </ng-container>
</nz-modal>