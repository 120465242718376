<nz-divider *ngIf="rental.agreementStatusId===10"
  [nzText]="'Marcar PAUSE de ' + rental?.customer?.firstName + ' ' + rental?.customer?.lastName + '    ['+ rental?.car.plate +']'"></nz-divider>
<nz-divider *ngIf="rental.agreementStatusId===130"
  [nzText]="'Marcar Reanudar Renta de ' + rental?.customer?.firstName + ' ' + rental?.customer?.lastName + '    ['+ rental?.car.plate +']'"></nz-divider>
<div nz-row nzJustify="center">
  <div nz-col nzSpan="3"></div>
  <div nz-col nzSpan="6">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" nz-form>
      <nz-form-item>
        <nz-form-label *ngIf="rental.agreementStatusId===10" [nzSpan]="9" nzRequired>Fecha de Pause</nz-form-label>
        <nz-form-label *ngIf="rental.agreementStatusId===130" [nzSpan]="9" nzRequired>Fecha de
          Reanudación</nz-form-label>
        <nz-form-control nzErrorTip="Por favor ingresa la fecha en la que se hizo el pause" nzSpan="15">
          <nz-date-picker nzSize="large" nzFormat="yyyy/MM/dd" formControlName="switchDate"></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9">Notas</nz-form-label>
        <nz-form-control nzErrorTip="" nzSpan="15">
          <textarea formControlName="note" nzSize="large" nz-input></textarea>
        </nz-form-control>
      </nz-form-item>
      <nz-card>
        <nz-card-meta [nzTitle]="'Vehículo'"></nz-card-meta>
        <div nz-row style="margin-top: 20px;">
          <div nz-col [nzSpan]="12">
            <p><span style="font-weight: 600;">Modelo: {{rental.car.carModelCode}}</span></p>
            <p><span style="font-weight: 600;">Placa: {{rental.car.plate}}</span></p>
            <p><span style="font-weight: 600;">Vin: {{rental.car.vin}}</span></p>
          </div>
          <div nz-col [nzSpan]="12">
          </div>
        </div>
      </nz-card>
      <nz-form-item nz-row style="margin-top: 20px;">
        <nz-form-control [nzSpan]="12" [nzOffset]="6">
          <button *ngIf="rental.agreementStatusId===10" type="submit" nz-button nzType="primary" nzBlock
            nzSize="large">Pausar</button>
          <button *ngIf="rental.agreementStatusId===130" type="submit" nz-button nzType="primary" nzBlock
            nzSize="large">Reanudar</button>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
  <div nz-col nzSpan="3"></div>
</div>