import { Component, Input, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { CarService } from 'src/app/services/car.service';

@Component({
  selector: 'app-available-cars-table',
  templateUrl: './available-cars-table.component.html',
  styles: [
  ]
})
export class AvailableCarsTableComponent implements OnInit {
  @Input() cars: any[] = [];
  addCarFormVisible = false;
  isUpdateCarFormVisible = false;
  carId = null;
  carDisplay: any[];
  constructor(
    private router: Router,
    private carService: CarService
  ) { }

  ngOnInit(): void {
    this.fetchCars();
  }

  openAddCarForm(): void {
    this.router.navigate(['/fleet/cars/add']);
  }

  openUpdateCarForm(carId): void {
    this.carId = carId;
    this.isUpdateCarFormVisible = !this.isUpdateCarFormVisible;
  }

  closeUpdateCarForm(event) {
    this.isUpdateCarFormVisible = !this.isUpdateCarFormVisible;
    this.fetchCars();
  }
  fetchCars(): void {
    this.carService.fetchCarsByStatus('AVAILABLE')
      .subscribe((data: any[]) => {
        this.cars = data;
        this.carDisplay = this.cars;
      });
  }
  search(event: any): void {
    event = event.toUpperCase();
    if(event.length === 0){
      this.carDisplay = this.cars;
      console.log(this.carDisplay);
    }else{
      this.carDisplay = this.cars.filter(value =>       
        value.plate.toUpperCase().includes(event) || 
        value.vin.toUpperCase().includes(event)
      );
    }
  }

  sendToInspect(carId): void {
    this.router.navigate(['/fleet/car/reception', carId]);
  }
  
}
