import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CarService } from "../../../services/car.service";
import { NotificationService } from "../../../services/notification.service";
import { BackofficeCoreService } from "../../../services/backoffice-core.service";
import * as uuid from 'uuid';
import { Router } from "@angular/router";

@Component({
  selector: 'app-register-car',
  templateUrl: './register-car.component.html',
  styles: [
  ]
})
export class RegisterCarComponent implements OnInit {
  form: FormGroup;
  constructor(
    private carService: CarService,
    private coreService: BackofficeCoreService,
    private notificationService: NotificationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      plate: new FormControl('', [Validators.required]),
      vin: new FormControl('', [Validators.required]),
      location: new FormControl(null, [Validators.required]),
      engineNumber: new FormControl(null, []),
      model: new FormControl('', [Validators.required]),
      year: new FormControl('', [Validators.required]),
      color: new FormControl(null, [Validators.required]),
      carType: new FormControl(null, [Validators.required]),
      notes: new FormControl(null, []),
      ownerRfc: new FormControl(null, []),
      purchaseDate: new FormControl(null, []),
      deliveryDate: new FormControl(null, []),
      circulationCard: new FormControl(null, []),
    });
  }
  onSubmit(): void {
    if (this.form.valid) {
      const request = {
        ...this.form.getRawValue(),
        carId: uuid.v4()
      };
      this.coreService.createCar(request).subscribe(
        value => {
          this.notificationService.createNotification('success', 'Vehiculo creado con éxito', 'El vehiculo se ha creado con exito con placas ' + request.plate);
          this.router.navigate(['/applications']);
        }, error => {
          this.notificationService.createNotification('error', 'Error', 'Error al registrar el vehiculo con placas ' + request.plate);
        }
      );
    } else {
      console.log(this.form.errors);
    }
  }
}
