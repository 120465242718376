import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NzUploadChangeParam, NzUploadFile} from 'ng-zorro-antd/upload';
import {environment} from '../../../../../environments/environment';
import {AuthService} from '../../../../services/auth.service';
import { Observable, Observer } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
@Component({
  selector: 'app-upload-file-form',
  templateUrl: './upload-file-form.component.html',
  styles: [
  ]
})
export class UploadFileFormComponent implements OnInit {
  @Output() fileUploaded: EventEmitter<any> = new EventEmitter<any>();
  @Input() documentId!: string;
  @Input() documentType: string;
  documentValid: boolean;
  documentsEndpoint = environment.documents_endpoint;
  constructor(private authService: AuthService,
    private msg: NzMessageService) { }

  ngOnInit(): void {
  }
  handleChange({ file, fileList }: NzUploadChangeParam): void {
    const status = file.status;
    if (status !== 'uploading') {
      console.log('Status is: ', status);
      console.log(file, fileList);
    }
    if (status === 'done') {
        console.log('HandleChange Done');
          this.fileUploaded.emit(file);
          //this.msg.success(`${file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      console.log('HandleChange Error');
      //this.msg.error(`${file.name} file upload failed.`);
    }
  }
  getEndpointToUploadFile(documentId: string): string {
      return `${this.documentsEndpoint}documents/${documentId}/files`;
  }
  getAuthHeaders(): any {
    return {
      Authorization: `Bearer ${this.authService.getCurrentToken()}`
    };
  }
  isPDF(file: NzUploadFile): boolean {
    const extension = file.name.split('.').pop()?.toLowerCase();
    return extension === 'pdf';
  }

  beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]): Observable<boolean> =>
  new Observable((observer: Observer<boolean>) => {
    if(this.documentType === 'RFC_OCR'){
      const isPDF = file.type === 'application/pdf';
      if (!isPDF) {
        this.msg.error('You can only upload PDF file!');
        observer.complete();
        return;
      }
      observer.next(isPDF);
    }else{
      observer.next(true);
    }
    observer.complete();
  });
}
