<button nz-button [disabled]="isDisabled" (click)="openForm()" nzType="primary">Create Paydown</button>
<nz-modal
nzTitle="Create Paydown"
[(nzVisible)]="isFormVisible"
(nzOnCancel)="isFormVisible=false"
(nzOnOk)="isFormVisible=false"
[nzFooter]="null"
>
  <app-create-paydown-form [chargeTypes]="chargeTypes" (submittedForm)="submitForm($event)"></app-create-paydown-form>
</nz-modal>
