export default class NewPaymentRequest {
  constructor(
    public customerId?: string,
    public paymentMethodId?: string,
    public amount?: number,
    public note?: string,
    public dueDate?: string,
    public createdBy?: string,
  ) {
  }
}
