import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '../auth/login/login.component';
import { PaydownsTableViewComponent } from "../collections/paydowns/paydowns-table-view/paydowns-table-view.component";
import { OnboardingViewComponent } from "../crm/applications/onboarding-view/onboarding-view.component";
import { AuthGuard } from '../guards/auth.guard';
import { RoleGuard } from '../guards/role.guard';
import { ActiveLeaseComponent } from "./agreements/active-lease/active-lease.component";
import { ActiveRentalComponent } from "./agreements/active-rental/active-rental.component";
import { CreateRentalComponent } from "./agreements/create-rental/create-rental.component";
import { FinishedAgreementsComponent } from "./agreements/finished-agreements/finished-agreements.component";
import { PauseCarComponent } from './agreements/pause-car/pause-car.component';
import { PerformRepoComponent } from "./agreements/perform-repo/perform-repo.component";
import { ReturnCarComponent } from "./agreements/return-car/return-car.component";
import { SubstituteCarComponent } from './agreements/substitute-car/substitute-car.component';
import { SwitchCarComponent } from "./agreements/switch-car/switch-car.component";
import { ActiveCustomersComponent } from './billing/active-customers/active-customers.component';
import { BillingStatementComponent } from './billing/billing-statement/billing-statement.component';
import { CollectionsComponent } from "./billing/collections/collections.component";
import { NewPaymentLinkComponent } from "./billing/links/new-payment-link/new-payment-link.component";
import { CustomerDetailComponent } from './customer/customer-detail/customer-detail.component';
import { CarsComponent } from './fleet/cars/cars.component';
import { RegisterCarComponent } from "./fleet/register-car/register-car.component";
import { ApprovedApplicationsComponent } from './onboarding/approved-applications/approved-applications.component';
import { DeniedApplicationsComponent } from './onboarding/denied-applications/denied-applications.component';
import { DidntTakeApplicationsComponent } from './onboarding/didnt-take-applications/didnt-take-applications.component';
import { OpenApplicationsComponent } from './onboarding/open-applications/open-applications.component';
import { PendingApproveApplicationsComponent } from './onboarding/pending-approve-applications/pending-approve-applications.component';
import { PagesComponent } from './pages.component';
import { CustomerInformationExternalShareComponent } from "./share/customer-information-external-share/customer-information-external-share.component";
import { ShareInformationLayoutComponent } from "./share/share-information-layout/share-information-layout.component";
import { CarReceptionComponent } from '../components/forms/fleet/car-reception-component/car-reception.component';

let role = JSON.parse(localStorage.getItem('roles'));
let route;
if (role != null) {
  switch (role[0]) {
    case 'MONITORING':
      route = { path: '', component: ActiveRentalComponent, data: { roles: ['ADMIN', 'MONITORING'] } };
      break;
    case 'ADMIN':
      route = { path: '', component: OpenApplicationsComponent, data: { roles: ['ADMIN'] } };
      break;
    default:
      localStorage.clear();
      route = { path: '', component: LoginComponent }
      break;
  }
} else {
  localStorage.clear();
  route = { path: '', component: LoginComponent }
}


const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    canActivate: [AuthGuard, RoleGuard],
    children: [
      { path: 'applications', component: OpenApplicationsComponent, data: { roles: ['ADMIN'] } },
      { path: 'onboarding', component: OnboardingViewComponent, data: { roles: ['ADMIN'] } },
      { path: 'approved', component: ApprovedApplicationsComponent, data: { roles: ['ADMIN'] } },
      { path: 'denied', component: DeniedApplicationsComponent, data: { roles: ['ADMIN'] } },
      { path: 'didnt-take', component: DidntTakeApplicationsComponent, data: { roles: ['ADMIN'] } },
      route
    ],
  },
  {
    path: 'share',
    component: ShareInformationLayoutComponent,
    children: [
      { path: 'customer/:id', component: CustomerInformationExternalShareComponent, data: { roles: ['MONITORING', 'ADMIN'] } }
    ],
    data: { roles: ['MONITORING', 'ADMIN'] }
  },
  {
    path: 'billing',
    component: PagesComponent,
    canActivate: [AuthGuard, RoleGuard],
    children: [
      { path: 'rentals', component: ActiveCustomersComponent, data: { roles: ['MONITORING', 'ADMIN'] } },
      { path: 'collections', component: CollectionsComponent, data: { roles: ['MONITORING', 'ADMIN'] } },
      { path: 'paydowns', component: PaydownsTableViewComponent, data: { roles: ['MONITORING', 'ADMIN'] } },
      { path: 'customer/:id', component: CustomerDetailComponent, data: { roles: ['MONITORING', 'ADMIN'] } },
      { path: 'statement/:id', component: BillingStatementComponent, data: { roles: ['MONITORING', 'ADMIN'] } },
      { path: 'links/new', component: NewPaymentLinkComponent, data: { roles: ['MONITORING', 'ADMIN'] } },
    ],
    data: { roles: ['MONITORING', 'ADMIN'] }
  },
  {
    path: 'agreements',
    component: PagesComponent,
    canActivate: [AuthGuard, RoleGuard],
    children: [
      { path: 'active/rental', component: ActiveRentalComponent, data: { roles: ['MONITORING', 'ADMIN'] } },
      { path: 'rental/:id/return', component: ReturnCarComponent, data: { roles: ['ADMIN'] } },
      { path: 'finished/rental', component: FinishedAgreementsComponent, data: { roles: ['MONITORING', 'ADMIN'] } },
      { path: 'rental/:id/switch', component: SwitchCarComponent, data: { roles: ['ADMIN'] } },
      { path: 'rental/:id/pause', component: PauseCarComponent, data: { roles: ['ADMIN'] } },
      { path: 'rental/:id/substitute', component: SubstituteCarComponent, data: { roles: ['ADMIN'] } },
      { path: 'rental/:id/repo', component: PerformRepoComponent, data: { roles: ['ADMIN'] } },
      { path: 'active/lease', component: ActiveLeaseComponent, data: { roles: ['MONITORING', 'ADMIN'] } },
      { path: 'create', component: CreateRentalComponent, data: { roles: ['ADMIN'] } },
    ],
    data: { roles: ['MONITORING', 'ADMIN'] }
  },
  {
    path: 'agreements',
    component: PagesComponent,
    canActivate: [AuthGuard, RoleGuard],
    children: [
      { path: 'create/:id', component: CreateRentalComponent, data: { roles: ['ADMIN'] } },
    ],
    data: { roles: ['ADMIN'] }
  },
  {
    path: 'uw',
    component: PagesComponent,
    canActivate: [AuthGuard, RoleGuard],
    children: [
      { path: 'pending', component: PendingApproveApplicationsComponent, data: { roles: ['ADMIN'] } }
    ],
    data: { roles: ['ADMIN'] }
  },
  {
    path: 'fleet',
    component: PagesComponent,
    canActivate: [AuthGuard, RoleGuard],
    children: [
      { path: 'cars', component: CarsComponent, data: { roles: ['ADMIN'] } },
      { path: 'cars/add', component: RegisterCarComponent, data: { roles: ['ADMIN'] } },
      { path: 'car/reception/:id', component: CarReceptionComponent, data: { roles: ['ADMIN'] } }
    ],
    data: { roles: ['ADMIN'] }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
