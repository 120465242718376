import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {DocumentsService} from "../../../services/documents.service";
import {ShareLinkServiceService} from "../../../services/share-link-service.service";
import {environment} from "../../../../environments/environment";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-customer-information-external-share',
  templateUrl: './customer-information-external-share.component.html',
  styles: [
  ]
})
export class CustomerInformationExternalShareComponent implements OnInit {
  magicLinkId = '';
  documents = [];
  constructor(
    private route: ActivatedRoute,
    private documentsService: DocumentsService,
    private shareLinkService: ShareLinkServiceService,
    private sanitizer: DomSanitizer
  ) {
    this.route.params.subscribe(
      params => {
        this.magicLinkId = params.id;
      }
    );
  }

  ngOnInit(): void {
    this.shareLinkService.findShareLink(this.magicLinkId).subscribe(
      res => {
        this.documentsService.fetchAnnonymousDocumentsByOwnerId(res.resourceUuid).subscribe(
          (docs: any[]) => {
            this.documents = docs;
          }
        );
      }
    );
  }
  buildEnpointForImages(fileId): string {
    return `${environment.documents_endpoint}documents/files/${fileId}`;
  }
  buildEnpointForPdf(fileId): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`https://docs.google.com/viewer?url=https://api.nave.mx/documents/documents/files/${fileId}&embedded=true`);
  }
}
