import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { CarService } from "../../../services/car.service";
import { NotificationService } from "../../../services/notification.service";
import { CustomerService } from "../../../services/customer.service";
import * as uuid from 'uuid';
import { CustomerApiService } from '../../../infrastructure/api/customer-api.service';
import Swal from 'sweetalert2';
import { CollectionsService } from '../../../services/collections.service';
import { CarInformationResponse } from '../../../domain/model/customer/car-information-response.model';
import { WeebFleetResponse } from 'src/app/domain/model/customer/weeb-fleet-response';
import { PalencaResponse } from '../../../domain/model/customer/palenca-response';
import * as moment from 'moment'

const endpoint = environment.core_endpoint;

@Component({
  selector: 'app-create-rental',
  templateUrl: './create-rental.component.html',
  styleUrls: ['./create-rental.component.css']
})
export class CreateRentalComponent implements OnInit {
  customerId: string;
  form: FormGroup;
  cars: any[];
  customer: any;
  checked: boolean = false;
  disabledDigitalAgreement: boolean = false;
  firstClause: boolean = false;
  secondClause: boolean = false;
  thirdClause: boolean = false;
  fourthClause: boolean = false;
  fifthClause: boolean = false;
  sixthClause: boolean = false;
  CarInformation: CarInformationResponse;
  webFleetInfo: WeebFleetResponse;
  palencaInfo: PalencaResponse;
  parkingAddress: any;
  address: any;
  surety: any;
  guarantor: any;
  inspectionDates: any[] = [];
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private carService: CarService,
    private notificationService: NotificationService,
    private customerService: CustomerService,
    private router: Router,
    private customerApiService: CustomerApiService,
    private collectionsService: CollectionsService,
  ) {
    this.route.params.subscribe(params => this.customerId = params.id);
  }

  fetchCarsAvailable(): void {
    this.carService.fetchCarsByStatus('AVAILABLE').subscribe(res => this.cars = res);
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      amount: new FormControl('', [Validators.required]),
      startDate: new FormControl('', [Validators.required]),
      dueDay: new FormControl(null, [Validators.required]),
      firstPaymentDate: new FormControl(null, [Validators.required]),
      firstRentalAmount: new FormControl(null, [Validators.required]),
      platforms: new FormControl('', [Validators.required]),
      vehicle: new FormControl(null, [Validators.required]),
      tag: new FormControl('', []),
      agreementType: new FormControl(null, [Validators.required]),
      guaranteeAmount: new FormControl('', [Validators.required]),
      ltoTerm: new FormControl(null, [Validators.min(0)]),
      ltoAmount: new FormControl('', [Validators.min(0)]),
      ltoStartDate: new FormControl(null, [Validators.min(0)]),
      ltoPaymentAmount: new FormControl('', [Validators.min(0)]),
      inspectionCalendar: new FormControl(null, [Validators.min(0)]),
    });
    this.fetchCarsAvailable();
    this.getCustomer();
    this.getCustomerInformation();
    this.form.controls.ltoAmount.setValue(0)
  }

  createStringInspectionDate(dates: any[]): string {
    if (dates.length === 0) {
      return "N/A"
    }
    let stringDate = ''
    dates.forEach((date, index) => {
      if (index === dates.length - 1)
        stringDate += date;
      else
        stringDate += date + ", "
    })
    return stringDate;
  }

  createAgreement(rentalRequest: any): void {
    const startDate = moment(this.form.controls.startDate.value).locale('es').format('dddd DD [de] MMMM [de] YYYY')
    const firstPaymentDate = moment(this.form.controls.firstPaymentDate.value).locale('es').format('dddd DD [de] MMMM [de] YYYY')
    let address = ''
    console.log('this.address.textAddress Create Agreement :>> ', this.address);
    this.address = this.address ?? false;
    if (this.address?.textAddress != null) {
      address = 'C.' + this.address?.street + ' No.Int ' + this.address?.intNumber + ' No.Ext ' + this.address?.extNumber + ' ' + this.address?.textAddress + ' C.P ' + this.address?.zipcode
    } else if (!this.address) {
      address = "N/A"
    } else {
      address = 'C.' + this.address?.street + ' No.Int ' + this.address?.intNumber + ' No.Ext ' + this.address?.extNumber + ' Col.' + this.address?.neighbourhood + ' Mun.' + this.address?.municipality + ' ' + (this.address != null ? this.address?.state : '  ') + ' C.P ' + this.address?.zipcode
    }

    let parkingAddress = ''
    console.log('this.address.textAddress Create Agreement:>> ', this.parkingAddress);
    this.parkingAddress = this.parkingAddress ?? false;
    if (this.parkingAddress?.textAddress != null) {
      parkingAddress = 'C.' + this.parkingAddress?.street + ' No.Int ' + this.parkingAddress?.intNumber + ' No.Ext ' + this.parkingAddress?.extNumber + ' ' + this.parkingAddress?.textAddress + ' C.P ' + this.parkingAddress?.zipcode
    }
    else if (!this.parkingAddress) {
      parkingAddress = "N/A"
    }
    else {
      parkingAddress = 'C.' + this.parkingAddress?.street + ' No.Int ' + this.parkingAddress?.intNumber + ' No.Ext ' + this.parkingAddress?.extNumber + ' Col.' + this.parkingAddress?.neighbourhood + ' Mun.' + this.parkingAddress?.municipality + ' ' + (this.parkingAddress != null ? this.parkingAddress?.state : '  ') + ' C.P ' + this.parkingAddress?.zipcode
    }

    let rentaInicial = 0;
    let montoSemanal = 0;

    if (!this.form.controls.firstRentalAmount.value) {
      rentaInicial = rentaInicial + this.form.controls.ltoAmount.value
    } else {
      rentaInicial = this.form.controls.firstRentalAmount.value + this.form.controls.ltoAmount.value
    }
    if (!this.form.controls.amount.value) {
      montoSemanal = montoSemanal + this.form.controls.ltoAmount.value
    } else {
      montoSemanal = this.form.controls.amount.value + this.form.controls.ltoAmount.value
    }

    const agreementRequest = {
      DIGITALAGREEMENT: this.checked,
      NOMBRE: this.customer.lastName ? this.customer.firstName + ' ' + this.customer.lastName : 'N/A',
      DOMICILIO: address,
      RFC: this.customer.rfc ? this.customer.rfc : 'N/A',
      CURP: this.customer.curp ? this.customer.curp : 'N/A',
      LICENCIA: this.customer.license ? this.customer.license : 'N/A',
      TELEFONO: this.customer.phone ? this.customer.phone : 'N/A',
      CORREOELECTRONICO: this.customer.email ? this.customer.email : 'N/A',
      MARCAYMODELO: (this.CarInformation.branch != null ? this.CarInformation.branch : '') + ' ' + (this.CarInformation.car_model_code ? this.CarInformation.car_model_code : 'N/A'),
      ANNO: this.CarInformation.year != null ? this.CarInformation.year : 'N/A',
      COLOREXTERIOR: this.CarInformation.color != null ? this.CarInformation.color : 'N/A',
      VIN: this.CarInformation.vin != null ? this.CarInformation.vin : 'N/A',
      PLACAS: this.CarInformation.plate != null ? this.CarInformation.plate : 'N/A',
      ODOMETRO: this.webFleetInfo.odometer != null ? this.webFleetInfo.odometer : 'N/A',
      NODEMOTOR: this.CarInformation.engine_number != null ? this.CarInformation.engine_number : 'N/A',
      NODETARJETA: this.CarInformation.circulation_card ? this.CarInformation.circulation_card : 'N/A',
      RESGUARDOVEHICULO: parkingAddress,
      FECHAINICIO: this.form.controls.startDate.value ? startDate : 'N/A',
      // PLAZOSEMANAS: this.form.controls.ltoTerm.value ? Math.round(this.form.controls.ltoTerm.value * 4) : 'N/A',
      PLAZOMESESLTO: this.form.controls.ltoTerm.value ? this.form.controls.ltoTerm.value : 'N/A',
      MONTOSEMANAL: montoSemanal ? montoSemanal : 'N/A',
      MONTOGARANTIA: this.form.controls.guaranteeAmount.value ? this.form.controls.guaranteeAmount.value : 'N/A',
      RENTAINICIAL: rentaInicial ? rentaInicial : 'N/A',
      PRIMERAFECHAPAGO: this.form.controls.firstPaymentDate.value ? firstPaymentDate : 'N/A',
      PRIMERMONTOPAGO: rentaInicial ? rentaInicial : 'N/A',
      NOMBREFIADOR: this.guarantor != null ? this.guarantor.name : 'N/A',
      DOMICILIOFIADOR: this.guarantor != null ? this.guarantor.address : 'N/A',
      TELEFONOFIADOR: this.guarantor != null ? this.guarantor.phone : 'N/A',
      CORREOELECTRONICOFIADOR: this.guarantor != null ? this.guarantor.email : 'N/A',
      FECHAFIRMAFIADOR: this.guarantor != null ? moment(this.guarantor.signDate).locale('es').format('dddd DD [de] MMMM [de] YYYY') : 'N/A',
      AFIANZADORA: this.surety != null ? this.surety.surety : 'N/A',
      NUMEROFIANZA: this.surety != null ? this.surety.suretyNumber : 'N/A',
      RAMOYTIPO: this.surety != null ? this.surety.type : 'N/A',
      VIGENCIAAFIANZADORA: this.surety != null ? moment(this.surety.endValidity).locale('es').format('dddd DD [de] MMMM [de] YYYY') : 'N/A',
      MONTOFIANZA: this.surety != null ? this.surety.amount : 'N/A',
      PROMITENTENOMBRE: this.customer.firstName + ' ' + this.customer.lastName,
      PROMITENTEDOMICILIO: address,
      PROMITENTERFC: this.customer.rfc,
      PROMITENTECURP: this.customer.curp,
      PROMITENTELICENCIA: this.customer.license ? this.customer.license : 'N/A',
      PROMITENTETELEFONO: this.customer.phone ? this.customer.phone : 'N/A',
      PROMITENTECORREOELECTRONICO: this.customer.email,
      FECHACONTRATOARRENDAMIENTO: this.form.controls.startDate.value ? startDate : 'N/A',
      PROMITENTEMARCOYMODELO: (this.CarInformation.branch != null ? this.CarInformation.branch : ' ') + ' ' + (this.CarInformation.car_model_code != null ? this.CarInformation.car_model_code : 'N/A'),
      PROMITENTEANNO: this.CarInformation.year != null ? this.CarInformation.year : 'N/A',
      PROMITENTECOLOREXTERIOR: this.CarInformation.color != null ? this.CarInformation.color : 'N/A',
      PROMITENTEVIN: this.CarInformation.vin != null ? this.CarInformation.vin : 'N/A',
      PROMITENTEPLACAS: this.CarInformation.plate != null ? this.CarInformation.plate : 'N/A',
      PROMITENTENOMOTOR: this.CarInformation.engine_number != null ? this.CarInformation.engine_number : 'N/A',
      PROMITENTENOTARJETA: this.CarInformation.circulation_card ? this.CarInformation.circulation_card : 'N/A',
      PROMITENTEFECHACELEBRACION: this.form.controls.startDate.value ? startDate : 'N/A',
      PROMITENTEPLAZOMESES: this.form.controls.ltoTerm.value ? this.form.controls.ltoTerm.value : 'N/A',
      INSPECTIONSDATES: this.createStringInspectionDate(this.inspectionDates),
      ACEPTOCAMBIO: this.firstClause ? 'Si' : 'No',
      ACEPTOBURODECREDITO: this.secondClause ? 'Si' : 'No',
      AUTORIZOPUBLICIDAD: this.thirdClause ? 'Si' : 'No',
      AUTORIZORECIBIRPUBLICIDAD: this.fourthClause ? 'Si' : 'No',
      RECIBIPOLIZA: this.fifthClause ? 'Si' : 'No',
      PROMITENTEACEPTOCAMBIO: this.firstClause ? 'Si' : 'No',
      PROMITENTEACEPTOBUROCREDITO: this.secondClause ? 'Si' : 'No',
      PROMITENTEAUTORIZOPUBLICIDAD: this.fourthClause ? 'Si' : 'No',
      PROMITENTEACEPTOPRIVACIDAD: this.sixthClause ? 'Si' : 'No',
      EXTERNALID: rentalRequest.rentalUuid,
      RENTALTYPE: this.form.controls.agreementType.value,
    }
    console.log('agreementRequest :>> ', JSON.stringify(agreementRequest));
    this.collectionsService.CreateMifielAgreement(this.customerId, agreementRequest,).subscribe((res) => {
      console.log('Mi Fiel Agreement', res.CreateMifielAgreementDto);
    }, error => {
      console.log(error);
    });
  }
  onSubmit(): void {
    const rentalRequest = {
      rentalUuid: uuid.v4(),
      customerId: this.customerId,
      startDate: this.form.controls.startDate.value,
      rentalAmount: this.form.controls.amount.value,
      duePay: this.form.controls.dueDay.value,
      carId: this.form.controls.vehicle.value.id,
      includesLto: this.form.controls.agreementType.value !== 'NORMAL'
        && this.form.controls.agreementType.value !== 'NORMAL_2',
      firstPaymentDate: this.form.controls.firstPaymentDate.value,
      firstRentalAmount: this.form.controls.firstRentalAmount.value,
      platforms: this.form.controls.platforms.value,
      ltoTerm: this.form.controls.ltoTerm.value,
      ltoAmount: this.form.controls.ltoAmount.value,
      ltoStartDate: this.form.controls.ltoStartDate.value,
      noTag: this.form.controls.tag.value,
      inspectionDates: this.inspectionDates
    };
    console.log(rentalRequest);

    if (this.checked) {
      const startDate = moment(this.form.controls.startDate.value).locale('es').format('dddd DD [de] MMMM [de] YYYY')
      const firstPaymentDate = moment(this.form.controls.firstPaymentDate.value).locale('es').format('dddd DD [de] MMMM [de] YYYY')
      let address = ''
      console.log('this.address.textAddress :>> ', this.address);
      this.address = this.address ?? false;
      if (this.address?.textAddress != null) {

        address = 'C.' + this.address?.street + ' No.Int ' + this.address?.intNumber + ' No.Ext ' + this.address?.extNumber + ' ' + this.address?.textAddress + ' C.P ' + this.address?.zipcode
      } else if (!this.address) {
        address = "N/A"
      }
      else {
        address = 'C.' + this.address?.street + ' No.Int ' + this.address?.intNumber + ' No.Ext ' + this.address?.extNumber + ' Col.' + this.address?.neighbourhood + ' Mun.' + this.address?.municipality + ' ' + (this.address != null ? this.address?.state : '  ') + ' C.P ' + this.address?.zipcode
      }

      let parkingAddress = ''
      this.parkingAddress = this.parkingAddress ?? false;
      console.log('this.address.textAddress :>> ', this.parkingAddress);
      if (this.parkingAddress?.textAddress != null) {

        parkingAddress = 'C.' + this.parkingAddress?.street + ' No.Int ' + this.parkingAddress?.intNumber + ' No.Ext ' + this.parkingAddress?.extNumber + ' ' + this.parkingAddress?.textAddress + ' C.P ' + this.parkingAddress?.zipcode
      } else if (!this.parkingAddress) {
        parkingAddress = "N/A"
      } else {
        parkingAddress = 'C.' + this.parkingAddress?.street + ' No.Int ' + this.parkingAddress?.intNumber + ' No.Ext ' + this.parkingAddress?.extNumber + ' Col.' + this.parkingAddress?.neighbourhood + ' Mun.' + this.parkingAddress?.municipality + ' ' + (this.parkingAddress != null ? this.parkingAddress?.state : '  ') + ' C.P ' + this.parkingAddress?.zipcode
      }

      Swal.fire({
        icon: 'warning',
        title: '¿Estas seguro de realizar esta áccion?',
        width: '800px',
        html: `
        <div style="text-align: left">
          <ul style="list-style: none;">
            <li><strong>Nombre:</strong> ${this.customer.firstName + ' ' + this.customer.lastName}</li>
            <li><strong>Domicilio:</strong> ${address}</li>
            <li><strong>Rfc:</strong> ${this.customer.rfc ? this.customer.rfc : 'N/A'}</li>
            <li><strong>Curp:</strong> ${this.customer.curp ? this.customer.curp : 'N/A'}</li>
            <li><strong>Licencia:</strong> ${this.customer.license ? this.customer.license : 'N/A'}</li>
            <li><strong>Teléfono:</strong> ${this.customer.phone ? this.customer.phone : 'N/A'}</li>
            <li><strong>Correo electrónico:</strong> ${this.customer.email ? this.customer.email : 'N/A'}</li>
            <li><strong>Marca y modelo:</strong> ${(this.CarInformation.branch != null ? this.CarInformation.branch : '  ') + ' ' + (this.CarInformation != null ? this.CarInformation.car_model_code : 'N/A')}</li>
            <li><strong>Año:</strong> ${this.CarInformation.year != null ? this.CarInformation.year : 'N/A'}</li>
            <li><strong>Color exterior:</strong> ${this.CarInformation.color != null ? this.CarInformation.color : 'N/A'}</li>
            <li><strong>Vin:</strong> ${this.CarInformation.vin != null ? this.CarInformation.vin : 'N/A'}</li>
            <li><strong>Placas:</strong> ${this.CarInformation.plate != null ? this.CarInformation.plate : 'N/A'}</li>
            <li><strong>Odometro:</strong> ${this.webFleetInfo.odometer ? this.webFleetInfo.odometer : 'N/A'}</li>
            <li><strong>No. motor:</strong> ${this.CarInformation.engine_number != null ? this.CarInformation.engine_number : 'N/A'}</li>
            <li><strong>No. tarjeta:</strong> ${this.CarInformation.circulation_card ? this.CarInformation.circulation_card : 'N/A'}</li>
            <li><strong>Resguardo vehículo:</strong>${parkingAddress}</li>
            <li><strong>Fecha de inicio:</strong> ${startDate}</li>            
            <li><strong>Plazo en meses (solo LTO):</strong> ${this.form.controls.ltoTerm.value ? this.form.controls.ltoTerm.value : 'N/A'}</li>
            <li><strong>Monto de la renta semanal:</strong> ${this.form.controls.amount.value + this.form.controls.ltoAmount.value}</li>
            <li><strong>Monto de la renta inicial (Pago inicial):</strong> ${this.form.controls.amount.value + this.form.controls.ltoAmount.value}</li>
            <li><strong>Monto del deposito en garantía:</strong> ${this.form.controls.guaranteeAmount.value}</li>
            <li><strong>Monto de la renta inicial(Pago inicial):</strong> ${this.form.controls.firstRentalAmount.value + this.form.controls.ltoAmount.value}</li>
            <li><strong>Primera fecha de pago:</strong> ${firstPaymentDate}</li>
            <li><strong>Monto del primer pago:</strong> ${this.form.controls.firstRentalAmount.value + this.form.controls.ltoAmount.value}</li>
            <li><strong>Nombre del fiador:</strong> ${this.guarantor != null ? this.guarantor.name : 'N/A'}</li>
            <li><strong>Domicilio del fiador:</strong> ${this.guarantor != null ? this.guarantor.address : 'N/A'} </li>
            <li><strong>Teléfono del fiador:</strong> ${this.guarantor != null ? this.guarantor.phone : 'N/A'}</li>
            <li><strong>correo electrónico del fiador:</strong> ${this.guarantor != null ? this.guarantor.email : 'N/A'} </li>
            <li><strong>Fecha de firma del fiador:</strong> ${this.guarantor != null ? this.guarantor.signatureDate : 'N/A'}</li>
            <li><strong>Afianzadora:</strong> ${this.surety != null ? this.surety.surety : 'N/A'}</li>
            <li><strong>Número de fianza:</strong> ${this.surety != null ? this.surety.suretyNumber : 'N/A'}</li>
            <li><strong>Ramo / Tipo :</strong> ${this.surety != null ? this.surety.type : 'N/A'}</li>
            <li><strong>Fecha de vencimiento de la fianza:</strong> ${this.surety != null ? this.surety.endValidity : 'N/A'}</li>
            <li><strong>Monto de la afianzadora:</strong> ${this.surety != null ? this.surety.amount : 'N/A'}</li>
            <li><strong>Nombre del promitente:</strong> ${this.customer.firstName + ' ' + this.customer.lastName}</li>
            <li><strong>Domicilio del promitente:</strong> ${address}</li>
            <li><strong>Rfc del promitente:</strong> ${this.customer.rfc ? this.customer.rfc : 'N/A'}</li>
            <li><strong>Curp del promitente:</strong> ${this.customer.curp ? this.customer.curp : 'N/A'}</li>
            <li><strong>Licencia del promitente:</strong> ${this.customer.license ? this.customer.license : 'N/A'}</li>
            <li><strong>Teléfono del promitente:</strong> ${this.customer.phone ? this.customer.phone : 'N/A'}</li>
            <li><strong>Correo electrónico del promitente:</strong> ${this.customer.email ? this.customer.email : 'N/A'}</li>
            <li><strong>Fecha de contrato de arrendamiento:</strong> ${this.form.controls.startDate.value ? startDate : 'N/A'}</li>
            <li><strong>Marca y modelo del promitente:</strong> ${(this.CarInformation.branch != null ? this.CarInformation.branch : ' ') + ' ' + (this.CarInformation.car_model_code != null ? this.CarInformation.car_model_code : 'N/A')}</li>
            <li><strong>Año del promitente:</strong> ${this.CarInformation.year != null ? this.CarInformation.year : 'N/A'}</li>
            <li><strong>Color exterior del promitente:</strong> ${this.CarInformation.color != null ? this.CarInformation.color : 'N/A'}</li>
            <li><strong>Vin del promitente:</strong> ${this.CarInformation.vin != null ? this.CarInformation.vin : 'N/A'}</li>
            <li><strong>Placas del promitente:</strong> ${this.CarInformation.plate != null ? this.CarInformation.plate : 'N/A'}</li>
            <li><strong>No. motor del promitente:</strong> ${this.CarInformation.engine_number != null ? this.CarInformation.engine_number : 'N/A'}</li>
            <li><strong>No. tarjeta del promitente:</strong> ${this.CarInformation.circulation_card ? this.CarInformation.circulation_card : 'N/A'}</li>
            <li><strong>Fecha de celebración promitente:</strong> ${this.form.controls.startDate.value ? startDate : 'N/A'}</li>
            <li><strong>Plazo en meses del promitente:</strong> ${this.form.controls.ltoTerm.value ? this.form.controls.ltoTerm.value : 'N/A'}</li>
            <li><strong>Calendario de inspecciones:</strong> ${this.createStringInspectionDate(this.inspectionDates)}</li>
            <li><strong> Declaro que la información establecida en el presente es verdadera, reconozco y ratifico las condiciones del vehículo, los términos del arrendamiento y las obligaciones contenidas en el contrato de la presente carátula. Asimismo, me comprometo a informar de cualquier cambio en mis datos de contacto, dentro de los 2 (dos) días siguientes a que lo realice: </strong> ${this.firstClause ? 'Si' : 'No'}</li>
            <li><strong>Autorizo por el presente que BTLC Latam, S. de R.L. de C.V. consulte con todas las agencias que emitan reportes crediticios, mi reporte crediticio, así como cualquier reporte legal, judicial o de otro tipo, y use dichos reportes e historiales de crédito y empleo a fin de revisar mi situación personal y crediticia, monitorear el Vehículo y/o para fines de cobranza en la medida permitida por la ley sin que BTLC Latam, S. de R.L. de C.V. requiera especificar un fin en particular: </strong> ${this.secondClause ? 'Si' : 'No'}</li>
            <li><strong>Autorizo que mis datos personales sean utilizados con fines publicitarios: </strong> ${this.thirdClause ? 'Si' : 'No'}</li>
            <li><strong>Autorizo a recibir publicidad o información promocional: </strong> ${this.fourthClause ? 'Si' : 'No'}</li>
            <li><strong>Reconozco conocer el contenido y alcance del Aviso de Privacidad del Proveedor, el cual se encuentra a mi alcance en www.nave.mx: </strong> ${this.sixthClause ? 'Si' : 'No'}</li>
            <li><strong>Recibí póliza del seguro:</strong> ${this.fifthClause ? 'Si' : 'No'}</li>
          </ul>
        </div>`,
        showCancelButton: true,
        confirmButtonText: 'Si, crear contrato digital y renta',
        cancelButtonText: 'Cancelar'
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.http.put(`${endpoint}agreements`, rentalRequest, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
              }
            }).toPromise().then(
              responseMifiel => {
                console.info({ responseMifiel });
                this.createAgreement(rentalRequest);
                this.notificationService.createNotification('success', 'Renta Creada', 'Renta creada con éxito para '
                  .concat(this.customer.firstName, ' ', this.customer.lastName));
                this.router.navigate(['/applications']);
              }, error => {
                // this.createAgreement(rentalRequest);
                this.notificationService.createNotification('error', 'Error', error.error.errorMessage);
                /* si hay error de que ya existe el id destruir el obj */
              }
            );
          }
        });

    } else {
      this.http.put(`${endpoint}agreements`, rentalRequest, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }).toPromise().then(
        res => {
          this.notificationService.createNotification('success', 'Renta Creada', 'Renta creada con éxito para '
            .concat(this.customer.firstName, ' ', this.customer.lastName));
          this.createAgreement(rentalRequest);
          this.router.navigate(['/applications']);
        }, error => {
          this.notificationService.createNotification('error', 'Error', error.error.errorMessage);
          /* si hay error de que ya existe el id destruir el obj */
        }
      );
    }
  }

  getCustomer(): void {
    this.customerService.findCustomer(this.customerId).subscribe((res) => {
      this.customer = res;
    });
  }

  onChangeRentType(e: any): any {
    if (e === 'NORMAL' || e === 'NORMAL_2') {
      this.form.controls.ltoAmount.setValue(0)
    }
    // if (e === 'NORMAL_2' || e === 'NORMAL_LTO_2') {
    //   this.checked = false;
    //   this.disabledDigitalAgreement = true;
    // }
    // else {
    //   this.checked = false;
    //   this.disabledDigitalAgreement = false;
    // }
  }

  onChange(e: EventEmitter<any[]>): void {
    this.collectionsService.CarInformationByPlate(e.toString()).subscribe((res) => {
      this.CarInformation = res;
    });
  }

  async getCustomerInformation(): Promise<void> {
    await this.customerApiService.fetchGuarantor(this.customerId).toPromise().then(res => [this.guarantor] = res, error => this.guarantor = null);
    await this.customerApiService.fetchCustomerAddress(this.customerId).toPromise().then(res => {
      this.address = res.filter(address => address.addressType === 1002)[0];
      this.parkingAddress = res.filter(address => address.addressType === 1003)[0];
    }, error => this.address = null);
    await this.customerApiService.fetchSurety(this.customerId).toPromise().then((res) => {
      [this.surety] = res
    }, error => this.surety = null);
    await this.collectionsService.fetchWeebFleetInformation(this.customerId).toPromise().then((res) => {
      this.webFleetInfo = res;
    }, (error) => {
      this.webFleetInfo = this.setWebfleet()
    });
    if(
      (this.customer?.ocr === null && !this.customer?.isTaxeable) || 
      (this.customer?.ocr === false && !this.customer?.isTaxeable) || 
      (this.customer?.ocr === true && !this.customer?.isTaxeable) || 
      (!this.customer?.blacklistSat && !this.customer?.isTaxeable)){
      Swal.fire({
        icon: 'warning',
        title: 'Solo puedes continuar si el conductor tiene agregado el siguiente documento: Constancia Situación Fiscal QR (OCR), de lo contrario marcar que el conductor factura como publico en general ',
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(['/applications']);
        } else {
          this.router.navigate(['/applications']);
        }
      });
    }else{
      this.warningMessage(
        (this.guarantor != null ? false : true),
        (this.address != null ? false : true),
        (this.parkingAddress != null ? false : true),
        (this.surety != null ? false : true),
        (this.webFleetInfo.id != null ? false : true));
    }
    
  }

  warningMessage(guarantor: boolean,
    address: boolean,
    parkingAddress: boolean,
    Surety: boolean,
    webFleet: boolean): void {
    const warnings = {
      guarantor: guarantor ? 'Fiador' : null,
      address: address ? 'Domicilio' : null,
      parkingAddress: parkingAddress ? 'Domicilio de estacionamiento' : null,
      Surety: Surety ? 'Afianzadora' : null,
      webFleet: webFleet ? 'Informacion de Webfleet' : null
    }

    Swal.fire({
      icon: 'warning',
      title: 'Debes agregar la siguiente información para poder continuar',
      html: `<ul style="display: flex; flex-direction: column; align-items: start;"> 
              ${warnings.guarantor ? `<li>${warnings.guarantor}</li>` : ''}
              ${warnings.address ? `<li class="li" >${warnings.address}</li>` : ''}
              ${warnings.parkingAddress ? `<li class="li">${warnings.parkingAddress}</li>` : ''}
              ${warnings.Surety ? `<li class="li">${warnings.Surety}</li>` : ''}
              ${warnings.webFleet ? `<li class="li">${warnings.webFleet}</li>` : ''}
            </ul>`,
    });
  }

  setWebfleet(): WeebFleetResponse {
    return new WeebFleetResponse(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null);
  }

  disabledButton(): boolean {
    if (this.form.valid) {
      if (this.checked) {
        if (this.firstClause && this.secondClause) {
          return false;
        }
      } else {
        return false;
      }
    }
    return true;
  }

  onOk(result: Date | null): void {
    this.inspectionDates.push(moment(result).locale('es').format('dddd DD [de] MMMM [de] YYYY [a las] HH[:]mm'));
    this.form.controls.inspectionCalendar.setValue(null);
  }

  removeDate(index: number): void {
    this.inspectionDates.splice(index, 1);
  }
}