<nz-upload
  nzType="drag"
  [nzMultiple]="true"
  [nzAction]="getEndpointToUploadFile(documentId)"
  [nzHeaders]="getAuthHeaders()"
  [nzBeforeUpload]="beforeUpload"
  (nzChange)="handleChange($event)"
>
  <p class="ant-upload-drag-icon">
    <i nz-icon nzType="inbox"></i>
  </p>
  <p class="ant-upload-text">Clic o arrastra un archivo a este recuadro para subirlo</p>
  <p class="ant-upload-hint">
    Soporta la subida de uno o varios archivos a la vez.
  </p>
</nz-upload>
