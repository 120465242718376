import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Router } from "@angular/router";

@Component({
    selector: 'app-notification-list-center',
    templateUrl: './notification-list-center.component.html',
    styles: [
    ]
})
export class NotificationListCenterComponent implements OnInit {
    @Input() notificationsList: [];
    @Input() isVisibleNotificationCenter: any;
    @Output() closeModalNotificationListEventEmitter = new EventEmitter<any>();
    @Output() clearNotificationsEventEmitter = new EventEmitter<any>();
    @Output() updateNotificationsEventEmitter = new EventEmitter<any>();

    constructor(
        private router: Router
    ) { }

    ngOnInit(): void {
    }

    closeNotificationList(value: boolean) {
        this.closeModalNotificationListEventEmitter.emit(value);
    }

    clearNotifications() {
        this.clearNotificationsEventEmitter.emit();
    }

    updateNotifications() {
        this.updateNotificationsEventEmitter.emit();
    }
}




