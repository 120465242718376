import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaydownsTableViewComponent } from './paydowns/paydowns-table-view/paydowns-table-view.component';
import {NzTableModule} from "ng-zorro-antd/table";
import {NzBadgeModule} from "ng-zorro-antd/badge";
import { ChargesTableViewComponent } from './paydowns/charges-table-view/charges-table-view.component';
import {NzPageHeaderModule} from "ng-zorro-antd/page-header";
import {NzRadioModule} from "ng-zorro-antd/radio";
import {NzSpaceModule} from "ng-zorro-antd/space";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NzTagModule} from "ng-zorro-antd/tag";
import {NzButtonModule} from "ng-zorro-antd/button";
import { CreatePaydownFormComponent } from './paydowns/create-paydown-form/create-paydown-form.component';
import {NzModalModule} from "ng-zorro-antd/modal";
import { CreatePaydownModalButtonComponent } from './paydowns/button-actions/create-paydown-modal-button/create-paydown-modal-button.component';
import {NzFormModule} from "ng-zorro-antd/form";
import {NzSelectModule} from "ng-zorro-antd/select";
import {NzInputModule} from "ng-zorro-antd/input";
import { ChooseCollectionAgentAssignmentFormComponent } from './assignments/choose-collection-agent-assignment-form/choose-collection-agent-assignment-form.component';
import {NaveComponentsModule} from "../components/nave-components.module";



@NgModule({
  declarations: [PaydownsTableViewComponent, ChargesTableViewComponent, CreatePaydownFormComponent, CreatePaydownModalButtonComponent, ChooseCollectionAgentAssignmentFormComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NzTableModule,
        NzBadgeModule,
        NzPageHeaderModule,
        NzRadioModule,
        NzSpaceModule,
        NzTagModule,
        NzButtonModule,
        NzModalModule,
        NzFormModule,
        NzSelectModule,
        NzInputModule,
        NaveComponentsModule,
    ],
    exports: [
        PaydownsTableViewComponent,
        CreatePaydownModalButtonComponent,
        ChooseCollectionAgentAssignmentFormComponent
    ]
})
export class CollectionsModule { }
