<nz-divider [nzText]="'Terminar contrato de ' + rental?.customer?.firstName + ' ' + rental?.customer?.lastName  + ' con el vehículo : ' + rental?.car.plate"></nz-divider>
<div nz-row nzJustify="center">
  <div nz-col nzSpan="2"></div>
  <div nz-col nzSpan="8">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" nz-form>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>Fecha de Finalización</nz-form-label>
        <nz-form-control nzErrorTip="Por favor ingresa la fecha en la que se hizo el return" nzSpan="15">
          <nz-date-picker nzSize="large" nzFormat="yyyy/MM/dd" formControlName="endDate"></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>Tipo de Término</nz-form-label>
        <nz-form-control nzErrorTip="Por favor selecciona una tipo" nzSpan="15">
          <nz-select nzSize="large" formControlName="endReason" nzPlaceHolder="Selecciona un tipo">
            <nz-option nzValue="RETURN" nzLabel="Return"></nz-option>
            <nz-option nzValue="REPO" nzLabel="Repo"></nz-option>
            <nz-option nzValue="STOLEN" nzLabel="Robo (recuperado)"></nz-option>
            <nz-option nzValue="STOLEN_NO_RECOVERED" nzLabel="Robo (no recuperado)"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>Razón</nz-form-label>
        <nz-form-control nzErrorTip="Por favor selecciona una razón" nzSpan="15">
          <nz-select nzSize="large" formControlName="endReason" nzPlaceHolder="Selecciona una razón">
            <nz-option *ngFor="let r of endReasons" [nzValue]="r.id" [nzLabel]="r.name"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9">Notas</nz-form-label>
        <nz-form-control nzErrorTip="" nzSpan="15">
          <textarea formControlName="notes" nzSize="large" nz-input></textarea>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control [nzSpan]="12" [nzOffset]="6">
          <button type="submit" nz-button nzType="primary" nzBlock nzSize="large" >Terminar contrato</button>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
  <div nz-col nzSpan="2"></div>
</div>
