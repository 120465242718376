import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../services/user.service';
import {Router} from '@angular/router';
import {NzNotificationService} from "ng-zorro-antd/notification";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: [
    './login.component.css'
  ]
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  constructor(private userService: UserService,
              private router: Router,
              public notification: NzNotificationService) { }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      username: new FormControl('' || null, [Validators.required]),
      password: new FormControl('' || null, [Validators.required]),
    });
  }
  onSubmit(): void {
    const loginData = {
      username: this.loginForm.controls.username.value,
      password: this.loginForm.controls.password.value
    };
    this.userService.login(loginData).subscribe((data) => {
      if(data['roles'][0] != null){
      switch (data['roles'][0]) {
        case 'MONITORING':
          this.router.navigateByUrl('/agreements/active/rental');
          break;
        case 'ADMIN':
          this.router.navigateByUrl('/applications');
          break;
          //checar el defaults
      }
    }else{
      this.router.navigateByUrl('/login');
    }
    }, (error) => {
      console.log('Ocurrrio un error', error);
      this.showMessage('error', 'Inicio de sesión fallido', 'La contraseña o nombre de usuario no son correctos');
    });
  }
  showMessage(type: string, title: string, message: string): void {
    this.notification.create(
      type,
      title,
      message,
      { nzDuration: 7000 }
    );
  }
}
