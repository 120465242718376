import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-approved-table-view',
  templateUrl: './approved-table-view.component.html',
  styleUrls: ['./approved-table-view.component.css']
})
export class ApprovedTableViewComponent implements OnInit {
  approvedCustomers: any[] = [];
  constructor() { }

  ngOnInit(): void {
    this.approvedCustomers = [1,2,3,4,5,6];
  }

  ViewCustomerDetail(item):void{

  }

  createContract(item){

  }

}
