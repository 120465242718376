import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import * as moment from 'moment';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApplicationService } from 'src/app/services/application.service';
import Swal from 'sweetalert2';
import { environment } from '../../../../environments/environment';
import { Charge } from '../../../collections/models/Chargev2';
import { OnboardingService } from '../../../crm/services/onboarding.service';
import { BillingStatement } from '../../../domain/model/billing-statement.model';
import { CarInformationResponse } from '../../../domain/model/customer/car-information-response.model';
import { PalencaResponse } from '../../../domain/model/customer/palenca-response';
import { Transaction } from '../../../domain/model/customer/transaction.model';
import { WeebFleetResponse } from '../../../domain/model/customer/weeb-fleet-response';
import { TransactionsFacade } from '../../../domain/transactions.facade';
import { CustomerApiService } from '../../../infrastructure/api/customer-api.service';
import NewChargeRequest from '../../../model/billing/request/NewChargeRequest';
import NewPaymentRequest from '../../../model/billing/request/NewPaymentRequest';
import { Customer } from '../../../model/onboarding/Customer';
import { AuthService } from '../../../services/auth.service';
import { BackofficeCoreService } from '../../../services/backoffice-core.service';
import { CollectionsService } from '../../../services/collections.service';
import { CustomerService } from '../../../services/customer.service';
import { DocumentsService } from '../../../services/documents.service';
import { InvoiceService } from '../../../services/invoice.service';
import { RentalService } from '../../../services/rental.service';
import { ShareLinkServiceService } from '../../../services/share-link-service.service';
import OnboardingOperations from '../../onboarding/OnboardingOperations';

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styles: [],
})
export class CustomerDetailComponent
  extends OnboardingOperations
  implements OnInit {
  timeActive: number = null;
  nonPaymenRisk: string;
  interviewRisk: string;
  panels = [
    {
      active: false,
      name: 'Vehículo',
      disabled: false,
    },
    {
      active: false,
      name: 'WebFleet',
      disabled: false,
    },
    {
      active: false,
      name: 'Palenca',
      disabled: false,
    },
    {
      active: false,
      name: 'Direcciones',
      disabled: false,
    },
    {
      active: false,
      name: 'Contratos',
      disabled: false,
    },
    {
      active: false,
      name: 'Teléfonos',
      disabled: false,
    }, {
      active: false,
      name: 'Contratos Digitales',
      disabled: false,
    },
  ];
  allowed: boolean = false;
  allowedRoles: string[] = ['ADMIN'];
  palencaInformation: PalencaResponse[] = [];
  addresses: any[] = [];
  WeebFleetInformation: WeebFleetResponse[] = [];
  CarInformation: CarInformationResponse[] = [];
  billingStatement: BillingStatement;
  expandSet = new Set<string>();
  charges: Charge[] = [];
  url: string;
  palencaLinkSafe: string =
    'https://connect.palenca.com/?widget_id=44abc47b-9bad-4d35-a3a7-a51ab215e6c2';
  // Key entities and arrays
  palencaWidget: SafeResourceUrl;
  palencaLink: string;
  miFielLink: string;
  customerId: string = '';
  customer: Customer;
  documents = [];
  transactions: Transaction[] = [];
  tempBalances = {};
  activeRental: any = {};
  activeRentals: any[] = [];
  editableChargeSelected = {};
  editablePaymentSelected = {};
  // Calculated variables
  balance = 0;
  securityDepositAccountBalance = 0;
  tempBalance = this.balance;
  // Style stuff
  gridStyle = {
    width: '33.3%',
    textAlign: 'center',
  };
  // Form Visibility variables
  isCreateNewChargeFormVisible = false;
  isCreateNewPaymentFormVisible = false;
  isEditChargeFormVisible = false;
  isEditPaymentFormVisible = false;
  // Lookup Arrays
  chargeTypes = [];
  paymentMethods = [];
  activeCustomers = [];
  public invoiceError: string;
  paymentMethodsAllowedToEdit = [
    'DEPOSIT_REFOUND',
    'UBER_FLEET',
    'DIDI_FLEET',
    'DISCOUNT',
    'APPLY_SECURITY',
    'INCENTIVE_UBER',
    'INCENTIVE_DIDI',
    'INCENTIVE_BEAT',
  ];
  isCustomerActive: boolean = null;
  weeksActive: number = null;
  customerAgreements: any[] = [];
  customerDigitalAgreements: any[] = [];
  customerPhones: any[] = [];
  isTaxable = false;
  invoiceStatusLoading = false;
  constructor(
    private route: ActivatedRoute,
    public customerService: CustomerService,
    public documentsService: DocumentsService,
    public collectionsService: CollectionsService,
    private sanitizer: DomSanitizer,
    public notificationService: NzNotificationService,
    public rentalService: RentalService,
    private shareLinkService: ShareLinkServiceService,
    private coreService: BackofficeCoreService,
    private router: Router,
    private transactionsFacade: TransactionsFacade,
    private authService: AuthService,
    public customerApiService: CustomerApiService,
    public applicationService: ApplicationService,
    private onboardingService: OnboardingService,
    private InvoiceService: InvoiceService,
  ) {
    super(
      applicationService,
      documentsService,
      notificationService,
      customerService,
      rentalService,
      customerApiService,
      collectionsService
    );
    this.route.params.subscribe((params) => {
      this.customerId = params.id;
    });
    this.allowed =
      this.authService
        .getCurrentRole()
        .filter((role) => this.allowedRoles.includes(role)).length > 0;
    /* "COMMERCIAL_ADMIN" */
  }

  ngOnInit(): void {
    this.fetchAllCustomerPhones();
    this.fetchAllCustomerAgreements();
    if (!this.allowed) {
      this.panels.splice(2, 1);
    } else {
      this.fetchPalencaInformation();
    }
    this.fetchWeebFletInformation();
    this.fetchCarInformation();
    this.fetchAddresses();
    this.palencaWidget = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.palencaLinkSafe
    );
    this.palencaLink = this.palencaLinkSafe + '&external_id=' + this.customerId;
    this.url = `billing/statement/${this.customerId}`;
    this.transactionsFacade
      .getBillingStatement(this.customerId)
      .subscribe(
        (billingStatement) => (this.billingStatement = billingStatement)
      );
    this.collectionsService
      .fetchTransactionsByCustomerIdV2(this.customerId)
      .subscribe((res) => {
        this.charges = res.charges;
      });
    this.fetchCustomer();
    this.fetchInvoiceStatus();
    this.documentsService
      .fetchDocumentsByOwnerId(this.customerId)
      .subscribe((res: any[]) => {
        this.documents = res;
      });
    this.fetchCustomerTransactions();
    this.collectionsService
      .fetchLookup('CHARGE_TYPE')
      .subscribe((chargeTypes: any[]) => {
        this.chargeTypes = chargeTypes;
      });
    this.collectionsService
      .fetchLookup('PAYMENT_METHOD')
      .subscribe((paymentMethods: any[]) => {
        this.paymentMethods = paymentMethods.filter((value) => {
          return value.userCreateEnabled;
        });
      });
    this.fetchCustomerRentals();
  }
  async fetchCustomerRentals(): Promise<void> {
    await this.coreService
      .getActiveCustomers()
      .toPromise()
      .then((rentals) => {
        this.activeRentals = rentals;
        for (const r of this.activeRentals) {
          if (r.customerId === this.customerId) {
            this.activeRental = r;
          }
        }
      });
  }

  convertDate(date) {
    if (!date)
      return '';
    return moment(date).utc().locale('es').format('dddd DD [de] MMMM [de] YYYY')
  }

  convertToLocalDate(date) {
    if (!date)
      return '';
    return moment(date).local().locale('es').format('dddd DD [de] MMMM [de] YYYY HH:mm:ss')
  }

  fetchCustomerTransactions(): void {
    this.balance = 0;
    this.securityDepositAccountBalance = 0;
    this.collectionsService
      .fetchTransactionsByCustomerId(this.customerId)
      .subscribe((res) => {
        this.transactions = res;
        for (const t of this.transactions) {
          this.balance += t.transactionAmount;
          if (
            t.transactionCode === 'SECURITY_DEPOSIT' ||
            t.transactionCode === 'APPLY_SECURITY' ||
            t.transactionCode === 'DEPOSIT_REFOUND'
          ) {
            this.securityDepositAccountBalance += t.transactionAmount;
          }
        }
        this.tempBalance = this.balance;
        this.tempBalances = {};
        let i = 0;
        for (const t of this.transactions) {
          if (i === 0) {
            this.tempBalances[t.transactionId] = this.tempBalance.toFixed(2);
          } else {
            this.tempBalance -= this.transactions[i - 1].transactionAmount;
            this.tempBalances[t.transactionId] = this.tempBalance.toFixed(2);
          }
          i++;
        }
      });
  }
  buildEnpointForImages(fileId): string {
    return `${environment.documents_endpoint}documents/files/${fileId}`;
  }
  buildEnpointForPdf(fileId): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      `https://docs.google.com/viewer?url=https://api.nave.mx/documents/documents/files/${fileId}&embedded=true`
    );
  }
  getTemporalBalance(transactionNumber: number): number {
    if (transactionNumber === 0) {
      this.tempBalance = this.balance;
      return this.tempBalance;
    } else {
      this.tempBalance =
        this.tempBalance -
        this.transactions[--transactionNumber].transactionAmount;
      return this.tempBalance;
    }
  }
  createNewCharge(charge: NewChargeRequest): void {
    this.collectionsService.createNewCharge(charge).subscribe(
      (res) => {
        // Refresh Transactions
        this.createNotification(
          'success',
          'Cargo creado',
          'El cargo fue creado con éxito'
        );
        this.fetchCustomerTransactions();
      },
      (error) => {
        // Throw message with error
        this.createNotification(
          'error',
          'No fue posible crear el cargo',
          'Ocurrió un error al crear el cargo.'
        );
      }
    );
    this.isCreateNewChargeFormVisible = false;
  }
  createNewPayment(payment: NewPaymentRequest): void {
    payment.createdBy = localStorage.getItem('userLoggedId');
    this.collectionsService.createNewPayment(payment).subscribe(
      (res) => {
        this.createNotification(
          'success',
          'Pago creado',
          'El pago fue creado con éxito'
        );
        this.fetchCustomerTransactions();
      },
      (error) => {
        // Throw message with error
        this.createNotification(
          'error',
          'No fue posible crear el pago',
          'Ocurrió un error al crear el pago.'
        );
      }
    );
    this.isCreateNewPaymentFormVisible = false;
  }

  /*
   * Utility Methods
   * */
  createNotification(
    type: string,
    title: string,
    message: string,
    duration = 7000
  ): void {
    this.notificationService.create(type, title, message, {
      nzDuration: duration,
    });
  }
  daysDiff = (dueDate): number => {
    const current = Date.parse(dueDate);
    // Can not select days before 7 days. Can not select days after two days
    const today = new Date();
    const diff = differenceInCalendarDays(current, today);
    return diff;
    //return !(diff > -7 && diff < 2);
  };
  openEditChargeForm(charge): void {
    this.editableChargeSelected = charge;
    this.isEditChargeFormVisible = true;
  }
  openEditPaymentForm(payment): void {
    this.editablePaymentSelected = payment;
    this.isEditPaymentFormVisible = true;
  }
  editPaymentAppliedSuccess(): void {
    this.isEditPaymentFormVisible = false;
    this.editablePaymentSelected = {};
    this.fetchCustomerTransactions();
  }
  editChargeAppliedSuccess(): void {
    this.isEditChargeFormVisible = false;
    this.editableChargeSelected = {};
    this.fetchCustomerTransactions();
  }

  createShareResource(): void {
    this.shareLinkService.createShareLink(this.customerId).subscribe((res) => {
      this.createNotification(
        'success',
        'Link Compartible',
        `Usa el siguiente link: https://nbs.nave.mx/share/customer/${res.linkId}`,
        15000
      );
    });
  }
  onExpandChange(id: string, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }
  toHumanReadableDate(date: Date): string {
    const pad = '00';
    const month = `${pad}${date.getMonth() + 1}`.slice(-pad.length);
    const day = `${pad}${date.getDate()}`.slice(-pad.length);
    return `${date.getFullYear()}-${month}-${day}`;
  }
  fetchWeebFletInformation() {
    this.collectionsService
      .fetchWeebFleetInformation(this.customerId)
      .subscribe(
        (res: WeebFleetResponse) => {
          this.WeebFleetInformation.push(res);
          if (this.WeebFleetInformation.length === 0) {
            this.panels[1].disabled = true;
          }
        },
        (err: HttpErrorResponse) => {
          if (err) {
            this.panels[1].disabled = true;
          }
        }
      );
  }
  fetchCarInformation() {
    this.collectionsService
      .fetchCustomerCarInformation(this.customerId)
      .subscribe(
        (res: CarInformationResponse) => {
          if (res != null) this.CarInformation.push(res);

          if (this.CarInformation.length === 0) {
            this.panels[0].disabled = true;
          }
        },
        (error: HttpErrorResponse) => {
          if (error) {
            this.panels[0].disabled = true;
          }
        }
      );
  }
  fetchPalencaInformation() {
    this.collectionsService.fetchPalencaInformation(this.customerId).subscribe(
      (res: PalencaResponse[]) => {
        this.palencaInformation = res;
        if (this.palencaInformation.length === 0) {
          this.panels[2].disabled = true;
        }
      },
      (error: HttpErrorResponse) => {
        if ((error.error.error = 'Not Found')) {
          this.panels[2].disabled = true;
        }
      }
    );
  }
  fetchAddresses(): void {
    this.customerApiService.fetchCustomerAddress(this.customerId).subscribe(
      (res: any) => {
        this.addresses = res;
        if (this.addresses.length === 0) {
          this.panels[3].disabled = true;
        }
      },
      (error: HttpErrorResponse) => {
        if (error) {
          this.panels[3].disabled = true;
        }
      }
    );
  }
  openUpdateCustomer(): void {
    this.openUpdateCustomerForm();
  }
  updateCustomerDetail(request): void {
    this.updateCustomer(request, this.customerId);
    setTimeout(() => {
      this.fetchCustomer();
    }, 1000);
  }
  addressChanges(event: boolean) {
    if (event) {
      this.panels[3].disabled = false;
      this.fetchAddresses();
    }
  }
  fetchCustomer(): void {
    this.customerService.findCustomer(this.customerId).subscribe((res) => {
      this.customer = { ...res, id: this.customerId };
      this.fetchAllCustomerDigitalAgreements(res?.rfc);
      this.getRisks(this.customer);
    });
  }

  getRisks(customer: Customer): void {
    const applicationsWithUnderwriting = customer.applications.filter(application => application.underwriting);

    if (applicationsWithUnderwriting.length === 1) {
      applicationsWithUnderwriting.forEach(item => {
        if (item.underwriting && item.underwriting.length > 0) {
          const underwriting = item.underwriting;
          underwriting.forEach(item => {
            console.log(item.nonPaymentRisk);

            this.nonPaymenRisk = item.nonPaymentRisk;
            this.interviewRisk = item.interviewRisk;
          });
        }
      });
    } else {
      this.nonPaymenRisk = null;
      this.interviewRisk = null;
    }
  }

  changeStatusBlacklistSat() {
    Swal.fire({
      icon: 'warning',
      title: `¿Estas seguro que desea cambiar la forma de facturar del cliente a ${this.customer.blacklistSat ? 'persona física' : 'publico en general'}?`,
      width: '800px',
      showCancelButton: true,
      confirmButtonText: 'Si, cambiar',
      cancelButtonText: 'Cancelar'
    })
      .then(async (result) => {
        if (result.isConfirmed)
          this.customerService.sendCustomerToBlacklistSat({ id: this.customer.id, blacklistSat: !this.customer.blacklistSat }).subscribe((response) => {
            this.customer.blacklistSat = response.blacklist_sat;
          })
      });

  }

  fetchAllCustomerAgreements(): void {
    this.customerApiService.fetchAllCustomerAgreements(this.customerId).subscribe((agreements) => {
      this.customerAgreements = agreements;

      if (this.customerAgreements.length === 0) {
        this.panels[4].disabled = true;
      }
      this.getTimeActive();
    }, error => {
      if (error) {
        this.panels[4].disabled = true;
      }
    });
  }

  fetchAllCustomerDigitalAgreements(rfc): void {
    this.customerApiService.fetchAllCustomerDigitalAgreements(rfc).subscribe((agreements) => {
      const newCustomerAgreements = agreements?.records.map(agreement => {
        agreement.miFielUrl = `https://app.mifiel.com/document-flow/status/${agreement.id}`
        return agreement;
      })
      this.customerDigitalAgreements = newCustomerAgreements
    }, error => {
      if (error) {
        this.panels[6].disabled = true;
      }
    });
  }

  getTimeActive(): void {
    // saber si tiene rentas activas o en pausa
    if (this.customerAgreements.length > 0) {
      let activeRental = this.customerAgreements.find(
        (a) => a.rental_agreement_agreement_status_id === 10 || a.rental_agreement_agreement_status_id === 130
      ) ? this.customerAgreements.find((a) => a.rental_agreement_agreement_status_id === 10 || a.rental_agreement_agreement_status_id === 130) : null;

      // si tiene contratos activos
      if (activeRental != null) {
        this.isCustomerActive = true;
        const start = new Date(activeRental.start_date);
        const end = new Date();
        this.timeActive = Number(moment(end).diff(start, 'days').toFixed(0));
        this.weeksActive = Number(Math.floor(
          moment.duration(this.timeActive, 'days').asWeeks()
        ).toFixed(0));
      } else {
        // si no tiene contratos activos
        this.isCustomerActive = true;
        const actualDate = new Date();
        let inactiveAgreements = this.customerAgreements.find(
          (a) => a.rental_agreement_agreement_status_id != 10
        );

        // obtener el último contrato
        let minorDiff = Math.abs(
          new Date(actualDate).getTime() -
          new Date(inactiveAgreements.end_date).getTime()
        );

        for (let i = 0; i < inactiveAgreements.length; i++) {
          const diff = Math.abs(
            new Date(actualDate).getTime() -
            new Date(inactiveAgreements[i].end_date).getTime()
          );

          if (diff < minorDiff) {
            minorDiff = diff;
            inactiveAgreements = inactiveAgreements[i];
          }
        }
        const start = new Date(inactiveAgreements.start_date);
        const end = new Date(inactiveAgreements.end_date);
        this.timeActive = Number(moment(end).diff(start, 'days').toFixed(0));
        this.weeksActive = this.weeksActive = Number(Math.floor(
          moment.duration(this.timeActive, 'days').asWeeks()
        ).toFixed(0));
      }
    } else {
      // si no tiene ningún contrato
      this.isCustomerActive = false;
      this.timeActive = 0;
      this.weeksActive = 0;
    }
  }
  fetchAllCustomerPhones(): void {
    this.customerApiService.fetchAllCustomerPhones(this.customerId).subscribe((phones) => {
      this.customerPhones = phones;
      if (this.customerPhones.length === 0) {
        this.panels[5].disabled = true;
      }
    }, error => {
      if (error) {
        this.panels[5].disabled = true;
      }
    });
  }
  public validateInvoiceStatus(customerId: string): void {
    this.invoiceStatusLoading = true;
    this.InvoiceService.getCustomerInvoiceStatus(customerId).subscribe((res) => {
      this.createNotification('success', 'Estatus de facturación Actualizado', 'Estado de facturación actualizado');
      this.invoiceStatusLoading = false;
      console.log(res,'637');
      this.isTaxable = res;
      this.fetchInvoiceStatus()
    }, (error) => {
      this.fetchInvoiceStatus()
      console.log(error);
      this.invoiceStatusLoading = false;
      console.log('Error :>> ', error.error);
      if(error.error.message){
        this.createNotification('error', 'Ha ocurrido un error al facturar', `${error.error.message}`);
      }else{
        const errors = Object.keys(error.error);
        if (errors.length > 0) {
          const firstKey = errors[0];
          const firstValue = error.error[firstKey];
          this.createNotification('error', 'Ha ocurrido un error al facturar', `${firstValue}`);
        }
      }
    });
  }

  public fetchInvoiceStatus(): void {
    this.InvoiceService.fetchInvoiceStatusByCustomerId(this.customerId).subscribe((res: any) => {
      this.isTaxable = res.is_taxeable;
      this.invoiceError = res.error_invoice;
    }, (error) => {
      if (error.error.Message != undefined) {
        // this.createNotification('error', 'Ha ocurrido un error al obtener el estatus de facturación', `${error.error.Message}`);
      } else {
        // this.createNotification('error', 'Ha ocurrido un error al recargar', `${error.error.message}`);
      }
    });
  }
}
