import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DidntTakeCarReason} from '../../../../model/onboarding/DidntTakeCarReason';
import {FormControl, FormGroup} from '@angular/forms';
import SendDidntTakeCarRequest from '../../../../model/onboarding/request/SendDidntTakeCarRequest';
import {Application} from '../../../../model/onboarding/Application';

@Component({
  selector: 'app-didnt-take-car-form',
  templateUrl: './didnt-take-car-form.component.html',
  styles: [
  ]
})
export class DidntTakeCarFormComponent implements OnInit {
  @Input() application: Application;
  @Input() didntTakeCarReasons: DidntTakeCarReason[];
  @Output() formSubmitted: EventEmitter<SendDidntTakeCarRequest> = new EventEmitter<SendDidntTakeCarRequest>();
  didntTakeCarForm: FormGroup;
  constructor() { }

  ngOnInit(): void {
    this.didntTakeCarForm = new FormGroup({
      reasonSelect: new FormControl(),
      note: new FormControl()
    });
  }
  onSubmit(): void {
    const data = new SendDidntTakeCarRequest(
      this.application.id,
      this.didntTakeCarForm.controls.reasonSelect.value,
      null,
      this.didntTakeCarForm.controls.note.value
    );
    this.formSubmitted.emit(data);
  }

}
