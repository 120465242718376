import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AgreementServiceService } from '../../../services/api/core/agreement-service.service';
import RentalAgreementResponse from '../../../domain/api/core/agreements/response/RentalAgreementResponse';
import IRentalAgreement from '../../../domain/model/agreements/IRentalAgreement';

@Component({
  selector: 'app-agreements-table-view',
  templateUrl: './agreements-table-view.component.html',
  styleUrls: ['./agreements-table-view.component.css'],
})
export class AgreementsTableViewComponent implements OnInit {
  @Output() editRentalAgreementRequested: EventEmitter<IRentalAgreement> = new EventEmitter<IRentalAgreement>();
  @Input() data: IRentalAgreement[] = [];
  constructor() {}

  ngOnInit(): void {
  }

  editRentalAgreement(rental: IRentalAgreement): void {
    this.editRentalAgreementRequested.emit(rental);
  }

}
