<nz-divider [nzText]="'Nuevo Vehículo'"></nz-divider>
<div nz-row nzJustify="center">
  <div nz-col nzSpan="3"></div>
  <div nz-col nzSpan="6">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>Placa</nz-form-label>
        <nz-form-control nzErrorTip="Por favor ingresa el nombre" nzSpan="15">
          <input type="text" nz-input placeholder="Placa" formControlName="plate" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>VIN</nz-form-label>
        <nz-form-control nzErrorTip="Por favor ingresa los apellidos" nzSpan="15">
          <input type="text" nz-input placeholder="VIN" formControlName="vin" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>Tarjeta de circulación</nz-form-label>
        <nz-form-control nzErrorTip="Por favor escriba la tarjeta de circulación" nzSpan="15">
          <input type="text" nz-input placeholder="Tarjeta de circulación" formControlName="circulationCard" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>Numero del motor</nz-form-label>
        <nz-form-control nzErrorTip="Por favor selecciona una razón" nzSpan="15">
          <input type="text" nz-input placeholder="Numero del motor" formControlName="engineNumber" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>Tarjeta de circulación</nz-form-label>
        <nz-form-control nzErrorTip="Por favor ingresa la tarjeta de circulación" nzSpan="15">
          <input type="text" nz-input placeholder="Tarjeta de circulación" formControlName="circulationCard" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>Año del vehiculo</nz-form-label>
        <nz-form-control nzErrorTip="Por favor selecciona una razón" nzSpan="15">
          <input type="text" nz-input placeholder="Año" formControlName="year" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>RFC del propietario</nz-form-label>
        <nz-form-control nzErrorTip="Por favor introduce un RFC válido" nzSpan="15">
          <input type="text" nz-input placeholder="Propietario" formControlName="ownerRfc" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>Color</nz-form-label>
        <nz-form-control nzErrorTip="Introduce un color" nzSpan="15">
          <input type="text" nz-input placeholder="Color" formControlName="color" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>Fecha de Compra</nz-form-label>
        <nz-form-control nzErrorTip="" nzSpan="15">
          <nz-date-picker nzSize="large" nzFormat="yyyy-MM-dd" formControlName="purchaseDate"></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>Fecha de Entrega</nz-form-label>
        <nz-form-control nzErrorTip="" nzSpan="15">
          <nz-date-picker nzSize="large" nzFormat="yyyy-MM-dd" formControlName="deliveryDate"></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>Ubicacion</nz-form-label>
        <nz-form-control nzErrorTip="Por favor selecciona una razón" nzSpan="15">
          <nz-select nzSize="large" formControlName="location" nzPlaceHolder="Selecciona una ubicación">
            <nz-option nzValue="CDMX" nzLabel="CDMX"></nz-option>
            <nz-option nzValue="GUADALAJARA" nzLabel="Guadalajara"></nz-option>
            <nz-option nzValue="MONTERREY" nzLabel="Monterrey"></nz-option>
            <nz-option nzValue="EDOMEX" nzLabel="Estado de México"></nz-option>
            <nz-option nzValue="PUEBLA" nzLabel="Puebla"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>Marca</nz-form-label>
        <nz-form-control nzErrorTip="Por favor selecciona una razón" nzSpan="15">
          <nz-select nzSize="large" formControlName="model" nzPlaceHolder="Selecciona la marca">
            <nz-option nzValue="AVEO" nzLabel="Aveo"></nz-option>
            <nz-option nzValue="VDRIVE" nzLabel="V-Drive"></nz-option>
            <nz-option nzValue="BEAT" nzLabel="Beat"></nz-option>
            <nz-option nzValue="VENTO" nzLabel="Vento"></nz-option>
            <nz-option nzValue="KIA" nzLabel="Kia Rio"></nz-option>
            <nz-option nzValue="HYUNDAI" nzLabel="Grand i10"></nz-option>
            <nz-option nzValue="ATTITUDE" nzLabel="Attitude"></nz-option>
            <nz-option nzValue="ONIX" nzLabel="Onix"></nz-option>
            <nz-option nzValue="KWID" nzLabel="Kwid"></nz-option>
            <nz-option nzValue="MIRAGE" nzLabel="Mirage"></nz-option>
            <nz-option nzValue="STEPWAY" nzLabel="Stepway"></nz-option>
            <nz-option nzValue="FIATUNO" nzLabel="Fiat Uno"></nz-option>
            <nz-option nzValue="VERSA" nzLabel="Versa"></nz-option>
            <nz-option nzValue="LOGAN" nzLabel="Renault Logan"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>Estado del vehiculo</nz-form-label>
        <nz-form-control nzErrorTip="Por favor selecciona una razón" nzSpan="15">
          <nz-select nzSize="large" formControlName="carType" nzPlaceHolder="Selecciona el estado del vehiculo">
            <nz-option nzValue="NEW" nzLabel="Nuevo"></nz-option>
            <nz-option nzValue="USED" nzLabel="Usado"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9">Notas</nz-form-label>
        <nz-form-control nzErrorTip="Por favor selecciona una razón" nzSpan="15">
          <textarea nz-input placeholder="" formControlName="notes"></textarea>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control [nzSpan]="12" [nzOffset]="6">
          <button type="submit" nz-button nzType="primary" nzBlock nzSize="large">Enviar</button>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
  <div nz-col nzSpan="3"></div>
</div>