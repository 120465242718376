export default class NewChargeRequest {
  constructor(
    public customerId?: string,
    public chargeTypeId?: string,
    public amount?: number,
    public dueDate?: string,
    public note?: string,
  ) {
  }
}
