<nz-table #paymentDetails [nzData]="payments" nzTableLayout="fixed">
<thead>
<tr>
    <th>Fecha</th>
    <th>Método de pago</th>
    <th>Monto total</th>
    <th>Monto aplicado</th>
    <th>Monto Pendiente</th>
</tr>
</thead>
    <tbody>
        <ng-container *ngFor="let p of paymentDetails.data">
            <tr>
            <td>{{ toHumanReadableDate(p.paymentDueDate) }}</td>
            <td>{{ p.paymentMethodName }}</td>
            <td style="font-weight: bold; color: green;">{{p.paymentAmount}}</td>
            <td>{{ p.paymentAmountApplied }}</td>
            <td>{{ p.paymentPendingAmountToApply }}</td>
        </ng-container>
    </tbody>
</nz-table>
