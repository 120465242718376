<nz-divider nzText="Actualizar Fiador"></nz-divider>
<div style="padding-bottom: 20px;" *ngFor="let guarantorItem of guarantorFormArray ; let i = index;">
  <nz-card nzType="inner" nzTitle="Fiador" [nzExtra]="actions">
    <form [formGroup]="guarantorItem.form">
      <nz-form-item>
        <nz-form-label nzSize="default" [nzSpan]="6" nzRequired>Nombre del fiador</nz-form-label>
        <nz-form-control nzErrorTip="Por favor introduce un nombre" nzSpan="15">
          <input type="text" nz-input placeholder="Nombre" formControlName="name" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzSize="default" [nzSpan]="6" nzRequired>Dirección</nz-form-label>
        <nz-form-control nzErrorTip="Por favor introduce una dirección" nzSpan="15">
          <input type="text" nz-input placeholder="Dirección" formControlName="address" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="6" nzRequired>Teléfono</nz-form-label>
        <nz-form-control nzErrorTip="Por favor ingresa un número de teléfono valido" nzSpan="15">
          <input type="text" nz-input placeholder="Teléfono" formControlName="phone"
            onkeypress="return (event.charCode >= 48 && event.charCode <= 57)" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="6" nzRequired>Email</nz-form-label>
        <nz-form-control nzErrorTip="Por favor ingresa el email" nzSpan="15">
          <input type="email" nz-input pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" placeholder="solominusculas@example.com"
            formControlName="email" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzSize="default" [nzSpan]="6" nzRequired>fecha de firma</nz-form-label>
        <nz-form-control nzErrorTip="Por favor introduce una fecha" nzSpan="15">
          <input type="date" nz-input placeholder="Fecha de firma" formControlName="signatureDate" />
        </nz-form-control>
      </nz-form-item>
    </form>
  </nz-card>
  <ng-template #actions>
    <button nz-button nzType="default" (click)="saveGuarantor(guarantorItem.form)" [disabled]="!guarantorItem.form.valid"><span nz-icon
        nzType="save"></span></button>
    <button nzDanger nz-popconfirm nzPopconfirmTitle="¿Estas segur@ de eliminar la dirección?" nzOkText="Confirmar"
      nzCancelText="Cancelar" (nzOnConfirm)="this.deleteGuarantor(i, guarantorItem)" nz-button nzType="primary"><span nz-icon
        nzType="delete"></span></button>
  </ng-template>
</div>
<nz-form-control *ngIf="isGuarantor" [nzSpan]="24" [nzOffset]="9">
    <button nz-button nzType="dashed" type="button" nzShape="round" nzSize="large" (click)="addGuarantor($event)">
      <span nz-icon nzType="plus"></span>Agregar Fiador
    </button>
  </nz-form-control>