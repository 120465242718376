import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inspect-car-modal-button',
  templateUrl: './inspect-car-modal-button.component.html',
  styleUrls: ['./inspect-car-modal-button.component.css']
})
export class InspectCarModalButtonComponent implements OnInit {
  isModalVisible = false;
  constructor() { }

  ngOnInit(): void {
  }
  openModal(): void {
    this.isModalVisible = true;
  }
  inspectionSurveyJson = {
    "pages": [
      {
        "name": "metrics_page",
        "elements": [
          {
            "type": "dropdown",
            "name": "gas_level",
            "title": "Nivel de Gasolina",
            "isRequired": true,
            "choices": [
              {
                "value": "1",
                "text": "Lleno"
              },
              {
                "value": "0.75",
                "text": "3/4 de Tanque"
              },
              {
                "value": "0.50",
                "text": "Mitad de tanque"
              },
              {
                "value": "0.25",
                "text": "1/4 de Tanque"
              },
              {
                "value": "0",
                "text": "Vacio"
              }
            ]
          },
          {
            "type": "text",
            "name": "odometer",
            "title": "Odómetro",
            "isRequired": true,
            "inputType": "number",
            "min": "0"
          }
        ],
        "title": "Metrics"
      },
      {
        "name": "general_check",
        "elements": [
          {
            "type": "radiogroup",
            "name": "has_damages",
            "title": "¿Tiene daños el vehículo?",
            "isRequired": true,
            "choices": [
              {
                "value": "1",
                "text": "Si"
              },
              {
                "value": "0",
                "text": "No"
              }
            ]
          },
          {
            "type": "radiogroup",
            "name": "has_fines",
            "title": "¿Tiene multas?",
            "choices": [
              {
                "value": "1",
                "text": "Si"
              },
              {
                "value": "0",
                "text": "No"
              }
            ]
          },
          {
            "type": "radiogroup",
            "name": "is_clean",
            "title": "¿El vehículo esta limpio?",
            "isRequired": true,
            "choices": [
              {
                "value": "1",
                "text": "Si"
              },
              {
                "value": "0",
                "text": "No"
              }
            ]
          }
        ],
        "title": "Estado General\n"
      },
      {
        "name": "damages",
        "elements": [
          {
            "type": "file",
            "name": "damage_pictures",
            "title": "Fotos de los daños",
            "isRequired": true,
            "allowMultiple": true,
            "waitForUpload": true,
            "maxSize": 0
          }
        ],
        "visibleIf": "{has_damages} = 1",
        "requiredIf": "{has_damages} = 1",
        "title": "Daños"
      },
      {
        "name": "components_accesories",
        "elements": [
          {
            "type": "checkbox",
            "name": "question1",
            "title": "Selecciona los accesorios y componentes que tiene el vehículo",
            "isRequired": true,
            "choices": [
              {
                "value": "right_mirror",
                "text": "Espejo lateral derecho"
              },
              {
                "value": "left_mirror",
                "text": "Espejo lateral izquierdo"
              },
              {
                "value": "rearview_mirror",
                "text": "Espejo retrovisor"
              },
              {
                "value": "rugs",
                "text": "Tapetes"
              },
              {
                "value": "cleaners",
                "text": "Limpiadores"
              },
              {
                "value": "hydraulic_jack",
                "text": "Gato"
              },
              {
                "value": "covid_division",
                "text": "Mampara"
              }
            ]
          }
        ],
        "title": "Componentes y Accesorios"
      }
    ]
  };
}
