import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pages',
  templateUrl: './pagesv2.component.html',
  styleUrls: [
    './pagesv2.component.css'
  ]
})
export class Pagesv2Component implements OnInit {

  isCollapsed = false;

  constructor() { }

  ngOnInit(): void {
  }

}
