import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {BillingStatement} from '../../../domain/model/billing-statement.model';
import {TransactionsFacade} from '../../../domain/transactions.facade';

@Component({
  selector: 'app-billing-statement',
  templateUrl: './billing-statement.component.html',
  styleUrls: ['./billing-statement.component.css']
})
export class BillingStatementComponent implements OnInit {
  billingStatement: BillingStatement =  null;
  customerId: string;
  months = [ 'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December' ];
  constructor(private route: ActivatedRoute,
              private router: Router,
              private cRef: ChangeDetectorRef,
              private transactionsFacade: TransactionsFacade) {
    this.route.params.subscribe(
      params => {
        this.customerId = params.id;
      }
    );
  }

  ngOnInit(): void {
    this.transactionsFacade.getBillingStatement(this.customerId).subscribe((res) => {
      this.billingStatement = res;
      console.log(res);
      this.cRef.detectChanges();
    });
  }
  download(): void{
    const el = document.getElementById('content');
    const pdf = new jsPDF('p', 'pt', 'letter');
    html2canvas(el).then((canvas) => {
      console.log(el.clientHeight);
      const imgData = canvas.toDataURL('image/png');
      const jspdf = new jsPDF();
      const imgHeight = el.clientHeight * 210 / el.clientWidth;
      jspdf.addImage(imgData, 0, 0, 210, imgHeight);
      jspdf.save('estado_de_cuenta.pdf');
    });
  }
  toHumanReadableDate(date: Date): string {
    const pad = '00';
    const month = `${pad}${date.getMonth() + 1}`.slice(-pad.length);
    const day = `${pad}${date.getDate()}`.slice(-pad.length);
    console.log(date);
    return `${date.getFullYear()}-${month}-${day}`;
  }
  todayDate(): Date {
    return new Date();
  }
  daysDifferenceFromNow(date: Date): string {
    const today = new Date();
    const difference = Math.abs(today.getTime() - date.getTime());
    const days = difference / (1000 * 3600 * 24);
    return days.toFixed(0);
  }
  absoluteNumber(n: number): number {
    return Math.abs(n);
  }
}
