<nz-divider [nzText]="'Agregar Documento'"></nz-divider>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <nz-form-item>
    <nz-form-label [nzSpan]="9" nzRequired>Tipo de Documento</nz-form-label>
    <nz-form-control nzErrorTip="Por favor selecciona el tipo de documento" nzSpan="15">
      <nz-select nzSize="large" formControlName="documentType" nzPlaceHolder="Selecciona el tipo de documento">
        <nz-option *ngFor="let d of documentTypes" [nzValue]="d.typeCode" [nzLabel]="d.typeName"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control [nzSpan]="12" [nzOffset]="6">
      <button type="submit" nz-button nzType="primary" nzBlock nzSize="large" [disabled]="isCreateButtonDisabled">Crear</button>
    </nz-form-control>
  </nz-form-item>
</form>
