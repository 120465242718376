export default class ReturnRequest {
  constructor(
    public rentalId?: string,
    public returnDate?: string,
    public returnReasonId?: string,
    public driveable?: boolean,
    public note?: string,
    public createdBy?: string
  ) {
  }
}
