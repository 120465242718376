import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Customer} from '../../../../model/onboarding/Customer';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import CreateDocumentRequest from '../../../../model/document/request/CreateDocumentRequest';
import DocumentType from '../../../../model/document/DocumentType';

@Component({
  selector: 'app-create-document-form',
  templateUrl: './create-document-form.component.html',
  styles: []
})
export class CreateDocumentFormComponent implements OnInit {
  @Input() customer!: Customer;
  @Input() documentTypes: Array<DocumentType> = [];
  @Output() formSubmitted: EventEmitter<CreateDocumentRequest> = new EventEmitter<CreateDocumentRequest>();
  form: FormGroup;
  isCreateButtonDisabled = false;
  constructor(){
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      documentType: new FormControl(null, [Validators.required]),
    });
    const allowedTypeIds = [1003, 1022, 1005, 1008, 1009, 1002, 1023, 1024, 1021, 1023, 1024, 1025, 1026, 1027, 1028, 1029, 1030, 1031];
    this.documentTypes = this.documentTypes.filter(type => allowedTypeIds.includes(type.id));
    this.documentTypes.sort((a, b) => a.typeName.localeCompare(b.typeName));
  }
  onSubmit(): void {
    this.isCreateButtonDisabled = true;
    this.form.controls.documentType.markAsDirty();
    this.form.controls.documentType.updateValueAndValidity();
    const data = new CreateDocumentRequest(
      this.customer.id,
      this.form.controls.documentType.value
    );
    this.formSubmitted.emit(data);
    this.isCreateButtonDisabled = false;
  }
}
