import { Adapter } from '../../adapter';
import { Injectable } from '@angular/core';

export class CarInformationResponse {
  constructor(
    public id: string,
    public plate?: string,
    public vin?: string,
    public engine_number?: string,
    public delivery_date?: string,
    public car_status_code?: string,
    public car_model_code?: string,
    public car_location_code?: string,
    public bos_id?: number,
    public created_at?: number,
    public updated_at?: number,
    public year?: number,
    public color?: string,
    public branch?: string,
    public segment?: string,
    public car_category_code?: string,
    public car_type_code?: string,
    public owner_rfc?: string,
    public created_by?: string,
    public updated_by?: string,
    public poliza?: string,
    public endoso?: string,
    public inciso?: string,
    public insurance_amount?: string,
    public insurance_expiration?: string,
    public insurance_broker?: string,
    public circulation_card?: string,
  ) {
  }
}

@Injectable({
  providedIn: 'root',
})
export class CarInformationResponseAdapter implements Adapter<CarInformationResponse> {
  adapt(item: any): CarInformationResponse {
    return new CarInformationResponse(
      item.id,
      item.plate,
      item.vin,
      item.engine_number,
      item.delivery_date.replace('/', '-'),
      /* new Date(item.delivery_date.replace('-', '/')), */
      item.car_status_code,
      item.car_model_code,
      item.car_location_code,
      item.bos_id,
      item.created_at,
      item.updated_at,
      item.year,
      item.color,
      item.branch,
      item.segment,
      item.car_category_code,
      item.car_type_code,
      item.owner_rfc,
      item.created_by,
      item.updated_by,
      item.poliza,
      item.endoso,
      item.inciso,
      item.insurance_amount,
      item.insurance_expiration,
      item.insurance_broker
    );
  }
}
