import { Component, OnInit } from '@angular/core';
import {PaydownService} from "../../services/paydown.service";
import Paydown from "../../models/Paydown";
import { addWeeks } from 'date-fns';
import { parse } from 'date-fns';
import { format } from 'date-fns';
@Component({
  selector: 'app-paydowns-table-view',
  templateUrl: './paydowns-table-view.component.html',
  styleUrls: ['./paydowns-table-view.component.css']
})
export class PaydownsTableViewComponent implements OnInit {
  paydownStatusFilterSelected = 'ALL';
  paydowns: Paydown[] = [];
  allPaydowns: Paydown[] = [];
  activePaydowns: Paydown[] = [];
  completedPaydowns: Paydown[] = [];
  pausePaydowns: Paydown[] = [];
  expandSet = new Set<string>();

  isCreatePaydownFormVisible = false;
  constructor(
    private paydownService: PaydownService
  ) {
  }
  ngOnInit(): void {
    this.fetchPaydowns();
    this.paydownStatusFilterChanged();
  }
  paydownStatusFilterChanged(): void {
    // Refresh table
    if (this.paydownStatusFilterSelected === 'ALL') {
      this.paydowns = this.allPaydowns;
    } else if (this.paydownStatusFilterSelected === 'COMPLETED') {
      this.paydowns = this.completedPaydowns;
    } else if (this.paydownStatusFilterSelected === 'PAUSE') {
      this.paydowns = this.pausePaydowns;
    } else if (this.paydownStatusFilterSelected === 'ACTIVE') {
      this.paydowns = this.activePaydowns;
    }
    this.expandSet.clear();
  }
  fetchPaydowns(): void {
    this.paydownService.fetchPaydowns().subscribe(
      (res) => {
        this.allPaydowns = res;
        this.activePaydowns = [];
        this.pausePaydowns = [];
        this.completedPaydowns = [];
        for (const p of res) {
          if (p.status === 'ACTIVE') {
            this.activePaydowns.push(p);
          } else if (p.status === 'COMPLETED') {
            this.completedPaydowns.push(p);
          } else if (p.status === 'PAUSE') {
            this.pausePaydowns.push(p);
          }
        }
        this.paydownStatusFilterChanged();
      }
    );
  }
  onExpandChange(id: string, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }
  calculateExpectedEndDate(givenDate, numberWeeks: number): string {
    const date = parse(givenDate, 'yyyy-MM-dd', new Date());
    return format(addWeeks(date, numberWeeks), 'yyyy-MM-dd');
  }
}
