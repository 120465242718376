import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cancel-reservation-modal-button',
  templateUrl: './cancel-reservation-modal-button.component.html',
  styleUrls: ['./cancel-reservation-modal-button.component.css']
})
export class CancelReservationModalButtonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
