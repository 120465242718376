import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {CarService} from "../../../../services/car.service";
import {OnboardingService} from "../../../services/onboarding.service";
import {NotificationService} from "../../../../services/notification.service";

@Component({
  selector: 'app-update-reservation-modal-button',
  templateUrl: './update-reservation-modal-button.component.html',
  styleUrls: ['./update-reservation-modal-button.component.css']
})
export class UpdateReservationModalButtonComponent implements OnInit {
  @Input() reservation;
  @Output() reservationUpdated: EventEmitter<any> = new EventEmitter<any>();
  isFormVisible = false;
  form: FormGroup;
  availableCars = [];
  products = [];
  constructor(
    private carService: CarService,
    private onboardingService: OnboardingService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      carReservedId: new FormControl(this.reservation.carReserved?.id),
      chosenProductId: new FormControl(this.reservation.chosenProduct.id)
    });
  }
  fetchLookups(): void {
    this.carService.fetchCarsByStatus('AVAILABLE').subscribe(
      (res) => {
        this.availableCars = res;
        if (this.reservation.carReserved) {
          this.availableCars.push({
            id: this.reservation.carReserved?.id,
            plate: this.reservation.carReserved?.plate,
            model: this.reservation.carReserved?.carModelCode,
          });
          this.form.controls.carReservedId.updateValueAndValidity(this.reservation.carReserved.id);
        }
      }
    );
    this.onboardingService.fetchAllProducts().subscribe(
      (res) => {
        this.products = res;
      }
    );
  }
  onSubmitForm(): void {
    if (this.form.valid) {
      this.onboardingService.updateReservation(this.reservation.id, {
        productId: this.form.controls.chosenProductId.value,
        carReserve: this.form.controls.carReservedId.value,
      }).subscribe(
        (res) => {
          this.closeFormModal();
          this.notificationService.createNotification('success', 'Reservation updated', 'Reservation has been updated');
          this.reservationUpdated.emit();
        }, (error) => {
          this.notificationService.createNotification('error', 'Error', error.error.errorMessage);
        }
      );
    }
  }
  openFormModal(): void {
    this.fetchLookups();
    this.isFormVisible = true;
  }
  closeFormModal(): void {
    this.isFormVisible = false;
    this.form.reset();
  }
}

