import { Component, OnInit } from '@angular/core';
import {OnboardingService} from "../../services/onboarding.service";

@Component({
  selector: 'app-reservations-table',
  templateUrl: './reservations-table.component.html',
  styleUrls: ['./reservations-table.component.css']
})
export class ReservationsTableComponent implements OnInit {
  allReservations = [];
  cancelledReservations = [];
  activeReservations = [];
  closedReservations = [];
  pendingCarReservations = [];
  displayReservations = [];
  statusSelected = 'ALL';
  constructor(
    private onboardingService: OnboardingService
  ) { }

  ngOnInit(): void {
    this.fetchReservations();
  }
  fetchReservations(): void {
    this.onboardingService.fetchAllReservations().subscribe(
      (res) => {
        this.allReservations = res;
        this.activeReservations = [];
        this.cancelledReservations = [];
        this.closedReservations = [];
        this.pendingCarReservations = [];
        this.displayReservations = this.allReservations;
        this.statusSelected = 'ALL';
        for (const r of this.allReservations) {
          if (r.status === 'ACTIVE') {
            this.activeReservations.push(r);
          }
          else if (r.status === 'CANCELLED') {
            this.cancelledReservations.push(r);
          }
          else if (r.status === 'CLOSED') {
            this.closedReservations.push(r);
          }
          else if (r.status === 'PENDING_RESERVATION') {
            this.pendingCarReservations.push(r);
          }
        }
      }
    );
  }
  statusSelectedChanged(): void {
    switch (this.statusSelected) {
      case 'ALL':
        this.displayReservations = this.allReservations;
        break;
      case 'ACTIVE':
        this.displayReservations = this.activeReservations;
        break;
      case 'CANCELLED':
        this.displayReservations = this.cancelledReservations;
        break;
      case 'PENDING_RESERVATION':
        this.displayReservations = this.pendingCarReservations;
        break;
      case 'CLOSED':
        this.displayReservations = this.closedReservations;
        break;
    }
  }
}
