import {Adapter} from '../../adapter';
import {Injectable} from '@angular/core';

export class PalencaResponse {
  constructor(
    public account_id: string,
    public next_step: any,
    public country: string,
    public platform: string,
    public status: string,
    public identifier: string,
    public worker_id: string,
    public failed_reason: string,
    public profile: Profile,
    public bank_info: BankInfo,
    public vehicle_info: VehicleInfo,
    public metrics_info: MetricsInfo,
    public ids_info?: any[]
  ) {
  }
}

export class Profile{
  constructor(
    public first_name?: string,
    public last_name?: string,
    public phone?:string,
    public email?: string,
    public address?: string,
    public city_name?: string,
    public birthday?: any,
    public picture_url?: string
  ) {
  }
}

export class BankInfo{
  constructor(
   public account_number?: string,
   public holder_name?: string, 
   public holder_address?: string, 
   public bank_name?: string, 
   public bank_code?: string
  ) {
  }
}

export class VehicleInfo{
  constructor(
    public type?: string,
    public brand?: string,
    public model?: string,
    public year?: string,
    public license_plate?: string,
    public license_plate_state?: string,
    public vin?: string,
  ) {
  }
}

export class MetricsInfo{
  constructor(
    public acceptance_rate?: number,
    public cancellation_rate?: number,
    public thumps_up?: number,
    public rating?: number,
    public lifetime_trips?: number,
    public time_since_first_trip?: string,
    public level_name?: string,
    public debt_pending?: any,
    public debt_paid?: any,
    public picking_speed?: any,
  ) {
  }
}

@Injectable({
  providedIn: 'root',
})
export class PalencaResponseAdapter implements Adapter<PalencaResponse> {
  adapt(item: any): PalencaResponse {
    let profile: Profile;
    let bank_info;
    let vehicle_info;
    let metrics_info;
    let ids_info = [];
    if(item.profile){
      profile =  this.adaptProfile(item.profile);
    }else{
      profile = new Profile();
    }
    if(item.bank_info){
      bank_info =  this.adaptBankInfo(item.bank_info);
    }else{
      bank_info = new BankInfo();
    }
    if(item.vehicle_info){
      vehicle_info = this.adaptVehicle(item.vehicle_info);
    }else{
      vehicle_info = new VehicleInfo();
    }
    if(item.metrics_info){
      metrics_info = this.adaptMetrics(item.metrics_info);
    }else{
      metrics_info = new MetricsInfo();
    }
    return new PalencaResponse(
      item.account_id,
      item.next_step,
      item.country,
      item.platform,
      item.status,
      item.identifier,
      item.worker_id,
      item.failed_reason,
      profile,
      bank_info,
      vehicle_info,
      metrics_info,
      ids_info
    );
  }
  adaptProfile(p: any): Profile{
    return new Profile(
      p.first_name,
      p.last_name,
      p.phone,
      p.email,
      p.address,
      p.city_name,
      p.birthday,
      p.picture_url
    );
  }
  adaptBankInfo(b: any): BankInfo{
    return new BankInfo(
        b.account_number,
        b.holder_name, 
        b.holder_address, 
        b.bank_name, 
        b.bank_code
    );
  }
  adaptVehicle(v: any): VehicleInfo{
    return new VehicleInfo(
      v.type,
      v.brand, 
      v.model, 
      v.year, 
      v.license_plate,
      v.license_plate_state,
      v.vin
    );
  }
  adaptMetrics(m: any): MetricsInfo{
    return new MetricsInfo(
      m.acceptance_rate,
      m.cancellation_rate,
      m.thumps_up,
      m.rating,
      m.lifetime_trips,
      m.time_since_first_trip,
      m.level_name,
      m.debt_pending,
      m.debt_paid,
      m.picking_speed
    )
  }
}

