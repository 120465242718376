<div nz-row style="width: 150px;">
  <div style="width: 100%; display: flex; justify-content: center;">
    <nz-progress
    nzType="circle"
    [nzPercent]="percent"
    [nzFormat]="formatOne"
    [nzStrokeColor]="{ '0%': '#108ee9', '50%': '#2db7f5', '100%': '#87d068' }"
  ></nz-progress>
  </div>
  <div style="width: 100%; display: flex; justify-content: center;">
    <nz-button-group style="margin : 5px; margin-top: 10px;" >
      <button nz-button (click)="decline()"><span nz-icon nzType="minus"></span></button>
      <button nz-button (click)="increase()"><span nz-icon nzType="plus"></span></button>
    </nz-button-group>
  </div>
</div>