import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Directive({
  selector: '[appRbacShow]'
})
export class RbacShowDirective {
  private allowedRoles:string[];
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService) {
  }
  
  @Input() set appRbacShow(allowedRoles: string[]){
    this.allowedRoles = allowedRoles;
    if (!this.allowedRoles || this.allowedRoles.length === 0 ||
      !this.authService.getCurrentRole()) {
      this.viewContainer.clear();
      return;
    }
    const allowed:boolean = this.authService.getCurrentRole().filter(
      (role) => this.allowedRoles.includes(role)).length > 0;
    if (allowed) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
    else {
        this.viewContainer.clear();
    }
  }
}
