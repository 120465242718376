import {Component, Input, OnInit} from '@angular/core';
import {CollectionsService} from "../../../../services/collections.service";
import ChargeType from "../../../models/ChargeType";
import {PaydownService} from "../../../services/paydown.service";
import {NotificationService} from "../../../../services/notification.service";

@Component({
  selector: 'app-create-paydown-modal-button',
  templateUrl: './create-paydown-modal-button.component.html',
  styleUrls: ['./create-paydown-modal-button.component.css']
})
export class CreatePaydownModalButtonComponent implements OnInit {
  @Input() isDisabled = false;
  @Input() customerId: string;
  chargeTypes: ChargeType[];
  isFormVisible = false;
  constructor(
    private collectionService: CollectionsService,
    private paydownService: PaydownService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.fetchChargeTypes();
  }
  fetchChargeTypes(): void  {
    this.collectionService.fetchLookup('CHARGE_TYPE')
      .subscribe(
        (res) => {
          this.chargeTypes = res;
        }
      );
  }

  openForm(): void {
    this.isFormVisible = true;
    console.log(this.customerId);
  }
  closeForm(): void {
    this.isFormVisible = false;
  }
  submitForm(data): void {
    this.paydownService.createPaydown({
      customerId: this.customerId,
      ...data
    }).subscribe(
      (res) => {
        this.notificationService.createNotification(
          'success',
          'Paydown created successfully',
          'Paydown was created.'
        );
        this.closeForm();
      }, (error) => {
        this.notificationService.createNotification(
          'error',
          'An error occurred',
          'An error occurred when creating paydown, please try again.'
        );
        this.closeForm();
      }
    );
  }
}
