import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-myleads-table-view',
  templateUrl: './myleads-table-view.component.html',
  styleUrls: ['./myleads-table-view.component.css']
})
export class MyleadsTableViewComponent implements OnInit {
  leads: any[] = [1,2,3,4,5];
  constructor() { }

  ngOnInit(): void {
  }
  ViewLeadDetail(lead):void{
  }
}
