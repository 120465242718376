import {Component, EventEmitter, Input, OnInit, Output, DoCheck, ChangeDetectorRef} from '@angular/core';
import {Application} from '../../model/onboarding/Application';
import * as moment from 'moment';
import {Router} from "@angular/router";
import Charge from '../../../app/collections/models/Charge';
import { SendReferencesService } from '../../../app/services/send-references.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-application-detail-viewer',
  templateUrl: './application-detail-viewer.component.html',
  styles: [
  ]
})
export class ApplicationDetailViewerComponent implements OnInit, DoCheck {
  @Input() application: Application;
  @Input() documents: any[] = [];
  @Input() visible = false;
  @Input() underwriting;
  @Input() miFielAgreementValid: boolean;
  @Input() itsPaid: boolean = false;
  @Input() paymentReferences: any;
  @Output() closedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() sendToPendingApprovalButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendToDidntTakeCarButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() approveApplicationButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() denyApplicationButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() newDocumentButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateCustomerButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateApplicationButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() createRentalButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() fileUploaded: EventEmitter<any> = new EventEmitter<any>();
  @Output() isOcrUploaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() newTokuCustomerButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  date = moment;
  constructor(
    private router: Router,
    private send_reference_services: SendReferencesService, 
    private cdr: ChangeDetectorRef,
    private notificationService: NzNotificationService,
  ) { }

  ngOnInit(): void {
      console.log(this.itsPaid);
      
    }

  ngDoCheck(): void {
    if (this.itsPaid !== undefined) {
      console.log('Valor de itsPaid:', this.itsPaid);
      // Puedes continuar con la lógica relacionada con itsPaid aquí

      // Marcar manualmente el componente como "dirty" para que se actualice la vista
      this.cdr.detectChanges();
    }
  }
  close(): void {
    this.visible = false;
    this.closedEvent.emit(true);
  }
  sendPendingApprovalClicked(): void {
    this.sendToPendingApprovalButtonClicked.emit(this.application);
  }
  approveApplicationClicked(): void {
    this.approveApplicationButtonClicked.emit(this.application);
  }
  denyApplicationClicked(): void {
    this.denyApplicationButtonClicked.emit(this.application);
  }
  sendToDidntTakeACarClicked(): void {
    this.sendToDidntTakeCarButtonClicked.emit(this.application);
  }
  updateApplication(): void {
    this.updateApplicationButtonClicked.emit(this.application);
  }
  updateCustomer(): void {
    this.updateCustomerButtonClicked.emit(this.application.customer);
  }
  newDocument(): void {
    this.newDocumentButtonClicked.emit(this.application);
  }
  createRentalClicked(): void {
    //this.createRentalButtonClicked.emit(this.application);
    this.router.navigate(['/agreements/create/', this.application.customer.id]);
  }
  fileHasBeenUploaded(file): void {
    this.fileUploaded.emit(file);
  }
  public getOcrUploadStatus(event: boolean): void{
    if(event === null || event === undefined) this.isOcrUploaded.emit(false);
    this.isOcrUploaded.emit(event)
  }

  public createTokuCustomer(): void{
    this.newTokuCustomerButtonClicked.emit(this.application);
  }

  public sendWaCustomer(paymentReferences:any, phone, CustomerName): void {
    console.log(CustomerName);
    
    if (paymentReferences.reference_cash.length > 0  && paymentReferences.reference_bank.length > 0 && phone.length > 0){
      if (phone.startsWith('+52')) {
        phone = phone
      } else {
        phone = '+52' + phone
      }
      const obj = {
        "referenceCash" : paymentReferences.reference_cash,
        "referenceBank" : paymentReferences.reference_bank,
        "phone": phone, 
        "customerName": CustomerName.toString()
    }
      this.send_reference_services.sendTokuReference(obj).subscribe(res=>{
          this.createNotification('success', 'Éxito', "referencias enviadas al número" + phone);
      }, err=>{
        this.createNotification('error', 'ocurrió un error', err);
      })
    } else{
      this.createNotification('error', 'ocurrió un error', 'Verifica que el cliente tenga las referencias y un número de celular valido');
    }
  }

  createNotification(type: string, title: string, message: string, duration = 7000): void {
    this.notificationService.create(
      type,
      title,
      message,
      { nzDuration: duration }
    );
  }

}




