<div nz-row [nzGutter]="[12, 12]" *ngIf="data">
    <div nz-col nzSpan="12" *ngFor="let a of data; let i = index;">
      <nz-card >
        <nz-card-meta
        [nzTitle]="'Dirección' + ' ' + (i+1)"
        nzDescription="{{a.addressType === 1001 ? 'Dirección Fiscal' : a.addressType === 1002 ? 'Dirección de comprobante de domicilio' : 'Estacionamiento' }}"
      ></nz-card-meta>
        <div nz-row style="margin-top: 20px;">
          <div nz-col [nzSpan]="12">
            <p><span style="font-weight: 600;">Estado: {{a.state}}</span></p>
            <p><span style="font-weight: 600;">Municipio: {{a.municipality}}</span></p>
            <p><span style="font-weight: 600;">Colonia: {{a.neighbourhood}}</span></p>
            <p><span style="font-weight: 600;">Calle: {{a.street}}</span></p>
          </div>
          <div nz-col [nzSpan]="12">
            <p><span style="font-weight: 600;">Número exterior: {{a.extNumber}}</span></p>
            <p><span style="font-weight: 600;">Número interior: {{a.intNumber}}</span></p>
            <p><span style="font-weight: 600;">Código postal: {{a.zipcode}}</span></p>
            <p><span style="font-weight: 600;">Notas: {{a.note}}</span></p>
          </div>
        </div>
      </nz-card>
  </div>
</div>


