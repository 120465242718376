import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Transaction, TransactionAdapter} from '../../domain/model/transaction.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ChargeDetail, ChargeDetailAdapter} from '../../domain/model/charge-detail.model';
import {BillingStatement, BillingStatementAdapter} from '../../domain/model/billing-statement.model';

const endpoint = environment.collections_endpoint;

@Injectable({
  providedIn: 'root',
})
export class CollectionApiService {
  constructor(private http: HttpClient,
              private billingStatementAdapter: BillingStatementAdapter) {
  }
  public getBillingStatement(customerId: string): Observable<BillingStatement> {
    return this.http.get(`${endpoint}v1/statements/${customerId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).pipe(
      // Adapt raw item to Billing Statement
      map(
        (data: any) => this.billingStatementAdapter.adapt(data)
      )
    );
  }
  public getTransactions(customerId: string): Observable<Transaction[]> {
    return this.http.get<any[]>(`${endpoint}v1/transactions/${customerId}`)
      .pipe(
        map(res => res.map(item => new TransactionAdapter().adapt(item)))
      );
  }
  public getChargeDetails(customerId: string): Observable<ChargeDetail[]> {
    return this.http.get<any[]>(`${endpoint}v1/chargeDetails/${customerId}`)
      .pipe(
        map(res => res.map(item => new ChargeDetailAdapter().adapt(item)))
      );
  }
}
