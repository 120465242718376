import {Adapter} from '../../adapter';
import {Injectable} from '@angular/core';

export class Customer {
  constructor(
    public id: string,
    public firstName: string,
    public lastName: string,
    public email: string,
    public phone: string,
    public billingEmail: string,
    public rfc: string,
    public curp: string,
    public customerType: string,
    public city: string,
    public createdBy: number,
    public note: string) {
  }
}

@Injectable({
  providedIn: 'root',
})
export class CustomerAdapter implements Adapter<Customer> {
  adapt(item: any): Customer {
    return new Customer(item.id,
      item.firstName,
      item.lastName,
      item.email,
      item.phone,
      item.billingEmail,
      item.rfc,
      item.curp,
      item.customerType,
      item.city,
      item.createdBy,
      item.note);
  }
}
