import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Customer } from '../../../../model/onboarding/Customer';
import {
  UpdateCustomerRequest,
  UpdateCustomerRequestAdapter
} from '../../../../infrastructure/update-customer.request';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomerApiService } from '../../../../infrastructure/api/customer-api.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-update-customer-information-form',
  templateUrl: './update-customer-information-form.component.html',
  styles: [
  ]
})
export class UpdateCustomerInformationFormComponent implements OnInit {
  @Input() customer!: Customer;
  @Output() formSubmitted: EventEmitter<UpdateCustomerRequest> = new EventEmitter<UpdateCustomerRequest>();
  @Output() closeUpdateCustomerForm: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  @Output() addressFormChanges: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  updateCustomerForm: FormGroup;

  constructor(
    private updateCustomerPersonalInformationRequestAdapter: UpdateCustomerRequestAdapter,
    private customerService: CustomerApiService,
    private nzMessageService: NzMessageService,
    public notificationService: NzNotificationService) { }

  ngOnInit(): void {
    this.updateCustomerForm = new FormGroup({
      firstName: new FormControl(this.customer.firstName || null, [Validators.required]),
      lastName: new FormControl(this.customer.lastName || null, [Validators.required]),
      license: new FormControl(this.customer.license || null),
      note: new FormControl(this.customer.note || null),
      curp: new FormControl(this.customer.curp || null, [Validators.minLength(18), Validators.maxLength(18)]),
      taxRegime: new FormControl(Number(this.customer.taxRegime) || null, []),
      phone: new FormControl(this.customer.phone || null, [Validators.minLength(10), Validators.maxLength(15)]),
      email: new FormControl(this.customer.email || null, []),
      billingEmail: new FormControl(this.customer.billingEmail || null, []),
      cfdiUse: new FormControl(this.customer.cfdiUse || null, []),
      rfc: new FormControl(this.customer.rfc || null, [Validators.minLength(13), Validators.maxLength(13)]),
    });
  }
  onSubmit(): void {
    this.updateCustomerForm.controls.firstName.markAsDirty();
    this.updateCustomerForm.controls.firstName.updateValueAndValidity();
    const data = {
      firstName: this.updateCustomerForm.controls.firstName.value,
      lastName: this.updateCustomerForm.controls.lastName.value,
      license: this.updateCustomerForm.controls.license.value,
      curp: this.updateCustomerForm.controls.curp.value,
      rfc: this.updateCustomerForm.controls.rfc.value ? this.updateCustomerForm.controls.rfc.value : null,
      note: this.updateCustomerForm.controls.note.value,
      phone: this.updateCustomerForm.controls.phone.value,
      email: this.updateCustomerForm.controls.email.value,
      billingEmail: this.updateCustomerForm.controls.billingEmail.value,
      taxRegime: this.updateCustomerForm.controls.taxRegime.value ? this.updateCustomerForm.controls.taxRegime.value.toString() : null,
      cfdiUse: this.updateCustomerForm.controls.cfdiUse.value ? this.updateCustomerForm.controls.cfdiUse.value.toString() : null,
    };
    this.formSubmitted.emit(this.updateCustomerPersonalInformationRequestAdapter.adapt(data));
  }
  getFormChanges(event) {
    if (event.type != 'delete') {
      this.closeUpdateCustomerForm.emit(event.value);
      this.addressFormChanges.emit(event.value)
    } else {
      this.addressFormChanges.emit(event.value)
    }
  }
  getEventCloseUpdateCustomerModal(event) {
    this.closeUpdateCustomerForm.emit(event);
  }

}
