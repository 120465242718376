import {Adapter} from '../../adapter';
import {Injectable} from '@angular/core';

export class WeebFleetResponse {
  constructor(
    public id: string,
    public course: number,
    public created_at: string,
    public direction: number,
    public ignition?: number,
    public ignition_time?: string,
    public lastmsgid?: string,
    public latitude_microdeg?: string,
    public location?: string,
    public longitude_microdeg?: string,
    public objectuid? : string,
    public odometer? : string,
    public plate? : string,
    public pos_time? : string,
    public postext? : string,
    public speed? : number,
    public standstill? : number,
    public status? : string
  ) {
  }
}

@Injectable({
  providedIn: 'root',
})
export class WeebFleetResponseAdapter implements Adapter<WeebFleetResponse> {
  adapt(item: any): WeebFleetResponse {
    return new WeebFleetResponse(
        item.id,
        item.course,
        item.created_at,
        item.direction,
        item.ignition,
        item.ignition_time,
        item.lastmsgid,
        item.latitude_microdeg,
        item.location,
        item.longitude_microdeg,
        item.objectuid,
        item.odometer,
        item.plate,
        item.pos_time,
        item.postext,
        item.speed,
        item.standstill,
        item.status
    );
  }
}
