import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import EditLeaseAgreement from '../../../../domain/api/core/agreements/requests/EditLeaseAgreement';
import ILeaseAgreement from '../../../../domain/model/agreements/ILeaseAgreement';

@Component({
  selector: 'app-edit-lease-agreement',
  templateUrl: './edit-lease-agreement.component.html',
  styleUrls: ['./edit-lease-agreement.component.css']
})
export class EditLeaseAgreementComponent implements OnInit {
  @Input() modalOpen = false;
  @Output() modalClosed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() leaseAgreement: ILeaseAgreement;
  @Output() formSubmitted:EventEmitter<EditLeaseAgreement> = new EventEmitter<EditLeaseAgreement>();
  form: FormGroup;
  days: string[];
  constructor() {
    this.form = new FormGroup({
      amount: new FormControl('', [Validators.required]),
      startDate: new FormControl(Date, [Validators.required]),
      dueDay: new FormControl('',[Validators.required]),
      termMonths: new FormControl('',[Validators.required]),
    });
    this.days = [
      'Lunes',
      'Martes',
      'Miércoles',
      'Jueves',
      'Viernes',
      'Sábado',
      'Domingo',
    ]
  }

  ngOnInit(): void {
  }

  closeModal(): void {
    this.modalOpen = false;
    this.modalClosed.emit(this.modalOpen);
  }
}
