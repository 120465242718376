import {Application} from '../../model/onboarding/Application';
import {DidntTakeCarReason} from '../../model/onboarding/DidntTakeCarReason';
import SendDidntTakeCarRequest from '../../model/onboarding/request/SendDidntTakeCarRequest';
import {ApplicationService} from '../../services/application.service';
import {DocumentsService} from '../../services/documents.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import DenyReason from '../../model/onboarding/DenyReason';
import SendDenyApplicationRequest from '../../model/onboarding/request/SendDenyApplicationRequest';
import CreateNewRentalRequest from '../../model/rental/request/CreateNewRentalRequest';
import CreateDocumentRequest from '../../model/document/request/CreateDocumentRequest';
import DocumentType from '../../model/document/DocumentType';
import {CustomerService} from "../../services/customer.service";
import {Underwriting} from "../../model/onboarding/Underwriting";
import {Observable} from "rxjs";
import {RentalService} from "../../services/rental.service";
import {Router} from '@angular/router';
import {CustomerApiService} from '../../infrastructure/api/customer-api.service';
import {UpdateCustomerRequest} from '../../infrastructure/update-customer.request';
import { HttpErrorResponse } from '@angular/common/http';
import { CollectionsService } from '../../../app/services/collections.service';
import NewChargeRequest from '../../model/billing/request/NewChargeRequest';

export default class OnboardingOperations {
  // Applications Arrays
  applications: Array<Application> = [];
  // Lookups Arrays
  didntTakeCarReasons: DidntTakeCarReason[] = [];
  denyReasons: DenyReason[] = [];
  documentTypes: DocumentType[] = [];
  // QuickViewer Visibility Status
  isQuickViewerVisible = false;
  // Forms visibility
  isDidntTakeCarFormVisible = false;
  isDeyFormVisible = false;
  isNewRentalFormVisible = false;
  isUpdateCustomerFormVisible = false;
  isCreateDocumentFormVisible = false;
  isUnderwritingFormIsVisible = false;
  isNewTokuCustomerFormVisible = false;
  miFielAgreementEnabled = false;
  // Application Selected to execute actions
  applicationDetail: Application;
  underwritingDetail: Underwriting;
  documentsToShow: any[];
  itsPaid: boolean;
  paymentReference: any;
  constructor(
    public applicationService: ApplicationService,
    public documentService: DocumentsService,
    public notification: NzNotificationService,
    public customerService: CustomerService,
    public rentalService: RentalService,
    public customerApiService: CustomerApiService,
    public collectionsService: CollectionsService
  ) {
  }
  /*
  * Start: Fetch methods to get applications with different statuses that fills application arrays
  * */
  fetchApplicationsByStatusAndText(status, text, pageIndex: number = 1, pageSize: number = 50): void {
    this.applicationService.fetchApplicationsByStatusAndText(status, text, pageIndex, pageSize).subscribe(
      (data: Application[]) => {
        this.applications = data;
      }
    );
  }
  fetchOpenedApplications(pageIndex: number = 1, pageSize: number = 50): void {
    this.applicationService.fetchApplicationsByStatus('OPEN', pageIndex, pageSize).subscribe(
      (data: Application[]) => {
        this.applications = data;
      }
    );
  }
  fetchDocumentsForSelectedApplication(): void {
    this.documentService.fetchDocumentsByOwnerId(this.applicationDetail.customer.id).subscribe(
      (data: any[]) => {
        this.documentsToShow = data;
      }
    );
  }
  fetchApplicationById(applicationId: string): Observable<Application> {
    return this.applicationService.findApplicationById(applicationId);
  }
  fetchUnderwritingForSelectedApplication(): void {
    this.applicationService.findUnderwritingById(this.applicationDetail.id).subscribe(
      (uw) => {
        this.underwritingDetail = uw;
        console.log(this.underwritingDetail);
        
      }, error => {
        console.log('Couldnt find underwriting information');
      }
    );
  }
  fetchPendingApprovalApplications(): void {
    this.applicationService
      .fetchApplicationsByStatus('PNDG_APPRV')
      .subscribe((data: any[]) => {
        this.applications = data;
      });
  }
  fetchApprovedApplications(): void {
    this.applicationService.fetchApplicationsByStatus('APPROVED').subscribe(
      (data: Application[]) => {
        this.applications = data;
      }
    );
  }
  fetchDeniedApplications(): void {
    this.applicationService.fetchApplicationsByStatus('DENIED').subscribe(
      (data: Application[]) => {
        this.applications = data;
      }
    );
  }
  fetchDidntTakeCarApplications(): void {
    this.applicationService.fetchApplicationsByStatus('DIDNT_TAKE').subscribe(
      (data: Application[]) => {
        this.applications = data;
      }
    );
  }
  fetchDidntTakeCarReasons(): void {
    this.applicationService.fetchDidntTakeCarReasons().subscribe(
      (data) =>  {
        this.didntTakeCarReasons = data;
      }
    );
  }
  fetchDenyReasons(): void {
    this.applicationService.fetchDenyReasons().subscribe(
      (data) =>  {
        this.denyReasons = data;
      }
    );
  }
  fetchDocumentTypes(): void {
    this.documentService.fetchDocumentTypes().subscribe(
      (data) =>  {
        this.documentTypes = data;
      }
    );
  }
  
  /*
  * End Fetch Methods
  * */
  /*
  * Methods to manage QuickViewer Visibility
  * */
  /* async fetchApplicationCustomerDetail(request) {
    this.applicationDetail = await this.fetchApplicationById(applicationId).toPromise();
  } */
  openQuickView(application: Application): void {
    this.fetchApplicationById(application.id).subscribe(
      app => {
        this.applicationDetail = app;
        this.fetchDocumentsForSelectedApplication();
        this.fetchUnderwritingForSelectedApplication();
        this.fetchTokuSecurityStatus();
        this.fetchTokuReferences(this.applicationDetail);
        this.isQuickViewerVisible = true;
      }
    );
  }
  closeQuickView(): void {
    this.isQuickViewerVisible = false;
  }
  /*
  * Methods to show or close Forms
  * */
  openDidntTakeCarForm(): void {
    this.isDidntTakeCarFormVisible = true;
  }
  closeDidntTakeCarForm(): void {
    this.isDidntTakeCarFormVisible = false;
  }
  openDenyForm(): void {
    this.isDeyFormVisible = true;
  }
  closeDenyForm(): void {
    this.isDeyFormVisible = false;
  }
  openNewRentalForm(): void {
    // Avoid open modal form to create rental
    // this.isNewRentalFormVisible = true;
  }
  closeNewRentalForm(): void {
    this.isNewRentalFormVisible = false;
  }
  openUpdateCustomerForm(): void {
    this.isUpdateCustomerFormVisible = true;
  }
  openUnderwritingForm(): void {
    this.fetchUnderwritingForSelectedApplication();
    this.isUnderwritingFormIsVisible = true;
  }
  closeUpdateCustomerForm(): void {
    this.isUpdateCustomerFormVisible = false;
  }
  openCreateDocumentForm(): void {
    this.isCreateDocumentFormVisible = true;
  }
  closeCreateDocumentForm(): void {
    this.isCreateDocumentFormVisible = false;
  }
  openNewTokuCustomerForm(event): void {
    this.isNewTokuCustomerFormVisible = true;
  }
  /*
  * Actions to perform
  * */
  fetchTokuReferences(applicationDetail?){
      const data = {curp: this.applicationDetail?.customer?.curp};
      const jsonData = JSON.stringify(data);
      this.applicationService.fetchTokuPaymentReference(jsonData).subscribe((res=>{
        this.paymentReference = res;
      }
    ), err=>{
      console.log(err);
    })
  }
  fetchTokuSecurityStatus():void{
    const data = {curp: this.applicationDetail?.customer?.curp};
    const jsonData = JSON.stringify(data);
    this.applicationService.fetchSecurityDepositStatus(jsonData).subscribe((res=>{
      this.itsPaid = res;
    }
  ), err=>{
    console.log(err);
  });
  }
  sendApplicationToDidntTakeCar(request: SendDidntTakeCarRequest): void {
    request.triggeredByUserId = localStorage.getItem('userLoggedId');
    this.applicationService.sendApplicationToDidnttakecar(request.applicationId, request).subscribe(
      (data: any) => {
        this.closeDidntTakeCarForm();
        this.createNotification(
          'success',
          'Didnt Take a Car Exitoso!',
          'Se ha mandado la aplicación de '.concat(
            this.applicationDetail.customer.firstName,
            ' ',
            this.applicationDetail.customer.lastName,
            ' a Didnt take a Car'
          ));
        this.fetchOpenedApplications();
        this.closeQuickView();
      }
    );
  }
  sendApplicationToApproval(application: Application): void {
    const triggeredByUserId = localStorage.getItem('userLoggedId');
    this.applicationService.sendApplicationToPendingApproval(application.id).subscribe(
      (data) => {
        this.createNotification(
          'success',
          'Aplicación ha sido enviada a Underwriting',
          'La aplicación de '.concat(application.customer.firstName, ' ', application.customer.lastName, ' ha sido enviada a Underwriting')
        );
      }
    );
    this.fetchOpenedApplications();
    this.closeQuickView();
  }
  denyApplication(request: SendDenyApplicationRequest): void {
    request.triggeredByUserId = localStorage.getItem('userLoggedId');
    this.applicationService.denyApplication(request.applicationId, request).subscribe(
      (data: any) => {
        this.closeDenyForm();
        this.createNotification(
          'success',
          'Aplicación denegada!',
          'Se ha denegado la aplicación de '.concat(
            this.applicationDetail.customer.firstName,
            ' ',
            this.applicationDetail.customer.lastName
          ));
        this.fetchPendingApprovalApplications();
        this.closeQuickView();
      }
    );
  }
  approveApplication(application: Application): void {
    this.applicationService.approveApplication(application.id).subscribe(
      (data: any) => {
        this.createNotification(
          'success',
          'Aplicación Aprobada!',
          'Se ha aprobado la aplicación de '.concat(
            this.applicationDetail.customer.firstName,
            ' ',
            this.applicationDetail.customer.lastName
          ));
        this.fetchPendingApprovalApplications();
        this.closeQuickView();
      }
    );
  }
  private createNewTokuCustomer(data: any): void{
    const { tokuRequest, newChargeRequest } = data;
    if (tokuRequest && tokuRequest.curp && tokuRequest.rfc) {
      this.applicationService.createTokuCustomer(tokuRequest).subscribe((res)=>{
        this.createNotification('success', 'Deposito aplicado', 'el deposito se ha creado con éxito');
        this.isNewTokuCustomerFormVisible = false;
        this.collectionsService.createNewCharge(newChargeRequest).subscribe(res=>{
          console.log("se creó el cargo", res);
        }, err=>{
          console.log(err);
        });
        this.closeQuickView();
      }, (error: HttpErrorResponse)=>{
        this.createNotification('error', 'No se puede crear el deposito de garantía', 'Ha ocurrido un error al crear el deposito');
      })
    } else {
      console.error("No se puede crear un nuevo cliente de Toku sin CURP y RFC.");
    }
    
  }
  createNewRental(rental: CreateNewRentalRequest): void {
    rental.createdBy = localStorage.getItem('userLoggedId');;
    this.rentalService.createNewRental(rental).subscribe(
      (res) => {
        this.closeNewRentalForm();
        this.createNotification('success', 'Renta creada', 'La renta se ha creado con éxito');
      }, (error) => {
        this.createNotification('error', 'No se puede crear la renta', 'Ha ocurrido un error al crear la renta');
      }
    );
  }
  updateCustomer(request: UpdateCustomerRequest, customerId: string): void {
    console.log('Updating customer: ', customerId ? customerId :this.applicationDetail.customer.id,  request);
    this.customerApiService.updateCustomerPersonalData(customerId ? customerId :this.applicationDetail.customer.id , request).subscribe(
      (res) => {
        this.createNotification('success', 'Conductor Actualizado', `El conductor ${request.firstName} ${request.lastName} ha sido actualizado`);
        if( this.applicationDetail ) { 
            this.applicationService.findApplicationById(this.applicationDetail.id).subscribe((app) => {
            this.applications = this.applications.filter(value => {
              return value.id !== this.applicationDetail.id;
            });
            this.applications.push(app);
            this.applicationDetail = app;
          });
        }
      }, error => {
        this.createNotification('error', 'Error al actualizar conductor', `El conductor no se ha podido actualizar: ${error.error.message}`);
      }
    );
    this.closeUpdateCustomerForm();
  }
  updateUnderwriting(request: Underwriting): void {
    console.log(request);
    
    this.applicationService.updateUnderwritingById(request.applicationId, request).subscribe(
      (res) => {
        this.createNotification('success', 'Información actualizada', 'La información se ha actualizado con éxito');
        this.applicationService.findApplicationById(this.applicationDetail.id).subscribe((app) => {
          this.applications = this.applications.filter(value => {
            return value.id !== this.applicationDetail.id;
          });
          this.applications.push(app);
          this.applicationDetail = app;
        });
        this.isUnderwritingFormIsVisible = false;
      }, error => {
        this.createNotification('error', 'Error, no se actualizó la información', 'Ocurrió un error inesperado, vuelva a intentarlo mas tarde');
        console.log('Error occurred');
      }
    );
  }
  createDocument(request: CreateDocumentRequest): void {
    request.triggeredByUserId = localStorage.getItem('userLoggedId');;
    this.documentService.createDocument(request).subscribe(
      (data: any) => {
        this.createNotification(
          'success',
          'Documento creado',
          'Se ha creado el documento con éxito para el conductor '.concat(
            this.applicationDetail.customer.firstName,
            ' ',
            this.applicationDetail.customer.lastName
          ));
        this.closeCreateDocumentForm();
        this.fetchDocumentsForSelectedApplication();
      }
    );
  }
  closeUpdateCustomer(event: Boolean): void{
    if(event){
      this.closeUpdateCustomerForm();
    }
  }
  /*
  * Utility Methods
  * */
  createNotification(type: string, title: string, message: string): void {
    this.notification.create(
      type,
      title,
      message,
      { nzDuration: 15000 }
    );
  }
}
