<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <nz-form-item>
    <nz-form-label [nzSpan]="9">Tipo de pago</nz-form-label>
    <nz-form-control nzErrorTip="Por favor ingresa alguna nota" nzSpan="15">
      <nz-select nzSize="large" formControlName="linkReason" nzPlaceHolder="Selecciona el tipo de Link">
        <nz-option nzValue="WEEKLY_RENTAL" nzLabel="Renta Semanal"></nz-option>
        <nz-option nzValue="SECURITY_DEPOSIT" nzLabel="Depósito de Seguridad"></nz-option>
        <nz-option nzValue="SURCHARGES" nzLabel="Recargos"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="9" nzRequired>Amount</nz-form-label>
    <nz-form-control nzErrorTip="Por favor ingresa el monto" nzSpan="15">
      <input type="number" min="0" nz-input placeholder="Monto" formControlName="amount" />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="9">Método de pago</nz-form-label>
    <nz-form-control nzErrorTip="Por favor ingresa alguna nota" nzSpan="15">
      <nz-select nzMode="tags" nzSize="large" formControlName="allowedPaymentMethods" nzPlaceHolder="Payment Methods">
        <nz-option nzValue="cash" nzLabel="OXXO"></nz-option>
        <nz-option nzValue="bank_transfer" nzLabel="SPEI"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="9" nzRequired>Dias de expiración</nz-form-label>
    <nz-form-control nzErrorTip="" nzSpan="15">
      <input nz-input type="number" formControlName="daysToExpire" min="3" />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="9" nzRequired>¿Enviar correo?</nz-form-label>
    <nz-form-control nzErrorTip="" nzSpan="15">
      <nz-radio-group nzSize="large" formControlName="requiresEmailNotifications" nzButtonStyle="solid">
        <label nz-radio-button [nzValue]="1">Si</label>
        <label nz-radio-button [nzValue]="0">No</label>
      </nz-radio-group>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-control [nzSpan]="12" [nzOffset]="6">
      <button type="submit" nz-button nzType="primary" nzBlock nzSize="large">Enviar</button>
    </nz-form-control>
  </nz-form-item>

</form>