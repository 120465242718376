<div nz-row nzJustify="center">
  <ng-container *ngFor="let document of documents">
    <div nz-col nzSpan="24" *ngFor="let file of document.files">
      <nz-card style="width:300px;" [nzBordered]="false" [nzTitle]="document.documentType.typeName">
        <img
          *ngIf="file.extension === 'image/png' || file.extension === 'image/jpeg'"
          nz-image
          width="200px"
          height="250px"
          [nzSrc]="this.buildEnpointForImages(file.id)"
          alt=""
        />
        <iframe
          *ngIf="file.extension === 'application/pdf'"
          style="width: 100%; height: 400px;"
          [src]="this.buildEnpointForPdf(file.id)"
        >
        </iframe>

      </nz-card>
    </div>
  </ng-container>
</div>
