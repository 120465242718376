<nz-page-header class="site-page-header" nzTitle="Agreements" >
  <nz-page-header-extra>
    <nz-radio-group [(ngModel)]="rentalStatusSelected" nzSize="large" nzButtonStyle="solid" (ngModelChange)="rentalStatusChanged()">
      <label nz-radio-button nzValue="ACTIVE">Active</label>
      <label nz-radio-button nzValue="RETURNED">Return</label>
      <label nz-radio-button nzValue="RECOVERED">Repo</label>
      <label nz-radio-button nzValue="STOLEN">Stolen</label>
    </nz-radio-group>
    <button nz-button nzType="primary">Upload Platform Payments</button>
  </nz-page-header-extra>
  <nz-page-header-content>
    <nz-row>
      <nz-space [nzSize]="'middle'" nzDirection="horizontal">
        <nz-space-item>
          <nz-statistic nzTitle="Rental Selected" [nzValue]="rentalStatusSelected"></nz-statistic>
          <nz-statistic nzTitle="Total drivers" [nzValue]="rentals.length" [nzValueStyle]="{ color: '#3F8600' }"></nz-statistic>
        </nz-space-item>
      </nz-space>
      <ng-template #prefixTplOne><i nz-icon nzType="arrow-up"></i></ng-template>
      <ng-template #prefixTplTwo><i nz-icon nzType="arrow-down"></i></ng-template>
    </nz-row>
  </nz-page-header-content>
</nz-page-header>
<br /><br />
<app-search-field (searchSubmitted)="searchCustomer($event)"></app-search-field>
<br /><br />
<nz-table #currentCustomers [nzData]="rentals" [nzPageSize]="60">
  <thead>
  <tr>
    <th>Name</th>
    <th>Plate</th>
    <th>Phone</th>
    <th>Email</th>
    <th>LTO Payment</th>
    <th>LTO Term</th>
    <th>Rental</th>
    <th *ngIf="rentalStatusSelected === 'ACTIVE'">Payment Day</th>
    <th></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let data of currentCustomers.data">
    <td>{{ data.customer.firstName }} {{ data.customer.lastName }}
      <nz-tag [nzColor]="'purple'" *ngIf="data.rentalStatus.statusCode === 'ACTIVE' && data.ltoPayment">LTO Customer</nz-tag>

    </td>
    <td>{{ data.car.plate }}</td>
    <td>{{ data.customer.phone }}</td>
    <td>{{ data.customer.email }}</td>
    <td>{{ data.ltoPayment }}</td>
    <td>{{ data.ltoTermLength }}</td>
    <td>{{ data.rentalAmount }}</td>
    <td *ngIf="rentalStatusSelected === 'ACTIVE'" [ngSwitch]="data.chargeDayofweek">
      <span *ngSwitchCase="'0'">Lunes</span>
      <span *ngSwitchCase="'1'">Martes</span>
      <span *ngSwitchCase="'2'">Miércoles</span>
      <span *ngSwitchCase="'3'">Jueves</span>
      <span *ngSwitchCase="'4'">Viernes</span>
      <span *ngSwitchCase="'5'">Sábado</span>
      <span *ngSwitchCase="'6'">Domingo</span>
    </td>
    <td>
      <a nz-button nzType="primary" [routerLink]="'/billing/customer/' + data.customer.id">Open</a>
      <button nz-button nz-dropdown [nzDropdownMenu]="menu">
        Acciones
        <i nz-icon nzType="down"></i>
      </button>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
          <li nz-menu-item>
            <a *ngIf="data.rentalStatus.statusCode == 'ACTIVE'" (click)="openRentalEditForm(data)">Editar Renta</a>
          </li>
          <li nz-menu-item>
            <a *ngIf="data.rentalStatus.statusCode == 'ACTIVE'">Pausar Renta</a>
          </li>
          <li nz-menu-item>
            <a (click)="openReturnForm(data)" *ngIf="data.rentalStatus.statusCode == 'ACTIVE'">Marcar Return</a>
          </li>
          <li nz-menu-item>
            <a *ngIf="data.rentalStatus.statusCode == 'ACTIVE'">Marcar Repo</a>
          </li>
          <li nz-menu-item>
            <a *ngIf="data.rentalStatus.statusCode == 'ACTIVE'">Marcar Switch</a>
          </li>
          <li nz-menu-item>
            <a *ngIf="data.rentalStatus.statusCode == 'ACTIVE'">Marcar Robo</a>
          </li>
          <li nz-menu-item>
            <a *ngIf="data.rentalStatus.statusCode == 'ACTIVE'">Pausar LTO</a>
          </li>
          <li nz-menu-item>
            <a *ngIf="data.rentalStatus.statusCode == 'ACTIVE'">Convertir a LTO</a>
          </li>
          <li nz-menu-item>
            <a *ngIf="data.rentalStatus.statusCode == 'ACTIVE'">Cancelar LTO</a>
          </li>
        </ul>
      </nz-dropdown-menu>
    </td>
  </tr>
  </tbody>
</nz-table>

<nz-modal
  [nzWidth]="800"
  [nzMaskClosable]="false"
  [(nzVisible)]="isReturnFormVisible"
  [nzTitle]="'Return de ' + this.rentalSelected?.customer?.firstName + ' ' + this.rentalSelected?.customer?.lastName"
  (nzOnCancel)="isReturnFormVisible=false"
  (nzOnOk)="isReturnFormVisible=false"
  [nzFooter]="null">
  <ng-container *nzModalContent>
    <app-return-car-form
      [customer]="this.rentalSelected?.customer"
      [rental]="this.rentalSelected"
      (formSubmitted)="closeReturnForm()"
    ></app-return-car-form>
  </ng-container>
</nz-modal>


<nz-modal
  [nzWidth]="800"
  [nzMaskClosable]="false"
  [(nzVisible)]="isEditRentalFormVisible"
  [nzTitle]="'Editar Renta de ' + this.rentalSelected?.customer?.firstName + ' ' + this.rentalSelected?.customer?.lastName"
  (nzOnCancel)="isEditRentalFormVisible=false"
  (nzOnOk)="isEditRentalFormVisible=false"
  [nzFooter]="null">
  <ng-container *nzModalContent>
    <app-edit-rental-form
      [rental]="this.rentalSelected"
      (formSubmitted)="closeRentalEditForm()"
    ></app-edit-rental-form>
  </ng-container>
</nz-modal>
