import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CarService} from "../../../services/car.service";
import {RentalService} from "../../../services/rental.service";
import {NotificationService} from "../../../services/notification.service";
import {BackofficeCoreService} from "../../../services/backoffice-core.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-perform-repo',
  templateUrl: './perform-repo.component.html',
  styleUrls: ['./perform-repo.component.css']
})
export class PerformRepoComponent implements OnInit {

  rentalId;
  form: FormGroup;
  rental: any;
  buttonDisabled = false;
  isFormVisible = false;
  constructor(private carService: CarService,
              private rentalService: RentalService,
              private notificationService: NotificationService,
              private coreService: BackofficeCoreService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      repoDate: new FormControl(null, [Validators.required]),
      note: new FormControl()
    });
    this.route.params.subscribe(
      params => {
        this.rentalId = params.id;
        this.coreService.getRentalAgreementById(this.rentalId).subscribe(value => {
          this.rental = value;
        });
      }
    );
  }
  onSubmit(): void {
    this.buttonDisabled = true;
    if (this.form.valid) {
      this.coreService.repoCar(this.rentalId, {
        rentalAgreementId: this.rentalId,
        repoDate: this.form.controls.repoDate.value,
        notes: this.form.controls.note.value
      }).subscribe(
        (res) => {
          this.notificationService.createNotification('success', 'Exito', 'La repo fue marcada con éxito');
          this.router.navigate(['/agreements/active/rental']);
        }, error => {
          this.notificationService.createNotification('error', 'Error', error.error.errorMessage);
        }
      );
    }
    this.buttonDisabled = false;
  }

}
