import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import DenyReasons from '../../../../model/onboarding/DenyReason';
import SendDenyApplicationRequest from '../../../../model/onboarding/request/SendDenyApplicationRequest';
import {Application} from '../../../../model/onboarding/Application';

@Component({
  selector: 'app-deny-application-form',
  templateUrl: './deny-application-form.component.html',
  styles: [
  ]
})
export class DenyApplicationFormComponent implements OnInit {
  @Input() application: Application;
  @Input() denyReasons: DenyReasons[];
  @Output() formSubmitted: EventEmitter<SendDenyApplicationRequest> = new EventEmitter<SendDenyApplicationRequest>();
  denyApplicationForm: FormGroup;
  constructor() { }

  ngOnInit(): void {
    this.denyApplicationForm = new FormGroup({
      reasonSelect: new FormControl(),
      note: new FormControl()
    });
  }
  onSubmit(): void {
    const data = new SendDenyApplicationRequest(
      this.application.id,
      this.denyApplicationForm.controls.reasonSelect.value,
      null,
      this.denyApplicationForm.controls.note.value
    );
    this.formSubmitted.emit(data);
  }

}
