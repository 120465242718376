<nz-page-header class="site-page-header" nzTitle="Contratos de Renta" >
  <nz-page-header-content>
    <nz-row>
      <nz-space [nzSize]="'middle'" nzDirection="horizontal">
        <nz-space-item>
          <div *ngIf="!loading" style="text-align: center; padding: 30px 25px;">
            <nz-spin></nz-spin>
          </div>
          <nz-statistic *ngIf="loading" nzTitle="Total" [nzValue]="customers.length" [nzValueStyle]="{ color: '#3F8600' }"></nz-statistic>
        </nz-space-item>
      </nz-space>
      <ng-template #prefixTplOne><i nz-icon nzType="arrow-up"></i></ng-template>
      <ng-template #prefixTplTwo><i nz-icon nzType="arrow-down"></i></ng-template>
    </nz-row>
  </nz-page-header-content>
</nz-page-header>
<br /><br />
<app-search-field (searchSubmitted)="searchCustomer($event)"></app-search-field>
<br /><br />
<nz-table [nzLoading]="!loading" #currentCustomers [nzData]="customersDisplay" [nzPageSize]="60">
  <thead>
  <tr>
    <th>Nombre</th>
    <th>Vehículo</th>
    <th>Teléfono</th>
    <th>Email</th>
    <th>Renta</th>
    <th>Inicio</th>
    <th>Fin</th>
    <th>Status</th>

  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let data of currentCustomers.data">
    <td>{{ data.customer.firstName }} {{ data.customer.lastName }}</td>
    <td>{{ data.car.plate }}</td>
    <td>{{ data.customer.phone }}</td>
    <td>{{ data.customer.email }}</td>
    <td>{{ data.amount }}</td>
    <td>{{ data.startDate }}</td>
    <td>{{ data.endDate }}</td>
    <td [ngSwitch]="data.agreementStatusId">
      <span *ngSwitchCase="50">Return</span>
      <span *ngSwitchCase="60">Repo</span>
      <span *ngSwitchCase="80">Robo</span>
      <span *ngSwitchCase="90">Robo no recuperado</span>
    <td>
  </tr>
  </tbody>
</nz-table>
