import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-update-document-form',
  templateUrl: './update-document-form.component.html',
  styles: [
  ]
})
export class UpdateDocumentFormComponent implements OnInit {
  form: FormGroup = this.fb.group({
    documentType: ['', [Validators.required]],
  });
  constructor(private fb: FormBuilder) { }
  ngOnInit(): void {

  }

}
