import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {
  Customer,
  CustomerAdapter
} from '../../domain/model/customer/customer.model';
import { catchError, map } from 'rxjs/operators';
import { UpdateCustomerRequest } from '../update-customer.request';

const endpoint = environment.customers_endpoint;
const endpointCustomerPhones = environment.customers_phones_endpoint;
const endpointCore = environment.core_endpoint;
const thirdPartyEndpoint = environment.nave_third_party;
const digitalContractEndpoint = environment.nave_core_digital_contract;

@Injectable({
  providedIn: 'root',
})
export class CustomerApiService {
  constructor(
    private http: HttpClient,
    private customerPersonalInformationAdapter: CustomerAdapter) {
  }
  public getCustomerPersonalData(customerId: string): Observable<Customer> {
    return this.http.get(`${endpoint}v1/customers/${customerId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).pipe(
      map((data: any) => this.customerPersonalInformationAdapter.adapt(data))
    );
  }

  public getCarInformationData(carId: string): Observable<any> {
    return this.http.get(`${endpointCore}car/${carId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    )
  }

  public putCarInformationData(car: any, carId: any): Observable<any> {
    return this.http.put(`${endpointCore}car/${carId}`, car, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    )
  }

  public updateCustomerPersonalData(customerId: string,
    updateCustomerPersonalInformationRequest: UpdateCustomerRequest): Observable<any> {
    return this.http.put(`${endpoint}v1/customers/${customerId}`, updateCustomerPersonalInformationRequest, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  fetchCustomerAddress(customerId: string): Observable<any> {
    return this.http.get(`${endpoint}v1/customers/${customerId}/addresses`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    )
  }
  fetchZipCode(zipCode: string): Observable<any> {
    return this.http.get(`${endpoint}v1/customers/zipcode/${zipCode}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    )
  }
  addAddress(customerId: string, body: any): Observable<any> {
    return this.http.post(`${endpoint}v1/customers/${customerId}/addresses`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    )
  }
  updateAddress(addressId: string, body: any): Observable<any> {
    return this.http.put(`${endpoint}v1/customers/addresses/${addressId}`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    );
  }
  deleteAddress(addressId: number): Observable<any> {
    return this.http.delete(`${endpoint}v1/customers/addresses/${addressId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
  }
  patchAddress(customerId: string, body: any): Observable<any> {
    return this.http.patch(`${endpoint}v1/customers/${customerId}`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    );
  }
  addGuarantor(customerId: string, body: any): Observable<any> {
    return this.http.post(`${endpoint}v1/customer/${customerId}/guarantor`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }
      ));
  }
  fetchGuarantor(customerId: string): Observable<any> {
    return this.http.get(`${endpoint}v1/customer/${customerId}/guarantor`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    )
  }
  deleteGuarantor(guarantorId: string): Observable<any> {
    return this.http.delete(`${endpoint}v1/customer/guarantor/${guarantorId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    )
  }
  updateGuarantor(guarantorId: string, body: any): Observable<any> {
    return this.http.put(`${endpoint}v1/customer/guarantor/${guarantorId}`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    )
  }
  addSurety(customerId: string, body: any): Observable<any> {
    return this.http.post(`${endpoint}v1/customer/${customerId}/surety`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    )
  }
  fetchSurety(customerId: string): Observable<any> {
    return this.http.get(`${endpoint}v1/customer/${customerId}/surety`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    )
  }

  deleteSurety(suretyId: string): Observable<any> {
    return this.http.delete(`${endpoint}v1/customer/surety/${suretyId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    )
  }
  updateSurety(suretyId: string, body: any): Observable<any> {
    return this.http.put(`${endpoint}v1/customer/surety/${suretyId}`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    )
  }
  fetchAllCustomerAgreements(customerId: string): Observable<any> {
    return this.http.get(`${thirdPartyEndpoint}/customer/${customerId}/agreements`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  fetchAllCustomerDigitalAgreements(rfc: string): Observable<any> {
    return this.http.get(`${digitalContractEndpoint}contract/agreements/${rfc}`);
  }
  fetchAllCustomerPhones(customerId: string): Observable<any> {
    return this.http.get(`${endpointCustomerPhones}customers-phone/${customerId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  createCustomerPhone(customerId: string, body: any): Observable<any> {
    return this.http.post(`${endpointCustomerPhones}customers-phone/${customerId}/phones`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  updateCustomerPhone(phoneId: string, body: any): Observable<any> {
    return this.http.put(`${endpointCustomerPhones}customers-phone/${phoneId}/phones`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  deleteCustomerPhone(phoneId: string): Observable<any> {
    return this.http.delete(`${endpointCustomerPhones}customers-phone/${phoneId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

}
