<nz-table #nzTable [nzData]="charges" nzTableLayout="fixed">
  <thead>
  <tr>
    <th>Charge</th>
    <th>Amount</th>
    <th>Due Date</th>
  </tr>
  </thead>
  <tbody>
  <ng-container *ngFor="let c of nzTable.data">
    <tr>
      <td>{{ c.chargeType.name }}</td>
      <td style="color: red; font-weight: bold;">{{ c.amount.toFixed(2) }}</td>
      <td>{{ c.dueDate }}</td>
  </ng-container>
  </tbody>
</nz-table>
