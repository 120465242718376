import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NzBadgeModule } from "ng-zorro-antd/badge";
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzCommentModule } from "ng-zorro-antd/comment";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NZ_I18N, es_ES } from 'ng-zorro-antd/i18n';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzImageModule } from "ng-zorro-antd/image";
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzListModule } from "ng-zorro-antd/list";
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzRadioModule } from "ng-zorro-antd/radio";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from "ng-zorro-antd/tag";
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { CollectionsModule } from "../collections/collections.module";
import { NaveComponentsModule } from '../components/nave-components.module';
import { NotificationCenterComponent } from '../components/notification-center/notification-center.component';
import { NotificationListCenterComponent } from '../components/notification-center/notification-list-center/notification-list-center.component';
import { CrmModule } from "../crm/crm.module";
import { RbacShowDirective } from '../directives/rbac-show.directive';
import { IconsProviderModule } from '../icons-provider.module';
import { NgzorroComponentsModule } from '../ngzorroComponents/ngzorro-components.module';
import { ActiveLeaseComponent } from './agreements/active-lease/active-lease.component';
import { ActiveRentalComponent } from './agreements/active-rental/active-rental.component';
import { AgreementsMonitoringComponent } from './agreements/agreements-monitoring/agreements-monitoring.component';
import { CancelLeaseToOwnAgreementComponent } from './agreements/cancel-lease-to-own-agreement/cancel-lease-to-own-agreement.component';
import { ConverttoLtoComponent } from './agreements/convertto-lto/convertto-lto.component';
import { CreateRentalComponent } from './agreements/create-rental/create-rental.component';
import { EditLeaseToOwnAgreementComponent } from './agreements/edit-lease-to-own-agreement/edit-lease-to-own-agreement.component';
import { EditRentalAgreementComponent } from './agreements/edit-rental-agreement/edit-rental-agreement.component';
import { EndRentalAgreementComponent } from './agreements/end-rental-agreement/end-rental-agreement.component';
import { FinishedAgreementsComponent } from './agreements/finished-agreements/finished-agreements.component';
import { PauseCarComponent } from './agreements/pause-car/pause-car.component';
import { PauseLeaseToOwnAgreementComponent } from './agreements/pause-lease-to-own-agreement/pause-lease-to-own-agreement.component';
import { PauseRentalAgreementComponent } from './agreements/pause-rental-agreement/pause-rental-agreement.component';
import { PerformRepoComponent } from './agreements/perform-repo/perform-repo.component';
import { ReturnCarComponent } from './agreements/return-car/return-car.component';
import { SubstituteCarComponent } from './agreements/substitute-car/substitute-car.component';
import { SwitchCarComponent } from './agreements/switch-car/switch-car.component';
import { ActiveCustomersComponent } from './billing/active-customers/active-customers.component';
import { BillingStatementComponent } from './billing/billing-statement/billing-statement.component';
import { CollectionsComponent } from './billing/collections/collections.component';
import { NewPaymentLinkComponent } from './billing/links/new-payment-link/new-payment-link.component';
import { ActiveCustomerComponent } from './customer/active-customer/active-customer.component';
import { CustomerDetailComponent } from "./customer/customer-detail/customer-detail.component";
import { CarsComponent } from './fleet/cars/cars.component';
import { RegisterCarComponent } from './fleet/register-car/register-car.component';
import { ApplicationsComponent } from './onboarding/applications/applications.component';
import { ApprovedApplicationsComponent } from './onboarding/approved-applications/approved-applications.component';
import { DeniedApplicationsComponent } from './onboarding/denied-applications/denied-applications.component';
import { DidntTakeApplicationsComponent } from './onboarding/didnt-take-applications/didnt-take-applications.component';
import { OpenApplicationsComponent } from './onboarding/open-applications/open-applications.component';
import { PendingApproveApplicationsComponent } from './onboarding/pending-approve-applications/pending-approve-applications.component';
import { TestpageComponent } from './onboarding/testpage/testpage.component';
import { PagesComponent } from './pages.component';
import { PagesRoutingModule } from './pages.routing';
import { CustomerInformationExternalShareComponent } from "./share/customer-information-external-share/customer-information-external-share.component";
import { ShareInformationLayoutComponent } from './share/share-information-layout/share-information-layout.component';

@NgModule({
  declarations: [
    PagesComponent,
    ApplicationsComponent,
    ActiveCustomersComponent,
    CustomerDetailComponent,
    CarsComponent,
    TestpageComponent,
    OpenApplicationsComponent,
    PendingApproveApplicationsComponent,
    ApprovedApplicationsComponent,
    DeniedApplicationsComponent,
    DidntTakeApplicationsComponent,
    NewPaymentLinkComponent,
    CollectionsComponent,
    CustomerInformationExternalShareComponent,
    ShareInformationLayoutComponent,
    CreateRentalComponent,
    ReturnCarComponent,
    SwitchCarComponent,
    SubstituteCarComponent,
    PauseCarComponent,
    ConverttoLtoComponent,
    PauseRentalAgreementComponent,
    PauseLeaseToOwnAgreementComponent,
    CancelLeaseToOwnAgreementComponent,
    EditRentalAgreementComponent,
    EditLeaseToOwnAgreementComponent,
    PerformRepoComponent,
    RegisterCarComponent,
    ActiveCustomerComponent,
    ActiveRentalComponent,
    ActiveLeaseComponent,
    AgreementsMonitoringComponent,
    EndRentalAgreementComponent,
    FinishedAgreementsComponent,
    BillingStatementComponent,
    NotificationCenterComponent,
    NotificationListCenterComponent,
    RbacShowDirective
  ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    IconsProviderModule,
    NzLayoutModule,
    NzMenuModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NzPageHeaderModule,
    NzStatisticModule,
    NzGridModule,
    NzButtonModule,
    NzTableModule,
    NzTabsModule,
    NzSpaceModule,
    NzDrawerModule,
    NzDescriptionsModule,
    NzDividerModule,
    NzTypographyModule,
    NaveComponentsModule,
    NzModalModule,
    NzNotificationModule,
    NzCardModule,
    NzCollapseModule,
    NzInputModule,
    NzRadioModule,
    NzImageModule,
    NzTagModule,
    NzBadgeModule,
    NzIconModule,
    NzDropDownModule,
    NzListModule,
    NzCommentModule,
    CrmModule,
    CollectionsModule,
    NzPageHeaderModule,
    NzFormModule,
    NzDatePickerModule,
    NzSelectModule,
    ReactiveFormsModule,
    NgzorroComponentsModule,
    NzSpinModule,
    NzCheckboxModule,
    NzToolTipModule
  ],
  providers: [{ provide: NZ_I18N, useValue: es_ES }],
})
export class PagesModule { }
