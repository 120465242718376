<nz-page-header nzTitle="Paydowns">
  <nz-page-header-content>
    <nz-radio-group [(ngModel)]="paydownStatusFilterSelected" nzSize="large" nzButtonStyle="solid" (ngModelChange)="paydownStatusFilterChanged()">
      <nz-space>
        <nz-space-item>
          <label nz-radio-button nzValue="ACTIVE">Active</label>
        </nz-space-item>
        <nz-space-item>
          <label nz-radio-button nzValue="PAUSE">Pause</label>
        </nz-space-item>
        <nz-space-item>
          <label nz-radio-button nzValue="COMPLETED">Completed</label>
        </nz-space-item>
        <nz-space-item>
          <label nz-radio-button nzValue="ALL">All</label>
        </nz-space-item>
      </nz-space>
    </nz-radio-group>
  </nz-page-header-content>
  <nz-page-header-extra>
  </nz-page-header-extra>
</nz-page-header>
<nz-table #nzTable [nzData]="paydowns" nzTableLayout="fixed">
  <thead>
  <tr>
    <th nzWidth="60px"></th>
    <th>Customer</th>
    <th>Weeks</th>
    <th>Remaining Weeks</th>
    <th>Total Amount</th>
    <th>Pending Amount</th>
    <th>Start Date</th>
    <th>End Date</th>
    <th>Status</th>
    <th>Created By</th>
  </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let data of nzTable.data">
      <tr>
        <td [nzExpand]="expandSet.has(data.id)" (nzExpandChange)="onExpandChange(data.id, $event)"></td>
        <td>{{ data.customer.firstName }} {{ data.customer.lastName }}</td>
        <td>{{ data.time }} <nz-tag [nzColor]="'magenta'">WEEKS</nz-tag></td>
        <td>{{ data.time - data.charges?.length }}</td>
        <td>{{ data.totalAmount }}</td>
        <td>{{ data.totalAmount - (data.charges?.length * data.totalAmount/data.time) }}</td>
        <td>{{ data.startDate }}</td>
        <td>{{ calculateExpectedEndDate(data.startDate, data.time) }}</td>
        <td>{{ data.status }}</td>
        <td>{{ data.createdBy?.username }}</td>
      </tr>
      <tr [nzExpand]="expandSet.has(data.id)">
        <ng-container>
          <app-charges-table-view [charges]="data.charges"></app-charges-table-view>
        </ng-container>
      </tr>
    </ng-container>
  </tbody>
</nz-table>
<nz-modal
[(nzVisible)]="isCreatePaydownFormVisible"

>
  <app-create-paydown-form>
  </app-create-paydown-form>
</nz-modal>