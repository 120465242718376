import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-reception-type-form',
  templateUrl: './reception-type-form.component.html',
  styleUrls: ['./reception-type-form.component.css']
})
export class ReceptionTypeFormComponent implements OnInit {
  receptionForm : FormGroup;
  @Output() type :EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
    this.receptionForm = new FormGroup({
      type: new FormControl('' || null, Validators.required),
    });
  }
  

}
