<form [formGroup]="receptionForm">
    <nz-form-item>
      <nz-form-label [nzSpan]="9" nzRequired>Tipo de recepción</nz-form-label>
      <nz-form-control nzErrorTip="Por favor selecione un tipo de recepción." nzSpan="15">
        <nz-select nzSize="large" formControlName="type" nzPlaceHolder="Selecciona un tipo de recepción:">
          <nz-option nzValue="delivery" nzLabel="Entrega."></nz-option>
          <nz-option nzValue="return" nzLabel="Regreso."></nz-option>
          <nz-option nzValue="highRisk  " nzLabel="Riesgo Alto."></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </form>
