<nz-table #nzTable [nzData]="documents" nzTableLayout="fixed">
  <thead>
  <tr>
    <th nzWidth="60px"></th>
    <th>Documento</th>
    <th>Status</th>
  </tr>
  </thead>
  <tbody>
  <ng-container *ngFor="let data of nzTable.data">
    <tr>
      <td [nzExpand]="expandSet.has(data.id)" (nzExpandChange)="onExpandChange(data.id, $event)"></td>
      <td>{{ data.documentType.typeName }}</td>
      <td>{{ data.isCompleted === true? 'Completed': 'Pending Capture' }}</td>
    </tr>
    <tr [nzExpand]="expandSet.has(data.id)">
      <nz-descriptions [nzColumn]="1" nzTitle="Información del documento">
        <nz-descriptions-item nzTitle="Archivos" [nzSpan]="1">
          <nz-image-group>
            <img *ngFor="let file of data.files" nz-image width="75px" [nzSrc]="getFilesEndpoint(file.id)" alt="" />
          </nz-image-group>
        </nz-descriptions-item>
        <nz-descriptions-item>
          <app-upload-file-form [documentId]="data.id" [documentType]="data.documentType.typeCode"  (fileUploaded)="fileUploadedSuccess($event, data.documentType.typeCode)"></app-upload-file-form>
        </nz-descriptions-item>
      </nz-descriptions>
    </tr>
  </ng-container>
  </tbody>
</nz-table>
