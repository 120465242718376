import { Injectable } from '@angular/core';
import { Adapter } from './../adapter';

export class BillingStatement {
  private chargePaymentMap = new Map<string, TransactionBS[]>();
  private paymentChargeMap = new Map<string, TransactionBS[]>();
  private readonly overdueCharges: ChargeDetailBS[];
  private readonly paymentsPending: PaymentDetailBS[];
  private readonly overdueTotal: number;
  private readonly paymentsPendingTotal: number;
  constructor(
    public customerInformation: CustomerInformationBS,
    public charges: ChargeDetailBS[],
    public payments: PaymentDetailBS[],
    public transactions: TransactionBS[]
  ) {
    this.paymentsPending = this.payments.filter(p => p.paymentPendingAmountToApply < 0);
    this.paymentsPendingTotal = this.paymentsPending.map(
      (p => p.paymentPendingAmountToApply)
    ).reduce(
      (previousValue, currentValue) => previousValue + currentValue, 0
    );
    this.overdueCharges = this.charges.filter(c => c.chargePendingAmountToCover > 0 && c.chargeDueDate < new Date());
    this.overdueTotal = this.overdueCharges.map(
      (c => c.chargePendingAmountToCover)
    ).reduce(
      (previousValue, currentValue) => previousValue + currentValue, 0
    );
    this.mapChargesPayments();
  }
  public getOverDueCharges(): ChargeDetailBS[] {
    return this.overdueCharges;
  }
  public getUnappliedPayments(): PaymentDetailBS[] {
    return this.paymentsPending;
  }
  public getUnappliedPaymentsTotal(): number {
    return this.paymentsPendingTotal;
  }
  public getOverDueTotal(): number {
    return this.overdueTotal;
  }
  public getChargesPaymentsMap(): Map<string, TransactionBS[]> {
    return this.chargePaymentMap;
  }
  public getPaymentsChargesMap(): Map<string, TransactionBS[]> {
    return this.paymentChargeMap;
  }
  private mapChargesPayments(): void {
    this.transactions.forEach(t => {
      if (!this.chargePaymentMap.has(t.transactionChargeId)) {
        this.chargePaymentMap.set(t.transactionChargeId, Array.of<TransactionBS>());
      }
      const paymentsMapped = this.chargePaymentMap.get(t.transactionChargeId);
      paymentsMapped.push(t);
    });
  }
  private mapPaymentsCharges(): void {
    this.transactions.forEach(t => {
      if (!this.paymentChargeMap.has(t.transactionPaymentId)) {
        this.paymentChargeMap.set(t.transactionPaymentId, Array.of<TransactionBS>());
      }
      const paymentsMapped = this.paymentChargeMap.get(t.transactionPaymentId);
      paymentsMapped.push(t);
    });
  }
}

export class CustomerInformationBS {
  constructor(
    public id: string,
    public firstName: string,
    public lastName: string,
    public curp: string,
    public rfc: string,
    public email: string,
    public phone: string,
    public city: string,
    public dateOfBirth: Date,
    public gender: string,
    public note: string,
    public hubspotId: string,
    public agentAssignedId: string
  ) {}
}

export class ChargeDetailBS {
  constructor(
    public chargeId: string,
    public chargeCustomerId: string,
    public chargeDueDate: Date,
    public chargeTypeName: string,
    public chargeTypeCode: string,
    public chargeAmount: number,
    public chargeAmountCovered: number,
    public chargePendingAmountToCover: number,
    public chargeYear: number,
    public chargeWeek: number,
    public chargePriority: number,
    public chargeRentalAgreementUuid: string
  ) {}
  isChargeFullCovered(): boolean {
    return this.chargePendingAmountToCover === 0;
  }
}

export class PaymentDetailBS {
  constructor(
    public paymentId: string,
    public paymentCustomerId: string,
    public paymentAmount: number,
    public paymentAmountApplied: number,
    public paymentPendingAmountToApply: number,
    public paymentMethodName: string,
    public paymentMethodCode: string,
    public paymentCategoryCode: string,
    public paymentDueDate: Date,
    public paymentCreatedAt: number,
    public paymentYear: number,
    public paymentWeek: number
  ) {}
}

export class TransactionBS {
  constructor(
    public transactionId: string,
    public transactionAmount: number,
    public transactionAppliedAt: number,
    public transactionPaymentId: string,
    public transactionPaymentAmount: number,
    public transactionPaymentDueDate: Date,
    public transactionPaymentMethodCode: string,
    public transactionPaymentMethodName: string,
    public transactionPaymentCategoryCode: string,
    public transactionChargeId: string,
    public transactionPaymentCustomerId: string,
    public transactionChargeCustomerId: string,
    public transactionChargeTypeCode: string,
    public transactionChargeTypeName: string,
    public transactionChargeAmount: number,
    public transactionChargeDueDate: Date,
    public transactionChargePendingAmountToCover: number,
    public transactionChargeAmountCovered: number,
    public transactionRentalAgreementUuid: string,
    public transactionCarUuid: string,
    public transactionCarPlate: string
  ) {}
}

@Injectable({
  providedIn: 'root',
})
export class BillingStatementAdapter implements Adapter<BillingStatement> {
  adapt(item: any): BillingStatement {
    let transactions;
    let charges;
    let payments;
    if (item.transactions) {
      transactions = item.transactions.map(
        (t: any) => new TransactionBS(
          t.transactionId,
          t.transactionAmount,
          t.transactionAppliedAt,
          t.transactionPaymentId,
          t.transactionPaymentAmount,
          new Date(t.transactionPaymentDueDate.replace('-', '/')),
          t.transactionPaymentMethodCode,
          t.transactionPaymentMethodName,
          t.transactionPaymentCategoryCode,
          t.transactionChargeId,
          t.transactionPaymentCustomerId,
          t.transactionChargeCustomerId,
          t.transactionChargeTypeCode,
          t.transactionChargeTypeName,
          t.transactionChargeAmount,
          new Date(t.transactionChargeDueDate.replace('-', '/')),
          t.transactionChargePendingAmountToCover,
          t.transactionChargeAmountCovered,
          t.transactionRentalAgreementUuid,
          t.transactionCarUuid,
          t.transactionCarPlate
        )
      );
    }
    if (item.charges) {
      charges = item.charges.map(
        (c) => new ChargeDetailBS(
          c.chargeId,
          c.chargeCustomerId,
          new Date(c.chargeDueDate.replace('-', '/')),
          c.chargeTypeName,
          c.chargeTypeCode,
          c.chargeAmount,
          c.chargeAmountCovered,
          c.chargePendingAmountToCover,
          c.chargeYear,
          c.chargeWeek,
          c.chargePriority,
          c.chargeRentalAgreementUuid
        )
      );
    }
    if (item.payments) {
      payments = item.payments.map(
        (p) => new PaymentDetailBS(
          p.paymentId,
          p.paymentCustomerId,
          p.paymentAmount,
          p.paymentAmountApplied,
          p.paymentPendingAmountToApply,
          p.paymentMethodName,
          p.paymentMethodCode,
          p.paymentCategoryCode,
          new Date(p.paymentDueDate.replace('-', '/')),
          p.paymentCreatedAt,
          p.paymentYear,
          p.paymentWeek
        )
      );
    }
    const customer = new CustomerInformationBS(
      item.customerInformation.id,
      item.customerInformation.firstName,
      item.customerInformation.lastName,
      item.customerInformation.curp,
      item.customerInformation.rfc,
      item.customerInformation.email,
      item.customerInformation.phone,
      item.customerInformation.city,
      item.customerInformation.dateOfBirth,
      item.customerInformation.gender,
      item.customerInformation.note,
      item.customerInformation.hubspotId,
      item.customerInformation.agentAssignedId
    );
    return new BillingStatement(customer, charges, payments, transactions);
  }
}
