import { Component, OnInit } from '@angular/core';
import {CarService} from '../../../services/car.service';

@Component({
  selector: 'app-cars',
  templateUrl: './cars.component.html',
  styles: [
  ]
})
export class CarsComponent implements OnInit {
  availableCars: any[] = [];
  constructor(private carService: CarService) { }
  ngOnInit(): void {
    this.loadAvailableCars();
  }
  loadAvailableCars(): void {
    this.carService.fetchCarsByStatus('AVAILABLE')
      .subscribe((data: any[]) => {
        console.log(data);
        this.availableCars = data;
      });
  }
}
