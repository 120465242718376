<div class="col-12">
  <div class="input-group">
    <input type="text" class="form-control" placeholder="Buscar..." />
    <span class="input-group-btn">
      <button class="btn rounded-0 btn-primary" type="button">
        <i class="fa fa-search fa-fw"></i> Buscar
      </button>
    </span>
  </div>
</div>
