<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <nz-form-item>
    <nz-form-label [nzSpan]="9" nzRequired>Choose User</nz-form-label>
    <nz-form-control nzErrorTip="Please select the user you want to assign" nzSpan="15">
      <nz-select nzSize="large" formControlName="user" nzPlaceHolder="Choose user">
        <nz-option *ngFor="let u of users" [nzValue]="u.id" [nzLabel]="u.username"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-control [nzSpan]="12" [nzOffset]="6">
      <button type="submit" nz-button nzType="primary" nzBlock nzSize="large" >Enviar</button>
    </nz-form-control>
  </nz-form-item>

</form>
