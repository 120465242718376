import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  currentUser: any;

  constructor(private http: HttpClient) { 
  }
  
  getCurrentRole(): string[] {
    var roles = localStorage.getItem('roles');
    if(roles === null){
      return [];
    }else{
      return JSON.parse(roles);
    }
  }
  getCurrentToken(): string {
    return localStorage.getItem('token');
  }
  getCurrentUserIdLoggedIn(): string {
    return localStorage.getItem('userLoggedId');
  }
}
