import { Adapter } from '../domain/adapter';
import {Injectable} from '@angular/core';

export class UpdateCustomerRequest {
  constructor(
    public firstName: string,
    public lastName: string,
    public email: string,
    public phone: string,
    public billingEmail: string,
    public rfc: string,
    public curp: string,
    public city: string,
    public note: string,
    public source: string,
    public customerType: string,
    public taxRegime?: string,
    public cfdiUse?: string,
    public license?: string) {
  }
}

@Injectable({
  providedIn: 'root',
})
export class UpdateCustomerRequestAdapter implements Adapter<UpdateCustomerRequest> {
  adapt(item: any): UpdateCustomerRequest {
    
    return new UpdateCustomerRequest(
      item.firstName,
      item.lastName,
      item.email,
      item.phone,
      item.billingEmail,
      item.rfc,
      item.curp,
      item.city,
      item.note,
      item.source,
      item.customerType,
      item.taxRegime,
      item.cfdiUse,
      item.license
    );
  }
}
