<form [formGroup]="newChargeForm" (ngSubmit)="onSubmit()">
    <nz-form-item>
        <nz-form-label [nzSpan]="24" nzRequired>Tipo de Cargo</nz-form-label>
        <nz-form-control nzErrorTip="Por favor ingresa alguna nota" nzSpan="24">
        <input type="text" nz-input placeholder="Ingresa el tipo de cargo" formControlName="chargeType"/>
        </nz-form-control>
    </nz-form-item>
    <nz-form-item>
        <nz-form-label [nzSpan]="24" nzRequired>Amount</nz-form-label>
        <nz-form-control nzErrorTip="Por favor ingresa el monto" nzSpan="24">
        <input type="number" min="0" nz-input placeholder="Monto" formControlName="amount" />
        </nz-form-control>
    </nz-form-item>
    <nz-form-item>
        <nz-form-control [nzSpan]="12" [nzOffset]="6">
        <button type="submit" nz-button nzType="primary" nzBlock nzSize="large" >Enviar</button>
        </nz-form-control>
    </nz-form-item>
</form>
  