import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomerApiService } from '../../../../infrastructure/api/customer-api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-bail-form',
  templateUrl: './bail-form.component.html',
  styleUrls: ['./bail-form.component.css']
})
export class BailFormComponent implements OnInit {
  @Input() customerId: string;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();
  bailForm: FormGroup;
  bailFormArray :any[] = [];
  isBail: boolean = true;
  constructor(private customerApiService:CustomerApiService,
    private notificationService: NzNotificationService,
    private nzMessageService: NzMessageService,) { }

  ngOnInit(): void {
    this.fetchSurety();
  }
  addSurety(event: PointerEvent): void{
    this.bailForm = new FormGroup({
        surety: new FormControl(null, [Validators.required]),
        suretyNumber: new FormControl(null, [Validators.required]),
        type: new FormControl(null, [Validators.required]),
        beginningValidity: new FormControl(null, [Validators.required]) ,
        endValidity: new FormControl(null, [Validators.required]) ,
        amount: new FormControl(null, [Validators.required])
      })
    this.bailFormArray.push({form: this.bailForm, id: undefined}
    )   
    this.isBail = false;
  }
  saveSurety(form: FormGroup): void{
    const obj = {
      surety: form.controls.surety.value,
      suretyNumber: form.controls.suretyNumber.value,
      type: form.controls.type.value,
      beginningValidity: form.controls.beginningValidity.value,
      endValidity: form.controls.endValidity.value,
      amount: form.controls.amount.value,
    }
    const [surety] = this.bailFormArray;
    if(surety.id === undefined) {
      this.customerApiService.addSurety(this.customerId, obj).subscribe(() => {
        this.createNotification('success', 'Éxito', 'Se creo correctamente la fianza');
        this.closeModal.emit(true);
      }, (err) => {
        this.createNotification('error', 'Error', `${err.message}`);
      });
    }else{
      this.customerApiService.updateSurety(surety.id, obj).subscribe(() => {
        this.createNotification('success', 'Éxito', 'Se actualizo correctamente la fianza');
        this.closeModal.emit(true);
      }, (err) => {
        this.createNotification('error', 'Error', `${err.message}`);
      });
    }
  }

  deleteSurety(i: number, surety): void{
    this.bailFormArray.splice(i, 1);
    this.isBail = true;
    if(surety.id){
      this.customerApiService.deleteSurety(surety.id).subscribe((res) => {
        this.nzMessageService.info('la afianzadora fue eliminada');
        this.closeModal.emit(true);
      }, (err) => {
        this.createNotification('success', 'Error', `${err.message}`);
      });
    }
  }

  fetchSurety(): void{
    this.customerApiService.fetchSurety(this.customerId).subscribe((res) => {
      var [surety] = res;
      this.bailFormArray.push({form: new FormGroup({
        surety: new FormControl(surety.surety , [Validators.required]),
        suretyNumber: new FormControl(surety.suretyNumber, [Validators.required]),
        type: new FormControl(surety.type, [Validators.required]),
        beginningValidity: new FormControl(surety.beginningValidity, [Validators.required]) ,
        endValidity: new FormControl(surety.endValidity, [Validators.required]) ,
        amount: new FormControl(surety.amount, [Validators.required])
      }), id: surety.id});
      if( this.bailFormArray.length > 0 ) this.isBail = false;
    }, (err) => {
    });
  }

  createNotification(type: string, title: string, message: string, duration = 7000): void {
    this.notificationService.create(
      type,
      title,
      message,
      { nzDuration: duration }
    );
  }
}
