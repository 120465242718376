<nz-page-header class="site-page-header" nzTitle="Contratos de Lease to Own" >
  <nz-page-header-content>
    <nz-row>
      <nz-space [nzSize]="'middle'" nzDirection="horizontal">
        <nz-space-item>
          <div *ngIf="!loading" style="text-align: center; padding: 30px 25px;">
            <nz-spin></nz-spin>
          </div>
          <nz-statistic *ngIf="loading" nzTitle="Total" [nzValue]="leases.length" [nzValueStyle]="{ color: '#3F8600' }"></nz-statistic>
        </nz-space-item>
      </nz-space>
      <ng-template #prefixTplOne><i nz-icon nzType="arrow-up"></i></ng-template>
      <ng-template #prefixTplTwo><i nz-icon nzType="arrow-down"></i></ng-template>
    </nz-row>
  </nz-page-header-content>
</nz-page-header>
<br /><br />
<app-search-field (searchSubmitted)="searchCustomer($event)"></app-search-field>
<br /><br />
<nz-table [nzLoading]="!loading" #currentCustomers [nzData]="leasesDisplay" [nzPageSize]="60">
  <thead>
  <tr>
    <th>Nombre</th>
    <th>Vehículo</th>
    <th>Teléfono</th>
    <th>Email</th>
    <th>Monto</th>
    <th>Plazo Meses</th>
    <th>Inicio</th>
    <th>Acciones</th>

  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let data of currentCustomers.data">
    <td>{{ data.customer.firstName }} {{ data.customer.lastName }}</td>
    <td>{{ data.car.plate }}</td>
    <td>{{ data.customer.phone }}</td>
    <td>{{ data.customer.email }}</td>
    <td>{{ data.amount }}</td>
    <td>{{ data.termMonths }}</td>
    <td>{{ data.startDate }}</td>
    <td>
      <a nz-button nzType="primary" [routerLink]="'/billing/customer/' + data.customer.id">Open</a>
      <button *appRbacShow="['ADMIN']" nz-button nz-dropdown [nzDropdownMenu]="menu">
        Acciones
        <i nz-icon nzType="down"></i>
      </button>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
          <li nz-menu-item>
            <a>Editar Lease to Own</a>
          </li>
          <li nz-menu-item>
            <a>Pausar Lease to Own</a>
          </li>
          <li nz-menu-item>
            <a>Cancelar Lease to Own</a>
          </li>
        </ul>
      </nz-dropdown-menu>
    </td>
  </tr>
  </tbody>
</nz-table>
