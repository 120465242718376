import { Component, OnInit } from '@angular/core';
import {ApplicationService} from '../../../services/application.service';
import {DocumentsService} from '../../../services/documents.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import OnboardingOperations from '../OnboardingOperations';
import {CustomerService} from "../../../services/customer.service";
import {RentalService} from "../../../services/rental.service";
import {CustomerApiService} from '../../../infrastructure/api/customer-api.service';
import { CollectionsService } from '../../../services/collections.service';

@Component({
  selector: 'app-denied-applications',
  templateUrl: './denied-applications.component.html',
  styles: [
  ]
})
export class DeniedApplicationsComponent extends OnboardingOperations implements OnInit {
  constructor(
    public applicationService: ApplicationService,
    public documentService: DocumentsService,
    public notification: NzNotificationService,
    public customerService: CustomerService,
    public rentalService: RentalService,
    public customerApiService: CustomerApiService,
    public collectionsService: CollectionsService,
  ) { super(applicationService, documentService, notification, customerService, rentalService, customerApiService, collectionsService); }
  
  ngOnInit(): void {
    this.fetchDeniedApplications();
  }
}
