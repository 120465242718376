import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Application} from '../../../../model/onboarding/Application';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Underwriting} from "../../../../model/onboarding/Underwriting";
import { CustomerApiService } from '../../../../infrastructure/api/customer-api.service';
import {Customer} from '../../../../model/onboarding/Customer';

@Component({
  selector: 'app-underwriting-update-form',
  templateUrl: './underwriting-update-form.component.html',
  styleUrls: ['./underwriting-update-form.component.css']
})
export class UnderwritingUpdateFormComponent implements OnInit {
  @Input() customer!: Customer;
  @Input() application: Application;
  @Input() underwriting: Underwriting;
  @Output() formSubmitted: EventEmitter<Underwriting> = new EventEmitter<Underwriting>();
  uwForm: FormGroup;
  constructor(private customerService: CustomerApiService) { }

  ngOnInit(): void {
    console.log(this.underwriting);
    
    this.uwForm = new FormGroup({
      ineHasBeenValidated: new FormControl(this.underwriting?.ineHasBeenValidated),
      qrAbleToGenerate: new FormControl(this.underwriting?.qrAbleToGenerate),
      platformWorkOn: new FormControl(this.underwriting?.platformWorkOn),
      acceptanceRate: new FormControl(this.underwriting?.acceptanceRate),
      platformProofEarnings: new FormControl(this.underwriting?.platformProofEarnings),
      contributorStatus: new FormControl(this.underwriting?.contributorStatus),
      garageType: new FormControl(this.underwriting?.garageType),
      isPermanentLicense: new FormControl(this.underwriting?.isPermanentLicense),
      isFederalLicense: new FormControl(this.underwriting?.isFederalLicense),
      platformCategoryAbbrev: new FormControl(this.underwriting?.platformCategoryAbbrev),
      licenseExpirationDate: new FormControl(this.underwriting?.licenseExpirationDate),
      licenseExpeditionDate: new FormControl(this.underwriting?.licenseExpeditionDate),
      cifOldAbbrev: new FormControl(this.underwriting?.cifOldAbbrev),
      ineExpirationYear: new FormControl(this.underwriting?.ineExpirationYear),
      ineZipcode: new FormControl(this.underwriting?.ineZipcode),
      nameEmbbededEmail: new FormControl(this.underwriting?.nameEmbbededEmail),
      fourAddressesMatch: new FormControl(this.underwriting?.fourAddressesMatch),
      proofOfAddressRelationshipAbbrev: new FormControl(this.underwriting?.proofOfAddressRelationshipAbbrev),
      licenseState: new FormControl(this.underwriting?.licenseState),
      proofOfAddressOldAbbrev: new FormControl(this.underwriting?.proofOfAddressOldAbbrev),
      hasComplianceOpinionUploaded: new FormControl(this.underwriting?.hasComplianceOpinionUploaded),
      cancellationRate: new FormControl(this.underwriting?.cancellationRate),
      proofOfAddressCategoryAbbrev: new FormControl(this.underwriting?.proofOfAddressCategoryAbbrev),
      proofOfAddressOwnerName: new FormControl(this.underwriting?.proofOfAddressOwnerName),
      proofOfAddressMatchIne: new FormControl(this.underwriting?.proofOfAddressMatchIne),
      platformNameAbbrev: new FormControl(this.underwriting?.platformNameAbbrev),
      truoraLegal: new FormControl(this.underwriting?.truoraLegal),
      truoraTotal: new FormControl(this.underwriting?.truoraTotal),
      rankingAtPlatformWorkedFor: new FormControl(this.underwriting?.rankingAtPlatformWorkedFor),
      estRidesGiven: new FormControl(this.underwriting?.estRidesGiven),
      yearsOnPlatforms: new FormControl(this.underwriting?.yearsOnPlatforms),
      monthsOnPlatforms: new FormControl(this.underwriting?.monthsOnPlatforms),
      interviewRisk: new FormControl(this.underwriting?.interviewRisk),
      nonPaymentRisk: new FormControl(this.underwriting?.nonPaymentRisk),
      accutestRisk: new FormControl(this.underwriting?.accutestRisk),
      backgroundCheckRisk: new FormControl(this.underwriting?.backgroundCheckRisk),
      emailInRfc: new FormControl(this.underwriting?.emailInRfc),
      accutestWarning: new FormControl(this.underwriting?.accutestWarning),
      parkingAddressMatches: new FormControl(this.underwriting?.parkingAddressMatches),
      contributorRegimen: new FormControl(Number(this.customer.taxRegime)),
      driverTypeName: new FormControl(this.underwriting?.driverTypeName),
      municipality: new FormControl(this.underwriting?.municipality),
      zipCode: new FormControl(this.underwriting?.zipCode),
      colonia: new FormControl(this.underwriting?.colonia),
      state: new FormControl(this.underwriting?.state),
      streetAddress: new FormControl(this.underwriting?.streetAddress),
      isCurpValidated: new FormControl(this.underwriting?.isCurpValidated),
      parkingAddress: new FormControl(this.underwriting?.parkingAddress),
      platformCategory: new FormControl(this.underwriting?.platformCategory),
      securityDeposit: new FormControl(this.underwriting?.securityDeposit)
    });
    console.log(this.uwForm);
    
  }
  onSubmit(): void {
    if (this.uwForm.controls.parkingAddressMatches.value === 'OTHER') {
      this.uwForm.controls.parkingAddress.setValidators([Validators.required]);
    } else {
      this.uwForm.controls.parkingAddress.clearValidators();
    }
    this.uwForm.controls.parkingAddress.markAsDirty();
    this.uwForm.controls.parkingAddress.updateValueAndValidity();
    if (!this.uwForm.controls.parkingAddress.valid) {
      return;
    }
    this.underwriting.ineHasBeenValidated = this.uwForm.controls.ineHasBeenValidated.value;
    this.underwriting.qrAbleToGenerate = this.uwForm.controls.qrAbleToGenerate.value;
    this.underwriting.platformWorkOn = this.uwForm.controls.platformWorkOn.value;
    this.underwriting.acceptanceRate = this.uwForm.controls.acceptanceRate.value;
    this.underwriting.platformProofEarnings = this.uwForm.controls.platformProofEarnings.value;
    this.underwriting.contributorStatus = this.uwForm.controls.contributorStatus.value;
    this.underwriting.garageType = this.uwForm.controls.garageType.value;
    this.underwriting.isPermanentLicense = this.uwForm.controls.isPermanentLicense.value;
    this.underwriting.isFederalLicense = this.uwForm.controls.isFederalLicense.value;
    this.underwriting.platformCategoryAbbrev = this.uwForm.controls.platformCategoryAbbrev.value;
    this.underwriting.licenseExpirationDate = this.uwForm.controls.licenseExpirationDate.value;
    this.underwriting.licenseExpeditionDate = this.uwForm.controls.licenseExpeditionDate.value;
    this.underwriting.cifOldAbbrev = this.uwForm.controls.cifOldAbbrev.value;
    this.underwriting.ineExpirationYear = this.uwForm.controls.ineExpirationYear.value;
    this.underwriting.ineZipcode = this.uwForm.controls.ineZipcode.value;
    this.underwriting.nameEmbbededEmail = this.uwForm.controls.nameEmbbededEmail.value;
    this.underwriting.fourAddressesMatch = this.uwForm.controls.fourAddressesMatch.value;
    this.underwriting.proofOfAddressRelationshipAbbrev = this.uwForm.controls.proofOfAddressRelationshipAbbrev.value;
    this.underwriting.licenseState = this.uwForm.controls.licenseState.value;
    this.underwriting.proofOfAddressOldAbbrev = this.uwForm.controls.proofOfAddressOldAbbrev.value;
    this.underwriting.hasComplianceOpinionUploaded = this.uwForm.controls.hasComplianceOpinionUploaded.value;
    this.underwriting.cancellationRate = this.uwForm.controls.cancellationRate.value;
    this.underwriting.proofOfAddressCategoryAbbrev = this.uwForm.controls.proofOfAddressCategoryAbbrev.value;
    this.underwriting.proofOfAddressOwnerName = this.uwForm.controls.proofOfAddressOwnerName.value;
    this.underwriting.proofOfAddressMatchIne = this.uwForm.controls.proofOfAddressMatchIne.value;
    this.underwriting.platformNameAbbrev = this.uwForm.controls.platformNameAbbrev.value;
    this.underwriting.truoraLegal = this.uwForm.controls.truoraLegal.value;
    this.underwriting.truoraTotal = this.uwForm.controls.truoraTotal.value;
    this.underwriting.rankingAtPlatformWorkedFor = this.uwForm.controls.rankingAtPlatformWorkedFor.value;
    this.underwriting.estRidesGiven = this.uwForm.controls.estRidesGiven.value;
    this.underwriting.yearsOnPlatforms = this.uwForm.controls.yearsOnPlatforms.value;
    this.underwriting.monthsOnPlatforms = this.uwForm.controls.monthsOnPlatforms.value;
    this.underwriting.interviewRisk = this.uwForm.controls.interviewRisk.value;
    this.underwriting.nonPaymentRisk = this.uwForm.controls.nonPaymentRisk.value;
    this.underwriting.accutestRisk = this.uwForm.controls.accutestRisk.value;
    this.underwriting.backgroundCheckRisk = this.uwForm.controls.backgroundCheckRisk.value;
    this.underwriting.emailInRfc = this.uwForm.controls.emailInRfc.value;
    this.underwriting.accutestWarning = this.uwForm.controls.accutestWarning.value;
    this.underwriting.parkingAddressMatches = this.uwForm.controls.parkingAddressMatches.value;
    this.underwriting.contributorRegimen = this.uwForm.controls.contributorRegimen.value;
    this.underwriting.driverTypeName = this.uwForm.controls.driverTypeName.value;
    this.underwriting.municipality = this.uwForm.controls.municipality.value;
    this.underwriting.zipCode = this.uwForm.controls.zipCode.value;
    this.underwriting.colonia = this.uwForm.controls.colonia.value;
    this.underwriting.state = this.uwForm.controls.state.value;
    this.underwriting.streetAddress = this.uwForm.controls.streetAddress.value;
    this.underwriting.isCurpValidated = this.uwForm.controls.isCurpValidated.value;
    this.underwriting.parkingAddress = this.uwForm.controls.parkingAddress.value;
    this.underwriting.platformCategory = this.uwForm.controls.platformCategory.value;
    this.underwriting.platformNameAbbrev = this.underwriting.platformWorkOn;
    this.underwriting.securityDeposit = this.uwForm.controls.securityDeposit.value;
    const obj = {
      taxRegime: this.underwriting.contributorRegimen.toString()
    }
    this.customerService.patchAddress(this.customer.id, obj).subscribe((res)=>{
      console.log(res);
    })
    this.formSubmitted.emit(this.underwriting);
  }
}
