<nz-divider nzText="Actualizar direcciones"></nz-divider>
<div style="padding-bottom: 20px;" *ngFor="let a of forms; let i = index;">
  <nz-card nzType="inner" [nzTitle]="'Dirección ' + (i + 1)" [nzExtra]="extraTemplate">
    <form [formGroup]="a.form">
      <nz-form-item>
        <nz-form-label [nzSpan]="6" nzRequired>Tipo</nz-form-label>
        <nz-form-control nzErrorTip="Por favor selecciona una opción" nzSpan="15">
          <nz-select nzSize="default" nzPlaceHolder="Selecciona el tipo de dirección" formControlName="addressType">
            <nz-option [nzValue]="1001" nzLabel="Dirección Fiscal"></nz-option>
            <nz-option [nzValue]="1002" nzLabel="Dirección de comprobante de domicilio"></nz-option>
            <nz-option [nzValue]="1004" nzLabel="Dirección de comprobante de domicilio 2"></nz-option>
            <nz-option [nzValue]="1003" nzLabel="Estacionamiento"></nz-option>
            <nz-option [nzValue]="1005" nzLabel="Estacionamiento 2"></nz-option>
            <nz-option [nzValue]="1006" nzLabel="Estacionamiento 3"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzSize="default" [nzSpan]="6" nzRequired>Calle</nz-form-label>
        <nz-form-control nzErrorTip="Por favor introduce una calle" nzSpan="15">
          <input type="text" nz-input placeholder="Calle" formControlName="street" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzSize="default" [nzSpan]="6" nzRequired>Número ext</nz-form-label>
        <nz-form-control nzErrorTip="Por favor introduce un número" nzSpan="15">
          <input type="text" nz-input placeholder="Número exterior" formControlName="extNumber" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzSize="default" [nzSpan]="6">Número int</nz-form-label>
        <nz-form-control nzErrorTip="Por favor introduce un número" nzSpan="15">
          <input type="text" nz-input placeholder="Número interior" formControlName="intNumber" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item nzJustify="center">
        <label style="width: 80%;" nz-checkbox [(ngModel)]="a.checked" [ngModelOptions]="{standalone: true}">
          No es la dirección correcta ¿Ingresar dirección de firma manual?
        </label>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzSize="default" [nzSpan]="6" nzRequired>Código Postal</nz-form-label>
        <nz-form-control nzErrorTip="Por favor introduce un código postal valido" nzSpan="15">
          <input type="text" pattern="^[0-9]+" maxlength=5 minlength=5 nz-input placeholder="Código postal"
            (keyup)="keyUp(a.form.controls.zipcode.value, i)" formControlName="zipcode" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item *ngIf="!a.checked">
        <nz-form-label nzSize="default" [nzSpan]="6" nzRequired>Estado</nz-form-label>
        <nz-form-control nzErrorTip="Por favor introduce un estado" nzSpan="15">
          <input type="text" nz-input placeholder="Estado" formControlName="state" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item *ngIf="!a.checked">
        <nz-form-label nzSize="default" [nzSpan]="6" nzRequired>Municipio</nz-form-label>
        <nz-form-control nzErrorTip="Por favor introduce un municipio" nzSpan="15">
          <input type="text" nz-input placeholder="Municipio" formControlName="municipality" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item *ngIf="!a.checked">
        <nz-form-label [nzSpan]="6" nzRequired>Colonia</nz-form-label>
        <nz-form-control nzErrorTip="Por favor selecciona una opción" nzSpan="15">
          <nz-select nzSize="default" nzPlaceHolder="Selecciona la colonia" formControlName="neighbourhood">
            <nz-option *ngFor="let s of a.suburbs" [nzValue]="s.suburb" [nzLabel]="s.suburb"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzSize="default" [nzSpan]="6">Dirección</nz-form-label>
        <nz-form-control nzErrorTip="Por favor introduce una dirección" nzSpan="15">
          <input type="text" nz-input placeholder="Colonia, Municipio , Estado" formControlName="address" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="6">Notas</nz-form-label>
        <nz-form-control nzErrorTip="Por favor selecciona una razón" nzSpan="15">
          <textarea nz-input placeholder="" formControlName="note"></textarea>
        </nz-form-control>
      </nz-form-item>
    </form>
    <ng-template #extraTemplate>
      <button nz-button nzType="default" (click)="saveAddress(i)" [disabled]="!a.form.valid"><span nz-icon
          nzType="save"></span></button>
      <button nzDanger nz-popconfirm nzPopconfirmTitle="¿Estas segur@ de eliminar la dirección?" nzOkText="Confirmar"
        nzCancelText="Cancelar" (nzOnConfirm)="this.deleteAddress(i)" nz-button nzType="primary"><span nz-icon
          nzType="delete"></span></button>
    </ng-template>
  </nz-card>
</div>
<nz-form-control [nzSpan]="24" [nzOffset]="9">
  <button nz-button nzType="dashed" type="button" nzShape="round" nzSize="large" (click)="addField($event)">
    <span nz-icon nzType="plus"></span>Agregar dirección
  </button>
</nz-form-control>