import { Component, OnInit } from '@angular/core';
import {DidntTakeCarReason} from '../../../model/onboarding/DidntTakeCarReason';
import DenyReason from '../../../model/onboarding/DenyReason';
import {Customer} from '../../../model/onboarding/Customer';
import {Application} from '../../../model/onboarding/Application';
import {ApplicationService} from '../../../services/application.service';
import Car from '../../../model/fleet/Car';
import PickupType from '../../../model/rental/PickupType';
import RentalType from '../../../model/rental/RentalType';
import * as moment from 'moment';
import { fromUnixTime } from 'date-fns';
import { parse } from 'date-fns';
import { format } from 'date-fns';

@Component({
  selector: 'app-testpage',
  templateUrl: './testpage.component.html',
  styles: [ `
    .content {
      display: flex;
    }
    .extra > div {
      display: flex;
      width: max-content;
      justify-content: flex-end;
    }
    @media (max-width: 576px) {
      .content {
        display: block;
      }

      .main {
        width: 100%;
        margin-bottom: 12px;
      }

      .extra {
        width: 100%;
        margin-left: 0;
        text-align: left;
      }
    }
  `
  ]
})
export class TestpageComponent implements OnInit {
  date = moment;
  parseDate = parse;
  transactions: any[] = [
    {
      id: 'xossnkldcklncd',
      amount: 2300,
      dueDate: '2020-12-22',
      type: 'PAYMENT',
      createdAt: 1000,
      name: 'Pago por Oxxo'
    },
    {
      id: 'kkladnclds',
      amount: 2500,
      dueDate: '2020-12-11',
      type: 'CHARGE',
      createdAt: 999,
      name: 'Cargo de Renta'
    },
    {
      id: 'kkladnclds',
      amount: 2500,
      dueDate: '2020-12-11',
      type: 'CHARGE',
      createdAt: 999,
      name: 'Cargo de Renta'
    },
    {
      id: 'kkladnclds',
      amount: 2500,
      dueDate: '2020-12-11',
      type: 'CHARGE',
      createdAt: 999,
      name: 'Cargo de Renta'
    },
    {
      id: 'kkladnclds',
      amount: 2500,
      dueDate: '2020-12-11',
      type: 'PAYMENT',
      createdAt: 999,
      name: 'Cargo de Renta'
    },
    {
      id: 'kkladnclds',
      amount: 2500,
      dueDate: '2020-12-11',
      type: 'PAYMENT',
      createdAt: 999,
      name: 'Cargo de Renta'
    },
    {
      id: 'kkladnclds',
      amount: 2500,
      dueDate: '2020-12-11',
      type: 'PAYMENT',
      createdAt: 999,
      name: 'Cargo de Renta'
    },
    {
      id: 'kkladnclds',
      amount: 2500,
      dueDate: '2020-12-11',
      type: 'CHARGE',
      createdAt: 999,
      name: 'Cargo de Renta'
    },
    {
      id: 'kkladnclds',
      amount: 2500,
      dueDate: '2020-12-11',
      type: 'PAYMENT',
      createdAt: 999,
      name: 'Cargo de Renta'
    },
    {
      id: 'kkladnclds',
      amount: 2500,
      dueDate: '2020-12-11',
      type: 'PAYMENT',
      createdAt: 999,
      name: 'Cargo de Renta'
    },
    {
      id: 'kkladnclds',
      amount: 2500,
      dueDate: '2020-12-11',
      type: 'PAYMENT',
      createdAt: 999,
      name: 'Cargo de Renta'
    },
    {
      id: 'kkladnclds',
      amount: 2500,
      dueDate: '2020-12-11',
      type: 'PAYMENT',
      createdAt: 999,
      name: 'Cargo de Renta'
    },
    {
      id: 'kkladnclds',
      amount: 2500,
      dueDate: '2020-12-11',
      type: 'CHARGE',
      createdAt: 999,
      name: 'Cargo de Renta'
    },
    {
      id: 'kkladnclds',
      amount: 2500,
      dueDate: '2020-12-11',
      type: 'PAYMENT',
      createdAt: 999,
      name: 'Cargo de Renta'
    },
    {
      id: 'kkladnclds',
      amount: 2500,
      dueDate: '2020-12-11',
      type: 'CHARGE',
      createdAt: 999,
      name: 'Cargo de Renta'
    },
    {
      id: 'kkladnclds',
      amount: 2500,
      dueDate: '2020-12-11',
      type: 'PAYMENT',
      createdAt: 999,
      name: 'Cargo de Renta'
    },
  ];
  reasons: DidntTakeCarReason[] = [];
  carsAvailable: Car[] = [];
  pickupTypes: PickupType[] = [];
  agreementTypes: RentalType[] = [];
  denyReasons: DenyReason[] = [];
  customer: Customer = new Customer();
  application: Application;
  constructor(
    private applicationService: ApplicationService
  ) { }
  formatDateToHumans(date: string): any {
    const d = parse(date, 'yyyy-MM-dd', new Date());
    return format(d, 'd MMMM y');
  }
  ngOnInit(): void {
    this.reasons.push(new DidntTakeCarReason(
      'NO_MONEY', '', 'No tiene dinero'
    ));
    this.denyReasons.push(new DenyReason(
      'AGE_NOT_ALLOWED', '', 'Es Menor de Edad'
    ));
    this.customer = new Customer(
      '',
      'Raúl',
      'Vivanco Borgonio',
      'rvivanco@nave.mx',
      '5554556677',
      '1994/08/26',
      null,
      null,
      'F'
    );
    this.applicationService.fetchApplicationsByStatus('OPEN').subscribe((data) => {
      this.application = data[0];
    });

  }
  onSubmitForm(data: any): void {
    console.log('Data Submitted', data);
  }
}
