import { Injectable } from '@angular/core';
import RentalAgreementResponse from '../../../domain/api/core/agreements/response/RentalAgreementResponse';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import LeaseAgreementResponse from '../../../domain/api/core/agreements/response/LeaseAgreementResponse';

const endpoint = environment.core_endpoint;
@Injectable({
  providedIn: 'root',
})
export class AgreementServiceService {
  constructor(private http: HttpClient) {}
  getRentalAgreementsByStatus(
    agreementStatus: string
  ): Observable<RentalAgreementResponse> {
    return this.http
      .get<RentalAgreementResponse>(
        `${endpoint}v2/agreements/rental?agreementStatus=${agreementStatus}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getLeaseAgreementsByStatus(
    agreementStatus: string
  ): Observable<LeaseAgreementResponse> {
    return this.http
      .get<LeaseAgreementResponse>(
        `${endpoint}v2/agreements/lease?agreementStatus=${agreementStatus}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
  }
}
