<!-- Modal -->
<div
  class="modal fade"
  [ngClass]="{ show: modalOpen }"
  [ngStyle]="{ display: modalOpen === true ? 'block' : 'none' }"
  id="editLeaseAgreementModal"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="editLeaseAgreementModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">
          <div class="d-flex justify-content-beetwen align-items-center">
            <div>
              <h5 id="ModalLabel">Editar LTO</h5>
              <small>{{ leaseAgreement?.customer?.fullName }}</small>
              <small class="ps-3">Placa: {{ leaseAgreement?.car?.plate }}</small>
              <small class="ps-3">Monto: {{ leaseAgreement?.amount }}</small>
            </div>
          </div>
        </div>
        <button type="button" class="btn-close" (click)="closeModal()"></button>
      </div>
      <div class="modal-body">
        <div class="pb-3">
          <form class="row g-2" [formGroup]="form">
            <div class="col-md-12">
              <label for="inputStartDate" class="form-label"
                >Fecha de Inicio</label
              >
              <input
                type="date"
                class="form-control"
                id="inputStartDate"
                formControlName="startDate"
                [(ngModel)]="leaseAgreement.startDate"
              />
            </div>
            <div class="col-md-12">
              <label for="dueDay" class="form-label">Día de pago</label>
              <select
                class="form-select"
                id="dueDay"
                formControlName="dueDay"
                [(ngModel)]="leaseAgreement.dueDay"
              >
                <option selected>Seleciona un día de la semana</option>
                <option *ngFor="let day of days; let i = index" [value]="i">
                  {{ day }}
                </option>
              </select>
            </div>
            <div class="col-md-12">
              <label for="amount" class="form-label">Monto</label>
              <select
                class="form-select"
                id="amount"
                formControlName="amount"
                [(ngModel)]="leaseAgreement.amount"
              >
                <option selected>Seleciona el monto</option>
                <option>...</option>
              </select>
            </div>
            <div class="col-md-12">
              <label for="termMonths" class="form-label">Plazo</label>
              <select
                class="form-select"
                id="termMonths"
                formControlName="termMonths"
                [(ngModel)]="leaseAgreement.termMonths"
              >
                <option selected>Seleciona el plazo</option>
                <option>...</option>
              </select>
            </div>
          </form>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeModal()"
          data-bs-dismiss="modal"
        >
          Cerrar
        </button>
        <button type="button" class="btn btn-primary">Enviar</button>
      </div>
    </div>
  </div>
</div>

<div
  [ngClass]="{
    'modal-backdrop': modalOpen,
    fade: modalOpen,
    show: modalOpen
  }"
></div>
