import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-convertto-lto',
  templateUrl: './convertto-lto.component.html',
  styleUrls: ['./convertto-lto.component.css']
})
export class ConverttoLtoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
