import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import RentalType from '../model/rental/RentalType';
import PickupType from '../model/rental/PickupType';
import CreateNewRentalRequest from '../model/rental/request/CreateNewRentalRequest';
import {environment} from '../../environments/environment';
import ReturnReason from "../model/rental/ReturnReason";
import Rental from "../model/rental/Rental";

const endpoint = environment.billing_endpoint;
const core_endpoint = environment.core_endpoint;

@Injectable({
  providedIn: 'root'
})
export class RentalService {

  constructor(private http: HttpClient) { }

  fetchRentalTypes(): Observable<RentalType[]> {
    return this.http.get<RentalType[]>(endpoint + `lookups?name=RENTAL_TYPE`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  fetchPickupTypes(): Observable<PickupType[]> {
    return this.http.get<PickupType[]>(endpoint + `lookups?name=PICKUP_TYPE`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  createNewRental(request: CreateNewRentalRequest): Observable<any> {
    return this.http.post(endpoint + `rentals`, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  fetchRentalsByStatus(status: string): Observable<any>{
    return this.http.get(`${endpoint}rentals?status=${status}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  fetchRentalsByStatusAndText(status: string, text: string): Observable<any>{
    return this.http.get(`${endpoint}rentals?status=${status}&search=${text}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  fetchRentalsByCustomerId(customerId: string): Observable<Rental[]>{
    return this.http.get<Rental[]>(`${endpoint}rentals?customerId=${customerId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  fetchReturnReasons(): Observable<ReturnReason[]> {
    return this.http.get<ReturnReason[]>(endpoint + `lookups?name=RETURN_REASON`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  applyReturn(request): Observable<any> {
    return this.http.post(`${endpoint}rentals/${request.rentalId}/return`, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  applySwitch(rentalId, payload): Observable<any> {
    return this.http.post(`${endpoint}rentals/${rentalId}/switch`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  applyRepo(request): Observable<any> {
    return this.http.post(`${endpoint}rentals/${request.rentalId}/apply-return`, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  editRental(request, rentalId): Observable<any> {
    return this.http.post(`${endpoint}rentals/${rentalId}/edit`, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
}
