<!-- Modal -->
<div
  class="modal fade"
  [ngClass]="{ show: modalOpen }"
  [ngStyle]="{ display: modalOpen === true ? 'block' : 'none' }"
  id="editLeaseAgreementModal"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="editLeaseAgreementModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">
          <div class="d-flex justify-content-beetwen align-items-center">
            <div>
              <h5 id="ModalLabel">Editar contrato</h5>
              <small>{{ rental?.customer?.fullName }}</small>
              <small class="ps-3">Placa: {{ rental?.car?.plate }}</small>
              <small class="ps-3">Monto: {{ rental?.amount }}</small>
            </div>
          </div>
        </div>
        <button type="button" class="btn-close" (click)="closeModal()"></button>
      </div>
      <div class="modal-body">
        <div class="pb-3">
          <form class="row g-2" [formGroup]="form">
            <div class="col-md-6">
              <label for="inputRental" class="form-label"
                >Monto de renta Semanal</label
              >
              <input
                type="number"
                class="form-control"
                id="inputRental"
                formControlName="amount"
                [(ngModel)]="rental.amount"
              />
            </div>
            <div class="col-md-6">
              <label for="inputStartDate" class="form-label"
                >Fecha de Inicio</label
              >
              <input
                type="date"
                class="form-control"
                id="inputStartDate"
                formControlName="startDate"
                [(ngModel)]="rental.startDate"
              />
            </div>
            <div class="col-md-6">
              <label for="inputPaymentDay" class="form-label"
                >Día de pago</label
              >
              <select
                class="form-select"
                id="inputPaymentDay"
                formControlName="dueDay"
                [(ngModel)]="rental.dueDay"
              >
                <option selected>Seleciona un día de la semana</option>
                <option *ngFor="let day of days; let i = index" [value]="i">
                  {{ day }}
                </option>
              </select>
            </div>
            <div class="col-md-6">
              <label for="inputFirstPayment" class="form-label"
                >First payment date</label
              >
              <input
                type="date"
                class="form-control"
                id="inputFirstPayment"
                formControlName="firstPaymentDate"
                [(ngModel)]="rental.firstPaymentDate"
              />
            </div>
            <div class="col-md-6">
              <label for="inputFirstAmount" class="form-label"
                >Monto de primer renta</label
              >
              <input
                type="number"
                class="form-control"
                id="inputFirstAmount"
                formControlName="firstPaymentAmount"
                [(ngModel)]="rental.firstPaymentAmount"
              />
            </div>
            <div class="col-md-6">
              <label for="inputPlataforms" class="form-label"
                >Plataformas a manejar</label
              >
              <select
                id="inputPlataforms"
                class="form-select"
                formControlName="platformsToDrive"
                [(ngModel)]="rental.platformsToDrive"
              >
                <option selected>Seleciona la plataforma a manejar</option>
                <option>Uber</option>
              </select>

            </div>
            <div class="col-md-6">
              <label for="inputVehicle" class="form-label">Vehículo</label>
              <select
                class="form-select"
                id="inputVehicle"
                formControlName="carModelCode"
                [(ngModel)]="rental.car.carModelCode"
              >
                <option selected>Seleciona un vehículo</option>
                <option>...</option>
              </select>
            </div>
            <div class="col-md-6">
              <label for="tagNumber" class="form-label">Número de tag</label>
              <input
                type="number"
                class="form-control"
                id="tagNumber"
                formControlName="tagNumber"
              />
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            (click)="closeModal()"
          >
            Cerrar
          </button>
          <button type="button" class="btn btn-primary">Enviar</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  [ngClass]="{
    'modal-backdrop': modalOpen,
    fade: modalOpen,
    show: modalOpen
  }"
></div>
