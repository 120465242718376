import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pause-rental-agreement',
  templateUrl: './pause-rental-agreement.component.html',
  styleUrls: ['./pause-rental-agreement.component.css']
})
export class PauseRentalAgreementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
