import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import CreatePaydownRequest from "../models/requests/CreatePaydownRequest";
import Paydown from "../models/Paydown";

const endpoint = environment.billing_endpoint;

@Injectable({
  providedIn: 'root'
})
export class PaydownService {

  constructor(
    private http: HttpClient
  ) { }
  createPaydown(payload: CreatePaydownRequest): Observable<any> {
    return this.http.post(`${endpoint}paydowns`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  fetchPaydowns(): Observable<Paydown[]> {
    return this.http.get<Paydown[]>(`${endpoint}paydowns`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
}
