<nz-page-header class="site-page-header">
  <nz-page-header-content>
    <app-search-field (searchSubmitted)="search($event)"></app-search-field>
    <br /><br />
  </nz-page-header-content>
</nz-page-header>

<nz-table style="overflow-x: scroll;max-height: 600px;" #availableCars [nzData]="carDisplay" [nzPageSize]="15">
  <thead>
    <tr>
      <th>Plate</th>
      <th>Vin</th>
      <th>Engine Number</th>
      <th>Model</th>
      <th>Location</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of availableCars.data">
      <td>{{ data.plate }}</td>
      <td>{{ data.vin }}</td>
      <td>{{ data.engineNumber }}</td>
      <td>{{ data.model }}</td>
      <td>{{ data.location }}</td>
      <td>
        <div nz-row [nzGutter]="6">
          <div nz-col>
            <a nz-button nzType="primary" (click)="openUpdateCarForm(data.id)">Editar</a>
          </div>
          <div nz-col>
            <a nz-button disabled="false" nzType="primary" (click)="sendToInspect(data.plate)">Inspección</a>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</nz-table>

<nz-modal [(nzVisible)]="addCarFormVisible" nzTitle="Create Nuevo vehículo" (nzOnCancel)="addCarFormVisible=false"
  (nzOnOk)="addCarFormVisible=false" [nzFooter]="null">
  <ng-container *nzModalContent>
    <form>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>Placa</nz-form-label>
        <nz-form-control nzErrorTip="Por favor ingresa el nombre" nzSpan="15">
          <input type="text" nz-input placeholder="Placa" formControlName="firstName" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>VIN</nz-form-label>
        <nz-form-control nzErrorTip="Por favor ingresa los apellidos" nzSpan="15">
          <input type="text" nz-input placeholder="VIN" formControlName="lastName" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>Numero del motor</nz-form-label>
        <nz-form-control nzErrorTip="Por favor selecciona una razón" nzSpan="15">
          <input type="text" nz-input placeholder="Numero del motor" formControlName="curp" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>Año del vehiculo</nz-form-label>
        <nz-form-control nzErrorTip="Por favor selecciona una razón" nzSpan="15">
          <input type="text" nz-input placeholder="Año" formControlName="curp" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>RFC del propietario</nz-form-label>
        <nz-form-control nzErrorTip="Por favor introduce un RFC válido" nzSpan="15">
          <input type="text" nz-input placeholder="Propietario" formControlName="rfc" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>Fecha de Compra</nz-form-label>
        <nz-form-control nzErrorTip="" nzSpan="15">
          <nz-date-picker nzSize="large" nzFormat="yyyy-MM-dd" formControlName="dateOfBirth"></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>Ubicacion</nz-form-label>
        <nz-form-control nzErrorTip="Por favor selecciona una razón" nzSpan="15">
          <nz-select nzSize="large" formControlName="genderSelect" nzPlaceHolder="Selecciona una ubicación">
            <nz-option nzValue="M" nzLabel="CDMX"></nz-option>
            <nz-option nzValue="F" nzLabel="Guadalajara"></nz-option>
            <nz-option nzValue="F" nzLabel="Monterray"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>Marca</nz-form-label>
        <nz-form-control nzErrorTip="Por favor selecciona una razón" nzSpan="15">
          <nz-select nzSize="large" formControlName="genderSelect" nzPlaceHolder="Selecciona la marca">
            <nz-option nzValue="0" nzLabel="Aveo"></nz-option>
            <nz-option nzValue="1" nzLabel="V-Drive"></nz-option>
            <nz-option nzValue="2" nzLabel="Beat"></nz-option>
            <nz-option nzValue="3" nzLabel="Vento"></nz-option>
            <nz-option nzValue="4" nzLabel="Kia Rio"></nz-option>
            <nz-option nzValue="5" nzLabel="Grand i10"></nz-option>
            <nz-option nzValue="6" nzLabel="Attitude"></nz-option>
            <nz-option nzValue="7" nzLabel="Onix"></nz-option>
            <nz-option nzValue="8" nzLabel="Kwid"></nz-option>
            <nz-option nzValue="9" nzLabel="Mirage"></nz-option>
            <nz-option nzValue="10" nzLabel="Stepway"></nz-option>
            <nz-option nzValue="11" nzLabel="Fiat Uno"></nz-option>
            <nz-option nzValue="12" nzLabel="Versa"></nz-option>
            <nz-option nzValue="13" nzLabel="Renault Logan"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>Estado del vehiculo</nz-form-label>
        <nz-form-control nzErrorTip="Por favor selecciona una razón" nzSpan="15">
          <nz-select nzSize="large" formControlName="genderSelect" nzPlaceHolder="Selecciona el estado del vehiculo">
            <nz-option nzValue="0" nzLabel="Nuevo"></nz-option>
            <nz-option nzValue="1" nzLabel="Usado"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9">Notas</nz-form-label>
        <nz-form-control nzErrorTip="Por favor selecciona una razón" nzSpan="15">
          <textarea nz-input placeholder="" formControlName="note"></textarea>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control [nzSpan]="12" [nzOffset]="6">
          <button type="submit" nz-button nzType="primary" nzBlock nzSize="large">Enviar</button>
        </nz-form-control>
      </nz-form-item>
    </form>

  </ng-container>
</nz-modal>
<nz-modal [nzWidth]="850" [(nzVisible)]="isUpdateCarFormVisible" nzTitle="Actualizar Vehículo"
  (nzOnCancel)="isUpdateCarFormVisible=false" (nzOnOk)="isUpdateCarFormVisible=false" [nzFooter]="null">
  <ng-container *nzModalContent>
    <app-update-car-information-form [carId]="carId" [isUpdateCarFormVisible]="isUpdateCarFormVisible"
      (closeUpdateCarForm)="closeUpdateCarForm($event)"></app-update-car-information-form>
  </ng-container>
</nz-modal>