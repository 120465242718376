<form [formGroup]="form" (ngSubmit)="onSubmit()" nz-form>
  <nz-form-item>
    <nz-form-label [nzSpan]="9" nzRequired>Monto de Renta</nz-form-label>
    <nz-form-control nzErrorTip="Ingresa un monto de renta valido" nzSpan="15">
      <input nz-input type="number" formControlName="rentalAmount" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="9" nzRequired>Día de Pago</nz-form-label>
    <nz-form-control nzErrorTip="Por favor selecciona un día de la semana" nzSpan="15">
      <nz-select nzSize="large" formControlName="paymentDay" nzPlaceHolder="Selecciona un día">
        <nz-option nzValue="0" nzLabel="Lunes"></nz-option>
        <nz-option nzValue="1" nzLabel="Martes"></nz-option>
        <nz-option nzValue="2" nzLabel="Miércoles"></nz-option>
        <nz-option nzValue="3" nzLabel="Jueves"></nz-option>
        <nz-option nzValue="4" nzLabel="Viernes"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="9">Pago de LTO</nz-form-label>
    <nz-form-control nzErrorTip="" nzSpan="15">
      <nz-select nzSize="large" formControlName="ltoPayment" nzPlaceHolder="Selecciona el pago">
        <nz-option [nzValue]="600" nzLabel="600"></nz-option>
        <nz-option [nzValue]="550" nzLabel="550"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="9">Duración de LTO</nz-form-label>
    <nz-form-control nzErrorTip="" nzSpan="15">
      <nz-select nzSize="large" formControlName="ltoTermLength" nzPlaceHolder="Selecciona un plazo">
        <nz-option [nzValue]="36" nzLabel="36 Meses"></nz-option>
        <nz-option [nzValue]="48" nzLabel="48 Meses"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control [nzSpan]="12" [nzOffset]="6">
      <button type="submit" nz-button nzType="primary" nzBlock nzSize="large" >Enviar</button>
    </nz-form-control>
  </nz-form-item>
</form>
