<nz-table #chargeDetails [nzData]="charges?.reverse()" nzTableLayout="fixed">
  <thead>
  <tr>
    <th nzWidth="60px"></th>
    <th>Fecha</th>
    <th>Concepto</th>
    <th>Monto total</th>
    <th>Monto cubierto</th>
    <th>Monto Pendiente</th>
  </tr>
  </thead>
  <tbody>
  <ng-container *ngFor="let c of chargeDetails.data">
    <tr>
      <td [nzExpand]="expandSet.has(c.chargeId)" (nzExpandChange)="onExpandChange(c.chargeId, $event)"></td>
      <td>{{ toHumanReadableDate(c.chargeDueDate) }}</td>
      <td>{{ c.chargeTypeName }}</td>
      <td>{{ c.chargeAmount }}</td>
      <td>{{ c.chargeAmountCovered }}</td>
      <td style="font-weight: bold" [ngStyle]="{ color: (c.chargePendingAmountToCover > 0) ? 'red': 'black'}">{{ c.chargePendingAmountToCover }}</td>
    </tr>
    <tr [nzExpand]="expandSet.has(c.chargeId)">
      <ng-container [ngTemplateOutlet]="transactionsTemplate" [ngTemplateOutletContext]="{ transactions: mapChargesTransactions.get(c.chargeId) }"></ng-container>
    </tr>
</ng-container>
</tbody>
</nz-table>

<ng-template #transactionsTemplate let-transactions="transactions">
  <nz-table #transactionsTable [nzData]="transactions">
    <thead>
    <tr>
      <th nzWidth="60px"></th>
      <th>Fecha</th>
      <th>Concepto</th>
      <th>Monto aplicado</th>
      <th>Monto pago original</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let t of transactionsTable.data">
      <td></td>
      <td>{{ toHumanReadableDate(t.transactionPaymentDueDate) }}</td>
      <td>{{ t.transactionPaymentMethodName }}</td>
      <td style="font-weight: bold; color: green;">{{ t.transactionAmount }}</td>
      <td>{{ t.transactionPaymentAmount }}</td>
    </tr>
    </tbody>
  </nz-table>
</ng-template>
