import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, of, throwError } from "rxjs";
import NewChargeRequest from "../model/billing/request/NewChargeRequest";
import NewPaymentRequest from "../model/billing/request/NewPaymentRequest";
import EditPaymentRequest from "../model/billing/request/EditPaymentRequest";
import EditChargeRequest from "../model/billing/request/EditChargeRequest";
import NewCustomerCommentRequest from "../model/billing/request/NewCustomerCommentRequest";
import CustomerComment from "../model/billing/CustomerComment";
import { Transaction, TransactionAdapter } from '../domain/model/customer/transaction.model';
import { catchError, map } from 'rxjs/operators';
import { WeebFleetResponse, WeebFleetResponseAdapter } from '../domain/model/customer/weeb-fleet-response';
import { CarInformationResponse, CarInformationResponseAdapter } from '../domain/model/customer/car-information-response.model';
import { PalencaResponse, PalencaResponseAdapter } from '../domain/model/customer/palenca-response';

const endpoint = environment.billing_endpoint;
const endpoint_document_receipt = environment.document_receipt_endpoint;
const endpointv1 = environment.nave_third_party;

@Injectable({
  providedIn: 'root'
})
export class CollectionsService {

  constructor(private http: HttpClient,
    private transactionAdapter: TransactionAdapter,
    private carInformationAdapter: CarInformationResponseAdapter,
    private webFlleetAdapter: WeebFleetResponseAdapter,
    private palencaAdapter: PalencaResponseAdapter) { }

  fetchRentalsByStatus(status: string): Observable<any> {
    return this.http.get(`${endpoint}rentals?status=${status}`);
  }
  fetchTransactionsByCustomerId(customerId: string): Observable<Transaction[]> {
    return this.http.get<Transaction[]>(`${endpoint}transactions?customerId=${customerId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).pipe(
      // Adapt raw item to Billing Statement
      map((data: any[]) => data.map((item) => this.transactionAdapter.adapt(item)))
    );
  }
  fetchTransactionsByCustomerIdV2(customerId: string): Observable<any> {
    return this.http.get(`${endpoint}v2/${customerId}/transactions`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  createDocumentReceipt(documentData: any): Observable<any> {
    return this.http.post(`${endpoint_document_receipt}car/document/receipt`, documentData, {
      //headers: {
      //  Authorization: `Bearer ${localStorage.getItem('token')}`
      //}
    });
  }

  fetchLookup(lookupName: string): Observable<any> {
    return this.http.get(`${endpoint}lookups?name=${lookupName}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  createNewCharge(charge: NewChargeRequest): Observable<any> {
    return this.http.post(`${endpoint}charges`, charge, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  createNewPayment(payment: NewPaymentRequest): Observable<any> {
    return this.http.post(`${endpoint}payments`, payment, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  editPayment(payment: EditPaymentRequest): Observable<any> {
    return this.http.put(`${endpoint}payments/${payment.paymentId}`, payment, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  editCharge(charge: EditChargeRequest): Observable<any> {
    return this.http.put(`${endpoint}charges/${charge.chargeId}`, charge, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  findAllActiveRentalDetails(): Observable<any> {
    return this.http.get(`${endpoint}collections`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  assignCustomersToAgent(userId, customerIds): Observable<any> {
    return this.http.post(`${endpoint}collections/agents/${userId}/assignment`, {
      customerIds: customerIds
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  fetchAllCustomerCommentsByCustomerId(customerId: string): Observable<CustomerComment> {
    return this.http.get(`${endpoint}comments?customerId=${customerId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  addCustomerComment(request: NewCustomerCommentRequest): Observable<any> {
    return this.http.post(`${endpoint}comments`, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  fetchBillingStatementByCustomerId(customerId: string): Observable<CustomerComment> {
    return this.http.get(`${endpoint}comments?customerId=${customerId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  fetchCustomerCarInformation(customerId: string): Observable<CarInformationResponse> {
    return this.http.get<CarInformationResponse>(`${endpointv1}customer/${customerId}/car`, {
    }).pipe(
      map((data) => data ? this.carInformationAdapter.adapt(data) : null),
      catchError((err: HttpErrorResponse) => {
        console.log(err);
        return throwError(err);
      })
    );
  }
  fetchWeebFleetInformation(customerId: string): Observable<WeebFleetResponse> {
    return this.http.get<WeebFleetResponse>(`${endpointv1}webfleet/customer/${customerId}/car`, {
    }).pipe(
      map((data => this.webFlleetAdapter.adapt(data))),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    );
  }
  fetchPalencaInformation(customerId: string): Observable<PalencaResponse[]> {
    return this.http.get<PalencaResponse[]>(`${endpointv1}palenca/customer/${customerId}`).pipe(
      // Adapt raw item to Palenca Response
      map((data: any[]) => data.map((item) => this.palencaAdapter.adapt(item))),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    );
  }
  CarInformation(customerId: string): Observable<CarInformationResponse> {
    return this.http.get<CarInformationResponse>(`${endpointv1}customer/${customerId}/car`, {
    }).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    );
  }
  CarInformationByPlate(plate: string): Observable<CarInformationResponse> {
    return this.http.get<CarInformationResponse>(`${endpointv1}car/${plate}`).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }
  // cambiar service
  CreateMifielAgreement(customerId: string, body: any): Observable<any> {
    return this.http.post(`${endpointv1}mifiel/${customerId}/agreement`, body).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    )
  }
}
