import { Component, OnInit } from '@angular/core';
import {BackofficeCoreService} from "../../../services/backoffice-core.service";

@Component({
  selector: 'app-active-customer',
  templateUrl: './active-customer.component.html',
  styles: [
  ]
})
export class ActiveCustomerComponent implements OnInit {
  customers: any[];
  customersDisplay: any[];
  constructor(
    private coreService: BackofficeCoreService
  ) { }

  ngOnInit(): void {
    this.coreService.getActiveRentalAgreements().subscribe(value => {
      this.customers = value;
      this.customersDisplay = this.customers;
    });
  }

}
