import { Component, OnInit } from '@angular/core';
import {AgreementServiceService} from '../../../services/api/core/agreement-service.service';
import ILeaseAgreement from '../../../domain/model/agreements/ILeaseAgreement';
import LeaseAgreementResponse from '../../../domain/api/core/agreements/response/LeaseAgreementResponse';

@Component({
  selector: 'app-lease-agreements-page',
  templateUrl: './lease-agreements-page.component.html',
  styleUrls: ['./lease-agreements-page.component.css']
})
export class LeaseAgreementsPageComponent implements OnInit {
  leases: ILeaseAgreement[];
  editAgreementFormOpen = false;
  leaseAgreementSelectedEdit: ILeaseAgreement;

  constructor(private agreementService: AgreementServiceService) { }
  ngOnInit(): void {
    this.agreementService.getLeaseAgreementsByStatus('ACTIVE')
      .subscribe(res => {
        this.mapLeasesResponse(res);
      });
  }
  openEditAgreementForm(editLeaseAgreement: ILeaseAgreement): void {
    this.editAgreementFormOpen = true;
    this.leaseAgreementSelectedEdit = editLeaseAgreement;
  }
  editAgreementFormModalClosed(): void {
    // Should be false always, to close modal
    this.editAgreementFormOpen = false;
  }
  mapLeasesResponse(leasesResponse: LeaseAgreementResponse): void {
    this.leases = leasesResponse.data.map(l => {
      return {
        startDate: l.startDate,
        amount: l.amount,
        termMonths: l.termMonths,
        firstPaymentAmount: l.firstPaymentAmount,
        id: l.id,
        uuid: l.uuid,
        dueDay: l.dueDay,
        endDate: null,
        firstPaymentDate: l.firstPaymentDate,
        customer: {
          firstName: l.customer.firstName,
          lastName: l.customer.lastName,
          fullName: l.customer.fullName,
          id: l.customer.id
        },
        car: {
          id: l.car.id,
          plate: l.car.plate,
          vin: l.car.vin,
          carLocationCode: l.car.carLocationCode,
          carModelCode: l.car.carModelCode
        }
      };
    });
  }


}
