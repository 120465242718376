import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import ChargeType from "../../../../model/billing/ChargeType";
import Transaction from "../../../../model/billing/Transaction";
import {CollectionsService} from "../../../../services/collections.service";
import EditChargeRequest from "../../../../model/billing/request/EditChargeRequest";
import {NzNotificationService} from "ng-zorro-antd/notification";

@Component({
  selector: 'app-edit-charge-form',
  templateUrl: './edit-charge-form.component.html',
  styles: [
  ]
})
export class EditChargeFormComponent implements OnInit {
  form: FormGroup;
  @Input() chargeTypes: ChargeType[] = [];
  @Input() transaction: Transaction;
  @Output() formSubmitted: EventEmitter<EditChargeRequest> = new EventEmitter<EditChargeRequest>();
  constructor(
    private collectionsService: CollectionsService,
    public notificationService: NzNotificationService
  ) { }

  ngOnInit(): void {
    const chargeType = this.chargeTypes.filter(
      value => {
        return value.chargeCode === this.transaction?.transactionCode;
      }
    );
      
    this.form = new FormGroup({
      amount: new FormControl(Math.abs(this.transaction?.amount)),
      chargeType: new FormControl(chargeType.length === 1 ? chargeType[0]?.id : null),
      note: new FormControl(this.transaction?.note),
      dueDate: new FormControl(this.transaction?.dueDate),
    });
  }
  onSubmit(): void {
    const request: EditChargeRequest = {
      chargeId: this.transaction['transactionId'],
      chargeTypeId: this.form.controls.chargeType.value,
      dueDate: this.form.controls.dueDate.value,
      amount: this.form.controls.amount.value,
      note: this.form.controls.note.value,
      updatedBy: localStorage.getItem('userLoggedId')
    };
    this.updateCharge(request);
  }
  createNotification(type: string, title: string, message: string): void {
    this.notificationService.create(
      type,
      title,
      message,
      { nzDuration: 7000 }
    );
  }
  updateCharge(request){
    this.collectionsService.editCharge(request).subscribe(
      res => {
        this.createNotification('success', 'Edit charge success', 'Charge has been edited successfully');
        this.formSubmitted.emit(request);
      }, error => {
        this.createNotification('error', 'Edit charge failed', 'Error updating charge, please try again');
      }
    );
  }
}
