import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {OnboardingService} from "../../../services/onboarding.service";
import {NotificationService} from "../../../../services/notification.service";

@Component({
  selector: 'app-close-reservation-modal-button',
  templateUrl: './close-reservation-modal-button.component.html',
  styleUrls: ['./close-reservation-modal-button.component.css']
})
export class CloseReservationModalButtonComponent implements OnInit {
  @Input() reservationId: string;
  isFormVisible = false;
  form: FormGroup;
  @Output() reservationClosed: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private onboardingService: OnboardingService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      pickupDate: new FormControl(null, [Validators.required]),
      firstPaymentDate: new FormControl(null, [Validators.required])
    });
  }
  openFormModal(): void {
    this.isFormVisible = true;
  }
  onSubmitForm(): void {
    if (this.form.valid) {
      this.onboardingService.closeReservation(this.reservationId, {
        pickupDate: this.form.controls.pickupDate.value,
        firstPaymentDate: this.form.controls.firstPaymentDate.value
      }).subscribe((res) => {
        this.notificationService.createNotification('success', 'Reservation Closed', 'Reservation was closed successfully. Rental will be created');
        this.closeFormModal();
        this.reservationClosed.emit();
      }, error => {
        this.notificationService.createNotification('error', 'Error', error.error.errorMessage);
      });
    }
  }
  closeFormModal(): void {
    this.form.reset();
    this.isFormVisible = false;
  }

}
