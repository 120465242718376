import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-didnt-take-applications',
  templateUrl: './didnt-take-applications.component.html',
  styles: [
  ]
})
export class DidntTakeApplicationsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
