<nz-page-header class="site-page-header" nzTitle="Applications" >
  <nz-page-header-extra>
    <button nz-button nzType="primary">Upload drivers</button>
  </nz-page-header-extra>
  <nz-page-header-content>
    <nz-row>
      <nz-statistic nzTitle="Today Applications" [nzValue]="33" [nzPrefix]="prefixTplOne" [nzValueStyle]="{ color: '#3F8600' }"></nz-statistic>
      <nz-statistic nzTitle="Today Approved" [nzValue]="20" [nzPrefix]="prefixTplOne" style="margin: 0 32px" [nzValueStyle]="{ color: '#3F8600' }"></nz-statistic>
      <nz-statistic nzTitle="Today Denied" [nzValue]="5" [nzPrefix]="prefixTplTwo" style="margin: 0 32px" [nzValueStyle]="{ color: '#CF1322' }"></nz-statistic>
      <nz-statistic nzTitle="Today Closed" [nzValue]="2" [nzPrefix]="prefixTplOne" style="margin: 0 32px" [nzValueStyle]="{ color: '#3F8600' }"></nz-statistic>

      <ng-template #prefixTplOne><i nz-icon nzType="arrow-up"></i></ng-template>
      <ng-template #prefixTplTwo><i nz-icon nzType="arrow-down"></i></ng-template>
    </nz-row>
  </nz-page-header-content>
</nz-page-header>
<nz-tabset [nzCentered]="true">
  <!--
  <nz-tab nzTitle="My Applications">
    <app-open-applications-table [applications]="openedApplications">
    </app-open-applications-table>
  </nz-tab>
  <nz-tab nzTitle="Open Applications">
    <app-open-applications-table [applications]="openedApplications" (applicationSentToPendingApproval)="sendApplicationToPendingApprove($event)">
    </app-open-applications-table>
  </nz-tab>
  <nz-tab nzTitle="Pending Approve">
    <app-pending-approve-applications-table [applications]="pendingApproveApplications" (applicationSentToApprove)="sendApplicationToApprove($event)" (applicationSentToDeny)="sendApplicationToDeny($event)" (applicationSentToDidnt)="sendApplicationToDidnt($event)">
    </app-pending-approve-applications-table>
  </nz-tab>
  <nz-tab nzTitle="Approved">
    <app-open-applications-table [applications]="approvedApplications">
    </app-open-applications-table>
  </nz-tab>
  <nz-tab nzTitle="Denied">
    <app-open-applications-table [applications]="deniedApplications">
    </app-open-applications-table>
  </nz-tab>
  <nz-tab nzTitle="Didn't take a car">
    <app-open-applications-table [applications]="didnttakecarApplications">
    </app-open-applications-table>
  </nz-tab> -->
</nz-tabset>


