import {Component, OnInit, TemplateRef} from '@angular/core';
import {PaymentLinkService} from "../../../../services/payment-link.service";
import PaymentLink from "../../../../model/billing/PaymentLink";
import {fromUnixTime, format, formatDistance} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

@Component({
  selector: 'app-new-payment-link',
  templateUrl: './new-payment-link.component.html',
  styleUrls: ['./new-payment-link.component.css']
})
export class NewPaymentLinkComponent implements OnInit {
  paymentLinks: PaymentLink[];
  constructor(
    private paymentLinkService: PaymentLinkService
  ) { }

  ngOnInit(): void {
    this.paymentLinkService.fetchAllPaymentLinks()
      .subscribe(
        (res) => {
          this.paymentLinks = res;
        }
      );
  }
  formatUnixTimestamp(timestamp): string {
    return format(fromUnixTime(timestamp), 'yyyy-MM-dd hh:mm:ss aaa');
  }

  parseEpochToDate(epoch: number) {
    return format(utcToZonedTime(fromUnixTime(epoch), 'America/Mexico_City'), 'yyyy-MM-dd HH:mm');
  }
  distanceFromNow(d: Date) {
    return formatDistance(d, new Date());
  }
}
