<nz-page-header class="site-page-header">
  <nz-page-header-title>Collections View</nz-page-header-title>
  <nz-page-header-extra>
    <!--
    <button nz-button>Operation</button>
    <button nz-button>Operation</button>
    <button nz-button nzType="primary">Primary</button>
    -->
  </nz-page-header-extra>
  <nz-page-header-content>
    <div class="content">
      <div class="main">
        <nz-descriptions nzSize="small" [nzColumn]="2">
          <nz-descriptions-item nzSpan="2" nzTitle=""></nz-descriptions-item>
          <nz-descriptions-item nzTitle="Weekdays" [nzSpan]="2">
            <nz-radio-group [(ngModel)]="weekdaySelected" nzSize="large" nzButtonStyle="solid" (ngModelChange)="weekdaySelectedChanged()">
              <nz-space>
                <nz-space-item>
                  <nz-badge [nzCount]="mondayRentalDetails.length" [nzOverflowCount]="999">
                    <label nz-radio-button nzValue="0">Monday</label>
                  </nz-badge>
                </nz-space-item>
                <nz-space-item>
                  <nz-badge [nzCount]="tuesdayRentalDetails.length" [nzOverflowCount]="999">
                    <label nz-radio-button nzValue="1">Tuesday</label>
                  </nz-badge>
                </nz-space-item>
                <nz-space-item>
                  <nz-badge [nzCount]="wednesdayRentalDetails.length">
                    <label nz-radio-button nzValue="2">Wednesday</label>
                  </nz-badge>
                </nz-space-item>
                <nz-space-item>
                  <nz-badge [nzCount]="thursdayRentalDetails.length">
                    <label nz-radio-button nzValue="3">Thursday</label>
                  </nz-badge>
                </nz-space-item>
                <nz-space-item>
                  <nz-badge [nzCount]="fridayRentalDetails.length">
                    <label nz-radio-button nzValue="4">Friday</label>
                  </nz-badge>
                </nz-space-item>
                <nz-space-item>
                  <nz-badge [nzCount]="allActiveRentalDetails.length" [nzOverflowCount]="999">
                    <label nz-radio-button nzValue="-1">All</label>
                  </nz-badge>
                </nz-space-item>
              </nz-space>
            </nz-radio-group>
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Actions">
            <nz-space>
              <nz-space-item>
                <button nz-button nzType="primary" (click)="openNewPaymentLinkForm()" [disabled]="setOfRentalsCheckedId.size === 0">Create Payment Link</button>
              </nz-space-item>
              <nz-space-item>
                <button nz-button nzType="primary" (click)="openCollectionAgentAssignmentForm()" [disabled]="setOfRentalsCheckedId.size === 0">Assign To</button>
              </nz-space-item>
              <nz-space-item>
                <app-create-paydown-modal-button [isDisabled]="setOfRentalsCheckedId.size !== 1" [customerId]="setOfRentalsCheckedId.values().next().value"></app-create-paydown-modal-button>
              </nz-space-item>
            </nz-space>
          </nz-descriptions-item>
        </nz-descriptions>
      </div>
    </div>
  </nz-page-header-content>
</nz-page-header>
<nz-dropdown-menu #menu="nzDropdownMenu">
  <div class="ant-table-filter-dropdown">
    <div class="search-box">
      <input type="text" nz-input placeholder="Search name" [(ngModel)]="searchByName" />
      <button nz-button nzSize="small" nzType="primary" (click)="doSearchByName()" class="search-button">
        Search
      </button>
      <button nz-button nzSize="small" (click)="resetSearchByName()">Reset</button>
    </div>
  </div>
</nz-dropdown-menu>
<nz-table #activeRentals [nzData]="rentalsSelected" nzPageSize="50" (nzCurrentPageDataChange)="onCurrentPageDataChange($event)" nzSize="middle">
  <thead>
  <tr>
    <th [nzChecked]="isAllchecked" [nzIndeterminate]="indeterminate" (nzCheckedChange)="onAllChecked($event)"></th>
    <th nzCustomFilter>
      Customer
      <nz-filter-trigger [(nzVisible)]="isSearchColumnToolVisible" [nzActive]="searchByName.length > 0" [nzDropdownMenu]="menu">
        <i nz-icon nzType="search"></i>
      </nz-filter-trigger>
    </th>
    <th>Car</th>
    <th>Phone</th>
    <th>Email</th>
    <th>Balance</th>
    <th>Rental Type</th>
    <th>Rental Amount</th>
    <th>Date Started</th>
    <th>Assigned To</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let r of activeRentals.data">
    <td
      [nzChecked]="setOfRentalsCheckedId.has(r.customerId)"
      (nzCheckedChange)="onItemChecked(r.customerId, $event)"
    ></td>
    <td>
      <a [routerLink]="'/billing/customer/'+r.customerId">
        {{ r.firstName }} {{ r.lastName }}
        <nz-tag [nzColor]="'orange'" *ngIf="isCurrentWeek(r.rentalStartDate)">Nuevo</nz-tag>
      </a>
    </td>
    <td>{{ r.rentalCarPlate }}</td>
    <td>{{ r.phone }}</td>
    <td>{{ r.email }}</td>
    <td>
      <span *ngIf="r.balance > 0" style="font-weight: bold; color: red;">{{r.balance}}</span>
      <span *ngIf="r.balance <= 0" style="font-weight: bold; color: green;">{{r.balance}}</span>
    </td>
    <td>
      <nz-tag [nzColor]="'blue'" *ngIf="r.leaseAmount">Lease To Own</nz-tag>
      <nz-tag [nzColor]="'purple'" *ngIf="!r.leaseAmount">Renta Normal</nz-tag>
    </td>
    <td>{{ r.rentalAmount }}</td>
    <td>{{ r.rentalStartDate }}</td>
    <td>{{ r.agentAssignedUsername }}</td>
    <td>
      <i nz-icon nzType="eye" nzTheme="fill" style="font-size: 20px;" (click)="openDrawer(r)"></i>
    </td>
  </tr>
  </tbody>
</nz-table>
<nz-modal
  [(nzVisible)]="isCreatePaymentLinkFormVisible"
  nzTitle="Create Payment Link"
  (nzOnCancel)="isCreatePaymentLinkFormVisible=false"
  (nzOnOk)="isCreatePaymentLinkFormVisible=false"
  [nzFooter]="null">
  <ng-container *nzModalContent>
    <app-new-payment-link-form
    (formSubmitted)="newPaymentLinkFormSubmitted($event)">
    </app-new-payment-link-form>
  </ng-container>
</nz-modal>

<nz-modal
  [(nzVisible)]="isCollectionAssignmentFormVisible"
  nzTitle="Create Payment Link"
  (nzOnCancel)="isCollectionAssignmentFormVisible=false"
  (nzOnOk)="isCollectionAssignmentFormVisible=false"
  [nzFooter]="null">
  <ng-container *nzModalContent>
    <app-choose-collection-agent-assignment-form
      [users]="users"
      (formDataSubmitted)="collectionAgentAssignmentFormSubmitted($event)"
    ></app-choose-collection-agent-assignment-form>
  </ng-container>
</nz-modal>

<nz-drawer [nzVisible]="isDrawerVisible" [nzWidth]="640" [nzClosable]="false" (nzOnClose)="drawerClosed()">
  <ng-container *nzDrawerContent>
    <p class="title" style=" margin-bottom: 24px; font-weight: bold">{{ selectedRentalDetail?.firstName }} {{ selectedRentalDetail?.lastName }}&nbsp;<a nz-button nzType="primary" [routerLink]="'/billing/customer/'+selectedRentalDetail?.customerId">Open</a></p>
    <nz-divider></nz-divider>
    <nz-descriptions [nzColumn]="2" nzTitle="Billing Notes">
    </nz-descriptions>
    <nz-comment>
      <nz-comment-content>
        <nz-form-item>
          <textarea [(ngModel)]="temporalComment" nz-input rows="4"></textarea>
        </nz-form-item>
        <nz-form-item>
          <button nz-button nzType="primary" [disabled]="temporalComment.length < 3" (click)="submitAddCommentForm()">
            Add Note
          </button>
        </nz-form-item>
      </nz-comment-content>
    </nz-comment>
    <ng-container *ngIf="selectedRentalDetail && comments.hasOwnProperty(selectedRentalDetail.customerId)">
      <nz-comment *ngFor="let comment of comments[selectedRentalDetail.customerId]" [nzAuthor]="comment.createdBy?.username" [nzDatetime]="distanceFromNow(parseEpochToDate(comment.createdAt))">
        <nz-comment-content>
          <p>{{ comment.comment }}</p>
        </nz-comment-content>
        <!-- It will be available in next version
        <nz-comment-action *ngIf="comment.createdBy.id === getCurrentUserIdLoggedIn()">
          Edit
        </nz-comment-action>
        -->
      </nz-comment>
    </ng-container>
  </ng-container>
</nz-drawer>
