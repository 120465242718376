<div class="container">
  <nav class="navbar navbar-text fixed-top navbar-expand-lg navbar-dark bg-dark justify-content-center mb-5">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-center" id="navbarTogglerDemo01">

        <a class="navbar-brand" href="#">
          <img src="/assets/img/navelogot.png" alt="" width="38" height="29" class="d-inline-block align-text-top">
        </a>

        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Conductores
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" routerLink="/bos/leads/leads" routerLinkActive="active">Leads</a></li>
              <li><a class="dropdown-item" routerLink="/bos/leads/myLeads" routerLinkActive="active">Mis Leads</a></li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" routerLink="/bos/leads/approvedCustomers" routerLinkActive="active">Conductores Aprobados</a></li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" routerLinkActive="active" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Contratos
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" routerLink="/bos/agreements/rentalAgreements" routerLinkActive="active">Contratos activos de renta</a></li>
              <li><a class="dropdown-item" routerLink="/bos/agreements/leaseAgreements" routerLinkActive="active">Contratos activos de lease</a></li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" href="#">Contratos terminados de renta</a></li>
              <li><a class="dropdown-item" href="#">Contratos terminados de lease</a></li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Underwriting
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" href="#">Pendientes de aprobación</a></li>
              <li><hr class="dropdown-divider"></li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Vehículos
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" href="#">Visualizar</a></li>
              <li><a class="dropdown-item" href="#">Bitacora</a></li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Cobranza
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" href="#">Por cobrar</a></li>
              <li><a class="dropdown-item" href="#">Links de pago</a></li>
              <li><a class="dropdown-item" href="#">Cargos diferidos</a></li>
              <li><a class="dropdown-item" href="#">Facturación</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <main class="container pt-5">
    <router-outlet></router-outlet>
  </main>
</div>

