import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-car-gasoline-guage-component',
  templateUrl: './car-gasoline-guage-component.component.html',
  styleUrls: ['./car-gasoline-guage-component.component.css']
})
export class CarGasolineGuageComponentComponent implements OnInit {
  @Output() getGasoline: EventEmitter<any> = new EventEmitter<any>();
  percent: number = 0;
  octavos = Math.round(this.percent * 8 / 100);
  formatOne;

  constructor() { }

  ngOnInit(): void {
  }

  increase() {
    if (this.percent < 100) {
      this.percent += 12.5;
      this.octavos = Math.round(this.percent * 8 / 100);
      console.log(this.octavos);
      this.formatOne = (percent: number): string => `${this.octavos}/8`;
      this.getGasoline.emit(`${this.octavos}/8`);
    }
  }
  
  decline() {
    if (this.percent > 0) {
      this.percent -= 12.5;
      this.octavos = Math.round(this.percent * 8 / 100);
      this.getGasoline.emit(`${this.octavos}/8`);
    }
  }

}
