import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.css']
})
export class AddressesComponent implements OnInit {
  @Input() data : any[];
  constructor() { }

  ngOnInit(): void {
    console.log(this.data);
    
  }

}
