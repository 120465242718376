import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomerService } from '../../../../services/customer.service';
import { CustomerApiService } from '../../../../infrastructure/api/customer-api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Customer } from '../../../../model/onboarding/Customer';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.css']
})
export class AddressFormComponent implements OnInit {
  @Input() customer: Customer;
  @Output() addressFormChanges: EventEmitter<any> = new EventEmitter<any>();
  address: Array<string> = [];
  checked: boolean = false;
  forms: any = [];
  static nonZero(control: any): { [key: string]: any; } {
    if (Number(control.value) < 0) {
      return { nonZero: true };
    } else {
      return null;
    }
  }

  constructor(
    private customerService: CustomerApiService,
    private notificationService: NzNotificationService,
    private nzMessageService: NzMessageService,
  ) { }

  ngOnInit(): void {
    this.fetchAddress();
  }

  addField(e?: MouseEvent): void {
    if (e) { e.preventDefault() }
    let form = new FormGroup({
      addressType: new FormControl(null, [Validators.required]),
      street: new FormControl(null, [Validators.required]),
      zipcode: new FormControl(null, [Validators.required]),
      neighbourhood: new FormControl(null, [Validators.required]),
      state: new FormControl(null, [Validators.required]),
      municipality: new FormControl(null, [Validators.required, AddressFormComponent.nonZero]),
      extNumber: new FormControl(null, [Validators.required, AddressFormComponent.nonZero]),
      intNumber: new FormControl(null),
      note: new FormControl(null),
      address: new FormControl(null),
    });
    this.forms.push({ form, })
  }

  keyUp(e: KeyboardEvent, i: number): void {
    if (e) {
      if (e.toString().length === 5) {
        this.customerService.fetchZipCode(e.toString()).subscribe((res) => {
          this.forms[i] = { form: this.forms[i].form, suburbs: res['suburbs'] }
          this.forms[i].form.controls["state"].setValue(res['state']);
          this.forms[i].form.controls["municipality"].setValue(res['municipality']);
          this.forms[i].form.controls["neighbourhood"].setValue('');
          this.forms[i].form.value.state = res['state'];
        }, (err) => {
          this.forms[i].form.controls["municipality"].setValue('El código postal no se encuentra disponible');
          this.forms[i].form.controls["state"].setValue('El código postal no se encuentra disponible');
          this.forms[i].form.controls["neighbourhood"].setValue('No existe el código postal.');
          this.forms[i] = { form: this.forms[i].form, suburbs: [{ suburb: 'No existe el código postal.' }] };
          // this.forms[i].checked = true;
        })
      }
    }
  }
  saveAddress(i: number): void {
    const obj = {
      street: this.forms[i].form.value.street,
      extNumber: this.forms[i].form.value.extNumber.toString(),
      neighbourhood: this.forms[i].form.value.neighbourhood,
      intNumber: this.forms[i].form.value.intNumber === null || undefined ? null : this.forms[i].form.value.intNumber.toString(),
      zipcode: this.forms[i].form.value.zipcode,
      note: this.forms[i].form.value.note,
      addressType: this.forms[i].form.value.addressType,
      state: this.forms[i].form.value.state,
      municipality: this.forms[i].form.value.municipality,
      textAddress: this.forms[i].form.value.address,
    }
    let existingAddress = this.address.find((addr: any) => addr.addressType === 1001);
    
    if (existingAddress) {
      if(this.address[i]){
        if((existingAddress['id'] !== this.address[i]['id']) && (existingAddress['addressType'] === obj.addressType)){
          this.createNotification('error', 'ocurrió un error', 'Solo puedes tener una dirección fiscal, verifica que estás agregando una dirección diferente');
          return;
        }else if (existingAddress['id'] === this.address[i]['id']) {
          const addressId = this.address[i]['id'];
            this.customerService.updateAddress(addressId, obj).subscribe((res) => {
              this.createNotification('success', 'Dirección actualizada', 'La dirección fue actualizada con éxito.');
              this.addressFormChanges.emit({ value: true, type: 'update' });
            }, (err) => {
            });
            return;
        }
      }else{
        if(existingAddress['addressType'] === obj.addressType){
          this.createNotification('error', 'ocurrió un error', 'Solo puedes tener una dirección fiscal, verifica que estás agregando una dirección diferente');
          return;
        }else{
          this.customerService.addAddress(this.customer.id, obj).subscribe((res) => {
            this.createNotification('success', 'Dirección creada', 'La dirección fue creada con éxito');
            this.addressFormChanges.emit({ value: true, type: 'create' });
          }, (err) => {
          });
          return;
        }
        
      }
    }
      if (this.address[i]) {
      const addressId = this.address[i]['id'];
      this.customerService.updateAddress(addressId, obj).subscribe((res) => {
        this.createNotification('success', 'Dirección actualizada', 'La dirección fue actualizada con éxito');
        this.addressFormChanges.emit({ value: true, type: 'update' });
      }, (err) => {
      });
    } else {
      this.customerService.addAddress(this.customer.id, obj).subscribe((res) => {
        this.createNotification('success', 'Dirección creada', 'La dirección fue creada con éxito');
        this.addressFormChanges.emit({ value: true, type: 'create' });
      }, (err) => {
      });
    }
    
  }
  deleteAddress(i: number): void {
    if (this.address[i]) {
      this.customerService.deleteAddress(this.address[i]['id']).subscribe((res) => {
        this.forms.splice(i, 1);
        this.address.splice(i, 1);
        this.nzMessageService.info('La dirección fue eliminada');
        this.addressFormChanges.emit({ value: true, type: 'delete' });
      }, (err) => {
        this.createNotification('error', 'ocurrió un error', 'Ocurrió un error al editar la dirección.')
      });
    } else {
      this.forms.splice(i, 1);
      this.nzMessageService.info('la dirección fue eliminada');
    }
  }
  fetchAddress(): void {
    this.customerService.fetchCustomerAddress(this.customer.id).subscribe((res) => {
      this.address = res;
      if (this.address.length > 0) {
        this.address.forEach((e, i) => {
          let form = new FormGroup({
            addressType: new FormControl(e['addressType'] || null, [Validators.required]),
            street: new FormControl(e['street'] || null, [Validators.required]),
            zipcode: new FormControl(e['zipcode'] || null, [Validators.required]),
            neighbourhood: new FormControl(e['neighbourhood'] || null, [Validators.required]),
            state: new FormControl(e['state'] || null, [Validators.required]),
            municipality: new FormControl(e['municipality'] || null, [Validators.required, AddressFormComponent.nonZero]),
            extNumber: new FormControl(e['extNumber'] || null, [Validators.required, AddressFormComponent.nonZero]),
            intNumber: new FormControl(e['intNumber'] || null),
            note: new FormControl(e['note'] || null),
            address: new FormControl(e['textAddress'] || null)
          })
          this.customerService.fetchZipCode(e['zipcode']).subscribe((res) => {
            this.forms.push({ form: form, suburbs: res['suburbs'] });
          }, (err: HttpErrorResponse) => {
            form.controls["municipality"].setValue('El código postal no se encuentra disponible');
            form.controls["state"].setValue('El código postal no se encuentra disponible');
            form.controls["neighbourhood"].setValue('No existe el código postal.');
            this.forms.push({ form: form, suburbs: [{ suburb: 'No existe el código postal.' }] });
            // this.forms[i].checked = true;
          })
        });
      }
    }, (err: HttpErrorResponse) => {
      console.log(err, 'Error fetching address');
    })
  }
  createNotification(type: string, title: string, message: string, duration = 7000): void {
    this.notificationService.create(
      type,
      title,
      message,
      { nzDuration: duration }
    );
  }

}
