import { Component, OnInit } from '@angular/core';
import {ApplicationService} from '../../../services/application.service';
import * as moment from 'moment';
import 'moment/locale/es-mx';

interface Customer {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  createdAt: number;
  updatedAt: number;
}

interface Application {
  id: string;
  applicationStatusCode: string;
  applicationStepCode: string;
  source: string;
  dateApplied: number;
  createdAt: number;
  updatedAt: number;
  customer: Customer;
}

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styles: [
  ]
})
export class ApplicationsComponent implements OnInit {
  date = moment;
   // Applications per status
  openedApplications: any[];
  myApplications: any[];
  pendingApproveApplications: any[];
  approvedApplications: any[];
  deniedApplications: any[];
  didnttakecarApplications: any[];
  // Lookup Values
  denyReasons: any[] = [];
  didntTakeCarReasons: any[] = [];
  // Behavior variables
  visible = false;
  applicationDetail = {};
  open(application): void {
    this.applicationDetail = application;
    this.visible = true;
  }

  close(): void {
    this.visible = false;
  }

  constructor(private applicationService: ApplicationService) { }
  ngOnInit(): void {
    this.loadOpenApplications();
    this.loadPendingApproveApplications();
    this.loadApprovedApplications();
    this.loadDeniedApplications();
    this.loadDidnttakecarApplications();
  }
  loadOpenApplications(): void {
    this.applicationService
      .fetchApplicationsByStatus('OPEN')
      .subscribe((data: any[]) => {
        this.openedApplications = data;
      });
  }
  loadPendingApproveApplications(): void {
    this.applicationService
      .fetchApplicationsByStatus('PNDG_APPRV')
      .subscribe((data: any[]) => {
        this.pendingApproveApplications = data;
      });
  }
  loadApprovedApplications(): void {
    this.applicationService
      .fetchApplicationsByStatus('APPROVED')
      .subscribe((data: any[]) => {
        this.approvedApplications = data;
      });
  }
  loadDeniedApplications(): void {
    this.applicationService
      .fetchApplicationsByStatus('DENIED')
      .subscribe((data: any[]) => {
        this.deniedApplications = data;
      });
  }
  loadDidnttakecarApplications(): void {
    this.applicationService
      .fetchApplicationsByStatus('DIDNT_TAKE')
      .subscribe((data: any[]) => {
        this.didnttakecarApplications = data;
      });
  }
  sendApplicationToPendingApprove(application: any): void {
    this.applicationService.sendApplicationToPendingApproval(application.id)
      .subscribe((data: any) => {
        this.loadOpenApplications();
        this.loadPendingApproveApplications();
      });
  }
  sendApplicationToDidnt(application: any): void {
    this.applicationService.sendApplicationToDidnttakecar(application.id, null)
      .subscribe((data: any) => {
        this.loadOpenApplications();
        this.loadPendingApproveApplications();
      });
  }
  sendApplicationToApprove(application: any): void {
    this.applicationService.approveApplication(application.id)
      .subscribe((data: any) => {
        this.loadOpenApplications();
        this.loadPendingApproveApplications();
      });
  }
  sendApplicationToDeny(application: any): void {

    this.applicationService.denyApplication(application.id, null)
      .subscribe((data: any) => {
        this.loadOpenApplications();
        this.loadPendingApproveApplications();
      });
  }

  expandSet = new Set<number>();
  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }
  listOfData = [
    {
      id: 1,
      name: 'Credencial para Votar',
      age: 'Capture Completed',
      expand: false,
      address: 'New York No. 1 Lake Park',
      description: 'My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.'
    },
    {
      id: 2,
      name: 'Licencia de Conducir',
      age: 'Pending to Capture',
      expand: false,
      address: 'London No. 1 Lake Park',
      description: 'My name is Jim Green, I am 42 years old, living in London No. 1 Lake Park.'
    },
    {
      id: 3,
      name: 'Perfil de Plataforma',
      age: 'Missing',
      expand: false,
      address: 'Sidney No. 1 Lake Park',
      description: 'My name is Joe Black, I am 32 years old, living in Sidney No. 1 Lake Park.'
    }
  ];

}
