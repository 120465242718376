import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import PaymentLink from "../model/billing/PaymentLink";
import CreatePaymentLinkRequest from "../model/billing/request/CreatePaymentLinkRequest";

const endpoint = environment.billing_endpoint;

@Injectable({
  providedIn: 'root'
})
export class PaymentLinkService {

  constructor(private httpClient: HttpClient) { }
  fetchAllPaymentLinks(): Observable<PaymentLink[]> {
    return this.httpClient.get<PaymentLink[]>(`${endpoint}payment-links`,  {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  createPaymentLink(request: CreatePaymentLinkRequest[]): Observable<any> {
    return this.httpClient.post(`${endpoint}payment-links`, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
}
