import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import ILeaseAgreement from '../../../domain/model/agreements/ILeaseAgreement';

@Component({
  selector: 'app-lease-table-view',
  templateUrl: './lease-table-view.component.html',
  styleUrls: ['./lease-table-view.component.css']
})
export class LeaseTableViewComponent implements OnInit {
  @Input() leases: ILeaseAgreement[];
  @Output() editLeaseAgreementRequested: EventEmitter<ILeaseAgreement> = new EventEmitter<ILeaseAgreement>();
  constructor() { }

  ngOnInit(): void {
  }
  editLeaseAgreement(lease: ILeaseAgreement): void {
    this.editLeaseAgreementRequested.emit(lease);
  }
}
