import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

const endpointCore = environment.core_endpoint;
const endpointCustomerOracle = environment.nave_core_customer_oracle;
@Injectable({
  providedIn: 'root'
})
export class BackofficeCoreService {
  constructor(private http: HttpClient) { }
  getActiveRentalAgreements(): Observable<any[]> {
    return this.http.get<any[]>(endpointCore + `agreements?status=10&type=RENTAL`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  getSubstituteRentalAgreements(): Observable<any[]> {
    return this.http.get<any[]>(endpointCore + `agreements?status=20&type=RENTAL`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  getAllAgreementsNaveId(): Observable<any[]> {
    return this.http.get<any[]>(endpointCustomerOracle + `agreements/all`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  getPauseRentalAgreements(): Observable<any[]> {
    return this.http.get<any[]>(endpointCore + `agreements?status=130&type=RENTAL`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  getFinishedRentalAgreements(): Observable<any[]> {
    return this.http.get<any[]>(endpointCore + `agreements/summary`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  getActiveLeaseToOwnAgreements(): Observable<any[]> {
    return this.http.get<any[]>(endpointCore + `agreements?status=10&type=LEASE_TO_OWN`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  createCar(carRequest: any): Observable<any> {
    return this.http.post(`${endpointCore}car`, carRequest, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  getRentalAgreementById(id): Observable<any[]> {
    return this.http.get<any[]>(endpointCore + `agreements/rental/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  returnCar(request: any): Observable<any> {
    return this.http.post(`${endpointCore}agreements/rental/${request.rentalAgreementId}/return`, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  switchCar(rentalId, request: any): Observable<any> {
    return this.http.post(`${endpointCore}agreements/rental/${rentalId}/switch`, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  substituteCar(rentalId, request: any): Observable<any> {
    return this.http.post(`${endpointCore}agreements/rental/${rentalId}/substitute`, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  pauseCar(rentalId, request: any): Observable<any> {
    return this.http.post(`${endpointCore}agreements/rental/${rentalId}/pause`, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  repoCar(rentalId, request: any): Observable<any> {
    return this.http.post(`${endpointCore}agreements/rental/${rentalId}/repo`, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  getActiveCustomers(): Observable<any> {
    return this.http.get(`${endpointCore}customers/active`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
}
