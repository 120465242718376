import {Component, Input, OnInit} from '@angular/core';
import {ChargeDetailBS, TransactionBS} from '../../domain/model/billing-statement.model';

@Component({
  selector: 'app-charge-payment-reference-table-view',
  templateUrl: './charge-payment-reference-table-view.component.html',
  styles: [
  ]
})
export class ChargePaymentReferenceTableViewComponent implements OnInit {
  @Input() charges: ChargeDetailBS[];
  @Input() mapChargesTransactions: Map<string, TransactionBS[]>;
  expandSet = new Set<string>();
  constructor() { }

  ngOnInit(): void {
  }
  toHumanReadableDate(date: Date): string {
    const pad = '00';
    const month = `${pad}${date.getMonth() + 1}`.slice(-pad.length);
    const day = `${pad}${date.getDate()}`.slice(-pad.length);
    return `${date.getFullYear()}-${month}-${day}`;
  }
  onExpandChange(id: string, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

}
