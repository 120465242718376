<nz-divider nzText="Actualizar afianzadora"></nz-divider>
<div style="padding-bottom: 20px;" *ngFor="let suretyItem of bailFormArray; let i = index;">
  <nz-card nzType="inner" nzTitle="Fianza" [nzExtra]="actions">
    <form [formGroup]="suretyItem.form">
      <nz-form-item>
        <nz-form-label nzSize="default" [nzSpan]="6" nzRequired>Afianzadora</nz-form-label>
        <nz-form-control nzErrorTip="Por favor introduce una afianzadora" nzSpan="15">
          <input type="text" nz-input placeholder="afianzadora" formControlName="surety"/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzSize="default" [nzSpan]="6" nzRequired>Número de fianza</nz-form-label>
        <nz-form-control nzErrorTip="Por favor introduce un número de fianza" nzSpan="15">
          <input type="number" nz-input placeholder="número de fianza" formControlName="suretyNumber"/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="6" nzRequired>Ramo / Tipo</nz-form-label>
        <nz-form-control nzErrorTip="Por favor ingresa un Ramo" nzSpan="15">
          <input type="text" nz-input placeholder="Ramo o tipo" formControlName="type"/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="6" nzRequired>Inicio de vigencia</nz-form-label>
        <nz-form-control nzErrorTip="Por favor ingresa una fecha" nzSpan="15">
          <input type="date" nz-input formControlName="beginningValidity"/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="6" nzRequired>Fin de vigencia</nz-form-label>
        <nz-form-control nzErrorTip="Por favor ingresa una fecha" nzSpan="15">
          <input type="date" nz-input formControlName="endValidity"/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzSize="default" [nzSpan]="6" nzRequired>Monto de la fianza</nz-form-label>
        <nz-form-control nzErrorTip="Por favor introduce un monto valido" nzSpan="15">
          <input type="number" nz-input placeholder="monto de la fianza" formControlName="amount"/>
        </nz-form-control>
      </nz-form-item>
    </form>
  </nz-card>
  <ng-template #actions>
    <button nz-button nzType="default" (click)="saveSurety(suretyItem.form)" [disabled]="!suretyItem.form.valid"><span nz-icon
        nzType="save"></span></button>
    <button nzDanger nz-popconfirm nzPopconfirmTitle="¿Estas segur@ de eliminar la dirección?" nzOkText="Confirmar"
      nzCancelText="Cancelar" (nzOnConfirm)="this.deleteSurety(i, suretyItem)" nz-button nzType="primary"><span nz-icon
        nzType="delete"></span></button>
  </ng-template>
</div>
<nz-form-control *ngIf="this.isBail" [nzSpan]="24" [nzOffset]="9">
    <button nz-button nzType="dashed" type="button" nzShape="round" nzSize="large" (click)="addSurety($event)">
      <span nz-icon nzType="plus"></span>Agregar afianzadora
    </button>
  </nz-form-control>
