import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as moment from 'moment';
import {DocumentsService} from '../../services/documents.service';
import {Application} from '../../model/onboarding/Application';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import { OnboardingService } from '../../crm/services/onboarding.service';

@Component({
  selector: 'app-open-applications-table',
  templateUrl: './open-applications-table.component.html',
  styles: [
  ]
})
export class OpenApplicationsTableComponent implements OnInit {
  visible = false;
  date = moment;
  @Input() pageIndex: number;
  @Input() pageSize: number;
  @Output() applicationQuickViewButtonClicked: EventEmitter<Application> =  new EventEmitter<Application>();
  @Input() applications: any[] = [];
  @Output() queryParamsChanged: EventEmitter<NzTableQueryParams> = new EventEmitter<NzTableQueryParams>();
  constructor(private documentService: DocumentsService,
    private onboardingService: OnboardingService) {  }
  ngOnInit(): void {
  }
  quickViewButtonClicked = (application) => {
    this.applicationQuickViewButtonClicked.emit(application);
  }
  onQueryParamsChange(e: NzTableQueryParams): void {
    this.queryParamsChanged.emit(e);
  }
}
