<div class="container pt-3">
  <div class="row mb-2">
    <div class="col-6 p-2 d-flex align-items-center">
      <h2 class="m-0">Contratos activos</h2>
    </div>
    <div class="col-6 p-2 d-flex align-items-center justify-content-end">
      <h6 class="m-0 pe-3">Total: {{ data.length }}</h6>
    </div>
  </div>
  <div class="row">
    <app-search-bar></app-search-bar>
    <div class="table-responsive mt-3">
      <table class="table table-sm table-hover">
        <thead>
          <tr>
            <th scope="col">ID Contrato</th>
            <th scope="col">Nombre</th>
            <th scope="col">Placa</th>
            <th scope="col">Modelo</th>
            <th scope="col">Inicio</th>
            <th scope="col">Monto</th>
            <th scope="col">Dia de Pago</th>
            <th scope="col">Ciudad</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of data">
            <th scope="row">{{ item.id }}</th>
            <td>{{ item.customer.fullName }}</td>
            <td>{{ item.car.plate }}</td>
            <td>{{ item.car.carModelCode }}</td>
            <td>{{ item.startDate }}</td>
            <td>{{ item.amount }}</td>
            <td [ngSwitch]="item.dueDay">
              <span *ngSwitchCase="'0'">Lunes</span>
              <span *ngSwitchCase="'1'">Martes</span>
              <span *ngSwitchCase="'2'">Miércoles</span>
              <span *ngSwitchCase="'3'">Jueves</span>
              <span *ngSwitchCase="'4'">Viernes</span>
              <span *ngSwitchCase="'5'">Sábado</span>
              <span *ngSwitchCase="'6'">Domingo</span>
            </td>
            <td>
              {{
                item.car.carLocationCode === "GUADALAJARA"
                  ? "GDL"
                  : item.car.carLocationCode
              }}
            </td>
            <td>
              <div class="btn-group">
                <button class="btn btn-outline-info btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Mas
                </button>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" (click)="editRentalAgreement(item)">Editar</a></li>
                  <li><a class="dropdown-item">Terminar</a></li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>




