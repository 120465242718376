export const environment = {
  production: false,
  documents_endpoint: 'http://10.128.15.220:8080/documents/',
  onboarding_endpoint: 'http://10.128.15.217:8080/onboarding/',
  billing_endpoint: 'http://10.128.15.219:8080/billing/',
  fleet_endpoint: 'http://10.128.15.215:8080/fleet/',
  core_endpoint: 'http://10.128.15.222:8080/core/',
  collections_endpoint: 'http://10.128.15.218:8080/collections/',
  customers_endpoint: 'http://10.128.15.216:8080/customer-service/',
  customers_phones_endpoint: 'http://10.128.15.216:8080/customer-service/v1/',
  customer_endpoint: 'http://10.128.15.216:8080/customer/',
  nave_third_party: 'https://nave-core-third-party-integration-dev-ejcj5szwiq-uc.a.run.app/',
  nave_core_digital_contract: '',
  document_receipt_endpoint: '',
  nave_core_customer_oracle: 'https://nave-core-customer-oracle-prod-ejcj5szwiq-uc.a.run.app/',
  ocr_endpoint: 'https://nave-core-ocr-detection-prod-ejcj5szwiq-uc.a.run.app/',
  toku_customer_endpoint: 'https://us-central1-centrar.cloudfunctions.net/create-toku-customer',
  nave_core_dynamics: 'https://nave-core-dinamycs-integration-toku-prod-ejcj5szwiq-uc.a.run.app/dinamycs/'
};
