import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { isDevMode } from '@angular/core';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: [
    './pages.component.css'
  ]
})
export class PagesComponent implements OnInit {
  public isDev: boolean;
  isCollapsed = false;
  public subscriber: Subscription;
  isSideBarVisible: boolean = false;
  constructor(
    private router: Router,
  ) {
    this.subscriber = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event) => {
      const url = event['url'].substring(0, 19)
      if (url === '/billing/statement/') {
        this.isSideBarVisible = true;
      } else {
        this.isSideBarVisible = false;
      }
    });
  }

  ngOnInit(): void {
    this.isDev = isDevMode();

  }

  ngOnDestroy() {
    this.subscriber.unsubscribe();
  }



}
