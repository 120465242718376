<div>
    <a class="head-example" (click)="openNotificationListCenter(isVisibleNotificationCenter)">
        <nz-badge [nzCount]="numberNotificationsList">
            <span nz-icon [nzType]="'bell'" [nzTheme]="'fill'"
                style="font-size: 25px; color: #aaaaaa;fill: #b6b6b6;"></span>
        </nz-badge>
    </a>
    <app-notification-list-center [notificationsList]="notificationsList"
        [isVisibleNotificationCenter]="isVisibleNotificationCenter"
        (closeModalNotificationListEventEmitter)="getModalVisibility($event)"
        (clearNotificationsEventEmitter)="clearNotifications()"
        (updateNotificationsEventEmitter)="updateNotifications()"></app-notification-list-center>
</div>