import { Component, OnInit } from '@angular/core';
import {BackofficeCoreService} from "../../../services/backoffice-core.service";

@Component({
  selector: 'app-active-lease',
  templateUrl: './active-lease.component.html',
  styles: [
  ]
})
export class ActiveLeaseComponent implements OnInit {
  leases: any[];
  leasesDisplay: any[];
  loading: boolean = false;
  constructor(
    private coreService: BackofficeCoreService
  ) { }

  ngOnInit(): void {
    this.coreService.getActiveLeaseToOwnAgreements().subscribe(value => {
      this.leases = value;
      if(value){
        this.loading = true;
      }
      this.leasesDisplay = this.leases;
    });
  }
  searchCustomer(search): void {
    search = search.toUpperCase();
    if (search.length === 0) {
      this.leasesDisplay = this.leases;
    } else {
      this.leasesDisplay = this.leases.filter(
        value => ''.concat(value.customer.firstName, ' ', value.customer.lastName).toUpperCase().includes(search) || value.car.plate === search || value.customer.oraculeBosId === search || value.customer_customer_bos_id === search || value.agreement_id === search || value.customer.id === search.toLowerCase() 
      );
    }
  }
}
