<span nz-tooltip nzTooltipTitle="Update Reservation"><button nz-button nzShape="circle" nzType="primary" (click)="openFormModal()"><i nz-icon nzType="edit" nzTheme="outline"></i></button></span>
<nz-modal
  nzTitle="Update Reservation"
  [(nzVisible)]="isFormVisible"
  (nzOnCancel)="isFormVisible=false"
  (nzOnOk)="isFormVisible=false"
  [nzFooter]="null"
>
  <nz-page-header>
    <nz-page-header-content>
      <nz-descriptions nzTitle="Reservation Information" nzSize="small" [nzColumn]="2" nzBordered>
        <nz-descriptions-item nzTitle="Car Plate" [nzSpan]="2">{{ reservation.carReserved?.plate }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Product Chosen" [nzSpan]="2">{{ reservation.chosenProduct?.name }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Rental Amount" [nzSpan]="2">{{ reservation.chosenProduct?.contractData?.rentalAmount }}</nz-descriptions-item>
      </nz-descriptions>
    </nz-page-header-content>
  </nz-page-header>
  <form [formGroup]="form" (ngSubmit)="onSubmitForm()">
    <nz-form-item>
      <nz-form-label [nzSpan]="9" nzRequired>Choose Product</nz-form-label>
      <nz-form-control nzErrorTip="Please choose a product" nzSpan="15">
        <nz-select nzSize="large" formControlName="chosenProductId" nzPlaceHolder="Choose product">
          <nz-option *ngFor="let p of products" [nzValue]="p.id" [nzLabel]="p.name"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSpan]="9" nzRequired>Choose Car</nz-form-label>
      <nz-form-control nzErrorTip="Please choose an available car to reserve" nzSpan="15">
        <nz-select nzSize="large" formControlName="carReservedId" nzPlaceHolder="Choose car">
          <nz-option *ngFor="let c of availableCars" [nzValue]="c.id" [nzLabel]="c.plate + ' - ' + c.model"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-control [nzSpan]="12" [nzOffset]="6">
        <button type="submit" nz-button nzType="primary" nzBlock nzSize="large" >Send</button>
      </nz-form-control>
    </nz-form-item>

  </form>
</nz-modal>
