import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Pagesv2Component} from './pagesv2.component';
import {Pagesv2RoutingModule} from './pagesv2.routing';
import { InteractionsBoardComponent } from './interactions/interactions-board/interactions-board.component';
import {BootstrapComponentsModule} from '../bootstrapComponents/bootstrap-components.module';
import {NgzorroComponentsModule} from '../ngzorroComponents/ngzorro-components.module';
import { RentalAgreementsPageComponent } from './agreements/rental-agreements-page/rental-agreements-page.component';
import { LeaseAgreementsPageComponent } from './agreements/lease-agreements-page/lease-agreements-page.component';
import { ApprovedCustomersPageComponent } from './leads/approved-customers-page/approved-customers-page.component';
import { LeadsPageComponent } from './leads/leads-page/leads-page.component';
import { MyLeadsPageComponent } from './leads/my-leads-page/my-leads-page.component';

@NgModule({
  declarations: [
    Pagesv2Component,
    InteractionsBoardComponent,
    RentalAgreementsPageComponent,
    LeaseAgreementsPageComponent,
    ApprovedCustomersPageComponent,
    LeadsPageComponent,
    MyLeadsPageComponent,
  ],
  imports: [
    CommonModule,
    Pagesv2RoutingModule,
    BootstrapComponentsModule,
    NgzorroComponentsModule
  ]
})
export class Pagesv2Module { }
