<nz-divider [nzText]="'Nueva Renta'"></nz-divider>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <nz-form-item>
    <nz-form-label [nzSpan]="9" nzRequired>Renta Semanal</nz-form-label>
    <nz-form-control nzErrorTip="Por favor ingresa el monto de renta semanal" nzSpan="15">
      <input type="number" nz-input placeholder="Monto" formControlName="amount" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="9" nzRequired>Fecha de Inicio</nz-form-label>
    <nz-form-control nzErrorTip="Por favor ingresa la fecha de inicio" nzSpan="15">
      <nz-date-picker nzSize="large" nzFormat="yyyy/MM/dd" formControlName="startDate" [nzDisabledDate]="disabledDate"></nz-date-picker>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="9" nzRequired>Vehículo</nz-form-label>
    <nz-form-control nzErrorTip="Por favor selecciona un vehículo" nzSpan="15">
      <nz-select nzSize="large" formControlName="carSelect" nzPlaceHolder="Selecciona un vehículo">
        <nz-option *ngFor="let c of cars" [nzValue]="c.id" [nzLabel]="c.plate + ' - ' + c.model"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="9" nzRequired>Pickup Type</nz-form-label>
    <nz-form-control nzErrorTip="Por favor selecciona un Pickup Type" nzSpan="15">
      <nz-select nzSize="large" formControlName="pickupType" nzPlaceHolder="Pickup Type">
        <nz-option *ngFor="let pt of pickupTypes" [nzValue]="pt.id" [nzLabel]="pt.typeName"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="9" nzRequired>Tipo de Renta</nz-form-label>
    <nz-form-control nzErrorTip="" nzSpan="15">
      <nz-select nzSize="large" formControlName="agreementType" nzPlaceHolder="Tipo de Renta">
        <nz-option *ngFor="let at of agreementTypes" [nzValue]="at.id" [nzLabel]="at.typeName"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item *ngIf="form.controls.agreementType.value === 'a2c6ed14-6233-40e6-8171-e98f6befea66'">
    <nz-form-label [nzSpan]="9" nzRequired>Duración de LTO</nz-form-label>
    <nz-form-control nzErrorTip="Por favor selecciona la duración del LTO" nzSpan="15">
      <nz-select nzSize="large" formControlName="lto_term_length" nzPlaceHolder="Duración en meses">
        <nz-option nzValue="12" nzLabel="12 meses"></nz-option>
        <nz-option nzValue="24" nzLabel="24 meses"></nz-option>
        <nz-option nzValue="36" nzLabel="36 meses"></nz-option>
        <nz-option nzValue="48" nzLabel="48 meses"></nz-option>
        <nz-option nzValue="60" nzLabel="60 meses"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item *ngIf="form.controls.agreementType.value === 'a2c6ed14-6233-40e6-8171-e98f6befea66'">
    <nz-form-label [nzSpan]="9" nzRequired>LTO Payment</nz-form-label>
    <nz-form-control nzErrorTip="Por favor indica cuanto es el monto de LTO" nzSpan="15">
      <input type="number" nz-input placeholder="Monto" formControlName="lto_payment_amount" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="9" nzRequired>First Payment Date</nz-form-label>
    <nz-form-control nzErrorTip="Por favor ingresa la fecha de inicio" nzSpan="15">
      <nz-date-picker nzSize="large" nzFormat="yyyy/MM/dd" formControlName="firstPaymentDate" [nzDisabledDate]="disabledDate"></nz-date-picker>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control [nzSpan]="12" [nzOffset]="6">
      <button type="submit" nz-button nzType="primary" nzBlock nzSize="large" >Enviar</button>
    </nz-form-control>
  </nz-form-item>
</form>
