<nz-divider [nzText]="'Registro de nueva renta para ' + customer?.firstName + ' ' + customer?.lastName"></nz-divider>
<div nz-row>
  <div nz-col nzSpan="24">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" style="margin: 10px;">
      <nz-form-item style="padding-top: 10px;">
        <nz-form-label [nzSpan]="9" nzRequired>Monto de Renta Semanal</nz-form-label>
        <nz-form-control nzErrorTip="Por favor ingresa el monto de renta semanal" nzSpan="10">
          <input type="number" nz-input placeholder="Monto" formControlName="amount" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>Fecha de Inicio</nz-form-label>
        <nz-form-control nzErrorTip="Por favor ingresa la fecha de inicio" nzSpan="10">
          <nz-date-picker nzSize="large" style="width: 100%" nzFormat="yyyy/MM/dd"
            formControlName="startDate"></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>Día de Pago</nz-form-label>
        <nz-form-control nzErrorTip="Por favor selecciona un día de la semana" nzSpan="10">
          <nz-select nzSize="large" formControlName="dueDay" nzPlaceHolder="Selecciona un día de la semana">
            <nz-option [nzValue]="0" nzLabel="Lunes"></nz-option>
            <nz-option [nzValue]="1" nzLabel="Martes"></nz-option>
            <nz-option [nzValue]="2" nzLabel="Miércoles"></nz-option>
            <nz-option [nzValue]="3" nzLabel="Jueves"></nz-option>
            <nz-option [nzValue]="4" nzLabel="Viernes"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>Primera fecha de pago</nz-form-label>
        <nz-form-control nzErrorTip="Por favor ingresa la fecha de inicio" nzSpan="10">
          <nz-date-picker nzSize="large" style="width: 100%" nzFormat="yyyy/MM/dd"
            formControlName="firstPaymentDate"></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>Monto de la renta inicial</nz-form-label>
        <nz-form-control nzErrorTip="Por favor ingresa el monto de primer renta" nzSpan="10">
          <input type="number" nz-input placeholder="Monto" formControlName="firstRentalAmount" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>Plataformas a manejar</nz-form-label>
        <nz-form-control nzErrorTip="Por favor selecciona una opción" nzSpan="10">
          <nz-select nzSize="large" formControlName="platforms" nzPlaceHolder="Selecciona una opción">
            <nz-option nzValue="UBER" nzLabel="Uber"></nz-option>
            <nz-option nzValue="DIDI" nzLabel="Didi"></nz-option>
            <nz-option nzValue="BEAT" nzLabel="Beat"></nz-option>
            <nz-option nzValue="UBER,DIDI" nzLabel="Multiplataforma (Uber, Didi)"></nz-option>
            <nz-option nzValue="UBER,DIDI,BEAT" nzLabel="Multiplataforma (Uber, Didi, Beat)"></nz-option>
            <nz-option nzValue="UBER,BEAT" nzLabel="Multiplataforma (Uber, Beat)"></nz-option>
            <nz-option nzValue="DIDI,BEAT" nzLabel="Multiplataforma (Didi, Beat)"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>Monto del depósito en garantía</nz-form-label>
        <nz-form-control nzErrorTip="Por favor ingresa el monto de garantía" nzSpan="10">
          <input type="number" nz-input placeholder="Monto" formControlName="guaranteeAmount" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>Vehículo</nz-form-label>
        <nz-form-control nzErrorTip="Por favor selecciona un vehículo" nzSpan="10">
          <nz-select (ngModelChange)="onChange($event.plate)" nzSize="large" nzShowSearch nzAllowClear
            formControlName="vehicle" nzPlaceHolder="Selecciona un vehículo">
            <nz-option *ngFor="let c of cars" [nzValue]="{id: c.id, plate: c.plate}"
              [nzLabel]="c.plate + ' - ' + c.model"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9">Numero de Tag</nz-form-label>
        <nz-form-control nzErrorTip="Por favor ingresa el monto de renta semanal" nzSpan="10">
          <input type="text" nz-input placeholder="Numero de Tag" formControlName="tag" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>Tipo de Renta</nz-form-label>
        <nz-form-control nzErrorTip="Por favor selecciona la duración del LTO" nzSpan="10">
          <nz-select (ngModelChange)="onChangeRentType($event)" nzSize="large" formControlName="agreementType"
            nzPlaceHolder="Tipo de Renta">
            <nz-option nzValue="NORMAL" nzLabel="Renta Normal Tipo 1"></nz-option>
            <nz-option nzValue="NORMAL_LTO" nzLabel="Renta + Lease to Own Tipo 1"></nz-option>
            <nz-option nzValue="NORMAL_2" nzLabel="Renta Normal Tipo 2"></nz-option>
            <nz-option nzValue="NORMAL_LTO_2" nzLabel="Renta + Lease to Own Tipo 2"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item
        *ngIf="form.controls.agreementType.value === 'NORMAL_LTO' || form.controls.agreementType.value === 'NORMAL_LTO_2'">
        <nz-form-label [nzSpan]="9" nzRequired>LTO Payment</nz-form-label>
        <nz-form-control nzErrorTip="Por favor indica cuanto es el monto de LTO" nzSpan="10">
          <input type="number" nz-input placeholder="Monto" formControlName="ltoAmount" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item
        *ngIf="form.controls.agreementType.value === 'NORMAL_LTO' || form.controls.agreementType.value === 'NORMAL_LTO_2'">
        <nz-form-label [nzSpan]="9" nzRequired>Duración de Lease to Own</nz-form-label>
        <nz-form-control nzErrorTip="Por favor selecciona la duración del LTO" nzSpan="10">
          <nz-select nzSize="large" formControlName="ltoTerm" nzPlaceHolder="Duración de LTO">
            <nz-option [nzValue]="36" nzLabel="36 Meses"></nz-option>
            <nz-option [nzValue]="48" nzLabel="48 Meses"></nz-option>
            <nz-option [nzValue]="60" nzLabel="60 Meses"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item
        *ngIf="form.controls.agreementType.value === 'NORMAL_LTO' || form.controls.agreementType.value === 'NORMAL_LTO_2'">
        <nz-form-label [nzSpan]="9" nzRequired>Fecha de Inicio de LTO</nz-form-label>
        <nz-form-control nzErrorTip="Por favor ingresa la fecha de inicio" nzSpan="10">
          <nz-date-picker nzSize="large" nzFormat="yyyy/MM/dd" formControlName="ltoStartDate"
            style="width: 100%;"></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9">Calendario de inspecciones</nz-form-label>
        <nz-form-control nzErrorTip="Por favor ingresa la fecha de inicio" nzSpan="10">
          <nz-date-picker
            nzShowTime
            nzFormat="yyyy-MM-dd HH:mm:ss"
            formControlName="inspectionCalendar"
            (nzOnOk)="onOk($event)"
            style="width: 100%;"
          ></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
      <div nz-row>
        <div nz-col *ngIf="this.inspectionDates.length > 0" style="width: 100%;">
          <div *ngFor="let date of this.inspectionDates; let i = index" style="display: flex; justify-content: center;">
            <div class="date">
              {{date}} <i nz-icon nzType="close" (click)="removeDate(i)"></i>
            </div>
          </div>
        </div>
      </div>
      <nz-form-item nzJustify="center">
        <label nz-checkbox [(ngModel)]="checked" [ngModelOptions]="{standalone: true}"
          [disabled]="this.disabledDigitalAgreement">¿Crear
          contrato digital de
          arrendamiento?</label>
      </nz-form-item>
      <nz-form-item nzJustify="center" *ngIf="checked">
        <label style="width: 80%;" nz-checkbox [(ngModel)]="firstClause" [ngModelOptions]="{standalone: true}">
          Declaro que la información establecida en el presente es verdadera, reconozco y ratifico las condiciones del
          vehículo, los términos del arrendamiento y las obligaciones contenidas en el contrato de la presente carátula.
          Asimismo, me comprometo a informar de cualquier cambio en mis datos de contacto, dentro de los 2 (dos) días
          siguientes a que lo realice. ACEPTO CAMBIO
        </label>
      </nz-form-item>
      <nz-form-item *ngIf="checked" nzJustify="center">
        <label style="width: 80%;" nz-checkbox [(ngModel)]="secondClause" [ngModelOptions]="{standalone: true}">
          Autorizo por el presente que BTLC Latam, S. de R.L. de C.V. consulte con todas las agencias que emitan
          reportes crediticios, mi reporte crediticio, así como cualquier reporte legal, judicial o de otro tipo, y use
          dichos reportes e historiales de crédito y empleo a fin de revisar mi situación personal y crediticia,
          monitorear el Vehículo y/o para fines de cobranza en la medida permitida por la ley sin que BTLC Latam, S. de
          R.L. de C.V. requiera especificar un fin en particular. ACEPTO
        </label>
      </nz-form-item>
      <nz-form-item *ngIf="checked" nzJustify="center">
        <label style="width: 80%;" nz-checkbox [(ngModel)]="thirdClause" [ngModelOptions]="{standalone: true}">
          Autorizo que mis datos personales sean utilizados con fines publicitarios. AUTORIZO
        </label>
      </nz-form-item>
      <nz-form-item *ngIf="checked" nzJustify="center">
        <label style="width: 80%;" nz-checkbox [(ngModel)]="fourthClause" [ngModelOptions]="{standalone: true}">
          Autorizo a recibir publicidad o información promocional. AUTORIZO RECIBIR PUBLICIDAD
        </label>
      </nz-form-item>
      <nz-form-item *ngIf="checked" nzJustify="center">
        <label style="width: 80%;" nz-checkbox [(ngModel)]="fifthClause" [ngModelOptions]="{standalone: true}">
          Recibí póliza de seguro del vehículo asignado.
        </label>
      </nz-form-item>
      <nz-form-item *ngIf="checked && (form.controls.agreementType.value === 'NORMAL_LTO' || form.controls.agreementType.value === 'NORMAL_LTO_2')" nzJustify="center">
        <label style="width: 80%;" nz-checkbox [(ngModel)]="sixthClause" [ngModelOptions]="{standalone: true}">
          Reconozco conocer el contenido y alcance del Aviso de Privacidad del Proveedor, el cual se encuentra a mi alcance en www.nave.mx
        </label>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control [nzSpan]="12" [nzOffset]="6">
          <button [disabled]="this.disabledButton()" type="submit" nz-button nzType="primary" nzBlock
            nzSize="large">Enviar</button>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
</div>