import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {CarService} from "../../../../services/car.service";
import {OnboardingService} from "../../../services/onboarding.service";
import {NotificationService} from "../../../../services/notification.service";

@Component({
  selector: 'app-create-reservation-modal-button',
  templateUrl: './create-reservation-modal-button.component.html',
  styleUrls: ['./create-reservation-modal-button.component.css']
})
export class CreateReservationModalButtonComponent implements OnInit {
  @Input() applicationId: string;
  isFormVisible = false;
  form: FormGroup;
  availableCars = [];
  products = [];
  constructor(
    private carService: CarService,
    private onboardingService: OnboardingService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      carReservedId: new FormControl(),
      chosenProductId: new FormControl()
    });
  }
  fetchLookups(): void {
    this.carService.fetchCarsByStatus('AVAILABLE').subscribe(
      (res) => {
        this.availableCars = res;
      }
    );
    this.onboardingService.fetchAllProducts().subscribe(
      (res) => {
        this.products = res;
      }
    );
  }
  onSubmitForm(): void {
    if (this.form.valid) {
      this.onboardingService.createReservation({
        chosenProductId: this.form.controls.chosenProductId.value,
        carReservedId: this.form.controls.carReservedId.value,
        applicationId: this.applicationId
      }).subscribe(
        (res) => {
          this.closeFormModal();
          this.notificationService.createNotification('success', 'Reservation created', 'Reservation was created successfully');
        }, (error) => {
          this.notificationService.createNotification('error', 'Error', error.error.errorMessage);
        }
      );
    }
  }
  openFormModal(): void {
    this.fetchLookups();
    this.isFormVisible = true;
  }
  closeFormModal(): void {
    this.isFormVisible = false;
    this.form.reset();
  }
}
