import { Component, OnInit } from '@angular/core';
import {CollectionsService} from "../../../services/collections.service";
import RentalDetail from "../../../model/rental/RentalDetail";
import {PaymentLinkService} from "../../../services/payment-link.service";
import CreatePaymentLinkRequest from "../../../model/billing/request/CreatePaymentLinkRequest";
import {NotificationService} from "../../../services/notification.service";
import { formatDistance, fromUnixTime, isThisWeek, parse } from "date-fns";
import {AuthService} from "../../../services/auth.service";
import {OnboardingService} from "../../../crm/services/onboarding.service";
import {BackofficeCoreService} from "../../../services/backoffice-core.service";

@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.css']
})
export class CollectionsComponent implements OnInit {
  weekdaySelected = '-1';
  rentalsSelected: RentalDetail[] = [];
  allActiveRentalDetails: RentalDetail[] = [];
  mondayRentalDetails: RentalDetail[] = [];
  tuesdayRentalDetails: RentalDetail[] = [];
  wednesdayRentalDetails: RentalDetail[] = [];
  thursdayRentalDetails: RentalDetail[] = [];
  fridayRentalDetails: RentalDetail[] = [];
  otherRentalDetails: RentalDetail[] = [];

  setOfRentalsCheckedId = new Set<string>();
  listOfCurrentPageData: ReadonlyArray<RentalDetail> = [];
  isAllchecked = false;
  indeterminate = false;

  isCreatePaymentLinkFormVisible = false;
  isSearchColumnToolVisible = false;
  searchByName = '';

  isDrawerVisible = false;
  temporalComment = '';
  selectedRentalDetail: RentalDetail;

  comments = {};

  users = [];
  isCollectionAssignmentFormVisible = false;

  constructor(
    private collectionService: CollectionsService,
    private paymentLinkService: PaymentLinkService,
    private notificationService: NotificationService,
    private authenticationService: AuthService,
    private onboardingService: OnboardingService,
    private coreService: BackofficeCoreService
  ) { }

  ngOnInit(): void {
    this.fetchAllActiveRentalDetails();
    this.fetchAllUsers();
    this.rentalsSelected = this.allActiveRentalDetails;
  }
  fetchAllUsers(): void {
    this.onboardingService.fetchAllUsers().subscribe(
      (res) => {
        this.users = res;
      }
    );
  }
  fetchAllActiveRentalDetails(): void {
    this.coreService.getActiveCustomers()
      .subscribe(
        (res: RentalDetail[]) => {
          this.mondayRentalDetails = [];
          this.tuesdayRentalDetails = [];
          this.wednesdayRentalDetails = [];
          this.thursdayRentalDetails = [];
          this.fridayRentalDetails = [];
          this.otherRentalDetails = [];
          this.allActiveRentalDetails = res;
          for (const r of this.allActiveRentalDetails) {
            if (r.rentalDueDay === 0) {
              this.mondayRentalDetails.push(r);
            }
            else if (r.rentalDueDay === 1) {
              this.tuesdayRentalDetails.push(r);
            }
            else if (r.rentalDueDay === 2) {
              this.wednesdayRentalDetails.push(r);
            }
            else if (r.rentalDueDay === 3) {
              this.thursdayRentalDetails.push(r);
            }
            else if (r.rentalDueDay === 4) {
              this.fridayRentalDetails.push(r);
            }
            else {
              this.otherRentalDetails.push(r);
            }
          }
          this.weekdaySelectedChanged();
        }
      );
  }
  onItemChecked(customerId, checked: boolean): void {
    this.updateCustomersCheckedSet(customerId, checked);
    this.refreshCheckedStatus();
  }
  updateCustomersCheckedSet(id: string, checked: boolean): void {
    if (checked) {
      this.setOfRentalsCheckedId.add(id);
    } else {
      this.setOfRentalsCheckedId.delete(id);
    }
  }
  weekdaySelectedChanged(): void {
    switch (this.weekdaySelected) {
      case '0':
        this.rentalsSelected = this.mondayRentalDetails;
        break;
      case '1':
        this.rentalsSelected = this.tuesdayRentalDetails;
        break;
      case '2':
        this.rentalsSelected = this.wednesdayRentalDetails;
        break;
      case '3':
        this.rentalsSelected = this.thursdayRentalDetails;
        break;
      case '4':
        this.rentalsSelected = this.fridayRentalDetails;
        break;
      case '-1':
        this.rentalsSelected = this.allActiveRentalDetails;
        break;
    }
    this.setOfRentalsCheckedId.clear();
    this.refreshCheckedStatus();
  }
  onCurrentPageDataChange(listOfCurrentPageData: ReadonlyArray<any>): void {
    this.listOfCurrentPageData = listOfCurrentPageData;
    this.refreshCheckedStatus();
  }
  onAllChecked(checked: boolean): void {
    this.listOfCurrentPageData.forEach(({ customerId }) => this.updateCustomersCheckedSet(customerId, checked));
    this.refreshCheckedStatus();
  }
  refreshCheckedStatus(): void {
    const listOfEnabledData = this.rentalsSelected;
    this.isAllchecked = listOfEnabledData.every(({ customerId }) => this.setOfRentalsCheckedId.has(customerId));
    this.indeterminate = listOfEnabledData.some(({ customerId }) => this.setOfRentalsCheckedId.has(customerId)) && !this.isAllchecked;
  }
  getRentalDetailsChecked(): RentalDetail[] {
    return this.allActiveRentalDetails.filter(data => this.setOfRentalsCheckedId.has(data.customerId));
  }
  openNewPaymentLinkForm(): void {
    this.isCreatePaymentLinkFormVisible = true;
  }
  newPaymentLinkFormSubmitted(formData): void {
    this.createPaymentLinksFor(formData);
    this.isCreatePaymentLinkFormVisible = false;
  }
  openCollectionAgentAssignmentForm(): void {
    this.isCollectionAssignmentFormVisible = true;
  }
  collectionAgentAssignmentFormSubmitted(formData): void {
     this.collectionService.assignCustomersToAgent(formData.userId, this.getRentalDetailsChecked().map(r => r.customerId)).subscribe(
       (res) => {
         this.notificationService.createNotification('success', 'Customers were assigned successfully', 'All customers were assigned');
         this.isCollectionAssignmentFormVisible = false;
       }
     );
  }
  createPaymentLinksFor(paymentLinkInformation: CreatePaymentLinkRequest): void {
    const data = this.getRentalDetailsChecked();
    const payload: CreatePaymentLinkRequest[] = [];
    for (const r of data) {
      payload.push(
        new CreatePaymentLinkRequest(
          r.customerId,
          paymentLinkInformation.amount,
          paymentLinkInformation.allowedPaymentMethods,
          paymentLinkInformation.daysToExpire,
          paymentLinkInformation.linkReasonCode,
          paymentLinkInformation.requiresEmailNotification,
          false
        )
      );
    }
    this.paymentLinkService.createPaymentLink(payload).subscribe(
      (res) => {
        this.notificationService.createNotification(
          'success',
          'Payment Links have been created',
          'Payment links requested have been created successfully. Please check them in Conekta Payment Link tool'
        );
      }, (error) => {
        this.notificationService.createNotification(
          'error',
          'Error Payment Links have been created',
          'Payment links requested have been created successfully. Please check them in Conekta Payment Link tool'
        );
      }
    );
  }
  doSearchByName(): void {
    this.isSearchColumnToolVisible = false;
    this.rentalsSelected = this.rentalsSelected
      .filter(
        (item: RentalDetail) =>
          item.firstName
            .concat(' ')
            .concat(item.lastName)
            .toUpperCase()
            .indexOf(this.searchByName.toUpperCase()) !== -1
      );
  }
  resetSearchByName(): void {
    this.searchByName = '';
    this.weekdaySelectedChanged();
  }
  openDrawer(rentalDetailSelected: RentalDetail): void {
    this.selectedRentalDetail = rentalDetailSelected;
    this.isDrawerVisible = true;
    if (!this.comments[rentalDetailSelected.customerId]) {
      this.fetchCommentsForCustomer(rentalDetailSelected.customerId);
    }
  }
  fetchCommentsForCustomer(customerId: string): void {
    this.collectionService.fetchAllCustomerCommentsByCustomerId(customerId).subscribe(
      (res) => {
        this.comments[customerId] = res;
      }
    );
  }
  drawerClosed(): void {
    this.isDrawerVisible = false;
  }
  submitAddCommentForm(): void {
    const payload = {
      customerId: this.selectedRentalDetail.customerId,
      comment: this.temporalComment
    };
    this.collectionService.addCustomerComment(payload)
      .subscribe(
        (res) => {
          this.temporalComment = '';
          this.notificationService.createNotification('success', 'Billing Note has been saved', 'Your Billing Note has been saved successfully');
          this.fetchCommentsForCustomer(this.selectedRentalDetail.customerId);
        }
      );
  }

  isCurrentWeek(date: string): boolean {
    return isThisWeek(parse(date, 'yyyy-MM-dd', new Date()));
  }

  parseEpochToDate(epoch: number) {
    return fromUnixTime(epoch);
  }
  distanceFromNow(d: Date) {
    return formatDistance(d, new Date());
  }
  getCurrentUserIdLoggedIn(): string {
    return this.authenticationService.getCurrentUserIdLoggedIn();
  }
}
