import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Customer} from "../../../model/onboarding/Customer";
import Rental from "../../../model/rental/Rental";
import ReturnRequest from "../../../model/rental/request/ReturnRequest";
import ReturnReason from "../../../model/rental/ReturnReason";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {RentalService} from "../../../services/rental.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import {ActivatedRoute, Router} from "@angular/router";
import {BackofficeCoreService} from "../../../services/backoffice-core.service";
import * as uuid from 'uuid';

@Component({
  selector: 'app-return-car',
  templateUrl: './return-car.component.html',
  styleUrls: ['./return-car.component.css']
})
export class ReturnCarComponent implements OnInit {
  @Input() customer: Customer;
  returnReasons: ReturnReason[] = [];
  form: FormGroup;
  today = new Date();
  rentalId: string;
  rental: any;
  constructor(
    private route: ActivatedRoute,
    private rentalService: RentalService,
    private notificationService: NzNotificationService,
    private coreService: BackofficeCoreService,
    private router: Router
  ) { }
  ngOnInit(): void {
    this.route.params.subscribe(
      params => {
        this.rentalId = params.id;
        this.coreService.getRentalAgreementById(this.rentalId).subscribe(value => {
          this.rental = value;
        });
      }
    );
    this.form = new FormGroup({
      returnDate: new FormControl(null, [Validators.required]),
      note: new FormControl(),
      returnReason: new FormControl(null, [Validators.required]),
    });
    this.fetchReturnReasons();
  }
  fetchReturnReasons = () => {
    this.rentalService.fetchReturnReasons().subscribe(
      (reasons) => {
        this.returnReasons = reasons;
        console.log(this.returnReasons);
      }
    );
  }
  onSubmit = () => {
    if (this.form.valid) {
      const request = {
        returnId: uuid.v4(),
        rentalAgreementId: this.rental.id,
        returnDate: this.form.controls.returnDate.value,
        returnReasonId: this.form.controls.returnReason.value,
        notes: this.form.controls.note.value,
        cancelOtherAgreements: false
      };
      this.coreService.returnCar(request).subscribe(
        (res) => {
          this.notificationService.create('success', 'Return Car exitoso', `Se ha completado el return car del conductor ${this.rental?.customer.firstName} ${this.rental?.customer.lastName}`, { nzDuration: 7000 });
          this.router.navigate(['/agreements/active/rental']);
        }, error => {
          this.notificationService.create('error', 'Return Car fallido', error.error.errorMessage, { nzDuration: 7000 });
        }
      );
    }
  }
  disabledDate = (current: Date): boolean => {
    // Can not select days before 7 days. Can not select days after two days
    const diff = differenceInCalendarDays(current, this.today);
    return !(diff > -90 && diff < 5);
  }
}
