import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";

const endpoint = environment.onboarding_endpoint;

@Injectable({
  providedIn: 'root'
})
export class ShareLinkServiceService {

  constructor(private http: HttpClient) { }

  findShareLink(linkId: string): Observable<any> {
    return this.http.get(`${endpoint}share/link/${linkId}`);
  }
  createShareLink(resourceId: string): Observable<any> {
    return this.http.post(`${endpoint}share/resources/${resourceId}`, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
}
