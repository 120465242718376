<form [formGroup]="tokuForm" nz-form [nzLayout]="'vertical'" (ngSubmit)="onSubmit()">
    <nz-form-item>
        <nz-form-label [nzSpan]="24" nzRequired>Deposito de garantía</nz-form-label>
        <nz-form-control nzErrorTip="" nzSpan="24">
          <input nzSize="large" type="text" nz-input formControlName="guaranteeAmount" placeholder="Ingresa la cantidad del deposito"/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control [nzSpan]="12" [nzOffset]="6">
          <button type="submit" nz-button nzType="primary" nzBlock nzSize="large" [disabled]="!tokuForm.valid">Enviar</button>
        </nz-form-control>
      </nz-form-item>
</form>
