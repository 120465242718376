import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import SendDidntTakeCarRequest from '../model/onboarding/request/SendDidntTakeCarRequest';
import { DidntTakeCarReason } from '../model/onboarding/DidntTakeCarReason';
import SendDenyApplicationRequest from '../model/onboarding/request/SendDenyApplicationRequest';
import DenyReason from '../model/onboarding/DenyReason';
import { environment } from '../../environments/environment';
import { Underwriting } from "../model/onboarding/Underwriting";
import { catchError } from 'rxjs/operators';

const endpoint = environment.onboarding_endpoint;
const toku_customer_endpoint = environment.toku_customer_endpoint;
const nave_core_dynamics = environment.nave_core_dynamics;

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  constructor(private http: HttpClient) { }
  fetchApplicationsByStatus = (status: string, page: number = 1, size: number = 50) => {
    const pageOffset = page - 1;
    return this.http.get(endpoint + `applications?status=${status}&page=${pageOffset}&size=${size}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  findApplicationById = (applicationId: string) => {
    return this.http.get(`${endpoint}applications/${applicationId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  findUnderwritingById = (applicationId: string): Observable<Underwriting> => {
    return this.http.get(`${endpoint}applications/${applicationId}/underwriting`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  fetchApplicationsByStatusAndText = (status: string, text: string, page: number = 1, size: number = 50) => {
    const pageOffset = page - 1;
    return this.http.get(endpoint + `applications?status=${status}&page=${pageOffset}&size=${size}&search=${text}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  fetchApplicationsByUser = () => { };
  sendApplicationToPendingApproval(applicationId: string): Observable<any> {
    return this.http.post(endpoint + `applications/${applicationId}/actions/send-pending-approval`, null, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  approveApplication(applicationId: string): Observable<any> {
    return this.http.post(endpoint + `applications/${applicationId}/actions/approve`, null, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  denyApplication(applicationId: string, body: SendDenyApplicationRequest): Observable<any> {
    return this.http.post(endpoint + `applications/${applicationId}/actions/deny`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  sendApplicationToDidnttakecar(applicationId: string, body: SendDidntTakeCarRequest): Observable<any> {
    return this.http.post(endpoint + `applications/${applicationId}/actions/send-didnt-take-car`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  fetchDidntTakeCarReasons(): Observable<DidntTakeCarReason[]> {
    return this.http.get<DidntTakeCarReason[]>(endpoint + `lookups/reasons?name=DIDNT_TAKE_CAR`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  fetchDenyReasons(): Observable<DenyReason[]> {
    return this.http.get<DenyReason[]>(endpoint + `lookups/reasons?name=DENY`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  updateUnderwritingById = (applicationId: string, underwriting: Underwriting): Observable<any> => {
    return this.http.put(`${endpoint}applications/${applicationId}/underwriting`, underwriting, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  public createTokuCustomer(tokuRequest: any): Observable<any>{
    return this.http.post(`${toku_customer_endpoint}`, tokuRequest, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      }
    });
  }
  public fetchTokuPaymentReference(CURP): Observable<any>{
    return this.http.post(`${nave_core_dynamics}references`, CURP, {headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    }}).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    );
  }

  public fetchSecurityDepositStatus(securityDepositRequest: any): Observable<any>{
    return this.http.post(`${nave_core_dynamics}security-deposit-status`, securityDepositRequest, {headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    }}).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    );
  }
}
