<div>
    <nz-modal [(nzVisible)]="isVisibleNotificationCenter" nzTitle="Centro de notificaciones" [nzTitle]="modalTitle"
        [nzFooter]="modalFooter" (nzOnCancel)="closeNotificationList(isVisibleNotificationCenter)" [nzWidth]="850">
        <ng-template #modalTitle>
            <div style="display: flex;justify-content: space-between;margin-top: 5px;">
                <div>Centro de notificaciones</div>
                <div style="display:flex; align-items:center">
                    <a style="font-size: 13px; color: #a9a9a9;" (click)="updateNotifications()">Actualizar
                        &nbsp;&nbsp;&nbsp;</a>
                    <span nz-icon [nzType]="'sync'" style="font-size: 20px; color: #a9a9a9;"
                        (click)="updateNotifications()"></span>
                </div>
                <div style="display:flex; align-items:center">
                    <!-- <a style="font-size: 13px; color: #a9a9a9;"></a> -->
                    <span nz-icon [nzType]="'delete'" style="font-size: 20px; color: #a9a9a9;"
                        (click)="clearNotifications()"></span>
                </div>
                <div></div>
            </div>
        </ng-template>
        <div style="max-height: 400px;overflow-y: scroll;overflow-x: scroll;">
            <nz-table>
                <thead>
                    <tr>
                        <th>Area</th>
                        <th>Tipo</th>
                        <th>Nombre</th>
                        <th>Mensaje</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let notificationsByArea of notificationsList; index as i;">
                        <td>{{notificationsByArea.area}}</td>
                        <td>{{notificationsByArea.type}}</td>

                        <td>{{notificationsByArea.first_name}} {{notificationsByArea.last_name}}</td>
                        <td>{{notificationsByArea.error_message}}</td>
                    </tr>
                </tbody>
            </nz-table>
        </div>
        <ng-template #modalFooter>
            <button nz-button nzType="primary"
                (click)="closeNotificationList(isVisibleNotificationCenter)">Aceptar</button>
        </ng-template>
    </nz-modal>
</div>