<nz-page-header>
  <nz-page-header-title>Payment Links</nz-page-header-title>
</nz-page-header>
<nz-table #paymentLinksTable [nzData]="paymentLinks" nzPageSize="100">
  <thead>
  <tr>
    <th>Customer</th>
    <th>Link For</th>
    <th>Amount</th>
    <th>Expired At</th>
    <th>Allowed Methods</th>
    <th>Status</th>
    <th>Created At</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let p of paymentLinksTable.data">
    <td>{{ p.customer.firstName }} {{ p.customer.lastName }}</td>
    <td>{{ p.name }}</td>
    <td>{{ p.amount }}</td>
    <td>{{ formatUnixTimestamp(p.expiredAt) }}</td>
    <td>
      <ng-container *ngFor="let t of p.allowedPaymentMethods.split(',')">
        <nz-tag *ngIf="t === 'cash'" nzColor="red">OXXO</nz-tag>
        <nz-tag *ngIf="t === 'bank_transfer'" nzColor="blue">SPEI</nz-tag>
      </ng-container>
    </td>
    <td>{{ p.status }}</td>
    <td>{{ parseEpochToDate(p.createdAt) }}</td>
    <td><a [href]="p.url" target="_blank">Ver</a></td>
  </tr>
  </tbody>
</nz-table>
