import {Adapter} from '../../adapter';
import {Injectable} from '@angular/core';
import * as moment from 'moment';

export class Transaction {
  constructor(
    public transactionId: string,
    public customerId: string,
    public transactionAmount: number,
    public transactionDueDate: Date,
    public transactionIssuedAt?: number,
    public transactionName?: string,
    public transactionNote?: string,
    public transactionCode?: string,
    public transactionType?: string,
    public weekOfDate?: number,
  ) {
  }
}

@Injectable({
  providedIn: 'root',
})
export class TransactionAdapter implements Adapter<Transaction> {
  adapt(item: any): Transaction {
    return new Transaction(
      item.id,
      item.customerId,
      item.amount,
      item.dueDate.replace('/', '-'),
      item.issuedAt,
      item.name,
      item.note,
      item.transactionCode,
      item.transactionType,
      moment(item.dueDate).week() + 1,
    );
  }
}
