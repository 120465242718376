<button nz-button nzType="primary" nzShape="round" [disabled]="isDisabled" (click)="openForm()">Switch</button>
<nz-modal
  nzTitle="Switch Car"
  [(nzVisible)]="isFormVisible"
  (nzOnCancel)="isFormVisible=false"
  (nzOnOk)="isFormVisible=false"
  [nzFooter]="null"
>
  <form [formGroup]="form" (ngSubmit)="onSubmit()" nz-form>
    <nz-form-item>
      <nz-form-label [nzSpan]="9" nzRequired>Fecha de Switch</nz-form-label>
      <nz-form-control nzErrorTip="Por favor ingresa la fecha en la que se hizo el switch" nzSpan="15">
        <nz-date-picker nzSize="large" nzFormat="yyyy/MM/dd" formControlName="switchDate" [nzDisabledDate]="disabledDate"></nz-date-picker>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSpan]="9" nzRequired>Nuevo Vehículo</nz-form-label>
      <nz-form-control nzErrorTip="Por favor selecciona una razón" nzSpan="15">
        <nz-select nzSize="large" formControlName="switchCarId" nzPlaceHolder="Selecciona un vehículo">
          <nz-option *ngFor="let c of availableCars" [nzValue]="c.id" [nzLabel]="c.plate"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSpan]="9">Notas</nz-form-label>
      <nz-form-control nzErrorTip="" nzSpan="15">
        <textarea formControlName="note" nzSize="large" nz-input></textarea>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control [nzSpan]="12" [nzOffset]="6">
        <button type="submit" nz-button nzType="primary" nzBlock nzSize="large" >Send</button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-modal>
