<div class="d-flex justify-content-end pt-2 pb-2">
  <button nz-button nzType="primary" (click)="download()">Descargar PDF</button>
</div>
<div class="m-0 p-0" id="content" #content>
  <div class="bg-secondary">
    <p class="h5 text-center text-light lh-lg pt-2 pb-2 fw-bold">Estado de cuenta</p>
    <div class="position-absolute">
      <img src="../../../../assets/img/logo_fondo_transparente.png" class="position-relative" style="height: 60px; width: 120px; left: 20px; top: -75px" alt="MI_NAVE_logo" />
    </div>
  </div>
  <div class="ps-3 pe-3">
    <div class="p-0">
      <div class="col-12">
        <div class="row">
          <div class="col">
            <p class="fw-bold text-dark p-0 m-0">id del cliente: {{ billingStatement?.customerInformation.id | uppercase}}</p>
            <p class="fw-bold text-dark p-0 m-0">Cliente: {{ billingStatement?.customerInformation.firstName | uppercase }} {{ billingStatement?.customerInformation.lastName | uppercase }}</p>
            <p *ngIf="billingStatement?.customerInformation" class="fw-bold text-dark p-0 m-0">RFC: {{ billingStatement?.customerInformation.rfc | uppercase }}</p>
            <p *ngIf="billingStatement?.customerInformation.curp" class="fw-bold text-dark p-0 m-0">CURP: {{ billingStatement?.customerInformation.curp | uppercase }}</p>
            <p class="fw-bold text-dark p-0 m-0">No. Celular: {{ billingStatement?.customerInformation.phone }}</p>
            <p class="fw-bold text-dark p-0 m-0">Correo: {{ billingStatement?.customerInformation.email | lowercase }}</p>
          </div>
          <div class="col">
            <div class="d-flex justify-content-end">
              <p class="fw-bold text-dark p-0 m-0">{{ toHumanReadableDate(todayDate()) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-1" #main_tables>
      <div class="col-7">
        <table class="table border table-sm">
          <tbody>
          <tr class="bg-secondary">
            <td colspan="2" class="text-light">
              <div class="w-100 text-center">
                <p class="fw-bold m-0 p-0">Información general</p>
              </div>
            </td>
          </tr>
          <tr>
            <td><strong><span class=""></span>Arrendatario:</strong></td>
            <td class="">{{ billingStatement?.customerInformation.firstName | uppercase }} {{ billingStatement?.customerInformation.lastName | uppercase }}</td>
          </tr>
          <tr>
            <td><strong><span class=""></span>Representado por:</strong></td>
            <td class="">Manuel Marihno</td>
          </tr>
          <tr>
            <td><strong><span class=""></span>Tipo de contrato:</strong></td>
            <td class="">Renta de vehículo</td>
          </tr>
          <tr>
            <td><strong><span class=""></span>Tasa de interés moratoria:</strong></td>
            <td class="">10%</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="col-5">
        <table class="table border table-sm">
          <tbody>
          <tr class="bg-secondary">
            <td class="text-light">
              <div class="w-100 text-center">
                <div class="d-flex justify-content-start m-0 p-0">
                  <p class="fw-bold m-0 p-0">Resumen del adeudo al</p>
                </div>
              </div>
            </td>
            <td class="text-light">
              <div class="d-flex justify-content-end m-0 p-0">
                <p class="fw-bold m-0 p-0">{{ toHumanReadableDate(todayDate()) }}</p>
              </div>
            </td>
          </tr>
          <tr>
            <td><strong><span class="glyphicon glyphicon-user"></span>Interés moratorio</strong></td>
            <td class="">$</td>
          </tr>
          <tr>
            <td><strong><span class="glyphicon glyphicon-cloud"></span>Daños a los vehiculos</strong></td>
            <td class="">$</td>
          </tr>
          <tr>
            <td><strong><span class="glyphicon glyphicon-bookmark"></span>Penalidades</strong></td>
            <td class="">$</td>
          </tr>
          <tr class="bg-secondary">
            <td class="text-light">
              <div class="w-100">
                <p class="fw-bold m-0 p-0">Total:</p>
              </div>
            </td>
            <td class="text-light">$</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <p class="text-muted m-0p-0">Todos los montos expresados en este Estado de Cuenta están denominados en Pesos Mexicanos.</p>
    </div>
    <div class="row" #tables>
      <div class="col-12">
        <table class="table table-sm">
          <thead>
          <tr class="bg-secondary">
            <td colspan="6" class="text-light">
              <div class="w-100 text-center">
                <p class="fw-bold m-0 p-0">Cargos vencidos - NO PAGADOS</p>
              </div>
            </td>
          </tr>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Fecha de vencimiento</th>
            <th scope="col">Concepto</th>
            <th scope="col">Días vencidos</th>
            <th scope="col">Monto por cubrir</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let c of billingStatement?.getOverDueCharges(); let i = index;">
            <tr>
              <th scope="row">{{ i + 1 }}</th>
              <td>{{ toHumanReadableDate(c.chargeDueDate) }}</td>
              <td>{{ c.chargeTypeName }}</td>
              <td>{{ daysDifferenceFromNow(c.chargeDueDate) }}</td>
              <td>${{ c.chargePendingAmountToCover }}</td>
            </tr>
          </ng-container>
          <tr>
            <th scope="row"></th>
            <td colspan="2"></td>
            <td class="fw-bold">Total</td>
            <td class="fw-bold">${{ billingStatement.getOverDueTotal() }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12">
        <table class="table table-sm" #table1>
          <thead>
          <tr class="bg-secondary">
            <td colspan="6" class="text-light">
              <div class="w-100 text-center">
                <p class="fw-bold m-0 p-0">Cargos</p>
              </div>
            </td>
          </tr>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Fecha</th>
            <th scope="col">Concepto</th>
            <th scope="col">Monto total</th>
            <th scope="col">Monto cubierto</th>
            <th scope="col">Monto por cubrir</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let c of billingStatement?.charges; let i = index;">
            <tr>
              <th scope="row">{{ i + 1 }}</th>
              <td>{{ toHumanReadableDate(c.chargeDueDate) }}</td>
              <td>{{ c.chargeTypeName }}</td>
              <td>{{ c.chargeAmount }}</td>
              <td>{{ c.chargeAmountCovered }}</td>
              <td>{{ c.chargePendingAmountToCover }}</td>
            </tr>
            <tr *ngFor="let p of billingStatement.getChargesPaymentsMap().get(c.chargeId)">
              <th colSpan="2"></th>
              <td>{{ toHumanReadableDate(p.transactionPaymentDueDate) }} - {{ p.transactionPaymentMethodName }}</td>
              <td>{{ p.transactionAmount }}</td>
            </tr>
          </ng-container>
          <tr>
            <th scope="row"></th>
            <td colspan="3"></td>
            <td class="fw-bold">Total</td>
            <td class="fw-bold">${{ billingStatement.getOverDueTotal() }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12">
        <table class="table table-sm">
          <thead>
          <tr class="bg-secondary">
            <td colspan="5" class="text-light">
              <div class="w-100 text-center">
                <p class="fw-bold m-0 p-0">Pagos realizados</p>
              </div>
            </td>
          </tr>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Fecha de pago</th>
            <th scope="col">Concepto</th>
            <th scope="col">Monto total</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let item of billingStatement?.payments; let i = index">
            <tr>
              <th scope="row">{{i+1}}</th>
              <td>{{ toHumanReadableDate(item.paymentDueDate) }}</td>
              <td>{{ item.paymentMethodName }}</td>
              <td>${{ absoluteNumber(item.paymentAmount) }}</td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>
      <div class="col-12">
        <p class="text-muted">
          <!-- legal -->
        </p>
      </div>
    </div>
    <div class="row mt-5" #sign>
      <div class="d-flex justify-content-between">
        <div class="d-flex justify-content-end w-100">
          <p class="m-0 p-0 pe-5 fw-bold">{{ billingStatement?.customerInformation.city === 'CDMX' ? 'Ciudad de México': 'Guadalajara' }}</p>
        </div>
        <div class="d-flex justify-content-start w-100">
          <p class="m-0 p-0 ps-5 fw-bold">{{ toHumanReadableDate(todayDate()) }}</p>
        </div>
      </div>
      <div class="w-100 pt-5 pb-5">
        <div class="d-flex flex-column">
          <div class="p-0 m-0 d-flex justify-content-center">
            <hr class="w-50 border border-dark border-1" />
          </div>
          <div class="p-0 m-0 d-flex justify-content-center">
            <p class="fw-bold">Representante legal: Manuel Marinho</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
