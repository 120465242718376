import { Component, Input, OnInit } from '@angular/core';
import {PaymentDetailBS, TransactionBS} from '../../domain/model/billing-statement.model';

@Component({
  selector: 'app-unapplied-payments-table-view',
  templateUrl: './unapplied-payments-table-view.html',
})
export class UnappliedPaymentsTableViewComponent implements OnInit {
  @Input() payments: PaymentDetailBS[];
  @Input() mapPayments: Map<string, TransactionBS[]>;
  constructor() { }

  ngOnInit(): void {
  }
  toHumanReadableDate(date: Date): string {
    const pad = '00';
    const month = `${pad}${date.getMonth() + 1}`.slice(-pad.length);
    const day = `${pad}${date.getDate()}`.slice(-pad.length);
    return `${date.getFullYear()}-${month}-${day}`;
  }

}
