import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-lease-to-own-agreement',
  templateUrl: './edit-lease-to-own-agreement.component.html',
  styleUrls: ['./edit-lease-to-own-agreement.component.css']
})
export class EditLeaseToOwnAgreementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
