import {Injectable} from '@angular/core';
import {v4 as uuid} from 'uuid';
import {Transaction} from './model/transaction.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {BillingStatement} from './model/billing-statement.model';
import {CollectionApiService} from '../infrastructure/api/collection-api.service';

/**
 * TransactionsFacade
 * Supports the following operations with transactions
 * Get Customer transactions
 * Get Customer charge details
 * Unlink payment
 * Link payment to specific charge
 * */
@Injectable({
  providedIn: 'root',
})
export class TransactionsFacade {
  constructor(
    private collectionServiceApi: CollectionApiService
  ) {}
  public getBillingStatement(customerId: string): Observable<BillingStatement> {
    return this.collectionServiceApi.getBillingStatement(customerId);
  }
  public getTransactions(customerId: string): Observable<Transaction[]> {
    return this.collectionServiceApi.getTransactions(customerId);
  }
  public getChargeMappingTransactions(transactions: Transaction[]): Map<string, Transaction[]> {
    const mapping = new Map<string, Transaction[]>();
    transactions.forEach(t => {
      if (!mapping.has(t.transactionChargeId)) {
        mapping.set(t.transactionChargeId, Array.of<Transaction>());
      }
      const transactionsLinked = mapping.get(t.transactionChargeId);
      transactionsLinked.push(t);
    });
    return mapping;
  }
}
