import {Adapter} from '../adapter';
import {Injectable} from '@angular/core';

export class ChargeDetail {
  constructor(
    public chargeId: string,
    public chargeCustomerId: string,
    public chargeDueDate: Date,
    public chargeTypeName: string,
    public chargeTypeCode: string,
    public chargeAmount: number,
    public chargeAmountCovered: number,
    public chargePendingAmountToCover: number,
    public chargeYear: number,
    public chargeWeek: number,
    public chargePriority: number,
    public chargeRentalAgreementUuid: string
  ) {}
  isChargeFullCovered(): boolean {
    return this.chargePendingAmountToCover === 0;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ChargeDetailAdapter implements Adapter<ChargeDetail> {
  adapt(item: any): ChargeDetail {
    return new ChargeDetail(
      item.chargeId,
      item.chargeCustomerId,
      new Date(item.chargeDueDate.replace('-', '/')),
      item.chargeTypeName,
      item.chargeTypeCode,
      item.chargeAmount,
      item.chargeAmountCovered,
      item.chargePendingAmountToCover,
      item.chargeYear,
      item.chargeWeek,
      item.chargePriority,
      item.chargeRentalAgreementUuid
    );
  }
}
