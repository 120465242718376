<nz-radio-group [(ngModel)]="statusSelected" nzSize="large" nzButtonStyle="solid" (ngModelChange)="statusSelectedChanged()">
  <nz-space>
    <nz-space-item>
      <nz-badge [nzCount]="activeReservations.length" [nzOverflowCount]="999">
        <label nz-radio-button nzValue="ACTIVE">ACTIVE</label>
      </nz-badge>
    </nz-space-item>
    <nz-space-item>
      <nz-badge [nzCount]="pendingCarReservations.length" [nzOverflowCount]="999">
        <label nz-radio-button nzValue="PENDING_RESERVATION">PENDING CAR</label>
      </nz-badge>
    </nz-space-item>
    <nz-space-item>
      <nz-badge [nzCount]="cancelledReservations.length">
        <label nz-radio-button nzValue="CANCELLED">CANCELLED</label>
      </nz-badge>
    </nz-space-item>
    <nz-space-item>
      <nz-badge [nzCount]="closedReservations.length">
        <label nz-radio-button nzValue="CLOSED">CLOSED</label>
      </nz-badge>
    </nz-space-item>
    <nz-space-item>
      <nz-badge [nzCount]="allReservations.length">
        <label nz-radio-button nzValue="ALL">ALL</label>
      </nz-badge>
    </nz-space-item>
  </nz-space>
</nz-radio-group>
<nz-table #reservationsTable [nzData]="displayReservations" nzPageSize="50" nzSize="middle">
  <thead>
  <tr>
    <th>Name</th>
    <th>Phone</th>
    <th>Email</th>
    <th>Car Reserved</th>
    <th>Product</th>
    <th>Status</th>
  </tr>
  </thead>
  <tbody>
    <tr *ngFor="let reservation of reservationsTable.data">
      <td>{{ reservation.application?.customer?.firstName }} {{ reservation.application?.customer?.lastName }}</td>
      <td>{{ reservation.application?.customer?.phone }}</td>
      <td>{{ reservation.application?.customer?.email }}</td>
      <td>{{ reservation.carReserved?.plate }}</td>
      <td>{{ reservation.chosenProduct?.name }} <nz-tag nzColor="blue" *ngIf="reservation.chosenProduct.isLeaseToOwn">LTO</nz-tag></td>
      <td>{{ reservation.status }}</td>
      <td>
        <ng-container [ngSwitch]="reservation.status">
          <nz-space>
            <nz-space-item>
              <app-close-reservation-modal-button [reservationId]="reservation.id" *ngSwitchCase="'ACTIVE'" (reservationClosed)="fetchReservations()"></app-close-reservation-modal-button>
            </nz-space-item>
            <nz-space-item>
              <app-update-reservation-modal-button *ngSwitchCase="'ACTIVE'" [reservation]="reservation" (reservationUpdated)="fetchReservations()"></app-update-reservation-modal-button>
            </nz-space-item>
            <nz-space-item>
              <app-update-reservation-modal-button *ngSwitchCase="'PENDING_RESERVATION'" [reservation]="reservation" (reservationUpdated)="fetchReservations()"></app-update-reservation-modal-button>
            </nz-space-item>
            <nz-space-item>
              <app-inspect-car-modal-button *ngSwitchCase="'ACTIVE'"></app-inspect-car-modal-button>
            </nz-space-item>
            <nz-space-item>
              <button *ngSwitchCase="'ACTIVE'" nz-button nzShape="circle" nzType="danger" nzSize="default"><i nz-icon nzType="delete"></i></button>
            </nz-space-item>
          </nz-space>
        </ng-container>
      </td>
    </tr>
  </tbody>
</nz-table>
