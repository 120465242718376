import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styles: [
  ]
})
export class SearchFieldComponent implements OnInit {
  @Output() searchSubmitted: EventEmitter<string> = new EventEmitter<string>();
  @Input() search = '';
  @Output('search') searchChanged: EventEmitter<string> = new EventEmitter<string>();
  searchForm: FormGroup;
  constructor() { }
  ngOnInit(): void {
    this.searchForm = new FormGroup({
      search: new FormControl(this.search),
    });
  }
  onSubmit(): void {
    this.searchSubmitted.emit(this.searchForm.controls.search.value);
  }
  searchValueChanged(): void {
    this.searchChanged.emit(this.search);
  }
}
