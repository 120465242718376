export default class CreatePaymentLinkRequest {
  constructor(
    public customerId?: string,
    public amount?: number,
    public allowedPaymentMethods?: string[],
    public daysToExpire?: number,
    public linkReasonCode?: string,
    public requiresEmailNotification?: boolean,
    public requiresSmsNotification?: boolean
  ) {
  }
}
