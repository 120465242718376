import { Component, Input, OnInit } from '@angular/core';
import { PalencaResponse } from '../../domain/model/customer/palenca-response';

@Component({
  selector: 'app-palenca',
  templateUrl: './palenca.component.html',
  styleUrls: ['./palenca.component.css']
})
export class PalencaComponent implements OnInit {
  @Input() data : PalencaResponse[] = [];
  constructor() { }

  ngOnInit(): void {
  }

}
