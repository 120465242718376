import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-share-information-layout',
  templateUrl: './share-information-layout.component.html',
  styles: [
  ]
})
export class ShareInformationLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
