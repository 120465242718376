import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomerApiService } from '../../../../infrastructure/api/customer-api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzMessageService } from 'ng-zorro-antd/message';
import { from } from 'rxjs';

@Component({
  selector: 'app-phone-form',
  templateUrl: './phone-form.component.html',
  styleUrls: ['./phone-form.component.css']
})
export class PhoneFormComponent implements OnInit {
  @Input() customerId: any;
  @Output() phoneFormChanges: EventEmitter<any> = new EventEmitter<any>();
  phonesArray: any[] = [];

  constructor(private customerApiService: CustomerApiService,
    private notificationService: NzNotificationService,
    private nzMessageService: NzMessageService,) { }

  ngOnInit(): void {
    this.fetchPhones();
  }

  createNotification(type: string, title: string, message: string, duration = 7000): void {
    this.notificationService.create(
      type,
      title,
      message,
      { nzDuration: duration }
    );
  }

  addPhone() {
    const phonesForm = new FormGroup({
      type: new FormControl('', [Validators.required]),
      relationship: new FormControl('', [Validators.required]),
      nameRelationship: new FormControl('',[Validators.required]),
      lastNameRelationship: new FormControl('',[Validators.required]),
      phoneCode: new FormControl('+52', [Validators.required]),
      phoneCodeReserve: new FormControl(''),
      phone: new FormControl('', [Validators.required]),
      phoneReserve: new FormControl(''),
    })
    this.phonesArray.push({ form: phonesForm, id: undefined });
    console.log(this.phonesArray);
  }

  deletePhone(i: number, event) {
    if (this.phonesArray[i].id) {
      this.customerApiService.deleteCustomerPhone(this.phonesArray[i].id).subscribe((res: any) => {
        this.nzMessageService.info('el teléfono fue eliminado con éxito');
        this.phoneFormChanges.emit({ value: true, type: 'update' });
        console.log(res);
        this.phonesArray.splice(i, 1);
      }, (err: any) => {
        console.log(err);
        this.createNotification('error', 'ocurrió un error', 'Ocurrió un error al eliminar el teléfono.');
      });
    }

  }

  savePhone(phone) {
    const obj = {
      phone: this.phonesArray[phone].form.controls.phoneCode.value + this.phonesArray[phone].form.controls.phone.value,
      reserve_phone: this.phonesArray[phone].form.controls.phoneCodeReserve.value + this.phonesArray[phone].form.controls.phoneReserve.value,
      phone_type_id: this.phonesArray[phone].form.controls.type.value,
      relationship: this.phonesArray[phone].form.controls.relationship.value,
      relationship_name: this.phonesArray[phone].form.controls.nameRelationship.value,
      relationship_last_name: this.phonesArray[phone].form.controls.lastNameRelationship.value
    };
    console.log(obj);
    
    if (this.phonesArray[phone].id) {
      this.customerApiService.updateCustomerPhone(this.phonesArray[phone].id, obj).subscribe((res: any) => {
        this.createNotification('success', 'Teléfono actualizado', 'el teléfono fue actualizado con éxito');
        this.phoneFormChanges.emit({ value: true, type: 'update' });
      }, (err: any) => {
        this.createNotification('error', 'ocurrió un error', 'Ocurrió un error al editar el teléfono.')
      });
    } else {
      this.customerApiService.createCustomerPhone(this.customerId, obj).subscribe((res: any) => {
        this.createNotification('success', 'Teléfono creado', 'el teléfono fue creado con éxito');
        this.phoneFormChanges.emit({ value: true, type: 'update' });
      }, (err: any) => {
        this.createNotification('error', 'ocurrió un error', 'Ocurrió un error al crear el teléfono.')
      });
    }
  }

  fetchPhones(): void {
    this.customerApiService.fetchAllCustomerPhones(this.customerId).subscribe((res: any) => {
      const phones = res;     
      if (phones.length > 0) {
        phones.forEach((phone: any) => {
          const phonesForm = new FormGroup({
            type: new FormControl(phone.phone_type_id.id, [Validators.required]),
            relationship: new FormControl(phone.relationship, [Validators.required]),
            nameRelationship: new FormControl(phone.relationship_name, [Validators.required]),
            lastNameRelationship: new FormControl(phone.relationship_last_name, [Validators.required]),
            phoneCode: new FormControl('+52', [Validators.required]),
            phone: new FormControl(phone.phone.substring(3), [Validators.required]),
            phoneCodeReserve: new FormControl(phone.reserve_phone === null ? '' : '+52'),
            phoneReserve: new FormControl(phone.reserve_phone === null ? '' : phone.reserve_phone.substring(3)),
          });
          this.phonesArray.push({ form: phonesForm, id: phone.id });
        });
      }      
    }, (err: any) => {
      this.phonesArray = [];
    });
  }

}
