<div class="bod">
  <div class="wrapper">
    <div class="title">
      Login</div>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="field">
        <input formControlName="username" type="text" required>
        <label>Username</label>
      </div>
      <div class="field">
        <input formControlName="password" type="password" required>
        <label>Password</label>
      </div>
      <div class="field">
        <input type="submit" value="Login">
      </div>
    </form>
  </div>

</div>
