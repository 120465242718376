<form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()">
  <!--
  <nz-form-item>
    <nz-form-control nzErrorTip="Please input your username!">
      <nz-input-group nzPrefixIcon="user">
        <input type="text" nz-input formControlName="userName" placeholder="Username" />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-select ngModel="lucy" formControlName="documentType" nzPlaceHolder="Selecciona el tipo de documento">
      <nz-option nzValue="INE" nzLabel="Credencial para votar"></nz-option>
      <nz-option nzValue="LICENSE" nzLabel="Licencia de conducir"></nz-option>
      <nz-option nzValue="disabled" nzLabel="Disabled" nzDisabled></nz-option>
    </nz-select>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control nzErrorTip="Please input your Password!">
      <nz-input-group nzPrefixIcon="lock">
        <input type="password" nz-input formControlName="password" placeholder="Password" />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>-->
  <nz-form-item>
    <nz-upload
      nzType="drag"
      [nzMultiple]="true"
      nzAction="http://localhost:7070/documents/{{documentId}}/files"
      (nzChange)="handleChange($event)"
    >
      <p class="ant-upload-drag-icon">
        <i nz-icon nzType="inbox"></i>
      </p>
      <p class="ant-upload-text">Click or drag file to this area to upload</p>
      <p class="ant-upload-hint">
        Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files
      </p>
    </nz-upload>
  </nz-form-item>
  <!-- <button nz-button class="login-form-button login-form-margin" [nzType]="'primary'">Upload document</button> -->
</form>
