export default class CreateNewRentalRequest {
  constructor(
    public customerId?: string,
    public carId?: string,
    public amount?: number,
    public startDate?: string,
    public rentalType?: string,
    public pickupType?: string,
    public ltoTermLength?: number,
    public ltoPayment?: number,
    public firstPaymentDate?: string,
    public triggeredByApplicationId?: string,
    public createdBy?: string,
  ) {
  }
}
