import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CustomerApiService } from '../../../../infrastructure/api/customer-api.service';
import {
  UpdateCustomerRequestAdapter
} from '../../../../infrastructure/update-customer.request';

@Component({
  selector: 'app-update-car-information-form',
  templateUrl: './update-car-information-form.component.html',
  styles: [
  ]
})
export class UpdateCarInformationFormComponent implements OnInit {
  @Input() carId!: string;
  @Input() isUpdateCarFormVisible!: boolean;
  @Output() formSubmitted: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeUpdateCarForm: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  updateCarForm: FormGroup;
  car: any;
  constructor(
    private updateCustomerPersonalInformationRequestAdapter: UpdateCustomerRequestAdapter,
    private customerService: CustomerApiService,
    private nzMessageService: NzMessageService,
    public notificationService: NzNotificationService,
    private router: Router) { }

  ngOnInit(): void {
    this.fetchCar()
  }

  fetchCar() {
    this.updateCarForm = new FormGroup({
      plate: new FormControl('', [Validators.required]),
      vin: new FormControl('', [Validators.required]),
      location: new FormControl(null, [Validators.required]),
      engineNumber: new FormControl(null, []),
      model: new FormControl('', [Validators.required]),
      year: new FormControl('', [Validators.required]),
      color: new FormControl(null, [Validators.required]),
      carType: new FormControl(null, [Validators.required]),
      notes: new FormControl(null, []),
      ownerRfc: new FormControl(null, []),
      purchaseDate: new FormControl(null, []),
      deliveryDate: new FormControl(null, []),
      circulationCard: new FormControl(null, []),
    });

    this.customerService.getCarInformationData(this.carId).subscribe((car: any) => {
      this.updateCarForm = new FormGroup({
        plate: new FormControl(car.plate, [Validators.required]),
        vin: new FormControl(car.vin, [Validators.required]),
        location: new FormControl(car.carLocationCode, [Validators.required]),
        engineNumber: new FormControl(car.engineNumber, []),
        model: new FormControl(car.carModelCode, [Validators.required]),
        year: new FormControl(car.year, [Validators.required]),
        color: new FormControl(car.color, [Validators.required]),
        carType: new FormControl(car.carTypeCode, [Validators.required]),
        notes: new FormControl(car.color, []),
        ownerRfc: new FormControl(car.ownerRfc, []),
        purchaseDate: new FormControl(car.purchaseDate, []),
        deliveryDate: new FormControl(car.deliveryDate, []),
        circulationCard: new FormControl(car.circulationCard, []),
      });
    })
  }

  onSubmit(): void {
    const data = {
      plate: this.updateCarForm.controls.plate.value,
      vin: this.updateCarForm.controls.vin.value,
      location: this.updateCarForm.controls.location.value,
      engineNumber: this.updateCarForm.controls.engineNumber.value,
      model: this.updateCarForm.controls.model.value,
      year: this.updateCarForm.controls.year.value,
      color: this.updateCarForm.controls.color.value,
      carType: this.updateCarForm.controls.carType.value,
      notes: this.updateCarForm.controls.notes.value,
      ownerRfc: this.updateCarForm.controls.ownerRfc.value,
      purchaseDate: this.updateCarForm.controls.purchaseDate.value,
      deliveryDate: this.updateCarForm.controls.deliveryDate.value,
      circulationCard: this.updateCarForm.controls.circulationCard.value,
    };

    this.customerService.putCarInformationData(data, this.carId).subscribe((response) => {
      this.createNotification('success', 'Vehículo Actualizado', 'Vehículo actualizado con éxito');

      this.closeUpdateCarForm.emit(false)
    }, (error) => {
      this.createNotification('error', 'ocurrió un error', 'No se pudo actualizar el vehículo')
    });
  }

  createNotification(type: string, title: string, message: string, duration = 7000): void {
    this.notificationService.create(
      type,
      title,
      message,
      { nzDuration: duration }
    );
  }
}
