<nz-divider [nzText]="'Marcar Repo de ' + rental?.customer?.firstName + ' ' + rental?.customer?.lastName + '    ['+ rental?.car.plate +']'"></nz-divider>
<div nz-row nzJustify="center">
  <div nz-col nzSpan="3"></div>
  <div nz-col nzSpan="6">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" nz-form>
      <nz-form-item>
        <nz-form-label [nzSpan]="9" nzRequired>Fecha de Repo</nz-form-label>
        <nz-form-control nzErrorTip="Por favor ingresa la fecha en la que se hizo el switch" nzSpan="15">
          <nz-date-picker nzSize="large" nzFormat="yyyy/MM/dd" formControlName="repoDate"></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="9">Notas</nz-form-label>
        <nz-form-control nzErrorTip="" nzSpan="15">
          <textarea formControlName="note" nzSize="large" nz-input></textarea>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control [nzSpan]="12" [nzOffset]="6">
          <button type="submit" nz-button nzType="primary" nzBlock nzSize="large" [disabled]="buttonDisabled">Send</button>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
  <div nz-col nzSpan="3"></div>
</div>
