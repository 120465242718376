import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { Subscription } from 'rxjs';
import { interval } from 'rxjs/internal/observable/interval';
import { startWith, switchMap } from 'rxjs/operators';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
    selector: 'app-notification-center',
    templateUrl: './notification-center.component.html',
    styles: [
    ]
})
export class NotificationCenterComponent implements OnInit, OnDestroy {
    timeInterval: Subscription
    title = 'Pooling collections notifications'
    status: any;
    notificationsList = []
    numberNotificationsList = 0
    isVisibleNotificationCenter = false;
    constructor(
        private router: Router,
        private customerService: CustomerService
    ) { }

    ngOnInit(): void {
        this.getNotificationsCollections()
        this.timeInterval = interval(900000).pipe(
            startWith(0),
            switchMap(() => this.getNotificationsCollections()),
        ).subscribe(res => {
            this.status = 'done'
        }),
            error => console.log('error when polling notifications :>> ', error);
    }

    ngOnDestroy(): void {
        this.timeInterval.unsubscribe();
    }

    async getNotificationsCollections() {
        this.customerService.findCustomerTaxNotifications().subscribe(
            value => {
                this.notificationsList = value;
                this.numberNotificationsList = value.length
                return value;
            }
        );
    }

    getModalVisibility(value: boolean) {
        this.isVisibleNotificationCenter = !value;
    }

    clearNotifications() {
        this.notificationsList = []
        this.numberNotificationsList = 0
    }

    updateNotifications() {
        this.customerService.findCustomerTaxNotifications().subscribe(
            value => {
                this.notificationsList = value;
                this.numberNotificationsList = value.length
                return value;
            }
        );
    }

    openNotificationListCenter(value: boolean): void {
        this.isVisibleNotificationCenter = !value;
    }
}