<div *ngFor="let d of data" style="padding-top: 20px;">
  <nz-card>
    <nz-card-meta [nzTitle]="d.platform | uppercase" [nzAvatar]="avatarTemplate"
      [nzDescription]="(d.profile.first_name ? d.profile.first_name : '')  + ' ' + (d.profile.last_name ? d.profile.last_name : '')"></nz-card-meta>
    <ng-template #avatarTemplate>
      <nz-avatar [nzSrc]="d.profile.picture_url ? d.profile.picture_url : null"></nz-avatar>
    </ng-template>
    <div nz-row style="padding-top: 10px;">
      <div nz-col nzSpan="12">
        <p *ngIf="d.profile.email"><span style="font-weight: 600;"></span> {{d.profile.email}}</p>
        <p *ngIf="d.profile.phone"><span style="font-weight: 600;"></span> {{d.profile.phone}}</p>
        <p *ngIf="d.profile.address"><span style="font-weight: 600;">Dirección: </span> {{d.profile.address}}</p>
        <nz-statistic [nzValue]="(d.metrics_info.acceptance_rate ? d.metrics_info.acceptance_rate : 0 | number)!"
          [nzTitle]="'Nivel de aceptación'"></nz-statistic>
        <nz-statistic [nzValue]="d.metrics_info.rating ? d.metrics_info.rating : 0" [nzTitle]="'Rating'"
          [nzSuffix]="'/ 5'"></nz-statistic>
        <nz-statistic
          [nzValue]="(d.metrics_info.time_since_first_trip ? d.metrics_info.time_since_first_trip : 0 | number)!"
          [nzTitle]="'Tiempo activo'"></nz-statistic>
        <nz-statistic [nzValue]="(d.metrics_info.debt_pending ? d.metrics_info.debt_pending :  0 )!"
          [nzTitle]="'Deuda pendiente'"></nz-statistic>
        <nz-statistic [nzValue]="(d.metrics_info.picking_speed ? d.metrics_info.picking_speed :  0 )!"
          [nzTitle]="'Velocidad recogida'"></nz-statistic>
      </div>
      <div nz-col nzSpan="12">
        <p *ngIf="d.status"><span style="font-weight: 600;">Estatus: </span> {{d.status}}</p>
        <p *ngIf="d.status_details"><span style="font-weight: 600;">Detalle estatus: </span> {{d.status_details}}</p>
        <p *ngIf="d.failed_reason"><span style="font-weight: 600;">Razón de la falla: </span> {{d.failed_reason }}</p>
        <p *ngIf="d.profile.city_name"><span style="font-weight: 600;">Ciudad: </span> {{d.profile.city_name}}</p>
        <p *ngIf="d.profile.birthday"><span style="font-weight: 600;">Birthday: </span> {{d.profile.birthday}}</p>
        <nz-statistic [nzValue]="(d.metrics_info.cancellation_rate ? d.metrics_info.cancellation_rate : 0 | number)!"
          [nzTitle]="'Tasa de cancelación'"></nz-statistic>
        <nz-statistic [nzValue]="(d.metrics_info.lifetime_trips ? d.metrics_info.lifetime_trips : 0 | number)!"
          [nzTitle]="'Viajes completados'"></nz-statistic>
        <nz-statistic [nzValue]="(d.metrics_info.level_name ? d.metrics_info.level_name : '' )!"
          [nzTitle]="'Nivel'"></nz-statistic>
        <nz-statistic [nzValue]="(d.metrics_info.debt_paid ? d.metrics_info.debt_paid :  0 )!"
          [nzTitle]="'Deuda pagada'"></nz-statistic>
        <nz-statistic [nzValue]="(d.metrics_info.thumps_up ? d.metrics_info.thumps_up :  0 )!"
          [nzTitle]="'Likes'"></nz-statistic>
      </div>
    </div>
  </nz-card>
</div>