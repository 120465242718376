import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChargePaymentReferenceTableViewComponent } from './charge-payment-reference-table-view/charge-payment-reference-table-view.component';
import {NzTableModule} from 'ng-zorro-antd/table';
import { UnappliedPaymentsTableViewComponent } from './unapplied-payments-table-view/unapplied-payments-table-view';
import { CarInformationComponent } from './car-information/car-information.component';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { WebFleetComponent } from './web-fleet/web-fleet.component';
import { PalencaComponent } from './palenca/palenca.component';
import { AddressesComponent } from './addresses/addresses.component';


@NgModule({
  declarations: [
    ChargePaymentReferenceTableViewComponent,
    UnappliedPaymentsTableViewComponent,
    CarInformationComponent,
    WebFleetComponent,
    PalencaComponent,
    AddressesComponent
  ],
  exports: [
    ChargePaymentReferenceTableViewComponent,
    UnappliedPaymentsTableViewComponent,
    CarInformationComponent,
    WebFleetComponent,
    PalencaComponent,
    AddressesComponent
  ],
  imports: [
    CommonModule,
    NzTableModule,
    NzCardModule,
    NzGridModule,
    NzAvatarModule,
    NzStatisticModule,
  ]
})
export class NgzorroComponentsModule { }
