<nz-page-header [nzGhost]="false">
  <nz-page-header-title>Applications</nz-page-header-title>
  <nz-page-header-extra>
    <nz-radio-group [(ngModel)]="appStatusSelected" nzSize="large" nzButtonStyle="solid"
      (ngModelChange)="statusSelectedChanged()">
      <label nz-radio-button nzValue="OPEN">Open</label>
      <label nz-radio-button nzValue="PNDG_APPRV">Pending Approval</label>
      <label nz-radio-button nzValue="DIDNT_TAKE">Didnt Take a Car</label>
      <label nz-radio-button nzValue="APPROVED">Approved</label>
      <label nz-radio-button nzValue="DENIED">Denied</label>
    </nz-radio-group>
  </nz-page-header-extra>
  <nz-page-header-content>
  </nz-page-header-content>
</nz-page-header>
<app-search-field (searchSubmitted)="searchApplication($event)"></app-search-field>
<br />
<br />
<app-open-applications-table [applications]="applications" (applicationQuickViewButtonClicked)="openQuickView($event)"
  [pageIndex]="pageIndex" [pageSize]="pageSize" (queryParamsChanged)="refreshQueryParams($event)">
</app-open-applications-table>
<app-application-detail-viewer [visible]="isQuickViewerVisible" (closedEvent)="closeQuickView()" [itsPaid]="itsPaid" [paymentReferences]="paymentReference"
  [application]="applicationDetail" [documents]="documentsToShow"
  (isOcrUploaded)="getOcrUploadStatus($event)" [underwriting]="this.underwritingDetail"
  (sendToDidntTakeCarButtonClicked)="openDidntTakeCarForm()"
  (sendToPendingApprovalButtonClicked)="sendApplicationToApproval($event)"
  [miFielAgreementValid]="true"
  (updateCustomerButtonClicked)="openUpdateCustomerForm()" (updateApplicationButtonClicked)="openUnderwritingForm()"
  (newDocumentButtonClicked)="openCreateDocumentForm()" (fileUploaded)="fetchDocumentsForSelectedApplication()" (tokuReferences)="fetchTokuReferences()"
  (approveApplicationButtonClicked)="approveApplication($event)" (denyApplicationButtonClicked)="openDenyForm()"
  (createRentalButtonClicked)="openNewRentalForm()" (newTokuCustomerButtonClicked)="openNewTokuCustomerForm($event)"></app-application-detail-viewer>
<nz-modal [(nzVisible)]="isDidntTakeCarFormVisible" nzTitle="Enviar a Didnt take a car"
  (nzOnCancel)="isDidntTakeCarFormVisible=false" (nzOnOk)="isDidntTakeCarFormVisible=false" [nzFooter]="null">
  <ng-container *nzModalContent>
    <app-didnt-take-car-form [application]="this.applicationDetail" [didntTakeCarReasons]="didntTakeCarReasons"
      (formSubmitted)="sendApplicationToDidntTakeCar($event)"></app-didnt-take-car-form>
  </ng-container>
</nz-modal>
<nz-modal [nzWidth]="850" [(nzVisible)]="isUpdateCustomerFormVisible" nzTitle="Actualizar Conductor"
  (nzOnCancel)="isUpdateCustomerFormVisible=false" (nzOnOk)="isUpdateCustomerFormVisible=false" [nzFooter]="null">
  <ng-container *nzModalContent>
    <app-update-customer-information-form [customer]="this.applicationDetail.customer"
      (formSubmitted)="updateCustomer($event)"
      (closeUpdateCustomerForm)="closeUpdateCustomer($event)"></app-update-customer-information-form>
  </ng-container>
</nz-modal>
<nz-modal [(nzVisible)]="isCreateDocumentFormVisible" nzTitle="Nuevo documento"
  (nzOnCancel)="isCreateDocumentFormVisible=false" (nzOnOk)="isCreateDocumentFormVisible=false" [nzFooter]="null">
  <ng-container *nzModalContent>
    <app-create-document-form [customer]="this.applicationDetail.customer" (formSubmitted)="createDocument($event)"
      [documentTypes]="documentTypes"></app-create-document-form>
  </ng-container>
</nz-modal>
<nz-modal [nzWidth]="800" [nzMaskClosable]="false" [(nzVisible)]="isUnderwritingFormIsVisible"
  nzTitle="Underwriting - Actualizar" (nzOnCancel)="isUnderwritingFormIsVisible=false"
  (nzOnOk)="isUnderwritingFormIsVisible=false" [nzFooter]="null">
  <ng-container *nzModalContent>
    <app-underwriting-update-form [customer]="this.applicationDetail.customer" [application]="this.applicationDetail"
      [underwriting]="this.underwritingDetail"
      (formSubmitted)="this.updateUnderwriting($event)"></app-underwriting-update-form>
  </ng-container>
</nz-modal>
<nz-modal [nzWidth]="800" [nzMaskClosable]="false" [(nzVisible)]="isDeyFormVisible" nzTitle="Denegar Aplicación"
  (nzOnCancel)="isDeyFormVisible=false" (nzOnOk)="isDeyFormVisible=false" [nzFooter]="null">
  <ng-container *nzModalContent>
    <app-deny-application-form [application]="this.applicationDetail" (formSubmitted)="this.denyApplication($event)"
      [denyReasons]="denyReasons"></app-deny-application-form>
  </ng-container>
</nz-modal>
<nz-modal [nzWidth]="800" [nzMaskClosable]="false" [(nzVisible)]="isNewRentalFormVisible" nzTitle="Denegar Aplicación"
  (nzOnCancel)="isNewRentalFormVisible=false" (nzOnOk)="isNewRentalFormVisible=false" [nzFooter]="null">
  <ng-container *nzModalContent>
    <app-new-rental-form [customer]="this.applicationDetail.customer"
      (formSubmitted)="createNewRental($event)"></app-new-rental-form>
  </ng-container>
</nz-modal>
<nz-modal [nzWidth]="800" [nzMaskClosable]="false" [(nzVisible)]="isNewTokuCustomerFormVisible" nzTitle="Deposito de garantía"
  (nzOnCancel)="isNewTokuCustomerFormVisible=false" (nzOnOk)="isNewTokuCustomerFormVisible=false" [nzFooter]="null">
  <ng-container *nzModalContent>
    <app-new-toku-customer [customer]="this.applicationDetail.customer"
      (formSubmitted)="createNewTokuCustomer($event)"></app-new-toku-customer>
  </ng-container>
</nz-modal>