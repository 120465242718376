<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <nz-form-item>
    <nz-form-label [nzSpan]="9" nzRequired>Total Amount</nz-form-label>
    <nz-form-control nzErrorTip="Please type a valid amount" nzSpan="15">
      <input nzSize="large" type="number" min="1" nz-input placeholder="" formControlName="totalAmount" />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="9" nzRequired>Weeks to differ</nz-form-label>
    <nz-form-control nzErrorTip="Please type a valid time duration in weeks" nzSpan="15">
      <input nzSize="large" type="number" min="1" nz-input placeholder="Number of weeks" formControlName="time" />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="9" nzRequired>Charge Type</nz-form-label>
    <nz-form-control nzErrorTip="Please select the charge type for this paydown" nzSpan="15">
      <nz-select nzSize="large" formControlName="chargeTypeId" nzPlaceHolder="Choose charge type">
        <nz-option *ngFor="let charge of chargeTypes" [nzValue]="charge.id" [nzLabel]="charge.name"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="9" nzRequired>Start Date</nz-form-label>
    <nz-form-control nzErrorTip="Specify the start date (date to apply first charge)" nzSpan="15">
      <input nzSize="large" nz-input type="date" formControlName="startDate" />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-control [nzSpan]="12" [nzOffset]="6">
      <button type="submit" nz-button nzType="primary" nzBlock nzSize="large" >Enviar</button>
    </nz-form-control>
  </nz-form-item>

</form>
