import { Component, Input, OnInit } from '@angular/core';
import { WeebFleetResponse } from '../../domain/model/customer/weeb-fleet-response';

@Component({
  selector: 'app-web-fleet',
  templateUrl: './web-fleet.component.html',
  styleUrls: ['./web-fleet.component.css']
})
export class WebFleetComponent implements OnInit {
  @Input() WeebFleetInformation : WeebFleetResponse[];
  constructor() { }

  ngOnInit(): void {
  }

}
