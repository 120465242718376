import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import UpdateCustomerRequest from '../model/onboarding/request/UpdateCustomerRequest';

const endpoint = environment.onboarding_endpoint;
const endpointOracleCustomer = environment.nave_core_customer_oracle;

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpClient) { }

  updateCustomer(customerId: string, body: UpdateCustomerRequest): Observable<any> {
    return this.http.put(`${endpoint}customers/${customerId}`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  findCustomer(customerId: string): Observable<any> {
    return this.http.get(`${endpoint}customers/${customerId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  findCustomerTaxNotifications(): Observable<any> {
    return this.http.get(`${endpointOracleCustomer}/customer/all/taxes/info/notifications`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  sendCustomerToBlacklistSat(customerInfo: any): Observable<any> {
    return this.http.post(`${endpointOracleCustomer}/customer/${customerInfo.id}/info`, customerInfo, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
}
