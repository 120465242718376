<nz-card *ngIf="carInformation">
    <div *ngFor="let i of carInformation">
        <div nz-row *ngIf="i!==null">
            <div nz-col nzSpan="12">
                <p><span style="font-weight: 600;">Placa: </span> {{i.plate}}</p>
                <p><span style="font-weight: 600;">Vin: </span> {{i.vin}}</p>
                <p><span style="font-weight: 600;">Número del motor: </span> {{i.engine_number}}</p>
                <p><span style="font-weight: 600;">Fecha de entrega: </span> {{ i.delivery_date ?
                    i.delivery_date.substring(0, 10) : '' }}</p>
                <p><span style="font-weight: 600;">Estado del vehículo: </span> {{i.car_status_code}}</p>
                <p><span style="font-weight: 600;">Modelo: </span> {{i.car_model_code}}</p>
                <p><span style="font-weight: 600;">Ubicación: </span> {{i.car_location_code}}</p>
            </div>
            <div nz-col nzSpan="12">
                <p><span style="font-weight: 600;">Ubicación: </span> {{i.car_location_code}}</p>
                <p><span style="font-weight: 600;">Año: </span> {{i.year}}</p>
                <p><span style="font-weight: 600;">Color: </span> {{i.color}}</p>
                <p><span style="font-weight: 600;">Marca: </span> {{i.branch}}</p>
                <p><span style="font-weight: 600;">Poliza: </span> {{i.poliza}}</p>
                <p><span style="font-weight: 600;">Endoso: </span> {{i.endoso}}</p>
                <p><span style="font-weight: 600;">Inciso: </span> {{i.inciso}}</p>
            </div>
        </div>
    </div>
</nz-card>

<nz-card nzTitle="WeebFleet" *ngIf="WeebFleetInformation">
    <div *ngFor="let i of WeebFleetInformation" style="overflow-y: scroll; height: 80px;">
        <!-- <p>created_at: {{i.created_at}}</p> -->
        <!-- <p><span style="font-weight: 600;">directión: </span> {{i.direction}}</p> -->
        <p><span style="font-weight: 600;">Estado de encendido: </span> {{ i.ignition == 1 ? 'Encendido' : 'Apagado'}}
        </p>
        <p><span style="font-weight: 600;">Tiempo de encendido: </span> {{i.ignition_time}}</p>
        <!-- <p><span style="font-weight: 600;"></span>lastmsgid: {{i.lastmsgid}}</p>
        <p><span style="font-weight: 600;"></span>latitude_microdeg: {{i.latitude_microdeg}}</p>
        <p><span style="font-weight: 600;"></span>longitude_microdeg: {{i.longitude_microdeg}}</p>
        <p><span style="font-weight: 600;"></span>objectuid: {{i.objectuid}}</p> -->
        <p><span style="font-weight: 600;">Odometro: </span> {{i.odometer | number}} Kms</p>
        <p><span style="font-weight: 600;">Placa: </span>{{i.plate}}</p>
        <p><span style="font-weight: 600;">Hora de posicion: </span> {{i.pos_time}}</p>
        <p><span style="font-weight: 600;">Posición: </span> {{i.postext}}</p>
        <p><span style="font-weight: 600;">Velocidad: </span> {{i.speed}}</p>
        <p><span style="font-weight: 600;">Parado: </span> {{i.standstill}}</p>
        <p><span style="font-weight: 600;">Estado: </span> {{i.status}}</p>
    </div>
</nz-card>