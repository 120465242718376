import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import SignaturePad from 'signature_pad';
import Swal from 'sweetalert2';
import { CarInformationResponse } from '../../../../domain/model/customer/car-information-response.model';
import { Observation } from '../../../../interfaces/fleet/observation.interface';
import { BackofficeCoreService } from '../../../../services/backoffice-core.service';
import { CollectionsService } from '../../../../services/collections.service';
import { Router } from "@angular/router";

interface Charge {
  charge_id: string;
  customer_id: string;
  charge_type: string;
  amount: number;
  date: Date;
}

@Component({
  selector: 'app-car-reception-component',
  styleUrls: ['./car-reception.component.css'],
  templateUrl: './car-reception.component.html'
})
export class CarReceptionComponent implements OnInit {
  @ViewChild("canvassignaturecustomer", { static: true }) canvasCustomer: ElementRef;
  signatureCustomer: SignaturePad;
  @ViewChild("canvassignaturesupervisor", { static: true }) canvasSupervisor: ElementRef;
  signatureSupervisor: SignaturePad;
  recepForm: FormGroup;
  observationForm: FormGroup;
  tools_form: FormGroup;
  signatureForm: FormGroup;
  returnForm: FormGroup;
  tireBrand: FormGroup;
  imageEndpoint;
  rightmirror: boolean = true;
  windshield: boolean = true;
  oilbayonet: boolean = true;
  lefttmirror: boolean = true;
  rearmedallion: boolean = true;
  crosskey: boolean = true;
  rearviewmirror: boolean = true;
  doorglass: boolean = true;
  hydraulicjack: boolean = true;
  rug: boolean = true;
  connector: boolean = true;
  frontplate: boolean = true;
  cleaners: boolean = true;
  headlights: boolean = true;
  backplate: boolean = true;
  horn: boolean = true;
  moldings: boolean = true;
  policy: boolean = true;
  visors: boolean = true;
  skulls: boolean = true;
  handles: boolean = true;
  towhook: boolean = true;
  defenses: boolean = true;
  verificationdoc: boolean = true;
  seatbelts: boolean = true;
  grill: boolean = true;
  ac: boolean = true;
  antenna: boolean = true;
  sparetire: boolean = true;
  safetytriangle: boolean = true;
  radio: boolean = true;
  wheelcaps: boolean = true;
  securitystuds: boolean = true;
  handbrake: boolean = true;
  gascap: boolean = true;
  plasticpartition: boolean = true;
  radiatorcap: boolean = true;
  oilplug: boolean = true;
  tagcheck: boolean = true;
  batterybrandcheck: boolean = true;

  rightside = {
    good: false,
    regular: false,
    bad: false,
  }

  trunk = {
    good: false,
    regular: false,
    bad: false,
  }

  leftside = {
    good: false,
    regular: false,
    bad: false,
  }
  paint = {
    good: false,
    regular: false,
    bad: false,
  }
  chest = {
    good: false,
    regular: false,
    bad: false,
  }

  alarmsystem = {
    good: false,
    regular: false,
    bad: false,
  }
  awning = {
    good: false,
    regular: false,
    bad: false,
  }
  clothing = {
    good: false,
    regular: false,
    bad: false,
  }
  cleaninside = {
    good: false,
    regular: false,
    bad: false,
  }
  cleanoutside = {
    good: false,
    regular: false,
    bad: false,
  }

  rightfront = {
    new: false,
    medium: false,
    quarter: false,
    smooth: false
  }
  rearleft = {
    new: false,
    medium: false,
    quarter: false,
    smooth: false
  }
  leftfront = {
    new: false,
    medium: false,
    quarter: false,
    smooth: false
  }
  repair = {
    new: false,
    medium: false,
    quarter: false,
    smooth: false
  }
  rightrear = {
    new: false,
    medium: false,
    quarter: false,
    smooth: false
  }

  signaturePad: SignaturePad;
  plate: string;
  carInformation: CarInformationResponse;
  applications: any;
  activeRentals: any;
  customer: any;
  signatureCustomerisHidden: boolean = false;
  isVisible: boolean = false;
  isPageLoaded: boolean = false;
  observations: Observation[] = [];
  pageSize: number = 50;
  previewImage: string | undefined = '';
  previewVisible = false;
  camModalVisible: boolean = false;
  transactions: Charge[] = [];
  balance: number = 0;
  securityDepositAccountBalance: number = 0;
  tempBalances = {};
  tempBalance = this.balance;
  isCreateNewChargeFormVisible: boolean = false;
  totalAmount: number = 0;
  customerId: string;
  i: number = 0;
  previousBalance: number = 0;
  inputGasoline: string;
  outputGasoline: string;
  url: string;

  constructor(
    private route: ActivatedRoute,
    private carInfoService: CollectionsService,
    private coreService: BackofficeCoreService,
    private location: Location,
    private router: Router,
  ) {
    this.route.params.subscribe((params) => {
      this.plate = params.id;
    });
  }


  ngOnInit(): void {
    this.signatureCustomer = new SignaturePad(this.canvasCustomer.nativeElement);
    this.signatureSupervisor = new SignaturePad(this.canvasSupervisor.nativeElement);
    this.signatureForm = new FormGroup({
      supervisor: new FormControl(null, Validators.required)
    });
    this.tireBrand = new FormGroup({
      brand: new FormControl(null, Validators.required),
    });
    this.tools_form = new FormGroup({
      tag: new FormControl(null, Validators.required),
      battery_branch: new FormControl(null, Validators.required),
    });
    this.observationForm = new FormGroup({
      observation: new FormControl(null, Validators.required),
    });
    this.recepForm = new FormGroup({
      type: new FormControl('' || null, Validators.required),
    });
    this.fetchCustomerInformation();
    this.returnForm = new FormGroup({
      nopolicy: new FormControl(null, Validators.required),
      arrivaldate: new FormControl(null, Validators.required),
      departuredate: new FormControl(null, Validators.required),
      arrivalmileage: new FormControl(null, Validators.required),
      outputmileage: new FormControl(null, Validators.required),
    });
    this.isPageLoaded = true;

  }

  getBalance(index: number, data: Charge) {
    let previousBalance = index != 0 ? this.previousBalance : (this.transactions[0] === undefined ? 0 : this.transactions[0].amount);
    const currentBalance = (index != 0 ? previousBalance : 0) + (data.amount === undefined ? 0 : data.amount);
    this.previousBalance = currentBalance;
    return currentBalance;
  }

  onSubmit(): void {
    this.createRequest();
    Swal.fire({
      icon: 'warning',
      title: '¿Estas seguro de crear un documento de inspección?',
      width: '800px',
      showCancelButton: true,
      confirmButtonText: 'Si, crear documento de inspección',
      cancelButtonText: 'Cancelar'
    })
      .then(async (result) => {
        console.log('result :>> ', result);
        const documentData = this.createRequest();
        await this.sendDataForCreateReceiptDocument(documentData)
        if (result.isConfirmed)
          this.router.navigate(['/fleet/cars']);
      });
  }

  createDocumentReceiptByCar(): void {
    console.log('object :>> obs', this.observationForm);
    console.log('this.o :>> ', this.observations);
  }

  createRequest(): any {
    var objetoBack = {
      customer_id: this.customerId,
      customer_name: this.customer.firstName + ' ' + this.customer.lastName,
      customer_phone: this.customer.phone,
      customer_email: this.customer.email,
      branch: this.carInformation.branch,
      model: this.carInformation.car_model_code,
      circulation_card: this.carInformation.circulation_card,
      plate: this.carInformation.plate,
      vin: this.carInformation.vin,
      color: this.carInformation.color,
      policy_number: this.returnForm.controls.nopolicy.value,
      departure_date: this.returnForm.controls.departuredate.value,
      arrival_date: this.returnForm.controls.arrivaldate.value,
      output_mileage: this.returnForm.controls.outputmileage.value,
      arrival_mileage: this.returnForm.controls.arrivalmileage.value,
      right_side_mirror: this.rightmirror,
      left_side_mirror: this.lefttmirror,
      rear_view_mirror: this.rearviewmirror,
      mats: this.rug,
      cleaners: this.cleaners,
      claxon: this.horn,
      visors: this.visors,
      tow_hook: this.towhook,
      seatBelts: this.seatbelts,
      antenna: this.antenna,
      radio: this.radio,
      hand_brake: this.handbrake,
      radiator_Cap: this.radiatorcap,
      windshield: this.windshield,
      rear_medallion: this.rearmedallion,
      door_glass_side: this.doorglass,
      connector: this.connector,
      headlights_and_lights: this.headlights,
      moldings: this.moldings,
      vehicle_skulls: this.skulls,
      fenders: this.defenses,
      grill: this.grill,
      spare_tire: this.sparetire,
      wheel_caps: this.wheelcaps,
      gas_cap: this.gascap,
      oil_plug: this.oilplug,
      oil_bayonet: this.oilbayonet,
      cross_key: this.crosskey,
      jack: this.hydraulicjack,
      front_plate: this.frontplate,
      back_plate: this.backplate,
      Policy: this.policy,
      handles: this.handles,
      verification_document: this.verificationdoc,
      ac: this.ac,
      safety_triangle: this.safetytriangle,
      security_studs: this.securitystuds,
      plastic_partition: this.plasticpartition,
      battery_brand: this.batterybrandcheck,
      battery_brand_text: this.tools_form.controls.battery_branch.value,
      tag: this.tagcheck,
      tag_text: this.tools_form.controls.tag.value,
      right_side: Object.keys(this.rightside).filter(opcion => this.rightside[opcion]).join(", "),
      trunk: Object.keys(this.trunk).filter(opcion => this.trunk[opcion]).join(", "),
      left_side: Object.keys(this.leftside).filter(opcion => this.leftside[opcion]).join(", "),
      paint: Object.keys(this.paint).filter(opcion => this.paint[opcion]).join(", "),
      chest: Object.keys(this.chest).filter(opcion => this.chest[opcion]).join(", "),
      alarm_system: Object.keys(this.alarmsystem).filter(opcion => this.alarmsystem[opcion]).join(", "),
      awning: Object.keys(this.awning).filter(opcion => this.awning[opcion]).join(", "),
      clothing: Object.keys(this.clothing).filter(opcion => this.clothing[opcion]).join(", "),
      clean_inside: Object.keys(this.cleaninside).filter(opcion => this.cleaninside[opcion]).join(", "),
      clean_outside: Object.keys(this.cleanoutside).filter(opcion => this.cleanoutside[opcion]).join(", "),
      right_front_tire: Object.keys(this.rightfront).filter(opcion => this.rightfront[opcion]).join(", "),
      rear_left_tire: Object.keys(this.rearleft).filter(opcion => this.rearleft[opcion]).join(", "),
      left_front_tire: Object.keys(this.leftfront).filter(opcion => this.leftfront[opcion]).join(", "),
      repair_tire: Object.keys(this.repair).filter(opcion => this.repair[opcion]).join(", "),
      right_rear_tire: Object.keys(this.rightrear).filter(opcion => this.rightrear[opcion]).join(", "),
      tire_brand: this.tireBrand.controls.brand.value,
      input_gasoline: this.inputGasoline,
      output_gasoline: this.outputGasoline,
      observations: this.observations,
      drivers_signature: this.signatureCustomer.toDataURL(),
      representatives_signature: this.signatureSupervisor.toDataURL(),
      supervisor: this.signatureForm.controls.supervisor.value,
      transactions: this.transactions,
      totalAmount: this.totalAmount,
      car_id: this.carInformation.id
    };
    console.log(objetoBack);
    return objetoBack;
  }

  clearSignatureCustomer(): void {
    this.signatureCustomer.clear();
  }

  clearSignatureSupervisor(): void {
    this.signatureSupervisor.clear();
  }

  async sendDataForCreateReceiptDocument(documentData) {
    await this.carInfoService.createDocumentReceipt(documentData).toPromise().then((response) => {
      this.url = response.url;
    })
    await window.open(this.url, '_blank');
  }

  async fetchCustomerInformation(): Promise<void> {
    await this.carInfoService
      .CarInformationByPlate(this.plate)
      .toPromise()
      .then((carInformation: CarInformationResponse) => {
        this.carInformation = carInformation;
      });

    await this.coreService
      .getActiveCustomers()
      .toPromise()
      .then((rentals) => {
        this.activeRentals = rentals;
        for (const r of this.activeRentals) {
          if (r.rentalCarId === this.carInformation.id) {
            this.customer = r;
            this.customerId = r.customerId;
          }
        }
      });
  }

  openSignatureModal(): void {
    this.isVisible = !this.isVisible;
  }

  handleCancel(): void {
    this.isVisible = false;
    const dataUrl = this.signatureCustomer.toDataURL();
  }

  handleOkReception(): void {
    if (this.recepForm.valid) {
      this.isPageLoaded = false;
    }

  }

  handleCancelReception(): void {
    this.location.back();
  }

  addObservation(observation: string) {
    if (observation.trim() != "") {
      this.observations.push({
        observation: observation,
        file: [],
      });
      this.observationForm.reset();
    }
  }

  removeObservation(observation: Observation) {
    const index = this.observations.indexOf(observation);
    if (index >= 0) {
      this.observations.splice(index, 1);
    }
  }

  daysDiff = (dueDate): number => {
    const current = Date.parse(dueDate);
    const today = new Date();
    const diff = differenceInCalendarDays(current, today);
    return diff;
  };

  handleNewChargeRequest(event) {
    this.isCreateNewChargeFormVisible = false;

    this.transactions = [
      ...this.transactions,
      {
        charge_id: event.charge_id,
        customer_id: event.customer_id,
        charge_type: event.charge_type,
        amount: event.amount,
        date: event.date
      }
    ];
    this.totalAmount = this.transactions.reduce((acc, curr) => acc + curr.amount, 0);
  }

  deleteCharge(charge_id: string, index: number): void {
    this.transactions.splice(index, 1);
    this.totalAmount = this.transactions.reduce((acc, curr) => acc + curr.amount, 0);
  }

  validateDocument(): boolean {
    const isTagEmpty = !this.tools_form.controls['tag'].value;
    const isBatteryBrandEmpty = !this.tools_form.controls['battery_branch'].value;
    const tag = this.tagcheck === true ? (
      isTagEmpty === true ? true : false
    ) : false;
    const batterybrand = this.batterybrandcheck === true ? (
      isBatteryBrandEmpty === true ? true : false
    ) : false;

    if (!this.returnForm.valid || tag || batterybrand
      || (this.inputGasoline === undefined ? true : false) || (this.inputGasoline === undefined ? true : false)
    ) {
      return true;
    } else {
      return false;
    }
  }
}
