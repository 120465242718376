import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const endpoint = environment.nave_core_customer_oracle;

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(private httpClient: HttpClient) { }

  public getCustomerInvoiceStatus(customerId: string): Observable<any> {
    return this.httpClient.get(`${endpoint}invoice/check/${customerId}`, {}).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    )
  }

  public fetchInvoiceStatusByCustomerId(customerId: string): Observable<any> {
    return this.httpClient.get(`${endpoint}invoice/invoiceStatus/${customerId}`, {}).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    )
  }

}
