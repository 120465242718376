import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import {Pagesv2Component} from './pagesv2.component';
import {AuthGuard} from '../guards/auth.guard';
import {InteractionsBoardComponent} from './interactions/interactions-board/interactions-board.component';
import {RentalAgreementsPageComponent} from './agreements/rental-agreements-page/rental-agreements-page.component';
import { LeaseAgreementsPageComponent } from './agreements/lease-agreements-page/lease-agreements-page.component';
import { ApprovedCustomersPageComponent } from './leads/approved-customers-page/approved-customers-page.component';
import { LeadsPageComponent } from './leads/leads-page/leads-page.component';
import { MyLeadsPageComponent } from './leads/my-leads-page/my-leads-page.component';

const routes: Routes = [
  {
    path: 'bos',
    component: Pagesv2Component,
    canActivate: [ AuthGuard ],
    children: [
      // { path: '', component: OpenApplicationsComponent },
      { path: 'interacciones', component: InteractionsBoardComponent },
      { path: 'agreements/rentalAgreements', component: RentalAgreementsPageComponent },
      { path: 'agreements/leaseAgreements', component: LeaseAgreementsPageComponent },
      { path: 'leads/approvedCustomers', component: ApprovedCustomersPageComponent },
      { path: 'leads/leads', component: LeadsPageComponent },
      { path: 'leads/myLeads', component: MyLeadsPageComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class Pagesv2RoutingModule {}
