import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import NewChargeRequest from "../../../../model/billing/request/NewChargeRequest";
import * as uuid from 'uuid';
import * as moment from 'moment';

@Component({
  selector: 'app-fleet-charges-form',
  templateUrl: './fleet-charges-form.component.html',
  styleUrls: ['./fleet-charges-form.component.css']
})
export class FleetChargesFormComponent implements OnInit {
  newChargeForm: FormGroup;
  @Input() chargeTypes = [];
  @Input() customerId;
  @Output() formSubmitted: EventEmitter<NewChargeRequest> = new EventEmitter<NewChargeRequest>();
  constructor() { }

  ngOnInit(): void {
    this.newChargeForm = new FormGroup({
      chargeType: new FormControl(null, [Validators.required]),
      amount: new FormControl(null, [Validators.required]),
    });
  }

  onSubmit(): void {
    if (this.newChargeForm.valid) {
      const actualDate = moment.utc();
      const request = {
        charge_id: uuid.v4(),
        customer_id: this.customerId,
        charge_type: this.newChargeForm.controls.chargeType.value,
        amount: this.newChargeForm.controls.amount.value,
        date: actualDate.format('YYYY-MM-DD'),
      }
      this.formSubmitted.emit(request);
    }
  }

}
