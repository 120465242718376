import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CarService } from "../../../services/car.service";
import { RentalService } from "../../../services/rental.service";
import { NotificationService } from "../../../services/notification.service";
import { BackofficeCoreService } from "../../../services/backoffice-core.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-pause-car',
  templateUrl: './pause-car.component.html',
  styleUrls: ['./pause-car.component.css']
})
export class PauseCarComponent implements OnInit {

  rentalId;
  form: FormGroup;
  rental: any;
  isFormVisible = false;
  availableCars = [];
  constructor(private carService: CarService,
    private rentalService: RentalService,
    private notificationService: NotificationService,
    private coreService: BackofficeCoreService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      switchDate: new FormControl(null, [Validators.required]),
      note: new FormControl()
    });
    this.fetchAvailableCars();
    this.route.params.subscribe(
      params => {
        this.rentalId = params.id;
        this.coreService.getRentalAgreementById(this.rentalId).subscribe(value => {
          this.rental = value;
        });
      }
    );
  }
  onSubmit(): void {
    if (this.form.valid) {
      this.coreService.pauseCar(this.rentalId, {
        pauseDate: this.form.controls.switchDate.value,
        notes: this.form.controls.note.value
      }).subscribe(
        (res) => {
          this.notificationService.createNotification('success', 'Cambio de la renta exitoso', 'El cambio de vehículo se ha realizado con éxito');
          this.router.navigate(['/agreements/active/rental']);
        }, error => {
          this.notificationService.createNotification('error', 'Error', error.error.errorMessage);
        }
      );
    }
  }
  fetchAvailableCars(): void {
    this.carService.fetchCarsByStatus('AVAILABLE').subscribe(
      (res) => {
        this.availableCars = res;
      }
    );
  }

}
