<div class="table-responsive">
  <table class="table">
    <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Nombre</th>
      <th scope="col">Tipo</th>
      <th scope="col">Area</th>
      <th scope="col">Status</th>
      <th scope="col">Creador</th>
      <th scope="col">Creado</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th scope="row">1</th>
      <td>RODRIGO LOPEZ TIOL</td>
      <td><span class="badge rounded-pill bg-info text-dark">Falta de pago</span></td>
      <td><span class="badge rounded-pill bg-warning text-dark">Cobranza</span></td>
      <td><span class="badge rounded-pill bg-success text-dark">Abierto</span></td>
      <td>griscelda</td>
      <td>2022-02-24 15:27</td>
      <td>
        <div class="btn-group dropstart">
          <button type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            Dropstart
          </button>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="#">Menu item</a></li>
            <li><a class="dropdown-item" href="#">Menu item</a></li>
            <li><a class="dropdown-item" href="#">Menu item</a></li>
          </ul>
        </div>
      </td>
    </tr>
    <tr>
      <th scope="row">2</th>
      <td>Jacob</td>
      <td>Thornton</td>
      <td>@fat</td>
    </tr>
    <tr>
      <th scope="row">3</th>
      <td>Larry</td>
      <td>the Bird</td>
      <td>@twitter</td>
    </tr>
    </tbody>
  </table>
</div>
