import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import NewChargeRequest from "../../../../model/billing/request/NewChargeRequest";

@Component({
  selector: 'app-new-charge-form',
  templateUrl: './new-charge-form.component.html',
  styles: [
  ]
})
export class NewChargeFormComponent implements OnInit {
  newChargeForm: FormGroup;
  @Input() chargeTypes = [];
  @Input() customerId;
  @Output() formSubmitted: EventEmitter<NewChargeRequest> = new EventEmitter<NewChargeRequest>();
  constructor() { }

  ngOnInit(): void {
    this.newChargeForm = new FormGroup({
      chargeType: new FormControl(null, [Validators.required]),
      amount: new FormControl(null, [Validators.required]),
      dueDate: new FormControl(null, [Validators.required]),
      notes: new FormControl()
    });
  }
  onSubmit(): void {
    if (this.newChargeForm.valid) {
      const request = new NewChargeRequest(
        this.customerId,
        this.newChargeForm.controls.chargeType.value,
        this.newChargeForm.controls.amount.value,
        this.newChargeForm.controls.dueDate.value,
        this.newChargeForm.controls.notes.value
      );
      this.formSubmitted.emit(request);
    }
  }

}
