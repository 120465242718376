import {Adapter} from '../adapter';
import {Injectable} from '@angular/core';

export class Transaction {
  constructor(
    public transactionId: string,
    public transactionAmount: number,
    public transactionAppliedAt: number,
    public transactionPaymentId: string,
    public transactionPaymentAmount: number,
    public transactionPaymentDueDate: Date,
    public transactionPaymentMethodCode: string,
    public transactionPaymentMethodName: string,
    public transactionPaymentCategoryCode: string,
    public transactionChargeId: string,
    public transactionPaymentCustomerId: string,
    public transactionChargeCustomerId: string,
    public transactionChargeTypeCode: string,
    public transactionChargeTypeName: string,
    public transactionChargeAmount: number,
    public transactionChargeDueDate: Date,
    public transactionChargePendingAmountToCover: number,
    public transactionChargeAmountCovered: number,
    public transactionRentalAgreementUuid: string,
    public transactionCarUuid: string,
    public transactionCarPlate: string
  ) {}
}

@Injectable({
  providedIn: 'root',
})
export class TransactionAdapter implements Adapter<Transaction> {
  adapt(item: any): Transaction {
    return new Transaction(
      item.transactionId,
      item.transactionAmount,
      item.transactionAppliedAt,
      item.transactionPaymentId,
      item.transactionPaymentAmount,
      new Date(item.transactionPaymentDueDate.replace('-', '/')),
      item.transactionPaymentMethodCode,
      item.transactionPaymentMethodName,
      item.transactionPaymentCategoryCode,
      item.transactionChargeId,
      item.transactionPaymentCustomerId,
      item.transactionChargeCustomerId,
      item.transactionChargeTypeCode,
      item.transactionChargeTypeName,
      item.transactionChargeAmount,
      new Date(item.transactionChargeDueDate.replace('-', '/')),
      item.transactionChargePendingAmountToCover,
      item.transactionChargeAmountCovered,
      item.transactionRentalAgreementUuid,
      item.transactionCarUuid,
      item.transactionCarPlate
    );
  }
}
