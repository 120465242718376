import { Application } from "./Application";

export class Customer {
  constructor(
    public id?: string,
    public firstName?: string,
    public lastName?: string,
    public email?: string,
    public billingEmail?: string,
    public phone?: string,
    public dateOfBirth?: string,
    public curp?: string,
    public rfc?: string,
    public gender?: string,
    public note?: string,
    public folderId?: number,
    public createdAt?: number,
    public updatedAt?: number,
    public taxRegime?: any,
    public cfdiUse?: string,
    public license?: number,
    public oraculeBosId?: string,
    public customerBosId?: string,
    public applications?: Application[],
    public errorInvoice?: string,
    public blacklistSat?: boolean,
    public ocr?: boolean
  ) { }
}
