<nz-divider nzText="Actualizar teléfonos"></nz-divider>
<div style="padding-bottom: 20px;" *ngFor="let phone of phonesArray; let i = index;">
  <nz-card nzType="inner" nzTitle="Teléfono" [nzExtra]="actions">
    <form [formGroup]="phone.form">
      <nz-form-item>
        <nz-form-label nzSize="default" [nzSpan]="6" nzRequired>Tipo de teléfono</nz-form-label>
        <nz-form-control nzSpan="15" nzErrorTip="Por favor selecciona un tipo de teléfono">
          <nz-select formControlName="type">
            <nz-option nzLabel="Teléfono móvil" [nzValue]=1001></nz-option>
            <nz-option nzLabel="Teléfono fijo" [nzValue]=1002></nz-option>
            <nz-option nzLabel="Teléfono de emergencia" [nzValue]=1003></nz-option>
            <nz-option nzLabel="Referencia 1" [nzValue]=1004></nz-option>
            <nz-option nzLabel="Referencia 2" [nzValue]=1005></nz-option>
            <nz-option nzLabel="Referencia 3" [nzValue]=1006></nz-option>
            <nz-option nzLabel="Referencia 4" [nzValue]=1007></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzSize="default" [nzSpan]="6" nzRequired>Tipo de parentesco</nz-form-label>
        <nz-form-control nzSpan="15" nzErrorTip="Por favor selecciona un tipo de parentesco">
          <nz-select formControlName="relationship">
            <nz-option nzLabel="Abuelo - Abuela" [nzValue]=2001></nz-option>
            <nz-option nzLabel="Padre - Madre" [nzValue]=2002></nz-option>
            <nz-option nzLabel="Cónyuge" [nzValue]=2003></nz-option>
            <nz-option nzLabel="Hijo - Hija" [nzValue]=2004></nz-option>
            <nz-option nzLabel="Nieto - Nieta" [nzValue]=2005></nz-option>
            <nz-option nzLabel="Tío - Tía" [nzValue]=2006></nz-option>
            <nz-option nzLabel="Hermano - Hermana" [nzValue]=2007></nz-option>
            <nz-option nzLabel="Primo - Prima" [nzValue]=2008></nz-option>
            <nz-option nzLabel="Sobrino - Sobrina" [nzValue]=2009></nz-option>
            <nz-option nzLabel="Suegra - Suegro" [nzValue]=2010></nz-option>
            <nz-option nzLabel="Vecino 1" [nzValue]=2011></nz-option>
            <nz-option nzLabel="Vecino 2" [nzValue]=2012></nz-option>
            <nz-option nzLabel="Conocido - Conocida" [nzValue]=2013></nz-option>
            <nz-option nzLabel="Amigo - Amiga" [nzValue]=2014></nz-option>
            <nz-option nzLabel="Novio - Novia" [nzValue]=2015></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="6" nzRequired>Nombre(s)</nz-form-label>
        <nz-form-control nzErrorTip="Por favor ingresa el nombre" nzSpan="15">
          <input type="text" nz-input placeholder="Nombre(s)" formControlName="nameRelationship" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="6" nzRequired>Apellidos</nz-form-label>
        <nz-form-control nzErrorTip="Por favor ingresa los apellidos" nzSpan="15">
          <input type="text" nz-input placeholder="Apellidos" formControlName="lastNameRelationship" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzSize="default" [nzSpan]="6" nzRequired>Teléfono</nz-form-label>
        <nz-form-control nzSpan="15" nzErrorTip="Por favor introduce un número de celular valido">
          <nz-input-group nzCompact>
            <nz-select formControlName="phoneCode" style="width: 20%;">
              <nz-option [nzLabel]="'+52'" [nzValue]="'+52'"></nz-option>
            </nz-select>
            <input type="text" nz-input placeholder="número de teléfono" formControlName="phone" style="width: 80%;" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzSize="default" [nzSpan]="6">Teléfono</nz-form-label>
        <nz-form-control nzSpan="15" nzErrorTip="Por favor introduce un número de celular valido">
          <nz-input-group nzCompact>
            <nz-select formControlName="phoneCodeReserve" style="width: 20%;">
              <nz-option [nzLabel]="'+52'" [nzValue]="'+52'"></nz-option>
            </nz-select>
            <input type="text" nz-input placeholder="número de teléfono" formControlName="phoneReserve" style="width: 80%;" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </form>
  </nz-card>
  <ng-template #actions>
    <button nz-button nzType="default" (click)="savePhone(i)" [disabled]="!phone.form.valid"><span nz-icon
        nzType="save"></span></button>
    <button nzDanger nz-popconfirm nzPopconfirmTitle="¿Estas segur@ de eliminar el teléfono?" nzOkText="Confirmar"
      nzCancelText="Cancelar" (nzOnConfirm)="this.deletePhone(i, phone)" nz-button nzType="primary"><span nz-icon
        nzType="delete"></span></button>
  </ng-template>
</div>
<nz-form-control [nzSpan]="24" [nzOffset]="9">
  <button nz-button nzType="dashed" type="button" nzShape="round" nzSize="large" (click)="addPhone()">
    <span nz-icon nzType="plus"></span>Agregar teléfono
  </button>
</nz-form-control>
