import {Component, Input, OnInit} from '@angular/core';
import {Customer} from "../../../model/onboarding/Customer";
import ReturnReason from "../../../model/rental/ReturnReason";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {RentalService} from "../../../services/rental.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {BackofficeCoreService} from "../../../services/backoffice-core.service";
import * as uuid from 'uuid';

@Component({
  selector: 'app-end-rental-agreement',
  templateUrl: './end-rental-agreement.component.html',
  styles: [
  ]
})
export class EndRentalAgreementComponent implements OnInit {
  @Input() customer: Customer;
  endReasons: any[] = [];
  form: FormGroup;
  today = new Date();
  rentalId: string;
  rental: any;
  constructor(
    private route: ActivatedRoute,
    private rentalService: RentalService,
    private notificationService: NzNotificationService,
    private coreService: BackofficeCoreService,
    private router: Router
  ) { }

  ngOnInit(): void {
    /*
    this.route.params.subscribe(
      params => {
        this.rentalId = params.id;
        this.coreService.getRentalAgreementById(this.rentalId).subscribe(value => {
          this.rental = value;
        });
      }
    );
    this.form = new FormGroup({
      endDate: new FormControl(null, [Validators.required]),
      endReason: new FormControl(null, [Validators.required]),
      endType: new FormControl(null, [Validators.required]),
      notes: new FormControl(null, []),
    });
    this.fetchEndReasons();
  }
  fetchEndReasons = () => {
    this.coreService.findAllEndReasons().subscribe(
      (reasons) => {
        this.endReasons = reasons;
      }
    );
    */
  }
  onSubmit = () => {
    /*
    if (this.form.valid) {
      const request = {
        endDate: this.form.controls.endDate.value,
        reasonId: this.form.controls.endReason.value,
        notes: this.form.controls.notes.value,
        endAgreementType: this.form.controls.endType.value,
      };
      this.coreService.endRentalAgreement(this.rental.id, request).subscribe(
        (res) => {
          this.notificationService.create('success', 'Contrato finalizado', `Se ha terminado el contrato de ${this.rental?.customer.firstName} ${this.rental?.customer.lastName}`, { nzDuration: 7000 });
          this.router.navigate(['/agreements/active/rental']);
        }, error => {
          this.notificationService.create('error', 'Error', error.error.errorMessage, { nzDuration: 7000 });
        }
      );
    } */
  }
}
