import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Customer} from '../../../../model/onboarding/Customer';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import Car from '../../../../model/fleet/Car';
import PickupType from '../../../../model/rental/PickupType';
import RentalType from '../../../../model/rental/RentalType';
import CreateNewRentalRequest from '../../../../model/rental/request/CreateNewRentalRequest';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import {RentalService} from '../../../../services/rental.service';
import {CarService} from '../../../../services/car.service';


@Component({
  selector: 'app-new-rental-form',
  templateUrl: './new-rental-form.component.html',
  styles: [
  ]
})
export class NewRentalFormComponent implements OnInit {
  today = new Date();
  @Input() customer!: Customer;
  @Input() cars!: Car[];
  @Input() pickupTypes: PickupType[] = [];
  @Input() agreementTypes: RentalType[] = [];
  @Output() formSubmitted: EventEmitter<CreateNewRentalRequest> = new EventEmitter<CreateNewRentalRequest>();
  form: FormGroup;
  constructor(
    private rentalService: RentalService,
    private carService: CarService
  ) { }
  ngOnInit(): void {
    this.form = new FormGroup({
      amount: new FormControl('', [Validators.required]),
      carSelect: new FormControl('' , [Validators.required]),
      startDate: new FormControl('', [Validators.required]),
      pickupType: new FormControl('', [Validators.required]),
      agreementType: new FormControl('', [Validators.required]),
      lto_term_length: new FormControl('', [Validators.min(0)]),
      lto_payment_amount: new FormControl('', [Validators.min(0)]),
      firstPaymentDate: new FormControl(null, [Validators.required])
    });
    this.fetchAgreementTypes();
    this.fetchPickupTypes();
    this.fetchAvailableCars();
  }
  fetchAgreementTypes = () => {
    this.rentalService.fetchRentalTypes().subscribe(
      (types) => {
        this.agreementTypes = types;
      }
    );
  }
  fetchPickupTypes = () => {
    this.rentalService.fetchPickupTypes().subscribe(
      (types) => {
        this.pickupTypes = types;
      }
    );
  }
  fetchAvailableCars = () => {
    this.carService.fetchCarsByStatus('AVAILABLE').subscribe(
      (cars: Car[]) => {
        this.cars = cars;
      }
    );
  }
  onSubmit(): void {
    if (this.form.valid) {
      const rentalRequest = new CreateNewRentalRequest(
        this.customer.id,
        this.form.controls.carSelect.value,
        this.form.controls.amount.value,
        this.form.controls.startDate.value,
        this.form.controls.agreementType.value,
        this.form.controls.pickupType.value,
        this.form.controls.lto_term_length.value,
        this.form.controls.lto_payment_amount.value,
        this.form.controls.firstPaymentDate.value
      );
      this.formSubmitted.emit(rentalRequest);
    }
  }
  disabledDate = (current: Date): boolean => {
    // Can not select days before 7 days. Can not select days after two days
    const diff = differenceInCalendarDays(current, this.today);
    return !(diff > -50 && diff < 15);
  }
}
