import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import ChargeType from "../../models/ChargeType";

@Component({
  selector: 'app-create-paydown-form',
  templateUrl: './create-paydown-form.component.html',
  styleUrls: ['./create-paydown-form.component.css']
})
export class CreatePaydownFormComponent implements OnInit {
  form: FormGroup;
  @Input() chargeTypes: ChargeType[];
  @Output() submittedForm: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
    this.form = new FormGroup({
      totalAmount: new FormControl(null, [Validators.min(1)]),
      time: new FormControl(null, [Validators.min(1)]),
      startDate: new FormControl(null, [Validators.required]),
      chargeTypeId: new FormControl(null, [Validators.required]),
    });
  }
  onSubmit(): void {
    if (this.form.valid) {
      console.log(this.form.getRawValue());
      this.submittedForm.emit(
        this.form.getRawValue(),
      );
      this.form.reset();
    }
  }

}
