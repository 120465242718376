import { Component, OnInit } from '@angular/core';
import {ApplicationService} from '../../../services/application.service';
import {DocumentsService} from '../../../services/documents.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import OnboardingOperations from '../OnboardingOperations';
import {CustomerService} from "../../../services/customer.service";

@Component({
  selector: 'app-pending-approve-applications',
  templateUrl: './pending-approve-applications.component.html',
  styles: [
  ]
})
export class PendingApproveApplicationsComponent implements OnInit {
  constructor(
    public applicationService: ApplicationService,
    public documentService: DocumentsService,
    public notification: NzNotificationService,
    public customerService: CustomerService,
  ) { }

  ngOnInit(): void {
  }
}
