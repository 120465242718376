<div class="container pt-3">
  <div class="row mb-2">
    <div class="col-6 p-2 d-flex align-items-center">
      <h2 class="m-0">Mis Leads</h2>
    </div>
    <div class="col-6 p-2 d-flex align-items-center justify-content-end">
      <h6 class="m-0 pe-3">Total: {{ leads.length }}</h6>
    </div>
  </div>
  <div class="row">
    <app-search-bar></app-search-bar>
    <div class="table-responsive mt-3">
      <table class="table table-sm table-hover">
        <thead>
          <tr>
            <th scope="col">ID lead</th>
            <th scope="col">Nombre</th>
            <th scope="col">Teléfono</th>
            <th scope="col">Estado</th>
            <th scope="col">Source</th>
            <th scope="col">Creación</th>
            <th scope="col">Ciudad</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let lead of leads">
            <th scope="row">525</th>
            <td>Erick marcelino Diaz rodiguez</td>
            <td>5624352455</td>
            <td>Didn't Take Car</td>
            <td>FORMULARIO UBER</td>
            <td>2022-04-05</td>
            <td>CDMX</td>
            <td>
              <div class="btn-group">
                <button class="btn btn-outline-info btn-sm" type="button" data-bs-toggle="dropdown" aria-expanded="false" (click)="ViewLeadDetail(item)">
                  Ver
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>


