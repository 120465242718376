import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {environment} from '../../../environments/environment';
import { DocumentsService } from '../../services/documents.service';
import { NotificationService } from '../../services/notification.service';
import { InvoiceService } from '../../services/invoice.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
@Component({
  selector: 'app-documents-quick-viewer',
  templateUrl: './documents-quick-viewer.component.html',
  styles: [
  ]
})
export class DocumentsQuickViewerComponent implements OnInit {
  @Input() documents = [];
  @Input() customer: any;
  @Output() isFileUploaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() fileUploaded: EventEmitter<any> = new EventEmitter<any>();
  expandSet = new Set<string>();
  documentsEndpoint = environment.documents_endpoint;
  constructor(private documentsService: DocumentsService,
    private NotificationService: NotificationService,
    private InvoiceService: InvoiceService,
    public notificationService: NzNotificationService) { }

  ngOnInit(): void {
  }
  onExpandChange(id: string, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  getFilesEndpoint(fileId): string {
    return `${this.documentsEndpoint}documents/files/${fileId}`;
  }

  public async fileUploadedSuccess(event, type): Promise<void> {
      if(type === 'RFC_OCR'){
        try {
          const res: any = await this.documentsService.uploadOpticalCharacterRecognizerRFC(this.customer.id, event.originFileObj).toPromise();
          this.NotificationService.createNotification('success', 'Éxito', 'Se actualizó el nombre, rfc. uso de cfdi, régimen fiscal, y código postal del cliente');
          this.fileUploaded.emit(event.originFileObj);
          this.isFileUploaded.emit(true);
          this.validateInvoiceStatus(this.customer.id);
        } catch (error) {
          console.log(error)
          this.NotificationService.createNotification('error', 'Error', 'Hubo un error al actualizar la información de facturación del cliente');
          this.isFileUploaded.emit(false);
        }
      }else{
        this.fileUploaded.emit(event.originFileObj);
      }
  }

  public async validateInvoiceStatus(customerId: string): Promise<void> {
    try {
      const res = await this.InvoiceService.getCustomerInvoiceStatus(customerId).toPromise();
      await this.NotificationService.createNotification('success', 'Estatus de facturación Actualizado', 'Estado de facturación actualizado');
    } catch (error) {
      console.log('response.error :>> ', error.error);
      if(error.error.message){
        this.NotificationService.createNotification('error', 'Ha ocurrido un error al facturar', `${error.error.message}`);
      }else{
        const errors = Object.keys(error.error);
        if (errors.length > 0) {
          const firstKey = errors[0];
          const firstValue = error.error[firstKey];
          this.NotificationService.createNotification('error', 'Ha ocurrido un error al facturar', `${firstValue}`);
        }
      }
    }
  }
}
