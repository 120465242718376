import {Component, Input, OnInit} from '@angular/core';
import Charge from "../../models/Charge";

@Component({
  selector: 'app-charges-table-view',
  templateUrl: './charges-table-view.component.html',
  styleUrls: ['./charges-table-view.component.css']
})
export class ChargesTableViewComponent implements OnInit {
  @Input() charges: Charge[];
  constructor() { }

  ngOnInit(): void {
  }

}
