<nz-page-header class="site-page-header" nzTitle="Contratos de Renta">
  <nz-page-header-content>
    <nz-row>
      <nz-space [nzSize]="'middle'" nzDirection="horizontal">
        <nz-space-item>
          <div *ngIf="!loading" style="text-align: center; padding: 30px 25px;">
            <nz-spin></nz-spin>
          </div>
          <nz-statistic *ngIf="loading" nzTitle="Total" [nzValue]="customers ? customers.length : 0"
            [nzValueStyle]="{ color: '#3F8600' }"></nz-statistic>
        </nz-space-item>
      </nz-space>
      <ng-template #prefixTplOne><i nz-icon nzType="arrow-up"></i></ng-template>
      <ng-template #prefixTplTwo><i nz-icon nzType="arrow-down"></i></ng-template>
    </nz-row>
  </nz-page-header-content>
</nz-page-header>
<br /><br />
<app-search-field (searchSubmitted)="searchCustomer($event)"></app-search-field>
<br /><br />
<nz-table style="overflow-x: scroll;max-height: 600px;" [nzLoading]="!loading" #currentCustomers
  [nzData]="customersDisplay" [nzPageSize]="60">
  <thead>
    <tr>
      <th>Nombre</th>
      <th>Vehículo</th>
      <th>Nave Lead Id</th>
      <th>Nave Customer Id</th>
      <th>Id Contrato</th>
      <th>Teléfono</th>
      <th>Email</th>
      <th>Renta</th>
      <th>Estado</th>
      <th>Inicio</th>
      <th>Dia de pago</th>
      <th>Acciones</th>

    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of currentCustomers.data">
      <td>{{ data.customer.firstName }} {{ data.customer.lastName }}</td>
      <td>{{ data.car.plate }}</td>
      <td>{{ data.customer.oraculeBosId }}</td>
      <td>{{ data.customer_customer_bos_id }}</td>
      <td>{{ data.agreement_id }}</td>
      <td>{{ data.customer.phone }}</td>
      <td>{{ data.customer.email }}</td>
      <td>{{ data.amount }}</td>
      <td *ngIf="data.agreementStatusId===10">Activo</td>
      <td *ngIf="data.agreementStatusId===20">Sustituto</td>
      <td *ngIf="data.agreementStatusId===130">PAUSE</td>
      <td>{{ data.startDate }}</td>
      <td [ngSwitch]="data.dueDay">
        <span *ngSwitchCase="'0'">Lunes</span>
        <span *ngSwitchCase="'1'">Martes</span>
        <span *ngSwitchCase="'2'">Miércoles</span>
        <span *ngSwitchCase="'3'">Jueves</span>
        <span *ngSwitchCase="'4'">Viernes</span>
        <span *ngSwitchCase="'5'">Sábado</span>
        <span *ngSwitchCase="'6'">Domingo</span>
      </td>
      <td>
        <a style="margin-bottom: 5px;margin-right: 5px;" nz-button nzType="primary"
          [routerLink]="'/billing/customer/' + data.customer.id">Abrir
          Perfil</a>
        <button nz-button nz-dropdown [nzDropdownMenu]="menu" *appRbacShow="['ADMIN']">
          Acciones
          <i nz-icon nzType="down"></i>
        </button>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item>
              <a [routerLink]="'/agreements/rental/' + data.id + '/return'">Marcar Return</a>
            </li>
            <li nz-menu-item>
              <a [routerLink]="'/agreements/rental/' + data.id + '/repo'">Marcar Repo</a>
            </li>
            <li nz-menu-item>
              <a [routerLink]="'/agreements/rental/' + data.id + '/switch'">Marcar Switch</a>
            </li>
            <li nz-menu-item>
              <a *ngIf="data.agreementStatusId===10"
                [routerLink]="'/agreements/rental/' + data.id + '/substitute'">Marcar Sustituto</a>
              <a *ngIf="data.agreementStatusId===20"
                [routerLink]="'/agreements/rental/' + data.id + '/substitute'">Marcar Regresar Vehículo Sustituto</a>
            </li>
            <li nz-menu-item>
              <a *ngIf="data.agreementStatusId!==130" [routerLink]="'/agreements/rental/' + data.id + '/pause'">Marcar
                PAUSE</a>
              <a *ngIf="data.agreementStatusId===130" [routerLink]="'/agreements/rental/' + data.id + '/pause'">Marcar
                REANUDAR</a>
            </li>
            <li nz-menu-item>
              <a>Marcar Robo</a>
            </li>
          </ul>
        </nz-dropdown-menu>
      </td>
    </tr>
  </tbody>
</nz-table>