import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import DocumentType from '../model/document/DocumentType';
import CreateDocumentRequest from '../model/document/request/CreateDocumentRequest';
import {environment} from '../../environments/environment';
import { catchError } from 'rxjs/operators';

const endpoint = environment.documents_endpoint;
const ocr_endpoint = environment.ocr_endpoint;

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor(private httpClient: HttpClient) { }
  fetchDocumentsByOwnerId = (ownerId: string) => {
    return this.httpClient.get(endpoint + `documents?ownerId=${ownerId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  fetchDocumentTypes(): Observable<Array<DocumentType>> {
    return this.httpClient.get<Array<DocumentType>>(endpoint + `lookups/documentTypes`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  createDocument(request: CreateDocumentRequest): Observable<any> {
    return this.httpClient.post(endpoint + `documents`, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  fetchAnnonymousDocumentsByOwnerId = (ownerId: string) => {
    return this.httpClient.get(endpoint + `anonymous/documents?ownerId=${ownerId}`);
  }
  public uploadOpticalCharacterRecognizerRFC(customerId: string, file: File): Observable<any>{
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.httpClient.post(`${ocr_endpoint}rfc-ocr/customer/${customerId}`, formData, {
    }).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    )
  }
}
