import { Component, OnInit } from '@angular/core';
import { BackofficeCoreService } from "../../../services/backoffice-core.service";

@Component({
  selector: 'app-active-rental',
  templateUrl: './active-rental.component.html',
  styles: [
  ]
})
export class ActiveRentalComponent implements OnInit {
  customers: any[];
  customersDisplay: any[];
  loading: boolean = false;
  constructor(
    private coreService: BackofficeCoreService
  ) { }
  ngOnInit(): void {

    var allRentals = [];
    this.coreService.getActiveRentalAgreements().subscribe(value => {
      this.customers = value;
      if (value) {
        this.loading = true;
      }
      allRentals = value;
      this.coreService.getPauseRentalAgreements().subscribe(value => {
        if (value) {
          this.loading = true;
        }
        allRentals.push(...value);
        this.customersDisplay = allRentals;
      });
      this.coreService.getSubstituteRentalAgreements().subscribe(value => {
        if (value) {
          this.loading = true;
        }
        allRentals.push(...value);
        this.customersDisplay = allRentals;
      });

      this.coreService.getAllAgreementsNaveId().subscribe(agreements => {
        if (agreements) {
          this.loading = true;
        }
        const allMergedRentals = [];
        agreements.forEach((agreement) => {
          allRentals.forEach((rental) => {
            if (Number(agreement.id) === rental.id) {
              const mergedItem = { ...agreement, ...rental }
              if (mergedItem.is_lto) {
                mergedItem.agreement_id = mergedItem.rentalLtoBosId
              } else {
                mergedItem.agreement_id = mergedItem.rentalBosId
              }
              allMergedRentals.push(mergedItem)
            }
          });
        });

        this.customersDisplay = allMergedRentals;
        this.customers = allMergedRentals;
      });


    });
  }

  searchCustomer(search): void {
    search = search.toUpperCase();
    if (search.length === 0) {
      this.customersDisplay = this.customers;
    } else
      this.customersDisplay = this.customers.filter(
        value => ''.concat(value.customer.firstName, ' ', value.customer.lastName).toUpperCase().includes(search) || value.car.plate === search || value.customer.oraculeBosId === search || value.customer_customer_bos_id === search || value.agreement_id === search || value.customer_id === search.toLowerCase() 
      );
  }
}
