<nz-divider [nzText]="'Enviar conductor '.concat(application.customer.firstName, ' ', application.customer.lastName, ' a Didnt Take a Car')"></nz-divider>

<form [formGroup]="didntTakeCarForm" (ngSubmit)="onSubmit()">
  <nz-form-item>
    <nz-form-label [nzSpan]="6" nzRequired>Razón</nz-form-label>
    <nz-form-control nzErrorTip="Por favor selecciona una razón" nzSpan="18">
      <nz-select nzSize="large" formControlName="reasonSelect" nzPlaceHolder="Selecciona una razón">
        <nz-option *ngFor="let reason of didntTakeCarReasons" [nzValue]="reason.reasonCode" [nzLabel]="reason.name"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="6">Nota</nz-form-label>
    <nz-form-control nzErrorTip="" nzSpan="18">
      <textarea nzSize="large" nz-input placeholder="Notas" formControlName="note"></textarea>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control [nzSpan]="12" [nzOffset]="6">
      <button type="submit" nz-button nzType="primary" nzBlock nzSize="large" >Enviar</button>
    </nz-form-control>
  </nz-form-item>
</form>

