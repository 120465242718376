import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import Transaction from "../../../model/billing/Transaction";
import {CollectionsService} from "../../../services/collections.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import EditPaymentRequest from "../../../model/billing/request/EditPaymentRequest";

@Component({
  selector: 'app-edit-payment-form',
  templateUrl: './edit-payment-form.component.html',
  styles: [
  ]
})
export class EditPaymentFormComponent implements OnInit {
  form: FormGroup;
  @Input() transaction: Transaction;
  @Output() formSubmitted: EventEmitter<EditPaymentRequest> = new EventEmitter<EditPaymentRequest>();

  constructor(private collectionsService: CollectionsService,
              public notificationService: NzNotificationService) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      amount: new FormControl(Math.abs(this.transaction?.amount)),
      description: new FormControl(this.transaction?.note),
      dueDate: new FormControl(this.transaction?.dueDate),
    });
  }
  onSubmit(): void  {
    const request = {
      paymentId: this.transaction.id,
      dueDate: this.form.controls.dueDate.value,
      amount: this.form.controls.amount.value,
      description: this.form.controls.description.value,
      updatedBy: localStorage.getItem('userLoggedId')
    };
    this.collectionsService.editPayment(request).subscribe(
      res => {
        this.createNotification('success', 'Edit payment success', 'Payment has been edited successfully');
        this.formSubmitted.emit(request);
      }, error => {
        this.createNotification('error', 'Edit payment failed', 'Error updating payment, please try again');
      }
    );
  }
  createNotification(type: string, title: string, message: string): void {
    this.notificationService.create(
      type,
      title,
      message,
      { nzDuration: 7000 }
    );
  }
}
