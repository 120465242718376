import { Component, OnInit } from '@angular/core';
import {ApplicationService} from '../../../services/application.service';
import {DocumentsService} from '../../../services/documents.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import OnboardingOperations from '../OnboardingOperations';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {CustomerService} from '../../../services/customer.service';
import {RentalService} from "../../../services/rental.service";
import {CustomerApiService} from '../../../infrastructure/api/customer-api.service';
import { CollectionsService } from '../../../services/collections.service';

@Component({
  selector: 'app-open-applications',
  templateUrl: './open-applications.component.html',
  styles: [
  ]
})
export class OpenApplicationsComponent extends OnboardingOperations implements OnInit {
  timer = 5000;
  appStatusSelected = 'OPEN';
  searchText = '';
  pageIndex = 1;
  pageSize = 50;
  constructor(
    public applicationService: ApplicationService,
    public documentService: DocumentsService,
    public notification: NzNotificationService,
    public customerService: CustomerService,
    public rentalService: RentalService,
    public customerApiService: CustomerApiService,
    public collectionsService: CollectionsService
  ) {
    super(applicationService, documentService, notification, customerService, rentalService, customerApiService, collectionsService);
  }
  ngOnInit(): void {
    this.fetchOpenedApplications(this.pageIndex, this.pageSize);
    this.fetchDidntTakeCarReasons();
    this.fetchDenyReasons();
    this.fetchDocumentTypes();
  }
  statusSelectedChanged(): void {
    this.fetchApplicationsByStatusAndText(this.appStatusSelected, this.searchText, this.pageIndex, this.pageSize);
  }
  refreshQueryParams(p: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort, filter } = p;
    this.pageSize = pageSize;
    this.pageIndex = pageIndex;
    this.fetchApplicationsByStatusAndText(this.appStatusSelected, this.searchText, this.pageIndex, this.pageSize);
  }
  searchApplication(text: string): void {
    this.searchText = text;
    this.pageIndex = 1;
    this.pageSize = 50;
    this.fetchApplicationsByStatusAndText(this.appStatusSelected, text, this.pageIndex, this.pageSize);
  }

  public getOcrUploadStatus(event: boolean): void{
    if(event) {
      this.isQuickViewerVisible = false;
      this.fetchApplicationsByStatusAndText(this.appStatusSelected, this.searchText, this.pageIndex, this.pageSize);
    }
  }
}
