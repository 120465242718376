import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzMessageModule, NzMessageServiceModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { ApplicationDetailViewerComponent } from './application-detail-viewer/application-detail-viewer.component';
import { AvailableCarsTableComponent } from './available-cars-table/available-cars-table.component';
import { DocumentsQuickViewerComponent } from './documents-quick-viewer/documents-quick-viewer.component';
import { CarGasolineGuageComponentComponent } from './fleet/car-gasoline-guage-component/car-gasoline-guage-component.component';
import { InspectCarModalButtonComponent } from './fleet/inspect-car-modal-button/inspect-car-modal-button.component';
import { DenyApplicationFormComponent } from './forms/applications/deny-application-form/deny-application-form.component';
import { DidntTakeCarFormComponent } from './forms/applications/didnt-take-car-form/didnt-take-car-form.component';
import { UnderwritingUpdateFormComponent } from './forms/applications/underwriting-update-form/underwriting-update-form.component';
import { AddressFormComponent } from './forms/billing/address-form/address-form.component';
import { EditChargeFormComponent } from './forms/billing/edit-charge-form/edit-charge-form.component';
import { NewChargeFormComponent } from './forms/billing/new-charge-form/new-charge-form.component';
import { NewPaymentFormComponent } from './forms/billing/new-payment-form/new-payment-form.component';
import { NewPaymentLinkFormComponent } from './forms/collections/new-payment-link-form/new-payment-link-form.component';
import { BailFormComponent } from './forms/customers/bail-form/bail-form.component';
import { GuarantorComponent } from './forms/customers/guarantor/guarantor.component';
import { PhoneFormComponent } from './forms/customers/phone-form/phone-form.component';
import { UpdateCarInformationFormComponent } from './forms/customers/update-car-information-form/update-car-information-form.component';
import { UpdateCustomerInformationFormComponent } from './forms/customers/update-customer-information-form/update-customer-information-form.component';
import { CreateDocumentFormComponent } from './forms/documents/create-document-form/create-document-form.component';
import { UpdateDocumentFormComponent } from './forms/documents/update-document-form/update-document-form.component';
import { UploadDocumentFormComponent } from './forms/documents/upload-document-form/upload-document-form.component';
import { UploadFileFormComponent } from './forms/documents/upload-file-form/upload-file-form.component';
import { EditPaymentFormComponent } from './forms/edit-payment-form/edit-payment-form.component';
import { CarReceptionComponent } from './forms/fleet/car-reception-component/car-reception.component';
import { ReceptionTypeFormComponent } from './forms/fleet/reception-type-form/reception-type-form.component';
import { EditRentalFormComponent } from './forms/rentals/edit-rental-form/edit-rental-form.component';
import { NewRentalFormComponent } from './forms/rentals/new-rental-form/new-rental-form.component';
import { ReturnCarFormComponent } from './forms/rentals/return-car-form/return-car-form.component';
import { SwitchCarFormComponent } from './forms/rentals/switch-car-form/switch-car-form.component';
import { OpenApplicationsTableComponent } from './open-applications-table/open-applications-table.component';
import { SearchFieldComponent } from './search-field/search-field.component';
import { SurveyComponent } from './survey/survey.component';
import { FleetChargesFormComponent } from './forms/billing/fleet-charges-form/fleet-charges-form.component';
import { NewTokuCustomerComponent } from './forms/new-toku-customer/new-toku-customer.component';

@NgModule({
  declarations: [
    OpenApplicationsTableComponent,
    AvailableCarsTableComponent,
    UploadDocumentFormComponent,
    DidntTakeCarFormComponent,
    DenyApplicationFormComponent,
    UpdateCustomerInformationFormComponent,
    UpdateDocumentFormComponent,
    UploadFileFormComponent,
    ApplicationDetailViewerComponent,
    DocumentsQuickViewerComponent,
    NewRentalFormComponent,
    ReturnCarFormComponent,
    SwitchCarFormComponent,
    NewChargeFormComponent,
    EditChargeFormComponent,
    CreateDocumentFormComponent,
    SearchFieldComponent,
    UnderwritingUpdateFormComponent,
    NewPaymentFormComponent,
    EditPaymentFormComponent,
    NewPaymentLinkFormComponent,
    SurveyComponent,
    InspectCarModalButtonComponent,
    EditRentalFormComponent,
    AddressFormComponent,
    GuarantorComponent,
    BailFormComponent,
    UpdateCarInformationFormComponent,
    PhoneFormComponent,
    CarReceptionComponent,
    CarGasolineGuageComponentComponent,
    ReceptionTypeFormComponent,
    FleetChargesFormComponent,
    NewTokuCustomerComponent
  ],
  exports: [
    OpenApplicationsTableComponent,
    AvailableCarsTableComponent,
    DidntTakeCarFormComponent,
    DenyApplicationFormComponent,
    UpdateCustomerInformationFormComponent,
    ApplicationDetailViewerComponent,
    UploadFileFormComponent,
    NewRentalFormComponent,
    CreateDocumentFormComponent,
    SearchFieldComponent,
    UnderwritingUpdateFormComponent,
    NewChargeFormComponent,
    NewPaymentFormComponent,
    ReturnCarFormComponent,
    EditChargeFormComponent,
    EditPaymentFormComponent,
    NewPaymentLinkFormComponent,
    SurveyComponent,
    InspectCarModalButtonComponent,
    SwitchCarFormComponent,
    EditRentalFormComponent,
    AddressFormComponent,
    GuarantorComponent,
    BailFormComponent,
    UpdateCarInformationFormComponent,
    PhoneFormComponent,
    CarReceptionComponent,
    CarGasolineGuageComponentComponent,
    ReceptionTypeFormComponent,
    FleetChargesFormComponent,
    NewTokuCustomerComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NzTableModule,
    NzSpaceModule,
    NzButtonModule,
    NzDrawerModule,
    NzDescriptionsModule,
    NzDividerModule,
    NzTypographyModule,
    NzFormModule,
    NzInputModule,
    NzModalModule,
    NzSelectModule,
    FormsModule,
    NzUploadModule,
    NzIconModule,
    NzMessageServiceModule,
    NzMessageModule,
    ReactiveFormsModule,
    NzImageModule,
    NzDatePickerModule,
    NzPopconfirmModule,
    NzRadioModule,
    NzToolTipModule,
    NzPageHeaderModule,
    NzCardModule,
    NzPopconfirmModule,
    NzTabsModule,
    NzCheckboxModule,
    NzProgressModule,
    NzDividerModule,
    NzListModule,
  ]
})
export class NaveComponentsModule { }
