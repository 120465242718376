import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

const endpoint = environment.onboarding_endpoint;

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {

  constructor(private http: HttpClient) { }
  fetchAllReservations(): Observable<any> {
    return this.http.get(`${endpoint}reservations`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  fetchAllUsers(): Observable<any> {
    return this.http.get(`${endpoint}users`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  fetchAllProducts(): Observable<any[]> {
    return this.http.get<any[]>(`${endpoint}lookups/products`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  createReservation(payload): Observable<any> {
    return this.http.post(`${endpoint}reservations`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  updateReservation(reservationId, payload): Observable<any> {
    return this.http.put(`${endpoint}reservations/${reservationId}`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
  closeReservation(reservationId, payload): Observable<any> {
    return this.http.post(`${endpoint}reservations/${reservationId}/close`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
}
