<nz-divider nzText="Actualizar conductor"></nz-divider>
<form [formGroup]="updateCustomerForm" (ngSubmit)="onSubmit()">
  <nz-form-item>
    <nz-form-label [nzSpan]="6" nzRequired>Nombre(s)</nz-form-label>
    <nz-form-control nzErrorTip="Por favor ingresa el nombre" nzSpan="15">
      <input type="text" nz-input placeholder="Nombre(s)" formControlName="firstName" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="6" nzRequired>Apellidos</nz-form-label>
    <nz-form-control nzErrorTip="Por favor ingresa los apellidos" nzSpan="15">
      <input type="text" nz-input placeholder="Apellidos" formControlName="lastName" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="6">Licencia</nz-form-label>
    <nz-form-control nzErrorTip="Por favor ingresa la licencia" nzSpan="15">
      <input type="text" nz-input placeholder="Licencia" formControlName="license" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="6">Email</nz-form-label>
    <nz-form-control nzErrorTip="Por favor ingresa el email" nzSpan="15">
      <input type="email" nz-input pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" placeholder="Correo"
        formControlName="email" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="6">Email de facturación</nz-form-label>
    <nz-form-control nzErrorTip="Por favor ingresa el billing email" nzSpan="15">
      <input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" nz-input placeholder="Correo"
        formControlName="billingEmail" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="6">Teléfono</nz-form-label>
    <nz-form-control nzErrorTip="Por favor ingresa un número de teléfono valido" nzSpan="15">
      <input type="text" nz-input placeholder="Teléfono" formControlName="phone" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="6">CURP</nz-form-label>
    <nz-form-control nzErrorTip="Por favor introduce un CURP válido" nzSpan="15">
      <input type="text" nz-input placeholder="Curp" formControlName="curp"
        onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || (event.charCode >= 65 && event.charCode <= 90) || event.charCode === 209"
        regex="^[A-Z]{1}[AEIOU]{1}[A-Z]{2}
        [0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])
        [HM]{1}
        (AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)
        [B-DF-HJ-NP-TV-Z]{3}
        [0-9A-Z]{1}
        [0-9]{1}$" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="6">RFC</nz-form-label>
    <nz-form-control nzErrorTip="Por favor introduce un RFC válido" nzSpan="15">
      <input type="text"
        regex="/^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/"
        onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || (event.charCode >= 65 && event.charCode <= 90) || event.charCode === 209"
        nz-input placeholder="RFC" formControlName="rfc" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="6">Régimen Fiscal</nz-form-label>
    <nz-form-control nzErrorTip="Por favor selecciona una razón" nzSpan="15">
      <nz-select nzSize="default" nzPlaceHolder="Selecciona una razón" formControlName="taxRegime">
        <nz-option [nzValue]="625"
          nzLabel="Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas"></nz-option>
        <nz-option [nzValue]="605" nzLabel="Sueldos y Salarios e Ingresos Asimilados a Salarios"></nz-option>
        <nz-option [nzValue]="612" nzLabel="Personas Físicas con Actividades Empresariales y Profesionales"></nz-option>
        <nz-option [nzValue]="606" nzLabel="Arrendamiento"></nz-option>
        <nz-option [nzValue]="608" nzLabel="Demás ingresos"></nz-option>
        <nz-option [nzValue]="621" nzLabel="Incorporación Fiscal"></nz-option>
        <nz-option [nzValue]="610"
          nzLabel="Residentes en el Extranjero sin Establecimiento Permanente en México"></nz-option>
        <nz-option [nzValue]="611" nzLabel="Ingresos por Dividendos (socios y accionistas)"></nz-option>
        <nz-option [nzValue]="614" nzLabel="Ingresos por intereses"></nz-option>
        <nz-option [nzValue]="616" nzLabel="Sin obligaciones fiscales"></nz-option>
        <nz-option [nzValue]="622" nzLabel="Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras"></nz-option>
        <nz-option [nzValue]="629"
          nzLabel="De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales"></nz-option>
        <nz-option [nzValue]="630" nzLabel="Enajenación de acciones en bolsa de valores"></nz-option>
        <nz-option [nzValue]="615" nzLabel="Régimen de los ingresos por obtención de premios"></nz-option>
        <nz-option [nzValue]="626" nzLabel="Régimen Simplificado de Confianza"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="6">Uso del cfdi</nz-form-label>
    <nz-form-control nzErrorTip="Por favor selecciona una razón" nzSpan="15">
      <nz-select nzSize="default" nzPlaceHolder="Selecciona una razón" formControlName="cfdiUse">
        <nz-option [nzValue]="'G01'" nzLabel="Adquisición de mercancías"></nz-option>
        <nz-option [nzValue]="'G02'" nzLabel="Devoluciones, descuentos o bonificaciones"></nz-option>
        <nz-option [nzValue]="'G03'" nzLabel="Gastos en general"> </nz-option>
        <nz-option [nzValue]="'I01'" nzLabel="Construcciones"></nz-option>
        <nz-option [nzValue]="'I02'" nzLabel="Mobiliario y equipo de oficina por inversiones"></nz-option>
        <nz-option [nzValue]="'I03'" nzLabel="Equipo de transporte"></nz-option>
        <nz-option [nzValue]="'I04'" nzLabel="Equipo de cómputo y accesorios"></nz-option>
        <nz-option [nzValue]="'I05'" nzLabel="Dados, troqueles, moldes, matrices y herramental"></nz-option>
        <nz-option [nzValue]="'I06'" nzLabel="Comunicaciones telefónicas"></nz-option>
        <nz-option [nzValue]="'I07'" nzLabel="Comunicaciones satelitales"></nz-option>
        <nz-option [nzValue]="'I08'" nzLabel="Otra maquinaria y equipo"></nz-option>
        <nz-option [nzValue]="'D01'" nzLabel="Honorarios médicos, dentales y gastos hospitalarios."></nz-option>
        <nz-option [nzValue]="'D02'" nzLabel="Gastos médicos por incapacidad o discapacidad"></nz-option>
        <nz-option [nzValue]="'D03'" nzLabel="Gastos funerales"></nz-option>
        <nz-option [nzValue]="'D04'" nzLabel="Donativos"></nz-option>
        <nz-option [nzValue]="'D05'"
          nzLabel="Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)."></nz-option>
        <nz-option [nzValue]="'D06'" nzLabel="Aportaciones voluntarias al SAR."></nz-option>
        <nz-option [nzValue]="'D07'" nzLabel="Primas por seguros de gastos médicos."></nz-option>
        <nz-option [nzValue]="'D08'" nzLabel="Gastos de transportación escolar obligatoria."></nz-option>
        <nz-option [nzValue]="'D09'"
          nzLabel="Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones."></nz-option>
        <nz-option [nzValue]="'D10'" nzLabel="Pagos por servicios educativos (colegiaturas)"></nz-option>
        <nz-option [nzValue]="'CP01'" nzLabel="Pagos"></nz-option>
        <nz-option [nzValue]="'CN01'" nzLabel="Nómina"></nz-option>
        <nz-option [nzValue]="'S01'" nzLabel="Sin Efectos Fiscales"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="6">Notas</nz-form-label>
    <nz-form-control nzErrorTip="Por favor selecciona una razón" nzSpan="15">
      <textarea nz-input placeholder="" formControlName="note"></textarea>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control [nzSpan]="12" [nzOffset]="6">
      <button type="submit" nz-button nzType="primary" nzBlock nzSize="large"
        [disabled]="!updateCustomerForm.valid">Enviar</button>
    </nz-form-control>
  </nz-form-item>
</form>
<nz-tabset nzSize="large" [nzCentered]="true">
  <nz-tab nzTitle="Direcciones">
    <app-address-form [customer]="this.customer" (addressFormChanges)="getFormChanges($event)">
    </app-address-form>
  </nz-tab>
  <nz-tab nzTitle="Fiador">
    <app-guarantor [customerId]="customer.id" (closeModal)="getEventCloseUpdateCustomerModal($event)"></app-guarantor>
  </nz-tab>
  <nz-tab nzTitle="Afianzadora">
    <app-bail-form [customerId]="customer.id" (closeModal)="getEventCloseUpdateCustomerModal($event)"></app-bail-form>
  </nz-tab>
  <nz-tab nzTitle="Teléfonos">
    <app-phone-form [customerId]="customer.id" (phoneFormChanges)="getFormChanges($event)"></app-phone-form>
  </nz-tab>
</nz-tabset>