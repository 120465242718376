import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-cancel-lease-to-own-agreement',
  templateUrl: './cancel-lease-to-own-agreement.component.html',
  styleUrls: ['./cancel-lease-to-own-agreement.component.css']
})
export class CancelLeaseToOwnAgreementComponent implements OnInit {
  leaseToOwnAgreementId: string;
  constructor(
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(
      params => {
        this.leaseToOwnAgreementId = params.id;
      }
    );
  }

  ngOnInit(): void {
  }

}
