import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import {CarService} from "../../../../services/car.service";
import {RentalService} from "../../../../services/rental.service";
import {NotificationService} from "../../../../services/notification.service";

@Component({
  selector: 'app-switch-car-form',
  templateUrl: './switch-car-form.component.html',
  styles: [
  ]
})
export class SwitchCarFormComponent implements OnInit {
  @Input()isDisabled = false;
  @Input()rentalId;
  @Output()formSubmitted: EventEmitter<any> = new EventEmitter<any>();
  form: FormGroup;
  isFormVisible = false;
  availableCars = [];
  constructor(private carService: CarService,
              private rentalService: RentalService,
              private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      switchDate: new FormControl(null, [Validators.required]),
      switchCarId: new FormControl(null, [Validators.required]),
      note: new FormControl()
    });
  }
  onSubmit(): void {
    if (this.form.valid) {
      this.rentalService.applySwitch(this.rentalId, {
        switchCarId: this.form.controls.switchCarId.value,
        switchDate: this.form.controls.switchDate.value,
        note: this.form.controls.note.value
      }).subscribe(
        (res) => {
          this.notificationService.createNotification('success', 'Switch Applied', 'Switch was applied successfully');
          this.closeForm();
        }
      );
    }
  }
  fetchAvailableCars(): void {
    this.carService.fetchCarsByStatus('AVAILABLE').subscribe(
      (res) => {
        this.availableCars = res;
      }
    );
  }
  disabledDate = (current: Date): boolean => {
    // Can not select days before 7 days. Can not select days after two days
    const today = new Date();
    const diff = differenceInCalendarDays(current, today);
    return !(diff > -40 && diff < 2);
  }
  openForm(): void {
    this.isFormVisible = true;
    this.fetchAvailableCars();
  }
  closeForm(): void {
    this.isFormVisible = false;
    this.form.reset();
  }
}
